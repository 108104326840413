<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t("competitionManagement") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-text class="px-0"></v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- name -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("competition") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :value="$i18n.locale ? formData.nameEn : formData.nameTc"
              color="#2c76b8"
              clearable
              disabled
            />
          </v-col>
        </v-row>
        <!-- refNo -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("refNo") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.refNo"
              color="#2c76b8"
              clearable
              disabled
            />
          </v-col>
        </v-row>
        <!-- teamSize -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("applicants") }}
            </h2>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <v-text-field
              v-model="formData.teamSize"
              color="#2c76b8"
              clearable
              disabled
            />
          </v-col>
        </v-row> -->
        <v-row>
          <v-col
            :cols="12 / data.competitionApplicantObjList.length"
            v-for="(ca, n) in data.competitionApplicantObjList"
            :key="ca.email"
            class="d-flex flex-column"
          >
            <v-card class="flex-grow-1" outlined>
              <v-container>
                <span class="d-flex">
                  <v-icon class="mr-2" color="grey darken-1">
                    mdi-account
                  </v-icon>
                  <div class="text-h6 font-weight-bold">
                    {{ $t("applicant") }} {{ n + 1 }}
                  </div>
                </span>

                <div class="text-body-1">
                  {{ ca.surname }} {{ ca.givenName }}
                </div>

                <div class="text-body-1" v-if="ca.chiName != undefined">
                  {{ ca.chiName }}
                </div>

                <div class="text-body-1">
                  {{ ca.schoolName }}
                </div>

                <div class="text-body-1">
                  {{ $t("class") }} {{ ca.yearGroup }} {{ ca.schoolClass }}
                </div>

                <div
                  class="text-body-1"
                  v-if="$store.state.user.userGroup == 'SYSADMIN'"
                >
                  {{ ca.email }}
                </div>

                <div
                  class="text-body-1"
                  v-if="$store.state.user.userGroup == 'SYSADMIN'"
                >
                  {{ ca.mobileNo }}
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <!-- material -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("material") }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <!-- <iframe
              v-if="fileName == undefined || !fileName.endsWith('.pdf')"
              style="height: 50vh; width: 100%"
              :src="fileContent"
            /> -->

            <v-progress-circular
              v-if="fileContent == undefined"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <video-player
              class="video-player-box vjs-big-play-centered"
              v-else-if="fileName == undefined || !fileName.endsWith('.pdf')"
              :options="{
                // videojs options
                height: 480,
                width: 720,
                muted: true,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                  {
                    type: 'video/mp4',
                    src: 'data:video/mp4;base64,' + fileContent,
                  },
                ],
              }"
            ></video-player>
            <vue-pdf-app
              v-else
              style="height: 50vh; width: 100%"
              :pdf="base64ToArrayBuffer(fileContent)"
              :config="config"
            ></vue-pdf-app>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn class="mr-3" @click="showMaterial" color="primary">
              {{ $t("material") }}
            </v-btn>

            <v-btn
              @click="showWrittenDescn"
              color="primary"
              :disabled="b64WrittenDescn == undefined"
            >
              {{ $t("writtenDescription2") }}
            </v-btn>
          </v-col>
        </v-row>

        <template v-if="$store.state.user.userGroup != 'SYSADMIN'">
          <v-row>
            <v-col>
              <h2>
                {{
                  $i18n.locale == "en"
                    ? "25% - Design Thinking, Creativity, Innovation & Effectiveness of Solution"
                    : "25% - 設計思維、創意、創新和建議⽅案的有效性"
                }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-3 mr-3">
              <v-slider
                v-model="formData.score1"
                thumb-label="always"
                :max="25"
                class="align-center pt-6"
                track-color="grey lighten-2"
              >
              </v-slider>
            </v-col>
          </v-row>
          <!-- category_no -->
          <v-row >
            <v-col>
              <h2>
                {{
                  $i18n.locale == "en"
                   ? "25% - Clarity of Presentation & Completeness of Submission"
                    : "25% - 陳述的清晰性及遞交申請的完整性"
                }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-3 mr-3">
              <v-slider
                v-model="formData.score2"
                thumb-label="always"
                :max="25"
                class="align-center pt-6"
                track-color="grey lighten-2"
              >
              </v-slider>
            </v-col>
          </v-row>
          <!-- category_no -->
          <v-row>
            <v-col>
              <h2>
                {{
                  $i18n.locale == "en"
                    ? "25% - Written Description of Solution Within Word/Character Limit"
                    : "25% - 建議⽅案的書⾯說明不應超出字數限制"
                }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-3 mr-3">
              <v-slider
                v-model="formData.score3"
                thumb-label="always"
                max="25"
                class="align-center pt-6"
                track-color="grey lighten-2"
              >
              </v-slider>
            </v-col>
          </v-row>
          <!-- category_no -->
          <v-row>
            <v-col>
              <h2>
                {{
                  $i18n.locale == "en"
                    ? "25% - Incorporation of ABCD Elements, Privacy-by-design, Cybersecurity by Design and Open Data"
                    : "25% - 引入 ABCD 元素、隱私設計、網絡安全設計和開放數據"
                }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mt-3 mr-3">
              <v-slider
                v-model="formData.score4"
                thumb-label="always"
                :max="25"
                class="align-center pt-6"
                track-color="grey lighten-2"
              >
              </v-slider>
            </v-col>
          </v-row>  
        </template>
        <!-- category_no -->
        
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="true"
        @click="uploadFile"
        :loading="isLoading"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VuePdfApp,
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,

      isLoading: false,
      search: null,

      config: {
        sidebar: {
          viewThumbnail: false,
          viewOutline: false,
          viewAttachments: false,
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryDownload: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: false,
          pageRotateCcw: false,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
          documentProperties: false,
        },
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
            previous: false,
            next: false,
            pageNumber: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: false,
            zoomIn: false,
            scaleSelectContainer: false,
          },
        },
        errorWrapper: false,
      },

      valid: false,
      requiredRules: [(v) => !!v || this.$t("requiredField")],
      marksRules: [(v) => parseInt(v) <= 25 || " "],

      file: null,
      formData: {
        careerNameEn: "",
        careerNameTc: "",

        partnership: {},
      },
      selected: null,
      partnershipList: [],

      b64Material: null,
      materialFileName: null,
      b64WrittenDescn: null,
      writtenDescnFileName: null,
      fileName: null,
      fileContent: null,
    };
  },
  created() {
    this.fetchPartnership();
    console.log("created formData", this.formData);

    this.formData = Object.assign(this.formData, this.data);
    if (this.data.imageBase64) {
      this.b64ToFile(this.data.imageBase64, (file) => {
        this.file = file;
      });
    }
    if (this.data.deadlineDate) {
      this.formData.deadlineDate = this.formatDate(
        this.data.deadlineDate,
        "YYYY-MM-DD"
      );
      console.log("this.formData.deadlineDate", this.formData.deadlineDate);
    }
    // handle b64Material
    // if(this.formData.b64Material){
    //   if( !this.formData.b64Material.includes("data:") ){
    //     if( this.formData.materialFileName.includes(".pdf") ){
    //       this.formData.b64Material = "data:application/pdf;base64," + this.formData.b64Material;
    //     } else {
    //       let temp = this.formData.b64Material.split('.');
    //       let type = temp[temp.length - 1];
    //       this.formData.b64Material = `data:video/${type};base64,` + this.formData.b64Material;
    //     }

    //     console.log(this.formData.b64Material)
    //   }
    // }
  },
  mounted() {
    this.callApi(
      this.$constant.GET_COMPETITION_ENROLLMENT_MATERIAL_URL,
      { id: this.data.competitionEnrollmentId },
      (resultData) => {
        this.b64Material = resultData.competitionEnrollmentObj.b64Material;
        this.b64WrittenDescn =
          resultData.competitionEnrollmentObj.b64WrittenDescn;
        this.materialFileName =
          resultData.competitionEnrollmentObj.materialFileName;
        this.writtenDescnFileName =
          resultData.competitionEnrollmentObj.writtenDescnFileName;

        this.showMaterial();

        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.$root.$emit("popSnackBar", "generalError", "error");
      }
    );
  },
  methods: {
    fetchPartnership() {
      console.log("fetchPartnership");
      this.callApi(
        this.$constant.GET_PARTNERSHIP_LIST_URL,
        {},
        (resultData) => {
          this.partnershipList = resultData.partnershipList.map((item) => {
            return this.objectDecode(item);
          });

          console.log(this.partnershipList);

          this.isLoading = false;
        },
        (e) => {
          console.log(e);
          this.isLoading = false;
        }
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    closeDialog(evt) {
      evt.preventDefault();
      this.$emit("close");
    },
    uploadFile() {
      this.validate();
      if (this.valid == false) {
        return;
      }
      this.isLoading = true;

      let formData = this.formData;

      var requestData = {
        data: formData,
      };

      this.callApi(
        this.$constant.UPDATE_COMPETITION_SCORE_URL,
        requestData,
        (resultData) => {
          console.log(resultData);
          this.isLoading = false;
          this.$emit("close");
        },
        () => {
          console.log("2");
          this.isLoading = false;
          this.$emit("message", this.$t("fail"));
        }
      );
    },
    showMaterial() {
      this.fileContent = this.b64Material;
      this.fileName = this.materialFileName;
    },
    showWrittenDescn() {
      this.fileContent = this.b64WrittenDescn;
      this.fileName = this.writtenDescnFileName;
    },
  },
};
</script>

<style lang="scss">
.pdf-app #sidebarContainer{
  display: none;
}

.pdf-app #toolbarContainer{
  display: none;
}

html[dir=ltr] .pdf-app #outerContainer.sidebarOpen #viewerContainer:not(.pdfPresentationMode) {
  left: 0 !important;
  bottom: 32px;
}
</style>