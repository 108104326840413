<template>
  <div id="app-bar">
    <div :class="isMobile ? 'mobile' : ''">
      <v-app-bar app color="white" :height="isMobile ? (isHome ? 80 : 80) : 80">
        <v-app-bar-nav-icon v-show="isBackend" @click="$emit('toggle')" />
        <!-- desktop -->
        <v-container
          v-if="!displayDrawer()"
          style="max-width: 1400px; padding: 0"
        >
          <v-row
            style="max-width: 100vw"
            class="pt-0 pb-0"
            :justify="!isMobile ? 'space-between' : 'space-between'"
          >
            <v-col cols="auto" v-if="displayDrawer()">
              <v-app-bar-nav-icon
                color="rgb(44, 118, 184)"
                @click.stop="drawer = !drawer"
                v-if="!isBackend"
              ></v-app-bar-nav-icon>
            </v-col>

            <v-col align-self="center" :cols="!isMobile ? 'auto' : '8'">
              <v-col cols="auto">
                <router-link to="/">
                  <v-img
                    class="shrink mt-1"
                    contain
                    :src="require('@/assets/images/ampower_logo_wide.png')"
                    transition="scale-transition"
                    :width="isMobile ? '100%' : 220"
                  />
                </router-link>
              </v-col>
            </v-col>

            <!-- <v-spacer v-if="isHome || isBackend" /> -->
            <!-- <v-spacer></v-spacer> -->

            <v-col align-self="center" :cols="!isMobile ? 'auto' : 'auto'">
              <v-btn
                v-if="isHome && !displayDrawer()"
                text
                color="#f44336"
                @click="showPressRelease"
              >
                {{ $t("news") }}
              </v-btn>

              <v-btn
                v-if="isHome && !displayDrawer()"
                text
                color="#2c76b8"
                @click="showEnquiry"
              >
                {{ $t("enrollHistory") }}
              </v-btn>

              <v-btn
                v-if="isHome && !displayDrawer()"
                text
                color="#2c76b8"
                @click="showAwardScheme"
              >
                {{ $t("masterClassesAwardScheme") }}
              </v-btn>

              <v-btn
                v-if="isHome && !displayDrawer() && $constant.isCareerExperienceEnabled"
                text
                color="#2c76b8"
                @click="onMenuBtnClick('Partnership')"
              >
                {{ $t("partnership") }}
              </v-btn>

              <v-btn
                v-if="isHome && !displayDrawer()"
                text
                color="#2c76b8"
                @click="showAboutUs"
              >
                {{ $t("aboutUs") }}
              </v-btn>

              <v-btn
                v-if="isHome && !displayDrawer()"
                text
                color="#2c76b8"
                @click="showFAQ"
              >
                {{ $t("faq") }}
              </v-btn>

              <v-btn
                text
                :color="$i18n.locale == 'en' ? '#2c76b8' : '#ccc'"
                @click="changeLanguage('en')"
              >
                EN
              </v-btn>

              <v-btn
                text
                :color="$i18n.locale == 'tc' ? '#2c76b8' : '#ccc'"
                @click="changeLanguage('tc')"
              >
                繁
              </v-btn>

              <v-badge
                v-if="!$constant.isStaffApp && isHome"
                bordered
                color="orange lighten-1"
                :content="total"
                :value="total > 0"
                overlap
              >
                <v-btn id="cart" color="#2c76b8" depressed icon @click="applyCourse">
                  <v-icon>mdi-cart</v-icon>
                </v-btn>
              </v-badge>

              <v-dialog
                v-if="
                  !isMobile &&
                  $constant.isStaffApp &&
                  $store.state.user.userId == ''
                "
                v-model="dialog"
                width="unset"
                persistent
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text color="#2c76b8" v-bind="attrs" v-on="on">
                    <span class="mr-2">{{ $t("userLogin") }}</span>
                    <v-icon>mdi-login-variant</v-icon>
                  </v-btn>
                </template>
                <Login @close="dialog = false" />
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
        <!-- mobile -->
        <v-container v-else>
          <v-row justify="center" align="center">
            <v-app-bar-nav-icon
              color="rgb(44, 118, 184)"
              @click.stop="drawer = !drawer"
              v-if="currentRouteName != 'Dashboard'"
            ></v-app-bar-nav-icon>

            <router-link to="/">
              <v-img
                class="shrink mt-1"
                contain
                :src="require('@/assets/images/ampower_logo_wide.png')"
                transition="scale-transition"
                :width="isMobile ? '45vw' : 220"
                max-width="280"
              />
            </router-link>

            <v-spacer></v-spacer>

            <v-btn
              text
              :color="$i18n.locale == 'en' ? '#2c76b8' : '#ccc'"
              @click="changeLanguage('en')"
            >
              EN
            </v-btn>

            <v-btn
              text
              :color="$i18n.locale == 'tc' ? '#2c76b8' : '#ccc'"
              @click="changeLanguage('tc')"
            >
              繁
            </v-btn>

            <v-badge
              v-if="!$constant.isStaffApp && isHome"
              bordered
              color="orange lighten-1"
              :content="total"
              :value="total > 0"
              overlap
            >
              <v-btn id="cart" color="#2c76b8" depressed icon @click="applyCourse">
                <v-icon>mdi-cart</v-icon>
              </v-btn>
            </v-badge>

            <v-dialog
              v-if="
                !isMobile &&
                $constant.isStaffApp &&
                $store.state.user.userId == ''
              "
              v-model="dialog"
              width="unset"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="#2c76b8" v-bind="attrs" v-on="on">
                  <span class="mr-2">{{ $t("userLogin") }}</span>
                  <v-icon>mdi-login-variant</v-icon>
                </v-btn>
              </template>
              <Login @close="dialog = false" />
            </v-dialog>
          </v-row>
        </v-container>
      </v-app-bar>

      <!-- drawer -->
      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        style="min-width: 250px"
        :width="isMobile ? '320' : 320"
      >
        <v-list nav dense>
          <div class="d-flex align-center justify-center mt-4 mb-6">
            <router-link to="/">
              <v-img
                class="shrink"
                contain
                :src="require('@/assets/images/ampower_logo_wide.png')"
                transition="scale-transition"
                width="250"
              />
            </router-link>
          </div>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-btn
              text
              class="justify-start mb-2"
              color="#f44336"
              @click="showPressRelease"
            >
              {{ $t("news") }}
            </v-btn>

            <v-btn
              text
              class="justify-start mb-2"
              color="#2c76b8"
              @click="showEnquiry"
              block
            >
              {{ $t("enrollHistory") }}
            </v-btn>

            <v-btn
              text
              class="justify-start mb-2"
              color="#2c76b8"
              @click="showAwardScheme"
              block
            >
              {{ $t("masterClassesAwardScheme") }}
            </v-btn>

            <v-btn
              text
              class="justify-start mb-2"
              color="#2c76b8"
              @click="onMenuBtnClick('Partnership')"
              block
            >
              {{ $t("partnership") }}
            </v-btn>

            <v-btn
              text
              class="justify-start mb-2"
              color="#2c76b8"
              @click="showAboutUs"
              block
            >
              {{ $t("aboutUs") }}
            </v-btn>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<style lang="scss">
#app-bar {
  .v-btn {
    padding: 0 12px !important;
  }
  .mobile {
    .v-toolbar__content {
      padding: 0 !important;
    }
    .v-btn {
      padding: 0 6px !important;
      min-width: 36px !important;
    }
  }
}
</style>

<script>
import Login from "../components/Login";

export default {
  props: ["classData", "isHome", "isBackend", "total", "isAboutUs"],
  components: {
    Login: Login,
  },
  data() {
    return {
      dialog: false,
      detailsDialog: false,
      index: 0,
      drawer: false,
      window: {
        width: 0,
        height: 0,
      },
      group: null,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    displayDrawer() {
      const windows_width = this.window.width;
      if (this.$i18n.locale == "en") {
        if (windows_width < 1186) {
          return true;
        }
      } else {
        if (windows_width < 960) {
          return true;
        }
      }
      return false;
    },
    changeLanguage: function (lang) {
      this.$store.commit("changeLang", lang);

      if (
        (this.$store.state.user.userId != "" || this.isAboutUs) &&
        this.$router.currentRoute.name != "Enquiry"
      ) {
        this.$i18n.locale = lang;
      } else {
        // For reloading recaptcha
        location.reload();
      }
    },
    applyCourse() {
      if (this.total == 0) {
        return;
      }

      this.$store.commit("clearCourses");

      for (var i in this.classData) {
        if (this.classData[i].checked) {
          this.$store.commit("addCourses", this.classData[i]);
        }
      }

      this.$router.push({ path: "/Apply" });
    },
    showAboutUs() {
      this.$router.push({ path: "/AboutUs" });
    },
    showAwardScheme() {
      this.$router.push({ path: "/AwardScheme" });
    },
    showEnquiry() {
      this.$router.push({ path: "/Enquiry" });
    },
    showFAQ() {
      this.$router.push({ path: "./FAQ" });
    },
    showPressRelease() {
      this.$router.push({ path: "./PressRelease" });
    },
    onMenuBtnClick(name) {
      this.$router.push({ name });
    },
  },
};
</script>