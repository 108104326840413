<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-card-title class="text-uppercase">
        {{ $t('confirm') }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('cancel')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <img :src="competition.imageBase64" 
              style="object-fit: contain; max-width: 100%;"/>

            <!-- <div class="text-center">
              {{ $t('category') + ' ' + competition.categoryNo }}
            </div> -->

            <p class="text-center text-h5 font-weight-bold">
              {{ $i18n.locale == 'en' ? competition.nameEn : competition.nameTc }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-for="(ca, n) in caList" :key="ca.email" :cols="isMobile ? 12 : 6" class="d-flex flex-column">
            <v-card class="flex-grow-1">
              <v-container>
                <span class="d-flex">
                  <v-icon class="mr-2" color="grey darken-1">
                    mdi-account
                  </v-icon>
                  <div class="text-h6 font-weight-bold">
                    {{ $t('applicant') }} {{ n + 1 }}
                  </div>
                </span>

                <div class="text-body-1">
                  {{ ca.surname }} {{ ca.givenName }}
                </div>

                <div class="text-body-1" v-if="ca.chiName != undefined">
                  {{ ca.chiName }}
                </div>

                <div class="text-body-1">
                  {{ ca.schoolName }}
                </div>

                <div class="text-body-1">
                  {{ $t('class') }} {{ ca.yearGroup }} {{ ca.schoolClass }}
                </div>

                <div class="text-body-1">
                  {{ ca.email }}
                </div>

                <div class="text-body-1">
                  {{ ca.mobileNo }}
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="orange lighten-1" dark @click="$emit('confirm')">
        {{ $t('confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['caList', 'competition'],
}
</script>