<template>
  <v-card-text>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="dataList"
        :items-per-page="5"
        class="elevation-1"
        :loading="isLoading"
        :footer-props="{
          'items-per-page-text': this.$t('rowsPerPage') + ':',        
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading-text="$t('loading') + '...'"
        :no-results-text="$t('noRecords')"
        :no-data-text="$t('noRecords')"
      >
        <template v-slot:[`item.loginDate`]="{ item }">
          <span>{{ formatDate(item.loginDate, 'YYYY-MM-DD hh:mm:ss a') }}</span>
        </template>
        <template v-slot:[`item.loginSuccess`]="{ item }">
          <span class="green--text text--accent-4" v-if="item.loginSuccess">
            {{ $t('succeed') }}
          </span>
          <span style="color: #FE253D !important; caret-color: #FE253D !important;" v-else>
            {{ $t('failed') }}
          </span>
        </template>
      </v-data-table>

      <v-subheader>
        * {{ $t('limitRecords', [100]) }}
      </v-subheader>
    </v-container>
  </v-card-text>
</template>

<script>
import DisplayText from '@/components/DisplayText'

export default {
  props: ['appUserId'],
  components: {
    DisplayText,
  },
  data() {
    return {
      isLoading: false,
      dataList: [],
      sortBy: 'loginDate',
      sortDesc: true,
    }
  },
  computed: {
    headers: function() {
      return [
        {
          text: this.$t('time'),
          align: 'start',
          value: 'loginDate',
        },
        { 
          text: this.$t('result'), 
          value: 'loginSuccess' 
        },
      ]
    }
  },
  methods: {
    getLoginHistory() {
      this.isLoading = true

      var requestData = {
        appUserId: this.appUserId,
      }

      this.callApi(this.$constant.GET_LOGIN_HISTORY_BY_ID_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.loginHistoryList
          if (this.dataList == null) {
            this.dataList = []
          }

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    }
  },
  mounted() {
    this.getLoginHistory()
  },
  watch: {
    appUserId: function(newVal) {
      this.appUserId = newVal
      this.getLoginHistory()
    }
  }
}
</script>