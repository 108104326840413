const moment = require("moment");
import "moment/locale/zh-hk";
import Compressor from "compressorjs";
import {
  CLOSING_CEREMONY_ID_UAT,
  CLOSING_CEREMONY_ID,
  isLocalEnv,
  isUAT,
} from "../../constants/constants";

export const utilityMixins = {
  computed: {
    dateLocale: function () {
      switch (this.$i18n.locale) {
        case "tc":
          return "zh-HK";
        case "sc":
          return "zh";
        default:
          return "en-US";
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return true;
        case "md":
        case "lg":
        case "xl":
          return false;
      }
    },
    isTablet() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
        case "md":
          return true;
        case "lg":
        case "xl":
          return false;
      }
    },
    isLocalEnv() {
      if (window.location.host.includes("localhost")) {
        return true;
      }
      return isLocalEnv;
    },
    closingCeremonyID() {
      if (isUAT) {
        return CLOSING_CEREMONY_ID_UAT;
      } else {
        return CLOSING_CEREMONY_ID;
      }
    },
  },
  filters: {
    truncate(value, length) {
      return value.length > length ? value.substr(0, length) + "..." : value;
    },
  },
  methods: {
    b64ToFile(b64, onSuccess, onFailure) {
      let url = "";
      if (b64.includes("data:image")) {
        url = b64;
      } else {
        url = `data:image/png;base64,${b64}`;
      }
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", { type: "image/png" });

          onSuccess && onSuccess(file);
        })
        .catch((e) => {
          console.log(e);
          onFailure && onFailure(e);
        });
    },
    objectEncode(obj, escapeKeys = []) {
      let _obj = Object.assign({}, obj);
      if (typeof _obj == "object") {
        Object.keys(_obj).forEach(function (key, index) {
          if (
            _obj[key] != null &&
            typeof _obj[key] == "string" &&
            !escapeKeys.includes(key)
          ) {
            _obj[key] = encodeURI(_obj[key]);
          }
        });
      }
      return _obj;
    },
    objectDecode(obj, escapeKeys = []) {
      let _obj = Object.assign({}, obj);
      if (typeof _obj == "object") {
        Object.keys(_obj).forEach(function (key, index) {
          if (
            _obj[key] != null &&
            typeof _obj[key] == "string" &&
            !escapeKeys.includes(key)
          ) {
            _obj[key] = decodeURI(_obj[key]);
          }
        });
      }
      return _obj;
    },
    isEmptyString(s) {
      return s == undefined || s == null || s == "";
    },
    isValidURL(s) {
      try {
        new URL(s);
      } catch (_) {
        return false;
      }

      return true;
    },
    convertToJSONDate(strDate) {
      var splitted = strDate.split("-");
      var dt = new Date(splitted[0], splitted[1] - 1, splitted[2]);
      return dt;
    },
    formatDate(timestamp, format) {
      moment.locale(this.$i18n.locale == "en" ? "en" : "zh-hk");
      return moment(timestamp).format(format);
    },
    compress(image, successCallBack, failCallBack) {
      if (!image) {
        return;
      }

      new Compressor(image, {
        quality: 0.6,
        convertSize: 100000,
        maxWidth: 1024,
        success(result) {
          successCallBack(result);
        },
        error(err) {
          failCallBack();
        },
      });
    },
    readCSV(fields, content) {
      var lines = content.split("\n");

      var dataList = [];
      // var fields = []
      var data = {};

      for (var line in lines) {
        if (line == 0) {
          // var tmpField = lines[0].split(',')
          // for (var i in tmpField) {
          //   fields.push(tmpField[i].trim())
          // }

          continue;
        }

        if (lines[line] == "") {
          continue;
        }

        data = {};

        var values = lines[line].split(",");

        for (var i in fields) {
          this.$set(data, fields[i], values[i] == "" ? null : values[i].trim());
        }

        dataList.push(data);
      }

      return dataList;
    },
    CSVToArray(CSV_string, delimiter) {
      delimiter = delimiter || ","; // user-supplied delimeter or default comma

      var pattern = new RegExp( // regular expression to parse the CSV values. // Delimiters:
        "(\\" +
          delimiter +
          "|\\r?\\n|\\r|^)" +
          // Quoted fields.
          '(?:"([^"]*(?:""[^"]*)*)"|' +
          // Standard fields.
          '([^"\\' +
          delimiter +
          "\\r\\n]*))",
        "gi"
      );

      var rows = [[]]; // array to hold our data. First row is column headers.
      // array to hold our individual pattern matching groups:
      var matches = false; // false if we don't find any matches
      // Loop until we no longer find a regular expression match
      while ((matches = pattern.exec(CSV_string))) {
        var matched_delimiter = matches[1]; // Get the matched delimiter
        // Check if the delimiter has a length (and is not the start of string)
        // and if it matches field delimiter. If not, it is a row delimiter.
        if (matched_delimiter.length && matched_delimiter !== delimiter) {
          // Since this is a new row of data, add an empty row to the array.
          rows.push([]);
        }
        var matched_value;
        // Once we have eliminated the delimiter, check to see
        // what kind of value was captured (quoted or unquoted):
        if (matches[2]) {
          // found quoted value. unescape any double quotes.
          matched_value = matches[2].replace(new RegExp('""', "g"), '"');
        } else {
          // found a non-quoted value
          matched_value = matches[3];
        }
        // Now that we have our value string, let's add
        // it to the data array.
        rows[rows.length - 1].push(matched_value);
      }
      return rows; // Return the parsed data Array
    },
    checkAccessRight(f) {
      this.checkAccessRight(f, null);
    },
    checkAccessRight(f, right) {
      if (
        this.$store.state.user == null ||
        this.$store.state.user == undefined
      ) {
        return false;
      }

      var accessRightList = this.$store.state.user.accessRightList;

      var accessRight = accessRightList.filter((func) => func.funcCode === f);
      if (accessRight.length == 0) {
        return false;
      }

      if (right == null) {
        return true;
      }

      switch (right.toLowerCase()) {
        case "read":
          return accessRight[0].canRead;
        case "add":
          return accessRight[0].canAdd;
        case "update":
          return accessRight[0].canUpdate;
        case "delete":
          return accessRight[0].canDelete;
      }

      return false;
    },
    getYoutubeId(link) {
      var videoId = link.split("v=")[1];
      var ampersandPosition = videoId.indexOf("&");
      if (ampersandPosition != -1) {
        videoId = video_id.substring(0, ampersandPosition);
      }

      return videoId;
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    t(obj) {
      if (typeof obj == "object") {
        this.$i18n.locale;
        let en = obj.en;
        let tc = obj.tc;

        if (this.$i18n.locale == "en") {
          if (en != null) {
            return en;
          } else {
            return tc;
          }
        } else {
          if (tc != null) {
            return tc;
          } else {
            return en;
          }
        }
      } else {
        return obj;
      }
    },
  },
};
