<template>
	<v-app>
    <AppBar ref="appBar" :classData="classData" :total="checkedData" isHome="true"/>

    <v-main>
      <Home ref="home" />
    </v-main>

    <NewsFeed />

  </v-app>
</template>

<script>
import AppBar from './AppBar'
import AppFooter from '@/views/AppFooter'
import Home from './Home'
import NewsFeed from './NewsFeed'

export default {
	name: 'Welcome',
	components: {
    AppBar,
    AppFooter,
    Home,
    NewsFeed,
	},
	data() {
    return {
      checkedData: 0,
      classData: [],
      index: 0,
    }
  },
  mounted() {
    this.$watch(
      () => {
          return this.$refs.home.classData
      },
      (val) => {
        this.classData = val
      }
    )

    this.$watch(
      () => {
          return this.$refs.home.checkedData
      },
      (val) => {
        this.checkedData = val
      }
    )

    this.$watch(
      () => {
          return this.$refs.appBar.index
      },
      (val) => {
        this.index = val
      }
    )
  },
}
</script>