<template>
  <v-card width="500">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('course.updateAttendance') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-subtitle>{{ $t('course.uploadCSVDescn') + ':' }}</v-card-subtitle>
      <v-card-text>{{ '*' + $t('engCsvOnly') }}</v-card-text>

      <v-file-input
        v-model="file"
        show-size
        truncate-length="30"
        :label="$t('course.csvFile')"
        color="#2c76b8"
        accept=".csv"
      />
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="file != null"
        @click="uploadFile"
        :loading="isLoading"
        :disabled="file == null"
      >
        {{ $t('upload') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      file: null,
      isLoading: false,
    }
  },
  methods: {
    closeDialog(evt) {
			evt.preventDefault()
      this.$emit('close')
		},
    uploadFile() {
      const reader = new FileReader()
      reader.onload = e =>{
        var content = e.target.result
        var lines = content.split('\n')

        var attendeeDetailsLineNo = 0
        for (var line in lines) {
          if (lines[line].startsWith('Attendee Details')) {
            attendeeDetailsLineNo = parseInt(line)
            break;
          }
        }

        var attendedColumnNo = 0, emailColumnNo = 0
        var attendeeDetailsColumnName = lines[attendeeDetailsLineNo + 1].split(',')
        for (var i = 0; i < attendeeDetailsColumnName.length; i++) {
          if (attendeeDetailsColumnName[i] == 'Attended') {
            attendedColumnNo = i
          }

          if (attendeeDetailsColumnName[i] == 'Email') {
            emailColumnNo = i
          }
        }

        var map = []
        for (var i = attendeeDetailsLineNo + 2; i < lines.length ; i++) {
          if (lines[i] == '') {
            continue
          }

          var temp = lines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)

          map.push({
            email: temp[emailColumnNo],
            isAttended: 'Yes' == temp[attendedColumnNo],
          })
        }

        this.updateAttendance(map)
      }
      reader.readAsText(this.file)    
    },
    updateAttendance(map) {
      this.isLoading = true

      var requestData = {
        attendanceList: map,
        courseId: this.courseId,
      }

      this.callApi(this.$constant.UPDATE_ATTENDANCE_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
  }
}
</script>