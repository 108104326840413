<template>
  <v-card class="pm-cms">
    <v-card-title>
      {{ $t("careerExpManagement") }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>

      <v-btn 
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="exportExcel"
        :loading="isExportLoading"
      >
        {{ $t('course.exportData') }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
      style="white-space: pre"
    >
      <!-- <template v-slot:[`item.name`]="{ item }">
        {{ getEnrollmentName(item) }}
      </template> -->

      <template v-slot:[`item.status`]="{ item }">
        <StatusTx :statusValue="item.status" />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2c76b8" dark icon v-bind="attrs" v-on="on">
              <v-icon dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("updateInfo") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="100%"
      scrollable
      persistent
    >
      <CareerExpForm @close="onCloseDialog" :data="selected" />
    </v-dialog>

    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          :loading="isLoading"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CareerExpForm from "./CareerExpForm.vue";
import StatusTx from "../../../components/StatusTx.vue";
import _ from "lodash";
import CSVFile from "@/components/common/CSVFile";

export default {
  components: {
    CareerExpForm,
    StatusTx,
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: "",
      dataList: [],
      sortBy: "schoolNameEn",
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,

      enrollments: {},
      careers: {},
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    headers: function() {
      const isEn = this.$i18n.locale == "en";
      return [
        {
          text: this.$t("name"),
          value: "enrollment.name",
        },
        {
          text: this.$t("result"),
          value: "resultForDisplay",
        },
        {
          text: this.$t("status"),
          value: "status",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getAll() {
      this.isLoading = true;
      this.getAllEnrollmentsData();
      // this.getCareerWithPartnership();
      // this.getAllCareerExpFormData();
    },

    getAllCareerExpFormData() {
      this.callApi(
        this.$constant.GET_ALL_CAREER_EXP_FORM_URL,
        {},
        (resultData) => {
          this.isLoading = false;
          console.log("EXP", resultData);
          this.dataList = resultData.careerExpForms.map((item) => {
            let resultTx = "";
            if (item.status != "R") {
              const resultCareerId = item.resultCareerId;
              const careerObj = this.careers[resultCareerId];
              if (resultCareerId != null && careerObj != null) {
                const partnership = careerObj.partnershipObj;
                resultTx = this.displayTextByLocale(
                  partnership.partnershipNameEn,
                  partnership.partnershipNameTc
                );

                resultTx += " ";

                let jobTx = this.displayTextByLocale(
                  careerObj.careerNameEn,
                  careerObj.careerNameTc
                );

                resultTx += `(${jobTx})`;
              }

            }
            return {
              ...item,
              resultForDisplay: resultTx,
              enrollment: this.enrollments[item.enrollmentId],
            };
          });

          this.isLoading = false;
        },
        () => {
          console.log("FAIL");
          this.isLoading = false;
        }
      );
    },
    getAllEnrollmentsData() {
      this.callApi(
        this.$constant.GET_ALL_PARTICIPANT_URL,
        {},
        (resultData) => {
          for (let [key, value] of Object.entries(resultData.participantList)) {
            value.name = value.participantSurname + ", " + value.participantGivenName
            if (value.participantChiName) {
              value.name += ' ' + value.participantChiName
            }
            this.enrollments[value.enrollmentId] = value;
          }

          this.isLoading = false;

          this.getCareerWithPartnership();
        },
        (e) => {
          console.log("e", e);
          this.isLoading = false;
        }
      );
    },
    getCareerWithPartnership() {
      this.callApi(
        this.$constant.GET_ALL_CAREER,
        {},
        (resultData) => {
          for (let [key, value] of Object.entries(resultData.careerObj)) {
            this.careers[value.careerId] = value;
          }

          this.isLoading = false;

          this.getAllCareerExpFormData();
        },
        (e) => {
          console.log("e", e);
          this.isLoading = false;
        }
      );
    },
    displayTextByLocale(en, tc) {
      const isEn = this.$i18n.locale == "en";
      if (!isEn && tc != null) {
        return tc;
      }
      return en;
    },
    getEnrollmentName(item) {
      var enrollment = item.enrollment

      if (enrollment != null) {
        const isEn = this.$i18n.locale == "en";

        const chiName = enrollment.participantChiName;
        const enName =
          enrollment.participantSurname +
          ", " +
          enrollment.participantGivenName;

        if (!isEn && chiName != null) {
          return chiName;
        } else {
          return enName;
        }
      }
      return "";
    },
    edit(item) {
      this.selected = item;
      this.showEditDialog = true;
    },
    onCloseDialog() {
      this.showEditDialog = false;
      this.selected = {};
      this.getAll();
    },
    formStatus(status) {
      switch(status) {
        case "P":
          return this.$t('pending')
        case "A":
          return this.$t('approved')
        case "R":
          return this.$t('rejected')
      }
    },
    exportExcel() {
      this.isExportLoading = true

      var requestData = {
      }
      
      this.callApi(this.$constant.GEN_CAREER_EXP_REPORT_URL,
        requestData,
        (resultData) => {
          var resultList = resultData.careerExpReportObjList
          
          var tempList = [
            
          ]
          tempList.push({
            participantSurname: this.$t('surname'),
            participantGivenName: this.$t('givenName'), 
            participantChiName: this.$t('chiName'), 
            mobileNo: this.$t('mobileNo'), 
            emailAddress: this.$t('email'),
            schoolName: this.$t('schoolName'), 
            yearGroup: this.$t("yearGroup"),
            classLevel: this.$t("class"),
            status: this.$t('status'), 
            careerName: this.$t('careerExp'),
          })

          for (var i in resultList) {
            var s = resultList[i]

            tempList.push({
              participantSurname: s.participantSurname,
              participantGivenName: s.participantGivenName, 
              participantChiName: s.participantChiName, 
              mobileNo: s.mobileNo, 
              emailAddress: s.emailAddress,
              schoolName: s.schoolName, 
              yearGroup: s.yearGroup,
              classLevel: s.classLevel,
              status: this.formStatus(s.status), 
              careerName: this.$i18n.locale == 'en' ? s.careerNameEn : s.careerNameTc,
            })
          }
          var fileTitle = `Career_Exp_${this.formatDate(
            new Date(),
            "YYYYMMDD_HHmmss"
          )}`;

          CSVFile(null, null, 'Career Exp', tempList, fileTitle, false, 
          () => {
            this.isExportLoading = false
          })
        },
        () => {
          
        }
      )
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
.text-start,
td {
  white-space: pre;
}
</style>

<style lang="scss">
.pm-cms {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    max-width: 150 px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
