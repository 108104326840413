<template>
  <v-card>
     <v-toolbar
      color="#2c76b8"
      dark
      flat
    >
      <v-toolbar-title>
        {{ $t('profile') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          align-with-title
        >
          <v-tabs-slider color="orange lighten-1"></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item"
      >
        <v-card flat min-height="30">
          <UserInfo v-if="tab == 0" :appUserId="appUserId" />
          <LoginHistory v-else-if="tab == 1" :appUserId="appUserId" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import UserInfo from './UserInfo'
import LoginHistory from './LoginHistory'

export default {
  props: ['appUserId'],
  components: {
    UserInfo,
    LoginHistory,
  },
  data() {
    return {
      tab: null,
      items: [
        this.$t('info'), 
        this.$t('loginHistory'),
      ],
    }
  },
  watch: {
    appUserId: function(newVal) {
      this.appUserId = newVal
      this.tab = 0
    }
  }
}
</script>