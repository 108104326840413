<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-card-title class="text-uppercase">
          {{ $t('bonusScore') }}
        </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <DisplayText
              :title="$t('courseName') + ':'"
              :content="$i18n.locale == 'en' ? course.courseNameEn : course.courseNameTc"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <DisplayText
              :title="$t('email') + ':'"
              :content="enrollment.email"
            />
          </v-col>
        </v-row>

        <v-row class="py-5">
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="text-h6 mt-3">
              {{ $t('bonusScoreInstruction') + ":" }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col>
            <span
              class="text-h2 font-weight-light mr-2"
              v-text="score"
            ></span>
            <span class="subheading font-weight-light mr-1">
              {{ $t('bonusScore') }}
            </span>
          </v-col>
        </v-row>

        <v-slider
          v-model="score"
          color="#2c76b8"
          track-color="grey"
          always-dirty
          min="0"
          max="10"
          ticks="always"
          tick-size="5"
        >
          <template v-slot:prepend>
            <v-icon
              color="#2c76b8"
              @click="decrement"
            >
              mdi-minus
            </v-icon>
          </template>

          <template v-slot:append>
            <v-icon
              color="#2c76b8"
              @click="increment"
            >
              mdi-plus
            </v-icon>
          </template>
        </v-slider>
      </v-container>
    </v-card-text>

    <v-divider />

		<v-card-actions class="justify-end">
      <v-spacer />
			
			<v-btn
				color="orange lighten-1"
        dark
				@click="submit"
				:loading="isLoading"
			>
				{{ $t('submit') }}
			</v-btn>
		</v-card-actions>
  </v-card>
</template>

<script>
import DisplayText from '@/components/DisplayText'

export default {
  props: ['enrollment', 'course'],
  components: {
    DisplayText,
  },
  data() {
    return {
      score: 0,
      isLoading: false,
    }
  },
  mounted() {
    this.score = this.enrollment.bonusScore
  },
  methods: {
    decrement () {
      this.score--
    },
    increment () {
      this.score++
    },
    submit() {
      this.isLoading = true

      var requestData = {
        courseId: this.course.courseId,
        enrollmentId: this.enrollment.enrollmentId,
        bonusScore: this.score,
      }

      this.callApi(this.$constant.UPDATE_BONUS_SCORE_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
  }
}
</script>