<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-badge
        inline
        :content="dataList != null ? dataList.length : 0"
        color="orange lighten-1"
        :value="dataList != null && dataList.length > 0"
      >
        <v-card-title class="text-uppercase">
          {{ $t('listOfEnrollment') }}
        </v-card-title>
      </v-badge>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>

        <v-card>
        <v-card-title>
          {{ $t('listOfEnrollmentDescn') + ':' }}

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search') + '...'"
            single-line
            hide-details
            color="orange lighten-1"
          ></v-text-field>

          <v-btn 
            class="ml-2"
            color="orange lighten-1"
            dark
            @click="showImportDataDialog"
          >
            {{ $t('course.importData') }}
          </v-btn>

          <v-btn 
            class="ml-2"
            color="orange lighten-1"
            dark
            @click="showAttendanceDialog"
          >
            {{ $t('course.updateAttendance') }}
          </v-btn>

          <v-btn 
            class="ml-2"
            color="orange lighten-1"
            dark
            @click="exportData"
          >
            {{ $t('course.exportData') }}
          </v-btn>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="dataList"
          :items-per-page="10"
          class="elevation-1"
          multi-sort
          :search="search"
          :loading="isLoading"
          :footer-props="{
            'items-per-page-text': this.$t('rowsPerPage') + ':',        
          }"
          :sort-by.sync="sortBy"
          :loading-text="$t('loading') + '...'"
          :no-results-text="$t('noRecords')"
          :no-data-text="$t('noRecords')"
          :calculate-widths="false"
        >
          <template v-slot:[`item.email`]="{ item }">
            <span>{{ isEmptyString(item.email) ? '-' : item.email }}</span>
          </template>
          <template v-slot:[`item.schoolName`]="{ item }">
            <span>{{ isEmptyString(item.schoolName) ? '-' : item.schoolName }}</span>
          </template>
          <template v-slot:[`item.yearGroup`]="{ item }">
            <span>{{ isEmptyString(item.yearGroup) ? '-' : item.yearGroup }}</span>
          </template>
          <template v-slot:[`item.classLevel`]="{ item }">
            <span>{{ isEmptyString(item.classLevel) ? '-' : item.classLevel }}</span>
          </template>
          <template v-slot:[`item.bonusScore`]="{ item }">
            <span>{{ item.Attended == 'NA' ? '-' : item.bonusScore }}</span>
          </template>
          <template v-slot:[`item.isAttended`]="{ item }">
            <span v-if="item.isAttended == 'NA'">{{ '-' }}</span>
            <v-icon color="green lighten-1" v-else-if="item.isAttended == 'Y'">mdi-check</v-icon>
            <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#2c76b8"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <!-- <v-list-item @click="modifyBonusScore(item)" :disabled="item.isAttended != 'Y'">
                  <v-list-item-icon>
                    <v-icon>mdi-numeric</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('bonusScore') }}</v-list-item-title>
                </v-list-item> -->

                <v-list-item @click="markAsAttended(item)" :disabled="item.isAttended == 'Y'">
                  <v-list-item-icon>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('markAsAttended') }}</v-list-item-title>
                </v-list-item>

                <v-list-item @click="markAsNotAttended(item)" :disabled="item.isAttended == 'N'">
                  <v-list-item-icon>
                    <v-icon>mdi-close-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('markAsNotAttended') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

    </v-container>
  </v-card-text>

    <v-dialog
      v-model="attendanceDialog"
      scrollable
      persistent
      width="unset"
    >
      <UpdateAttendance
        :courseId="course.courseId"
        @close="closeAttendanceDialog"
        @message="showMessage"
      />
    </v-dialog>

    <v-dialog
      v-if="importDataDialog"
      v-model="importDataDialog"
      scrollable
      persistent
      width="unset"
    >
      <ImportAttendanceData
        :courseId="course.courseId"
        @close="closeImportDataDialog"
        @message="showMessage"
      />
    </v-dialog>

    <v-snackbar
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
      top
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="bonusScoreDialog"
      v-model="bonusScoreDialog"
      scrollable
      persistent
      width="unset"
    >
      <BonusScore
        :course="course"
        :enrollment="selected"
        @close="closeBonusScoreDialog"
        @message="showMessage"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import DisplayText from '@/components/DisplayText'
import UpdateAttendance from './UpdateAttendance'
import CSVFile from "@/components/common/CSVFile";
import BonusScore from './BonusScore';
import ImportAttendanceData from './ImportAttendanceData.vue';

export default {
  props: ['course'],
  components: {
    DisplayText,
    UpdateAttendance,
    CSVFile,
    BonusScore,
    ImportAttendanceData,
  },
  data() {
    return {
      dataList: [],
      isLoading: false,
      search: null,
      sortBy: 'email',
      attendanceDialog: false,
      showSnackbar: false,
      snackbarText: null,
      bonusScoreDialog: false,
      selected: null,
      importDataDialog: false,
    }
  },
  mounted() {
    this.getEnrollmentList()
  },
  methods: {
    showMessage(message) {
      this.showSnackbar = true
      this.snackbarText = message
    },
    getEnrollmentList() {
      this.isLoading = true

      var requestData = {
        courseId: this.course.courseId
      }

      this.callApi(this.$constant.LIST_ENROLLMENT_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          this.dataList = resultData.enrollmentList
          if (this.dataList == null) {
            this.dataList = []
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    showAttendanceDialog() {
      this.attendanceDialog = true
    },
    closeAttendanceDialog() {
      this.attendanceDialog  = false
      this.getEnrollmentList()
    },
    exportData() {
      var tableHead = [
        this.$t('email'),
        this.$t('firstName'),
        this.$t('lastName'),
      ]

      var tableData = [{
        email: this.$t('email'),
        firstName: this.$t('firstName'),
        lastName: this.$t('lastName'),
      }]

      this.dataList.map(d => {
        var temp = {
          email: d.email,
          firstName: d.participantGivenName,
          lastName: d.participantSurname,
        }

        tableData.push(temp)
      })

      var fileTitle = `Participants_${this.course.courseNameEn}_${this.formatDate(
          new Date(),
          "YYYYMMDD_HHmmss"
        )}`;

      CSVFile(tableHead, null, 'Data', tableData, fileTitle, false)
    },
    markAsAttended(item) {
      this.isLoading = true

      var requestData = {
        courseId: this.course.courseId,
        email: item.email,
        isAttended: true,
      }

      this.callApi(this.$constant.MARK_AS_ATTENDED_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.getEnrollmentList()
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    markAsNotAttended(item) {
      this.isLoading = true

      var requestData = {
        courseId: this.course.courseId,
        email: item.email,
        isAttended: false,
      }

      this.callApi(this.$constant.MARK_AS_ATTENDED_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.getEnrollmentList()
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    modifyBonusScore(item) {
      this.bonusScoreDialog = true
      this.selected = item
    },
    closeBonusScoreDialog() {
      this.bonusScoreDialog = false
      this.getEnrollmentList()
    },
    showImportDataDialog() {
      this.importDataDialog = true
    },
    closeImportDataDialog() {
      this.importDataDialog  = false
      this.getEnrollmentList()
    },
  },
  watch: {
    course(newVal) {
      this.dataList = []

      this.course = newVal
      this.getEnrollmentList()
    }
  },
  computed: {
    headers: function() {
      return [
        { 
          text: this.$t('email'), 
          value: 'email', 
        },
        { 
          text: this.$t('school'), 
          value: 'schoolName' 
        },
        { 
          text: this.$t('yearGroup'), 
          value: 'yearGroup' 
        },
        { 
          text: this.$t('class'), 
          value: 'classLevel' 
        },
        { 
          text: this.$t('course.completed'), 
          value: 'isAttended' 
        },
        { 
          text: this.$t('bonusScore'), 
          value: 'bonusScore' 
        },
        { 
          text: this.$t('actions'), 
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },
}
</script>