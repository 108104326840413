<template>
  <v-card>
    <v-card-title>
      {{ $t('schoolManagement') }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',        
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
    >
      <template v-slot:[`item.schoolType`]="{ item }">
        <span>{{ isEmptyString(item.schoolType) ? '-' : ($i18n.locale == 'en' ? item.schoolType.EN : item.schoolType.TC) }}</span>
      </template>
      <template v-slot:[`item.schoolNo`]="{ item }">
        <span>{{ isEmptyString(item.schoolNo) ? '-' : item.schoolNo }}</span>
      </template>
      <template v-slot:[`item.location`]="{ item }">
        <span>{{ isEmptyString(item.location) ? '-' : ($i18n.locale == 'en' ? item.location.EN : item.location.TC) }}</span>
      </template>
      <template v-slot:[`item.district`]="{ item }">
        <span>{{ isEmptyString(item.district) ? '-' : ($i18n.locale == 'en' ? item.district.EN : item.district.TC) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2c76b8"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('updateInfo') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="80%"
      scrollable
      persistent
    >      
      <EditSchoolInfo @close="onCloseDialog" v-if="selected" :school="selected" />
    </v-dialog>

    <v-snackbar
			top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import EditSchoolInfo from './EditSchoolInfo.vue'
import CSVFile from "@/components/common/CSVFile";

export default {
  components: {
    EditSchoolInfo,
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: '',
      dataList: [],
      sortBy: 'schoolNameEn',
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,
    }
  },
  mounted() {
    this.getAll()
  },
  computed: {
    headers: function() {
      return [
        { 
          text: this.$t('schoolNameEn'),
          align: 'start', 
          value: 'schoolNameEn' 
        },
        { 
          text: this.$t('schoolNameTc'),
          value: 'schoolNameTc' 
        },
        { 
          text: this.$t('schoolType'), 
          value: 'schoolType' 
        },
        { 
          text: this.$t('schoolNo'), 
          value: 'schoolNo' 
        },
        { 
          text: this.$t('location'), 
          value: 'location' 
        },
        { 
          text: this.$t('district'), 
          value: 'district' 
        },
        { 
          text: this.$t('actions'), 
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },
  methods: {
    getAll() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_SCHOOL_LIST_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.detailedSchoolList

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    edit(item) {
      this.selected = item
      this.showEditDialog = true
    },
    onCloseDialog() {
      this.showEditDialog = false
      this.getAll()
    },
  }
}
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
</style>