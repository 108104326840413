  
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Formatter from './formatter'

Vue.use(VueI18n)

const locale = 'en' // default locale
const formatter = new Formatter({ locale })

export default new VueI18n({
  locale,
  fallbackLocale: locale,
  formatter,
  messages: {
    'en': require('./en.json'),
    'tc': require('./tc.json'),
    'sc': require('./sc.json'),
  }
})