<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('course.importData') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-subtitle class="px-0">{{ $t('course.uploadCSVDescn') + ':' }}</v-card-subtitle>
      <v-card-text class="px-0">{{ '*' + $t('engCsvOnly') }}</v-card-text>

      <v-autocomplete
        v-model="school"
        :items="schoolList"
        :search-input.sync="search"
        color="#2c76b8"
        hide-no-data
        hide-selected
        :label="$t('school')"
        class="required"
        item-text="displayName"
        return-object
        clearable
        @change="otherSchoolName = null"
        :hint="$t('schoolHint')"
        persistent-hint
      >
        <template v-slot:item="data">
          <h5>
            {{ data.item.displayName }}
          </h5>
        </template>
      </v-autocomplete>

      <v-text-field
        v-model="otherSchoolName"
        :label="$t('schoolName') + ':'"
        color="#2c76b8"
        clearable
        counter="100"
        maxlength="100"
        :rules="schoolRules"
        class="required"
        v-if="school != null && school.schoolId == 0"
      />

      <v-file-input
        v-model="file"
        show-size
        truncate-length="30"
        :label="$t('course.csvFile')"
        color="#2c76b8"
        accept=".csv"
      />
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="file != null && ((school != null && school.schoolId != 0) || (school.schoolId == 0 && otherSchoolName != undefined))"
        @click="uploadFile"
        :loading="isLoading"
        :disabled="file == null || school == null || (school.schoolId == 0 && otherSchoolName == null)"
      >
        {{ $t('upload') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      file: null,
      isLoading: false,
      schoolList: [],
      school: null,
      otherSchoolName: null,
      search: null,

      schoolRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        v => !v || /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
    }
  },
  mounted() {
    this.getAllSchool()
  },
  methods: {
    closeDialog(evt) {
			evt.preventDefault()
      this.$emit('close')
		},
    uploadFile() {
      const reader = new FileReader()
      reader.onload = e =>{
        var content = e.target.result
        var lines = content.split('\n')

        var lineNo = 1
        
        var map = []
        for (var i = lineNo; i < lines.length ; i++) {
          if (lines[i] == '') {
            continue
          }

          var temp = lines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)

          map.push({
            email: temp[0],
            participantSurname: temp[1],
            participantGivenName: temp[2],
            participantChiName: temp[3],
            yearGroup: temp[4],
            classLevel: temp[5],
            isTeacher: 'Y' == temp[6],
          })
        }

        this.importAttendance(map)
      }
      reader.readAsText(this.file)    
    },
    importAttendance(map) {
      this.isLoading = true

      var schoolName = this.school.schoolId == 0 ? this.otherSchoolName.trim() : this.school.displayName.trim()

      var requestData = {
        enrollmentList: map,
        courseId: this.courseId,
        schoolName: schoolName,
      }

      this.callApi(this.$constant.IMPORT_ATTENDANCE_DATA_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    getAllSchool() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_ALL_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.schoolList = resultData.schoolList
          if (this.schoolList != null) {
            this.schoolList.map(s => {
              s.displayName = s.schoolNameEn + ' - ' + s.schoolNameTc
            })

            this.schoolList.push({
              schoolId: 0,
              displayName: 'Others - 其他',
            })
          }

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
  }
}
</script>