<template>
  <v-card
    class="mx-auto"
    :height="isMobile ? 'unset' : 880"
    :loading="loading"
  >
    <!-- <v-img
      class="white--text align-start"
      max-height="200"
      :src="image != null ? image : require('@/assets/images/ampower_logo.png')"
      contain
    >
    </v-img> -->

    <img :src="image != null ? image : require('@/assets/images/ampower_logo.png')" style="object-fit: contain; max-width: 100%;"/>

    <v-card-subtitle>
      <v-icon>mdi-calendar</v-icon>
      {{ formatDate(date, $i18n.locale == 'en' ? 'DD MMM YYYY hh:mm a' : 'YYYY年MMMD日 ahh:mm') }}
    </v-card-subtitle>

    <v-card-title class="d-block pt-0" style="word-break: break-word">
      <v-tooltip bottom v-if="isRecommended">
        <template v-slot:activator="{ on, attrs }">
          <v-icon 
            class="pa-0" 
            color="yellow darken-2" 
            v-bind="attrs" 
            v-on="on"
          >
            mdi-star
          </v-icon>
        </template>
        <!-- <span> -->
          {{ $t('recommended') }}
        <!-- </span> -->
      </v-tooltip>
      {{ title }}
    </v-card-title>

    <v-card-text :class="isMobile ? '' : 'imageTextBottom'">
      <v-row class="d-flex align-start" v-bind:style="{'min-height': isMobile ? 'unset' : '380px'}">
        <v-col cols="4" class="d-flex flex-column justify-center align-center" v-for="lecturer in lecturerList" v-bind:key="lecturer.lecturerId">
          <v-avatar
            color="white"
            size="70"
          >
            <img
              :src="lecturer.b64Image != null ? lecturer.b64Image : require('@/assets/images/profile.png')"
              :alt="$i18n.locale == 'en' ? lecturer.engName : lecturer.chiName"
            >
          </v-avatar>

          <p class="text-center subtitle-6 text-wrap mt-2 mb-0 font-weight-bold black--text">
            {{ $i18n.locale == 'en' ? lecturer.engName : lecturer.chiName }}
          </p>

          <p class="text-center subtitle-6 text-wrap my-0" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.titleEn : lecturer.titleTc }}
          </p>

          <!-- <p class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.orgNameEn : lecturer.orgNameTc }}
          </p> -->

          <p 
            v-html="$i18n.locale == 'en' ? lecturer.orgNameEn : lecturer.orgNameTc" 
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">

          </p>

          <p
            v-if="lecturer.title2En || lecturer.title2Tc" 
            class="text-center subtitle-6 text-wrap my-0" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.title2En : lecturer.title2Tc }}
          </p>

          <!-- <p
            v-if="lecturer.orgName2En || lecturer.orgName2Tc"  
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.orgName2En : lecturer.orgName2Tc }}
          </p> -->

          <p
            v-if="lecturer.orgName2En || lecturer.orgName2Tc"   
            v-html="$i18n.locale == 'en' ? lecturer.orgName2En : lecturer.orgName2Tc" 
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">

          </p>

          <p
            v-if="lecturer.title3En || lecturer.title3Tc"  
            class="text-center subtitle-6 text-wrap my-0" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.title3En : lecturer.title3Tc }}
          </p>

          <!-- <p
            v-if="lecturer.orgName3En || lecturer.orgName3Tc"  
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.orgName3En : lecturer.orgName3Tc }}
          </p> -->

          <p
            v-if="lecturer.orgName3En || lecturer.orgName3Tc"   
            v-html="$i18n.locale == 'en' ? lecturer.orgName3En : lecturer.orgName3Tc" 
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">

          </p>

          <p
            v-if="lecturer.title4En || lecturer.title4Tc"  
            class="text-center subtitle-6 text-wrap my-0" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.title4En : lecturer.title4Tc }}
          </p>

          <!-- <p
            v-if="lecturer.orgName4En || lecturer.orgName4Tc"  
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.orgName4En : lecturer.orgName4Tc }}
          </p> -->

          <p
            v-if="lecturer.orgName4En || lecturer.orgName4Tc"   
            v-html="$i18n.locale == 'en' ? lecturer.orgName4En : lecturer.orgName4Tc" 
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">

          </p>

          <p
            v-if="lecturer.title5En || lecturer.title5Tc"  
            class="text-center subtitle-6 text-wrap my-0" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.title5En : lecturer.title5Tc }}
          </p>

          <!-- <p
            v-if="lecturer.orgName5En || lecturer.orgName5Tc"  
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">
            {{ $i18n.locale == 'en' ? lecturer.orgName5En : lecturer.orgName5Tc }}
          </p> -->

          <p
            v-if="lecturer.orgName5En || lecturer.orgName5Tc"   
            v-html="$i18n.locale == 'en' ? lecturer.orgName5En : lecturer.orgName5Tc" 
            class="text-center text-wrap mb-2" style="font-size: 10px; line-height: 16px; max-width:100%;">

          </p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions :class="isMobile ? '' : 'stickToBottom'" style="padding-right: 16px; padding-left: 16px">
      <v-btn
        dark
        color="orange lighten-1"
        @click="showDetails"
      >
        {{ $t('details') }}
      </v-btn>

      <v-btn
        :dark="!isEmptyString(youtubeLink)"
        :disabled="isEmptyString(youtubeLink)"
        
        color="red"
        @click="showCheckApply"
        v-if="isExpired || title == 'Career Planning App (Proof of Concept) Design Competition Briefing' || title == '職業規劃應用程式(概念化)設計比賽簡介會'" 
      >
        {{ $t('replay') }}
      </v-btn>

      <v-spacer />

      <v-checkbox
        :id="'checkbox' + courseId"
        v-if="!$constant.isStaffApp && title != 'Career Planning App (Proof of Concept) Design Competition Briefing' && title != '職業規劃應用程式(概念化)設計比賽簡介會'"
        v-model="isSelected"
        :label="$t('register')"
        color="orange lighten-1"
        @change="toggleSelect"
        class="my-2"
      />
    </v-card-actions>

    <v-dialog
      v-if="checkApplyDialog"
      v-model="checkApplyDialog"
      scrollable
      persistent
      width="unset"
    >
      <CheckApply
        :courseId="courseId"
        @close="closeCheckApply"
        @showVideo="showVideo"
      />
    </v-dialog>

    <v-dialog
      v-if="showVideoDialog"
      v-model="showVideoDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer 
        @playing="playing"
        @ended="ended"
        @close="closeVideo"
        :videoList="videoList"
        :title="title"
        :showAmpowerLogo="false"
        :showClassReplay="true"
      />
    </v-dialog>

    <v-dialog
      v-if="showSurveyDialog"
      v-model="showSurveyDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
      scrollable
    >
      <Survey
        :courseId="courseId" 
        :enrollmentId="enrollmentId"
        @close="closeSurvey"
      />
    </v-dialog>

    <v-dialog
      v-if="showWatchNowDialog"
      v-model="showWatchNowDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <WatchNow
        :refNo="refNo"
        @watchNow="watchNow"
        @close="closeWatchNow"
      />
    </v-dialog>

    <v-dialog
      v-model="showEndMsg"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark>
          <v-toolbar-title>
            {{ $t('thx4Watching') + '!' }}
          </v-toolbar-title>
          <!-- <v-spacer />
          <v-btn
            icon
            dark
            @click="showEndMsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-toolbar>

        <v-card-title>
          {{ $t('thx4WatchingContent') + '!' }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="orange lighten-1"
            dark
            @click="showEndMsg = false"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CheckApply from './CheckApply'
import YoutubePlayer from '@/components/YoutubePlayer'
import Survey from './Survey'
import WatchNow from './WatchNow'

  export default {
    name: 'ClassCard',
    props: ['courseId', 'title', 'details', 'image', 'date', 'checked', 'loading', 'lecturerList', 'isRecommended', 'isExpired', 'youtubeLink', 'isReqRegister'],
    components: {
      CheckApply,
      YoutubePlayer,
      Survey,
      WatchNow,
    },
    data() {
      return {
        isSelected: this.checked,
        checkApplyDialog: false,
        showVideoDialog: false,
        showSurveyDialog: false,
        videoList: [],
        enrollmentId: null,
        surveyReq: true,
        showWatchNowDialog: false,
        refNo: null,
        isWatched: false,
        showEndMsg: false,
      }
    },
    methods: {
      toggleSelect() {
        this.$parent.toggleSelect(this.courseId, this.isSelected)
      },
      showDetails() {
        this.$parent.showDetails(this.courseId)
      },
      showCheckApply() {
        if (this.isReqRegister) {
          this.checkApplyDialog = true
        } else {
          this.surveyReq = false

          this.checkApplyDialog = false
          
          this.showWatchNowDialog = false
          this.showVideoDialog = true

          this.videoList = []
          this.videoList.push({
            id: this.getYoutubeId(this.youtubeLink),
            title: this.title,
          })
        }
      },
      closeCheckApply() {
        this.checkApplyDialog = false
      },
      showVideo(enrollmentId, surveyReq, refNo, isRefNo) {
        if (isRefNo == undefined && refNo == undefined) {
          return
        }

        this.enrollmentId = enrollmentId
        this.surveyReq = surveyReq

        this.checkApplyDialog = false
        if (!isRefNo) {
          this.refNo = refNo
          this.showWatchNowDialog = true
          this.showVideoDialog = false
        } else {
          this.showWatchNowDialog = false
          this.showVideoDialog = true
        }
        this.videoList = []
        this.videoList.push({
          id: this.getYoutubeId(this.youtubeLink),
          title: this.title,
        })
      },
      closeVideo() {
        this.showVideoDialog = false
        // if (this.surveyReq) {
        //   this.showSurveyDialog = true
        // }
      },
      watchNow() {
        this.showVideoDialog = true
        this.showWatchNowDialog = false
      },
      closeWatchNow() {
        this.showVideoDialog = false
        this.showWatchNowDialog = false
      },
      playing() {
        if (!this.isWatched) {
          var requestData = {
            courseId: this.courseId,
            enrollmentId: this.enrollmentId,
          }

          this.callApi(this.$constant.MARK_AS_ATTENDED_URL,
            requestData,
            (resultData) => {
              this.isWatched = true   
            },
            () => {
              
            }
          )
        }
      },
      ended() {
        this.showVideoDialog = false
        if (this.surveyReq) {
          this.showSurveyDialog = true
        } else {
          this.showEndMsg = true
        }
      },
      closeSurvey() {
        this.showSurveyDialog = false
        this.showEndMsg = true
      }
    },
    watch: {
      checked: function(newVal, oldVal) {
        this.isSelected = newVal
      }
    },
    mounted() {
      // this.isExpired = true
    }
  }
</script>

<style lang="scss" scoped>
// TODO: common scss
.v-card__text, .v-card__title {
  word-break: normal !important;
}

.singleLineDetails {
  white-space: nowrap ;
  word-break: normal;
  overflow: hidden ;
  text-overflow: ellipsis;
}

.stickToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.imageTextBottom {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}
</style>
