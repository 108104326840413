<template>
  <v-app>
    <AppBar />

    <v-main>
      <v-container style="max-width: 1400px">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="
                $constant.isUAT
                  ? $i18n.locale == 'en'
                    ? require('@/assets/images/background_en_UAT.png')
                    : require('@/assets/images/background_tc_UAT.png')
                  : $i18n.locale == 'en'
                  ? require('@/assets/images/background_en.png')
                  : require('@/assets/images/background_tc.png')
              "
              width="100%"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col style="background-color: #f4dccf; margin: 0px 6px">
            <v-card-title class="py-1">
              {{ $t("registration") }}
            </v-card-title>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto">
              <v-toolbar color="#2c76b8" dark>
                <v-badge
                  inline
                  :content="courses.length"
                  color="orange lighten-1"
                  :value="courses.length > 0"
                >
                  <v-card-title class="text-uppercase">
                    {{
                      courses != null &&
                      courses[0].courseId == closingCeremonyID
                        ? $t("ceremonyRegistration")
                        : $t("interested")
                    }}
                  </v-card-title>
                </v-badge>
              </v-toolbar>

              <v-card-text class="text--primary">
                <v-container>
                  <v-row
                    v-for="(course, index) in courses"
                    v-bind:key="course.courseId"
                    class="d-flex"
                  >
                    <v-col cols="1" align-self="center">
                      <h2>
                        {{ index + 1 }}
                      </h2>
                    </v-col>

                    <v-col cols="9" align-self="center">
                      <DisplayText
                        :title="
                          $i18n.locale == 'en'
                            ? course.courseNameEn
                            : course.courseNameTc
                        "
                        :content="
                          formatDate(
                            course.courseDate,
                            $i18n.locale == 'en'
                              ? 'DD MMM YYYY hh:mm a'
                              : 'YYYY年MMMD日 ahh:mm'
                          )
                        "
                        :inverted="true"
                      />
                    </v-col>

                    <v-col cols="2" align-self="center" class="text-right">
                      <v-icon
                        v-if="courses.length > 1"
                        color="red lighten-1"
                        large
                        @click="removeCourse(course)"
                      >
                        mdi-delete
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto">
              <v-card-title class="text-uppercase" style="color: #1a237e">
                {{ $t("perInfo") }}
              </v-card-title>

              <v-card-subtitle style="color: #888" class="pb-0">
                {{ $t("fillInfo") + ":" }}
              </v-card-subtitle>

              <v-card-subtitle style="color: #888" class="pt-0">
                {{ "*" + $t("applyRemarks") }}
              </v-card-subtitle>

              <v-card-text class="text--primary">
                <v-container>
                  <v-form v-model="isFormValid">
                    <v-row>
                      <v-col :cols="isMobile ? 12 : 6">
                        <v-text-field
                          v-model="surname"
                          :label="$t('surname') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="100"
                          maxlength="100"
                          :rules="nameRules"
                          class="required"
                        />
                      </v-col>

                      <v-col :cols="isMobile ? 12 : 6">
                        <v-text-field
                          v-model="givenName"
                          :label="$t('givenName') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="100"
                          maxlength="100"
                          :rules="nameRules"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="chiName"
                          :label="$t('chiName') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="10"
                          maxlength="10"
                          :rules="nameRules"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="school"
                          :items="schoolList"
                          :search-input.sync="search"
                          color="#2c76b8"
                          hide-no-data
                          hide-selected
                          :label="$t('school')"
                          class="required"
                          item-text="displayName"
                          return-object
                          clearable
                          @change="otherSchoolName = null"
                          :hint="$t('schoolHint')"
                          persistent-hint
                        >
                          <template v-slot:item="data">
                            <h5>
                              {{ data.item.displayName }}
                            </h5>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row v-if="school != null && school.schoolId == 0">
                      <v-col>
                        <v-text-field
                          v-model="otherSchoolName"
                          :label="$t('schoolName') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="100"
                          maxlength="100"
                          :rules="schoolRules"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="!isMobile">
                      <v-col cols="2">
                        <v-checkbox
                          v-model="isTeacher"
                          :label="$t('teacher')"
                        ></v-checkbox>
                      </v-col>

                      <v-col v-if="!isMobile && !isTeacher">
                        <v-text-field
                          v-model="yearGroup"
                          :label="$t('yearGroup') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="30"
                          maxlength="30"
                          :rules="classRules"
                          class="required"
                        />
                      </v-col>

                      <v-col v-if="!isMobile && !isTeacher">
                        <v-text-field
                          v-model="schoolClass"
                          :label="$t('class') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="30"
                          maxlength="30"
                          :rules="classRules"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row v-if="isMobile">
                      <v-col>
                        <v-checkbox
                          v-model="isTeacher"
                          :label="$t('teacher')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row v-if="isMobile && !isTeacher">
                      <v-col>
                        <v-text-field
                          v-model="yearGroup"
                          :label="$t('yearGroup') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="30"
                          maxlength="30"
                          :rules="classRules"
                          class="required"
                        />
                      </v-col>

                      <v-col>
                        <v-text-field
                          v-model="schoolClass"
                          :label="$t('class') + ':'"
                          color="#2c76b8"
                          clearable
                          counter="30"
                          maxlength="30"
                          :rules="classRules"
                          class="required"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="email"
                          :label="$t('email') + ':'"
                          color="#2c76b8"
                          :rules="emailRules"
                          clearable
                          counter="100"
                          maxlength="100"
                          class="required email"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="mb-4">
                      <v-col>
                        <v-text-field
                          v-model="confirmEmail"
                          :label="$t('confirmEmail') + ':'"
                          color="#2c76b8"
                          :rules="confirmEmailRules"
                          clearable
                          counter="100"
                          maxlength="100"
                          class="required email"
                        />
                      </v-col>
                    </v-row>

                    <!-- <v-row>
                      <v-col>
                        <v-text-field
                          v-model="mobileNo"
                          :label="$t('mobileNo') + ':'"
                          color="#2c76b8"
                          :rules="mobileNoRules"
                          clearable
                          counter="8"
                          maxlength="8"
                        />
                      </v-col>
                    </v-row> -->
                  </v-form>

                  <v-row>
                    <v-col>
                      <vue-recaptcha
                        :sitekey="$constant.RECAPTCHA_SITE_KEY"
                        :loadRecaptchaScript="true"
                        @verify="verify"
                        :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                        @expired="isRobot = true"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text-right">
                      <v-btn text color="orange lighten-1" @click="cancel">
                        {{ $t("cancel") }}
                      </v-btn>

                      <v-btn
                        color="orange lighten-1"
                        :dark="!isDisabled"
                        :disabled="isDisabled"
                        @click="confirm"
                        :loading="isLoading"
                      >
                        {{ $t("confirm") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar v-model="showSnackbar" timeout="5000" color="#FE253D" top>
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from "@/views/AppBar";
import AppFooter from "@/views/AppFooter";
import VueRecaptcha from "vue-recaptcha";
import DisplayText from "@/components/DisplayText";

export default {
  name: "Apply",
  components: {
    "vue-recaptcha": VueRecaptcha,
    AppBar,
    AppFooter,
    DisplayText,
  },
  mounted() {
    this.courses = this.$store.state.courses;

    this.getAllSchool();
  },
  computed: {
    isDisabled: function () {
      return (
        this.isRobot ||
        this.isEmptyString(this.surname) ||
        this.isEmptyString(this.givenName) ||
        this.isEmptyString(this.school) ||
        (!this.isTeacher &&
          this.isEmptyString(this.yearGroup) &&
          this.isEmptyString(this.schoolClass)) ||
        this.isEmptyString(this.confirmEmail) ||
        this.isEmptyString(this.email) ||
        !this.isFormValid
      );
    },
  },
  data() {
    return {
      courses: [],
      // name: null,
      surname: null,
      givenName: null,
      chiName: null,
      school: null,
      schoolClass: null,
      email: null,
      confirmEmail: null,
      mobileNo: null,
      isRobot: true,
      isLoading: false,
      showSnackbar: false,
      snackbarText: null,
      isFormValid: false,
      schoolList: [],
      search: null,
      otherSchoolName: null,
      isTeacher: false,
      yearGroup: null,

      nameRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noNumAndSpecialCharacters"),
      ],
      schoolRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noSpecialCharacters"),
      ],
      classRules: [
        // v => (v || '').length <= 30 || this.$t('lengthTooLong', [30]),
        (v) =>
          !v ||
          /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noSpecialCharacters"),
      ],
      emailRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          this.$t("invalidEmail"),
      ],
      confirmEmailRules: [
        (v) =>
          !v ||
          this.email == undefined ||
          v.toLowerCase() == this.email.toLowerCase() ||
          this.$t("emailNotMatch"),
      ],
      mobileNoRules: [
        (v) => !v || /^[0-9]+$/.test(v) || this.$t("invalidMobileNo"),
      ],
    };
  },
  methods: {
    verify(response) {
      var requestData = {
        userResponse: response,
      };
      this.callApi(
        this.$constant.VERIFY_RECAPTCHA_URL,
        requestData,
        (resultData) => {
          this.isRobot = !resultData.result;
        },
        () => {
          this.isLoading = false;
          this.isRobot = true;
        }
      );
    },
    cancel() {
      this.$router.push({ path: "/" });
    },
    removeCourse(course) {
      var index = this.courses
        .map((c) => {
          return c.courseId;
        })
        .indexOf(course.courseId);

      this.courses.splice(index, 1);
    },
    confirm() {
      this.isLoading = true;

      var courseIdList = [];
      for (var i in this.courses) {
        courseIdList.push(this.courses[i].courseId);
      }

      var schoolName =
        this.school.schoolId == 0
          ? this.otherSchoolName.trim()
          : this.school.displayName.trim();

      var requestData = {
        courseIdList: courseIdList,
        surname: this.surname.trim(),
        givenName: this.givenName.trim(),
        chiName: this.chiName == null ? null : this.chiName.trim(),
        school: schoolName,
        schoolClass: this.isTeacher ? null : this.schoolClass.trim(),
        yearGroup: this.isTeacher ? null : this.yearGroup.trim(),
        email: this.email.toLowerCase(),
        mobileNo: this.mobileNo,
        isTeacher: this.isTeacher,
        isExpired: false,
      };

      this.callApi(
        this.$constant.REGISTER_COURSE_URL,
        requestData,
        (resultData) => {
          this.isLoading = false;

          if (!resultData.result) {
            this.showSnackbar = true;
            this.snackbarText = resultData.remarks;

            return;
          }

          this.$store.commit("saveStudentInfo", {
            name: this.givenName.trim() + " " + this.surname.trim(),
            school: schoolName,
            yearGroup: this.yearGroup,
            schoolClass: this.schoolClass,
            email: this.email,
          });
          this.$router.push({
            path: "/ApplySuccess",
            query: { refNo: resultData.refNo },
          });
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    getAllSchool() {
      this.isLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GET_ALL_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.schoolList = resultData.schoolList;
          if (this.schoolList != null) {
            this.schoolList.map((s) => {
              s.displayName = s.schoolNameEn + " - " + s.schoolNameTc;
            });

            this.schoolList.push({
              schoolId: 0,
              displayName: "Others - 其他",
            });
          }

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
  },
};
</script>

<style lang="scss">
.required label::before {
  content: "* ";
  color: #fe253d;
}
.email input {
  text-transform: lowercase;
}
h5 {
  font-weight: normal;
}
</style>
