<template>
  <v-card>
    <v-card-title>
      {{ $t('userManagement') }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>

      <v-btn 
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="showAddUserDialog = true"
      >
        {{ $t('addUser') }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',        
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
    >
      <template v-slot:[`item.lastName`]="{ item }">
        <span>{{ isEmptyString(item.lastName) ? '-' : item.lastName }}</span>
      </template>
      <template v-slot:[`item.firstName`]="{ item }">
        <span>{{ isEmptyString(item.firstName) ? '-' : item.firstName }}</span>
      </template>
      <template v-slot:[`item.email`]="{ item }">
        <span>{{ isEmptyString(item.email) ? '-' : item.email }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          :color="item.status == 'A' ? 'green accent-4' : '#FE253D'"
          dark
        >
          {{ $i18n.locale == 'en' ? item.userStatus.EN : item.userStatus.TC }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2c76b8"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showInfo(item)">
              <v-list-item-icon>
                <v-icon>mdi-information</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('showProfile') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="blockUnblock(item)" >
              <v-list-item-icon>
                <v-icon v-if="item.status == 'A'">mdi-account-lock</v-icon>
                <v-icon v-else>mdi-account-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.status == 'A' ? $t('block') : $t('unblock') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showUserInfoDialog"
      width="450"
      persistent
    >      
      <UserProfile @close="showUserInfoDialog = false" v-if="selected" :appUserId="selected.appUserId" />
    </v-dialog>

    <v-dialog
      v-model="showBlockUnblockDialog"
      width="unset"
      persistent
    >      
      <BlockUnblock @close="afterBlockUnblock" v-if="selected" :appUserId="selected.appUserId" :status="selected.status"/>
    </v-dialog>

    <v-dialog
      v-model="showAddUserDialog"
      width="500"
      persistent
    >      
      <AddUser @close="afterAddUser" />
    </v-dialog>

    <v-snackbar
			top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import BlockUnblock from './BlockUnblock'
import UserProfile from './UserProfile'
import AddUser from './AddUser'

export default {
  components: {
    BlockUnblock,
    UserProfile,
    AddUser,
  },
  data() {
    return {
      isLoading: false,
      search: '',
      dataList: [],
      sortBy: 'username',
      selected: null,
      showBlockUnblockDialog: false,
      showUserInfoDialog: false,
      showAddUserDialog: false,
      showSnackbar: false,
      snackbarText: null,
    }
  },
  mounted() {
    this.getAllUser()
  },
  computed: {
    headers: function() {
      return [
        {
          text: this.$t('username'),
          align: 'start',
          value: 'username',
        },
        { 
          text: this.$t('lastName'), 
          value: 'lastName' 
        },
        { 
          text: this.$t('firstName'), 
          value: 'firstName' 
        },
        { 
          text: this.$t('email'), 
          value: 'email' 
        },
        { 
          text: this.$t('status'), 
          value: 'status'
        },
        { 
          text: this.$t('actions'), 
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },
  methods: {
    getAllUser() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_USER_LIST_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.appUserList

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    blockUnblock(selectedData) {
      this.selected = selectedData
      this.showBlockUnblockDialog = true
    },
    showInfo(selectedData) {
      this.selected = selectedData
      this.showUserInfoDialog = true
    },
    afterBlockUnblock(updated) {
      this.showBlockUnblockDialog = false

      if (updated) {
        this.getAllUser()
      }
    },
    afterAddUser(updated) {
      this.showAddUserDialog = false

      if (updated) {
        this.showSnackbar = true
        this.snackbarText = this.$t('userAdded')
        this.getAllUser()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
</style>