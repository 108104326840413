<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('enrollSuccess') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <p class="text-h6 mt-4">
        {{ $t('thankYouForRegister') + ':' }}
      </p>

      <p class="text-h4 my-4 font-weight-bold">
        {{ refNo }}
      </p>

      <p class="text-h6">
        {{ $t('thankYouForRegister2') }}
      </p>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        dark
        @click="confirm"
      >
        {{ $t('watchNow') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['refNo'],
  methods: {
    confirm() {
      this.$emit('watchNow')
    },
    closeDialog() {
      this.$emit('close')
    }
  }
}
</script>