<template>
  <v-card width="500">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('course.uploadQAReport') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-subtitle>{{ $t('course.uploadCSVDescn') + ':' }}</v-card-subtitle>
      <v-card-text>{{ '*' + $t('engCsvOnly') }}</v-card-text>

      <v-file-input
        v-model="file"
        show-size
        truncate-length="30"
        :label="$t('course.csvFile')"
        color="#2c76b8"
        accept=".csv"
      />
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="file != null"
        @click="uploadFile"
        :loading="isLoading"
        :disabled="file == null"
      >
        {{ $t('upload') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      file: null,
      isLoading: false,
    }
  },
  methods: {
    closeDialog(evt) {
			evt.preventDefault()
      this.$emit('close')
		},
    uploadFile() {
      const reader = new FileReader()
      reader.onload = e =>{
        var content = e.target.result
        var arrays = this.CSVToArray(content)
        console.log(arrays)

        var targetLineNo = 0
        for (var i in arrays) {
          if (arrays[i][0].startsWith('Question Details')) {
            targetLineNo = parseInt(i)
            break;
          }
        }

        var emailColumnNo = 0, questionColumnNo = 0, answerColumnNo = 0
        var columnNames = arrays[targetLineNo + 1]
        for (var i = 0; i < columnNames.length; i++) {
          if (columnNames[i] == 'Asker Email') {
            emailColumnNo = i
          }

          if (columnNames[i] == 'Question') {
            questionColumnNo = i
          }

          if (columnNames[i] == 'Answer(s)') {
            answerColumnNo = i
          }
        }

        var map = []
        for (var i = targetLineNo + 2; i < arrays.length ; i++) {
          if (arrays[i] == '') {
            continue
          }

          if (arrays[i][emailColumnNo] == "") {
            continue
          }

          map.push({
            email: arrays[i][emailColumnNo],
            question: arrays[i][questionColumnNo],
            answer: arrays[i][answerColumnNo],
          })
        }

        this.uploadReport(map)
      }
      reader.readAsText(this.file)    
    },
    uploadReport(map) {
      this.isLoading = true

      var requestData = {
        qaList: map,
        courseId: this.courseId,
      }

      this.callApi(this.$constant.UPLOAD_QA_REPORT_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
  }
}
</script>