var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('userManagement'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search') + '...',"single-line":"","hide-details":"","color":"orange lighten-1","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"orange lighten-1","dark":""},on:{"click":function($event){_vm.showAddUserDialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('addUser'))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":10,"multi-sort":"","search":_vm.search,"loading":_vm.isLoading,"footer-props":{
        'items-per-page-text': this.$t('rowsPerPage') + ':',        
      },"sort-by":_vm.sortBy,"loading-text":_vm.$t('loading') + '...',"no-results-text":_vm.$t('noRecords'),"no-data-text":_vm.$t('noRecords')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.isEmptyString(item.lastName) ? '-' : item.lastName))])]}},{key:"item.firstName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.isEmptyString(item.firstName) ? '-' : item.firstName))])]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.isEmptyString(item.email) ? '-' : item.email))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status == 'A' ? 'green accent-4' : '#FE253D',"dark":""}},[_vm._v(" "+_vm._s(_vm.$i18n.locale == 'en' ? item.userStatus.EN : item.userStatus.TC)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#2c76b8","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.showInfo(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-information")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('showProfile')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.blockUnblock(item)}}},[_c('v-list-item-icon',[(item.status == 'A')?_c('v-icon',[_vm._v("mdi-account-lock")]):_c('v-icon',[_vm._v("mdi-account-check")])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.status == 'A' ? _vm.$t('block') : _vm.$t('unblock')))])],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"450","persistent":""},model:{value:(_vm.showUserInfoDialog),callback:function ($$v) {_vm.showUserInfoDialog=$$v},expression:"showUserInfoDialog"}},[(_vm.selected)?_c('UserProfile',{attrs:{"appUserId":_vm.selected.appUserId},on:{"close":function($event){_vm.showUserInfoDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"unset","persistent":""},model:{value:(_vm.showBlockUnblockDialog),callback:function ($$v) {_vm.showBlockUnblockDialog=$$v},expression:"showBlockUnblockDialog"}},[(_vm.selected)?_c('BlockUnblock',{attrs:{"appUserId":_vm.selected.appUserId,"status":_vm.selected.status},on:{"close":_vm.afterBlockUnblock}}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.showAddUserDialog),callback:function ($$v) {_vm.showAddUserDialog=$$v},expression:"showAddUserDialog"}},[_c('AddUser',{on:{"close":_vm.afterAddUser}})],1),_c('v-snackbar',{attrs:{"top":"","timeout":"5000","color":"orange lighten-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }