<template>
  <v-app>
    <AppBar ref="appBar" isAboutUs="true" />

    <v-main>
      <v-container style="max-width: 1400px">
        <v-tabs v-model="tab" grow>
          <v-tab href="#tab-class">
            {{ $t("masterClasses") }}
          </v-tab>

          <v-tab href="#tab-competition">
            {{ $t("competitionName") }}
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-class">
              <v-container>
                <v-row class="mt-5">
                  <v-col :cols="!isMobile ? 5 : 12">
                    <h2 class="text-uppercase">
                      {{ $t("enquiry") }}
                    </h2>
                  </v-col>

                  <v-col cols="7" v-if="!isMobile">
                    <h2 class="text-uppercase">
                      {{ $t("achievementStatus") }}
                    </h2>
                  </v-col>
                </v-row>

                <v-row class="pb-2">
                  <v-col :cols="!isMobile ? 5 : 12">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <p class="subtitle-6 text-wrap">
                              {{ $t("fillInfo") + ":" }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col :cols="!isMobile ? 6 : 12" class="pt-0">
                            <v-text-field
                              v-model="refNo"
                              :label="$t('refNo')"
                              prepend-icon="mdi-filter"
                            ></v-text-field>
                          </v-col>

                          <v-col :cols="!isMobile ? 6 : 12" class="pt-0">
                            <v-text-field
                              v-model="email"
                              :label="$t('email')"
                              prepend-icon="mdi-filter"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <vue-recaptcha
                              :sitekey="$constant.RECAPTCHA_SITE_KEY"
                              :loadRecaptchaScript="true"
                              @verify="verify"
                              :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                              @expired="isRobot = true"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="auto">
                            <v-btn
                              color="orange lighten-1"
                              :dark="!isDisabled"
                              @click="enquiry"
                              :disabled="isDisabled"
                            >
                              {{ $t("enquiry") }}
                            </v-btn>
                          </v-col>

                          <v-col cols="1">
                            <v-btn
                              color="orange lighten-1"
                              dark
                              text
                              @click="clear"
                            >
                              {{ $t("clear") }}
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <p class="text-wrap mb-1">
                              {{ "* " + $t("courseEnrollRemainder") }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col v-if="isMobile">
                    <h2 class="text-uppercase">
                      {{ $t("achievementStatus") }}
                    </h2>
                  </v-col>

                  <v-col
                    :cols="!isMobile ? 7 : 12"
                    class="d-flex align-center px-5"
                  >
                    <v-img
                      :src="
                        require('@/assets/images/award/' +
                          (schoolType == 'PRIMARY' ? 'primary' : 'secondary') +
                          '/' +
                          $i18n.locale +
                          '/' +
                          (level > 28 ? 28 : level) +
                          '.png')
                      "
                      class="align-end text-right"
                    >
                      <div
                        class="d-flex justify-end mb-1 mr-1"
                        v-if="!isMobile"
                      >
                        <v-card color="#1CBBC9" style="border-radius: 15px">
                          <v-card-text>
                            <p
                              class="text-center my-0 font-weight-bold text-h6 text-uppercase"
                              style="color: white"
                            >
                              <span v-if="level >= 4">
                                <v-img
                                  :src="
                                    require('@/assets/images/award/' +
                                      rank +
                                      '.png')
                                  "
                                  max-width="80"
                                  contain
                                  class="d-flex align-center"
                                >
                                  <p
                                    class="mb-0 font-weight-bold"
                                    style="color: white"
                                  >
                                    {{ level }}
                                  </p>
                                </v-img>
                              </span>
                              {{ $t("level") }} {{ level }}
                            </p>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-img>
                  </v-col>
                </v-row>
                <!-- <v-col> -->
                <v-row v-if="!isMobile">
                  <v-spacer></v-spacer>

                  <v-col cols="4" class="text-right">
                    <v-btn
                      v-if="
                        ['diamond', 'silver', 'gold', 'bronze'].includes(rank)
                      "
                      color="primary"
                      @click="setIsECertDialogVisible(true)"
                      block
                    >
                      {{ $t("eCert") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!isMobile && $constant.isCareerExperienceEnabled">
                  <v-spacer></v-spacer>

                  <v-col cols="4" class="text-right">
                    <v-btn
                      v-if="rank != undefined"
                      :disabled="rank == 'bronze'"
                      color="success"
                      @click="setIsCareerExpDialogVisible(true)"
                      block
                    >
                      {{ $t("applyForCareerExp") }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!isMobile && $constant.isCareerExperienceEnabled">
                  <v-spacer></v-spacer>

                  <v-col class="text-right">
                    <div
                      v-if="rank == 'bronze' && dataList.length > 0"
                      style="color: #ff5252"
                    >
                      <v-icon class="mr-2" color="error"
                        >mdi-alert-circle-outline</v-icon
                      >
                      {{ $t("careerExpReminder") }}
                    </div>
                  </v-col>
                </v-row>

                <v-card v-if="isMobile" color="#1CBBC9" class="my-3">
                  <v-card-text>
                    <v-row v-if="level >= 4">
                      <v-col class="d-flex justify-center">
                        <v-img
                          :src="
                            require('@/assets/images/award/' + rank + '.png')
                          "
                          max-width="80"
                          contain
                          class="d-flex align-center text-center"
                        >
                          <p class="mb-0 font-weight-bold" style="color: white">
                            {{ level }}
                          </p>
                        </v-img>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p
                          class="text-center my-0 font-weight-bold text-h6"
                          style="color: white"
                        >
                          {{ $t("level") }} {{ level }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <div v-if="isMobile">
                  <v-btn
                    v-if="
                      ['diamond', 'silver', 'gold', 'bronze'].includes(rank)
                    "
                    color="primary"
                    class="mb-3"
                    block
                    @click="setIsECertDialogVisible(true)"
                  >
                    {{ $t("eCert") }}
                  </v-btn>

                  <v-btn
                    v-if="rank != undefined"
                    :disabled="rank == 'bronze'"
                    color="success"
                    class="mb-3"
                    block
                    @click="setIsCareerExpDialogVisible(true)"
                  >
                    {{ $t("applyForCareerExp") }}
                  </v-btn>

                  <div
                    v-if="rank == 'bronze' && dataList.length > 0"
                    class="text-center"
                  >
                    <v-icon color="error" size="40">
                      mdi-alert-circle-outline
                    </v-icon>
                  </div>

                  <p
                    v-if="rank == 'bronze' && dataList.length > 0"
                    class="text-center"
                    style="color: #ff5252"
                  >
                    {{ $t("careerExpReminder") }}
                  </p>
                </div>

                <v-row>
                  <v-col>
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <h2 class="text-uppercase">
                      {{ $t("enrollHistory") }}
                    </h2>

                    <v-btn
                      v-if="
                        isLocalEnv &&
                        dataList.filter((data) => data.isAttended != 'Y')
                          .length > 0
                      "
                      @click="completeAllCourses"
                      depressed
                      color="primary"
                    >
                      complete all courses
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <p class="text-wrap mb-0">
                      {{ "* " + $t("attendanceRemarks") }}
                    </p>
                  </v-col>
                </v-row>

                <v-row>
                  <v-data-table
                    :headers="headers"
                    :items="dataList"
                    :items-per-page="5"
                    class="elevation-1"
                    multi-sort
                    :search="search"
                    :loading="isLoading"
                    :footer-props="{
                      'items-per-page-text': this.$t('rowsPerPage') + ':',
                    }"
                    :sort-by.sync="sortBy"
                    :loading-text="$t('loading') + '...'"
                    :no-results-text="$t('noRecords')"
                    :no-data-text="$t('noRecords')"
                    style="width: 100%"
                    :header-props="{
                      sortByText: this.$t('sortBy'),
                    }"
                  >
                    <template v-slot:[`item.courseNameEn`]="{ item }">
                      <span v-if="item.courseDate >= new Date()">{{
                        item.courseNameEn
                      }}</span>
                      <a
                        v-else
                        style="color: #000000de; text-decoration: underline"
                        @click="showVideo(item)"
                        >{{ item.courseNameEn }}</a
                      >
                    </template>
                    <template v-slot:[`item.courseNameTc`]="{ item }">
                      <span v-if="item.courseDate >= new Date()">{{
                        item.courseNameTc
                      }}</span>
                      <a
                        v-else
                        style="color: #000000de; text-decoration: underline"
                        @click="showVideo(item)"
                        >{{ item.courseNameTc }}</a
                      >
                    </template>
                    <template v-slot:[`item.courseDate`]="{ item }">
                      <span>{{
                        isEmptyString(item.courseDate)
                          ? "-"
                          : formatDate(
                              item.courseDate,
                              $i18n.locale == "en"
                                ? "DD MMM YYYY hh:mm a"
                                : "YYYY年MMMD日 ahh:mm"
                            )
                      }}</span>
                    </template>
                    <template v-slot:[`item.enrollDate`]="{ item }">
                      <span>{{
                        isEmptyString(item.enrollDate)
                          ? "-"
                          : formatDate(
                              item.enrollDate,
                              $i18n.locale == "en"
                                ? "DD MMM YYYY hh:mm a"
                                : "YYYY年MMMD日 ahh:mm"
                            )
                      }}</span>
                    </template>
                    <template v-slot:[`item.isAttended`]="{ item }">
                      <span v-if="item.isAttended == 'NA'">{{ "-" }}</span>
                      <v-icon
                        color="green lighten-1"
                        v-else-if="item.isAttended == 'Y'"
                        >mdi-check</v-icon
                      >
                      <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                      <!-- <span>{{ item.isAttended }}</span> -->
                    </template>
                  </v-data-table>
                </v-row>
              </v-container>
            </v-tab-item>

            <v-tab-item value="tab-competition">
              <v-container>
                <v-row class="mt-5">
                  <v-col>
                    <h2 class="text-uppercase">
                      {{ $t("competitionEnquiry") }}
                    </h2>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <p class="subtitle-6 text-wrap">
                              {{ $t("fillInfo") + ":" }}
                            </p>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col :cols="!isMobile ? 6 : 12" class="pt-0">
                            <v-text-field
                              v-model="refNo"
                              :label="$t('refNo')"
                              prepend-icon="mdi-filter"
                            ></v-text-field>
                          </v-col>

                          <v-col :cols="!isMobile ? 6 : 12" class="pt-0">
                            <v-text-field
                              v-model="email"
                              :label="$t('email')"
                              prepend-icon="mdi-filter"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <vue-recaptcha
                              :sitekey="$constant.RECAPTCHA_SITE_KEY"
                              :loadRecaptchaScript="true"
                              @verify="verify"
                              :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                              @expired="isRobot = true"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="auto">
                            <v-btn
                              color="orange lighten-1"
                              :dark="!isCompetitionEnquiryDisabled"
                              @click="competitionEnquiry"
                              :disabled="isCompetitionEnquiryDisabled"
                            >
                              {{ $t("competitionEnquiry") }}
                            </v-btn>
                          </v-col>

                          <v-col cols="1">
                            <v-btn
                              color="orange lighten-1"
                              dark
                              text
                              @click="clear"
                            >
                              {{ $t("clear") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <h2 class="text-uppercase">
                      {{ $t("enrollHistory") }}
                    </h2>
                  </v-col>
                </v-row>

                <v-row
                  style="padding-right: 6px; padding-left: 6px"
                  class="mt-3 mb-2"
                >
                  <v-data-table
                    :headers="competitionHeaders"
                    :items="competitionList"
                    :items-per-page="5"
                    class="elevation-1"
                    multi-sort
                    :search="search"
                    :loading="isLoading"
                    :footer-props="{
                      'items-per-page-text': this.$t('rowsPerPage') + ':',
                    }"
                    :sort-by.sync="sortBy"
                    :loading-text="$t('loading') + '...'"
                    :no-results-text="$t('noRecords')"
                    :no-data-text="$t('noRecords')"
                    style="width: 100%"
                    :header-props="{
                      sortByText: this.$t('sortBy'),
                    }"
                  >
                    <template v-slot:[`item.isTeam`]="{ item }">
                      <v-icon color="green lighten-1" v-if="item.isTeam"
                        >mdi-check</v-icon
                      >
                    </template>
                    <template v-slot:[`item.material`]="{ item }">
                      <span v-if="item.b64Material">
                        <a
                          class="text-decoration-underline"
                          @click="
                            downloadFile(item.b64Material, $t('material'))
                          "
                        >
                          {{ $t("material") }}
                        </a>
                      </span>
                      <!-- <span>
                        <a :href="'data:application/pdf;base64,' + item.material" download="material.pdf" :title="$t('file')" v-if="item.material" />
                      </span> -->
                      <!-- <span v-if="item.writtenDescription">{{ $t('writtenDescription') }}</span> -->
                      <span v-if="item.b64WrittenDescn" class="ml-3">
                        <a
                          class="text-decoration-underline"
                          @click="
                            downloadFile(
                              item.b64WrittenDescn,
                              $t('writtenDescription2')
                            )
                          "
                        >
                          {{ $t("writtenDescription2") }}
                        </a>
                      </span>
                    </template>
                    <!-- eCert -->
                    <template v-slot:[`item.eCert`]="{ item }">
                      <v-btn
                        v-if="true"
                        color="primary"
                        @click="
                          setIsCompetitionCertDialogVisible(true);
                          competitionCertData = item;
                        "
                        
                      >
                        {{ $t("applyForECert") }}
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-container>
    </v-main>

    <v-dialog
      v-if="showVideoDialog"
      v-model="showVideoDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @playing="playing"
        @ended="ended"
        @close="closeVideo(selected)"
        :videoList="videoList"
        :title="$t('replay')"
        :showAmpowerLogo="false"
        :showClassReplay="true"
      />
    </v-dialog>

    <v-dialog
      v-if="showSurveyDialog"
      v-model="showSurveyDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
      scrollable
    >
      <Survey
        :courseId="selected.courseId"
        :enrollmentId="selected.enrollmentId"
        @close="closeSurvey"
      />
    </v-dialog>

    <v-dialog
      v-model="showEndMsg"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark>
          <v-toolbar-title>
            {{ $t("thx4Watching") + "!" }}
          </v-toolbar-title>
          <!-- <v-spacer />
          <v-btn
            icon
            dark
            @click="showEndMsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-toolbar>

        <v-card-title>
          {{ $t("thx4WatchingContent") + "!" }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="orange lighten-1" dark @click="showEndMsg = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ECertDialog
      v-if="isECertDialogVisible"
      :isVisible="isECertDialogVisible"
      :setIsVisible="setIsECertDialogVisible"
      :email="email"
      :refNo="refNo"
      :name="name"
      :enName="enName"
      :chiName="chiName"
      :rank="rank"
    />
    <CompetitionCertDialog
      v-if="isCompetitionCertDialogVisible"
      :isVisible="isCompetitionCertDialogVisible"
      :setIsVisible="setIsCompetitionCertDialogVisible"
      :email="email"
      :refNo="refNo"
      :enName="enName"
      :competitionCertData="competitionCertData"
    />
    <CareerExpDialog
      v-if="isCareerExpDialogVisible"
      :isVisible="isCareerExpDialogVisible"
      :setIsVisible="setIsCareerExpDialogVisible"
      :email="email"
    />
  </v-app>
</template>

<script>
import AppBar from "./AppBar";
import AppFooter from "@/views/AppFooter";
import ClassCard from "@/views/class/ClassCard";
import VueRecaptcha from "vue-recaptcha";
import YoutubePlayer from "@/components/YoutubePlayer";
import Survey from "@/views/class/Survey";
import ECertDialog from "../components/ECertDialog.vue";
import CareerExpDialog from "../components/CareerExpDialog.vue";
import CompetitionCertDialog from "../components/CompetitionCertDialog.vue";

export default {
  components: {
    AppBar,
    AppFooter,
    ClassCard,
    VueRecaptcha,
    YoutubePlayer,
    Survey,
    ECertDialog,
    CareerExpDialog,
    CompetitionCertDialog,
  },
  data() {
    return {
      dataList: [],
      competitionList: [],
      search: "",
      sortBy: "courseDate",
      selected: null,

      email: "",
      refNo: "",
      // email: "paul.fung.pj@gmail.com",
      // refNo: "507586",
      // email: "sophiapkyap@gmail.com",
      // refNo: "404502",
      isLoading: false,
      isRobot: true,
      schoolType: null,

      showVideoDialog: false,
      showSurveyDialog: false,
      videoList: [],
      showEndMsg: false,
      isWatched: false,
      tab: null,

      name: {},

      enName: "",
      chiName: "",

      isECertDialogVisible: false,
      isCareerExpDialogVisible: false,
      isCompetitionCertDialogVisible: false,

      competitionCertData: {},
    };
  },
  methods: {
    completeAllCourses() {
      console.log("this.dataList", this.dataList);

      this.dataList.forEach((data) => {
        var requestData = {
          courseId: data.courseId,
          enrollmentId: data.enrollmentId,
        };

        this.callApi(
          this.$constant.UPLOAD_SURVEY_FOR_EXPIRED_COURSE_REPORT_URL,
          requestData,
          (resultData) => {
            console.log(resultData);
          },
          () => {
            this.isLoading = false;
          }
        );
      });
    },
    verify(response) {
      var requestData = {
        userResponse: response,
      };
      this.callApi(
        this.$constant.VERIFY_RECAPTCHA_URL,
        requestData,
        (resultData) => {
          this.isRobot = !resultData.result;
        },
        () => {
          this.isLoading = false;
          this.isRobot = true;
        }
      );
    },
    enquiry() {
      this.isLoading = true;

      var requestData = {
        refNo: this.refNo.toLowerCase(),
        email: this.email.toLowerCase(),
      };

      this.callApi(
        this.$constant.ENQUIRY_URL,
        requestData,
        (resultData) => {
          var i = 1;
          this.dataList = resultData.courseList;
          this.dataList.map((d) => {
            d.index = i;
            i++;
          });

          this.schoolType = resultData.schoolType;

          // this.name = {
          //   participantSurname: resultData.participantSurname,
          //   participantGivenName: resultData.participantGivenName,
          //   participantChiName: resultData.participantChiName,
          // };
          // this.enName =
          //   resultData.participantGivenName +
          //   ", " +
          //   resultData.participantSurname;
          this.enName =
            resultData.participantSurname +
            " " +
            resultData.participantGivenName;

          this.chiName = resultData.participantChiName;
          console.log("enName", this.enName);

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    competitionEnquiry() {
      this.isLoading = true;

      var requestData = {
        refNo: this.refNo.toLowerCase(),
        email: this.email.toLowerCase(),
      };

      this.callApi(
        this.$constant.COMPETITION_ENQUIRY_URL,
        requestData,
        (resultData) => {
          console.log("competitionEnquiry", resultData);

          var i = 1;
          this.competitionList = resultData.dataList;
          this.competitionList.map((d) => {
            d.index = i;
            i++;
          });

          // set name
          this.enName =
            resultData.participantSurname +
            " " +
            resultData.participantGivenName;
          this.chiName = resultData.participantChiName;

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    clear() {
      this.refNo = null;
      this.email = null;
      this.schoolType = null;
      this.dataList = [];
      this.competitionList = [];
    },
    showVideo(item) {
      this.videoList = [];
      this.videoList.push({
        id: this.getYoutubeId(item.youtubeLink),
        title:
          this.$i18n.locale == "en" ? item.courseNameEn : item.courseNameTc,
      });

      this.selected = item;
      this.showVideoDialog = true;
    },
    closeVideo(item) {
      this.showVideoDialog = false;
      // if (item.surveyId == undefined) {
      //   this.showSurveyDialog = true
      // }
    },
    closeSurvey() {
      this.showSurveyDialog = false;
      this.enquiry();
      this.showEndMsg = true;
      this.isWatched = false;
    },
    playing() {
      if (!this.isWatched) {
        var requestData = {
          courseId: this.selected.courseId,
          enrollmentId: this.selected.enrollmentId,
        };

        this.callApi(
          this.$constant.MARK_AS_ATTENDED_URL,
          requestData,
          (resultData) => {
            this.isWatched = true;
          },
          () => {}
        );
      }
    },
    ended() {
      this.showVideoDialog = false;
      if (this.selected.surveyId == null) {
        this.showSurveyDialog = true;
      } else {
        this.showEndMsg = true;
        this.enquiry();
        this.isWatched = false;
      }
    },
    downloadFile(data, filename) {
      filename = filename.toLowerCase().replace(" ", "_");

      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename + ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    setIsECertDialogVisible(visible) {
      this.isECertDialogVisible = visible;
    },
    setIsCareerExpDialogVisible(visible) {
      this.isCareerExpDialogVisible = visible;
    },
    setIsCompetitionCertDialogVisible(visible) {
      this.isCompetitionCertDialogVisible = visible;
    },
  },
  computed: {
    headers: function () {
      return [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        {
          text: this.$t("course.name"),
          align: "start",
          value: this.$i18n.locale == "en" ? "courseNameEn" : "courseNameTc",
          width: "50%",
        },
        {
          text: this.$t("refNo"),
          value: "refNo",
        },
        {
          text: this.$t("course.courseDate"),
          value: "courseDate",
        },
        {
          text: this.$t("course.enrollDate"),
          value: "enrollDate",
        },
        {
          text: this.$t("course.completed"),
          value: "isAttended",
        },
      ];
    },
    competitionHeaders: function () {
      return [
        {
          text: "#",
          align: "start",
          value: "index",
        },
        {
          text: this.$t("course.name"),
          align: "start",
          value: this.$i18n.locale == "en" ? "nameEn" : "nameTc",
          width: "50%",
        },
        {
          text: this.$t("joinAsTeam"),
          value: "isTeam",
        },
        {
          text: this.$t("competitionMaterial"),
          value: "material",
        },
        {
          text: this.$t("eCert"),
          value: "eCert",
        },
      ];
    },
    isDisabled: function () {
      if (this.isLocalEnv) {
        return this.isEmptyString(this.refNo) || this.isEmptyString(this.email);
      } else {
        return (
          this.isRobot ||
          this.isEmptyString(this.refNo) ||
          this.isEmptyString(this.email)
        );
      }
    },
    isCompetitionEnquiryDisabled: function () {
      if (this.isLocalEnv) {
        return this.isEmptyString(this.refNo) || this.isEmptyString(this.email);
      } else {
        return (
          this.isRobot ||
          this.isEmptyString(this.refNo) ||
          this.isEmptyString(this.email)
        );
      }
    },
    level: function() {
      if (this.dataList == undefined) {
        return 0;
      }

      var list = this.dataList.filter((d) => {
        return (
          d.isAttended == "Y" &&
          d.courseNameEn !=
            "Career Planning App (Proof of Concept) Design Competition Briefing" &&
          d.courseNameTc != "職業規劃應用程式(概念化)設計比賽簡介會"
        );
      });

      return list.length > 28 ? 28 : list.length;
    },
    rank: function() {
      if (this.schoolType != "PRIMARY") {
        if (this.level < 4) {
          return null;
        }

        if (this.level < 6) {
          return "bronze";
        }

        if (this.level < 9) {
          return "silver";
        }

        if (this.level < 10) {
          return "gold";
        }

        return "diamond";
      } else {
        if (this.level < 2) {
          return null;
        }

        if (this.level == 2) {
          return "bronze";
        }

        if (this.level == 3) {
          return "silver";
        }

        if (this.level == 4) {
          return "gold";
        }

        return "diamond";
      }
    },
  },
};
</script>
