<template>
  <v-card class="pm-cms">
    <v-card-title>
      {{ $t("partnershipManagement") }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>
      <v-btn
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="showEditDialog = true"
      >
        {{ $t("addPartnership") }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
      style="white-space: pre"
    >
      <template v-slot:[`item.imageB64`]="{ item }">
        <div
          class="d-flex align-center justify-center"
          v-if="item.imageB64 != null"
        >
          <img
            class="py-1"
            width="50"
            :src="'data:image/png;base64,' + item.imageB64"
          />
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2c76b8" dark icon v-bind="attrs" v-on="on">
              <v-icon dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <!--  -->
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("updateInfo") }}</v-list-item-title>
            </v-list-item>
            <!--  -->
            <v-list-item @click="onShowCareerListDialogBtnClick(item)">
              <v-list-item-icon>
                <v-icon>mdi-format-list-checkbox</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("listOfCareers") }}</v-list-item-title>
            </v-list-item>
            <!--  -->
            <v-list-item @click="removePartnership(item)">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="80%"
      scrollable
      persistent
    >
      <PartnerShipFormDialog @close="onCloseDialog" :data="selected" />
    </v-dialog>

    <v-dialog
      v-if="showCareerListDialog"
      v-model="showCareerListDialog"
      width="80%"
      scrollable
      persistent
    >
      <v-card width="1000">
        <v-toolbar color="#2c76b8" dark>
          <v-toolbar-title class="text-uppercase">
            {{ $t("partnershipManagement") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCareerListDialogClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <CareerManagement :filters="careerFilter" />
      </v-card>
    </v-dialog>

    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          :loading="isLoading"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import PartnerShipFormDialog from "./PartnerShipFormDialog.vue";
import CareerManagement from "../career/CareerManagement.vue";

export default {
  components: {
    PartnerShipFormDialog,
    CareerManagement,
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: "",
      dataList: [],
      sortBy: "schoolNameEn",
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,

      showCareerListDialog: false,
      careerFilter: [],
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    headers: function() {
      const isEn = this.$i18n.locale == "en";
      return [
        {
          // text: this.$t("image"),
          value: "imageB64",
          sortable: false,
          filterable: false,
        },
        {
          text: this.$t("companyName"),
          value: isEn ? "partnershipNameEn" : "partnershipNameTc",
        },
        {
          text: this.$t("companyWebsite"),
          value: "link",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    removePartnership(item) {
      const isEn = this.$i18n.locale == "en";

      this.callApi(
        this.$constant.REMOVE_PARTNERSHIP_URL,
        { id: item.partnershipId },
        (resultData) => {
          console.log(resultData);

          if (resultData.code == 0) {
            let msg = this.$t("generalRemoveSuccess", [
              isEn ? item.partnershipNameEn : item.partnershipNameTc,
            ]);

            this.$root.$emit("popSnackBar", msg, "green");
          } else {
            this.$root.$emit("popSnackBar", "generalError", "error");
          }

          this.isLoading = false;
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
    getAll() {
      this.isLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GET_PARTNERSHIP_LIST_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.partnershipList.map((item) => {
            return this.objectDecode(item, ["b64FileName", "b64FileName"]);
          });

          console.log(this.dataList);

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    edit(item) {
      this.selected = item;
      this.showEditDialog = true;
    },
    onCloseDialog() {
      this.showEditDialog = false;
      this.selected = {};
      this.getAll();
    },
    onShowCareerListDialogBtnClick(item) {
      this.showCareerListDialog = true;
      this.careerFilter = [
        {
          path: "partnershipId",
          value: item.partnershipId,
        },
      ];
    },
    onCareerListDialogClose() {
      this.showCareerListDialog = false;
      this.careerFilter = [];
      this.getAll();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
.text-start,
td {
  white-space: pre;
}
</style>

<style lang="scss">
.pm-cms {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    max-width: 150 px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
