import CryptoUtil from './CryptoUtil.js';

export const apiServiceMixins = {
  methods: {
    generateKeyPair(callback) {
      CryptoUtil.generateKeyPair((keypair) =>
        callback(keypair)
      )
    },
    hashPassword(password) {
      return CryptoUtil.gHashPassword(password, this.$store.state.user.saltKey)
    },
    async callApi(url, data, successCallBackFunction, failCallBackFunction) {
      const noEncryptionList = [
        this.$constant.GET_ALL_COURSE_URL,
        this.$constant.REGISTER_COURSE_URL,
        this.$constant.USER_LOGIN_URL,
        this.$constant.GET_SALT_KEY_URL,
        this.$constant.GET_ALL_SCHOOL_URL,
        this.$constant.VERIFY_RECAPTCHA_URL,
        this.$constant.GET_ALL_COMPETITION_URL,
        this.$constant.APPLY_COMPETITION_URL,
        this.$constant.ENQUIRY_TO_EDIT_URL,
        this.$constant.SUBMIT_CAREER_EXP_FORM,
        this.$constant.GET_CAREER_EXP_FORM_BY_ID_URL,
        this.$constant.FIND_ENROLLMENT_BY_ID_URL,
        this.$constant.GET_CAREER_CHOICES_BY_ID_URL,
        this.$constant.REMOVE_PARTNERSHIP_URL,
        this.$constant.REMOVE_CAREER_URL,
      ]
      
      // this.$set(data, 'imei', this.$constant.IMEI)
      this.$set(data, 'lang', this.$i18n.locale)
      if (this.$store.state.user != null) {
         this.$set(data, 'userId', this.$store.state.user.userId)
         this.$set(data, 'session', this.$store.state.user.session)
      }

      // this.$set(data, 'userId', 1)

      for (var key in data) {
        if (data.hasOwnProperty(key) && (typeof data[key] === 'string' || data[key] instanceof String)) {
          data[key] = data[key] ? data[key].trim() : null
        }
      }

      console.log(data)

      if (noEncryptionList.indexOf(url) < 0) {
        data = CryptoUtil.encrypt(data)
      }

      await this.axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      })
      .then(response => {
        if(response == null) {
          return
        }

        switch (response.status) {
          case 400:
            this.showNotification('Server returns error code 400 (Bad Request)', 'is-danger')
            break;
          case 401:
            this.showNotification('Server returns error code 400 (Unauthorized)', 'is-danger')
            break;
          case 404:
            this.showNotification('Server returns error code 404 (Not Found)', 'is-danger')
            break;
          case 500:
            this.showNotification('Server returns error code 500 (Interal Server Error)', 'is-danger')
            break;
          case 502:
            this.showNotification('Server returns error code 502 (Bad Gateway)', 'is-danger')
            break;
          case 503:
            this.showNotification('Server returns error code 503 (Service Unavailable)', 'is-danger')
            break;
          default:
            break;
        }

        if (response.status != 200) {
          if(failCallBackFunction != null) {
            failCallBackFunction();
          }
        }
        
        if (response.data != null) {
          switch (response.data.code) {
            case 0:
              if(successCallBackFunction != null) {
                var resultData = response.data.resultData
                if (response.data.encrypted) {
                  resultData = CryptoUtil.decrypt(resultData, this.$store.state.user.priKey)
                }
                console.log(resultData)                
                successCallBackFunction(resultData)
              }
              break
            case -2:
              // this.showNotification(response.data.remarks, 'is-danger')
              alert(response.data.remarks)

              this.$store.commit('logout', resultData)
              // this.hideLoadingPic()
              this.$router.push({path: '/'})
              break
            default:
              // this.showNotification(response.data.remarks, 'is-danger')
              
              if(failCallBackFunction != null) {
                failCallBackFunction()
              }
              break
          }
        } else {
          // this.showNotification('Action is not success, please try again later', 'is-danger')

          if(failCallBackFunction != null) {
            failCallBackFunction();
          }
        }
      })
      .catch( error => {
        // this.showNotification(error.message, 'is-danger')
        // this.showNotification('Action is not success, please try again later', 'is-danger')
        console.error(error)
        if(failCallBackFunction != null) {
          failCallBackFunction();
        }
      });
    }
  }
}