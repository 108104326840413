<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t("competitionManagement") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-text class="px-0"></v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- name -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("name") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.nameEn"
              label="EN"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.nameTc"
              label="繁"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- name -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("scenario") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.scenarioEn"
              label="EN"
              color="#2c76b8"
              clearable
              filled
              no-resize
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.scenarioTc"
              label="繁"
              color="#2c76b8"
              clearable
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- mission -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("mission") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.missionEn"
              label="EN"
              color="#2c76b8"
              clearable
              filled
              no-resize
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.missionTc"
              label="繁"
              color="#2c76b8"
              clearable
              filled
              no-resize
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- toBeIncluded -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("toBeInc") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.toBeIncludedEn"
              label="EN"
              color="#2c76b8"
              clearable
              filled
              no-resize
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.toBeIncludedTc"
              label="繁"
              color="#2c76b8"
              clearable
              filled
              no-resize
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- category_no -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("category") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.categoryNo"
              color="#2c76b8"
              clearable
              counter="200"
              type="number"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <!-- image -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("course.image") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              show-size
              truncate-length="30"
              color="#2c76b8"
              accept=".png, .jpg"
              class="pt-0"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- deadlineDate -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("deadlineDate") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="formData.deadlineDate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.deadlineDate"
                  label="Picker in dialog"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="requiredRules"
                ></v-text-field>
              </template>
              <v-date-picker v-model="formData.deadlineDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(formData.deadlineDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="true"
        @click="uploadFile"
        :loading="isLoading"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      menu: false,
      modal: false,
      menu2: false,

      isLoading: false,
      search: null,

      valid: false,
      requiredRules: [(v) => !!v || this.$t("requiredField")],

      file: null,
      formData: {
        careerNameEn: "",
        careerNameTc: "",

        partnership: {},
      },
      selected: null,
      partnershipList: [],
    };
  },
  created() {
    this.fetchPartnership();
    console.log("created formData", this.formData);

    this.formData = Object.assign(this.formData, this.data);
    if (this.data.imageBase64) {
      this.b64ToFile(this.data.imageBase64, (file) => {
        this.file = file;
      });
    }
    if (this.data.deadlineDate) {
      this.formData.deadlineDate = this.formatDate(
        this.data.deadlineDate,
        "YYYY-MM-DD"
      );
      console.log("this.formData.deadlineDate", this.formData.deadlineDate);
    }
  },
  methods: {
    fetchPartnership() {
      console.log("fetchPartnership");
      this.callApi(
        this.$constant.GET_PARTNERSHIP_LIST_URL,
        {},
        (resultData) => {
          console.log("22");
          this.partnershipList = resultData.partnershipList.map((item) => {
            return this.objectDecode(item);
          });

          console.log(this.partnershipList);

          this.isLoading = false;
        },
        (e) => {
          console.log(e);
          this.isLoading = false;
        }
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    closeDialog(evt) {
      evt.preventDefault();
      this.$emit("close");
    },
    uploadFile() {
      this.validate();
      if (this.valid == false) {
        return;
      }
      this.isLoading = true;
      const reader = new FileReader();

      if (this.file) {
        this.compress(this.file, (image) => {
          console.log("1");
          reader.onload = (e) => {
            console.log("2");
            let formData = this.formData;

            // formData = this.objectEncode(formData);

            formData.imageB64 = e.target.result;

            var requestData = {
              data: formData,
            };

            this.callApi(
              this.$constant.SAVE_COMPETITION_URL,
              requestData,
              (resultData) => {
                console.log("1");
                console.log(resultData);
                this.isLoading = false;
                this.$emit("close");
              },
              () => {
                console.log("2");
                this.isLoading = false;
                this.$emit("message", this.$t("fail"));
              }
            );
          };
          reader.readAsDataURL(image);
        });
      } else {
        console.log("??");
        this.isLoading = false;
      }
    },
  },
};
</script>
