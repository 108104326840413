export const awardList = [
  //
  {
    competitionName: {
      en: "Career Compass & Skills Ladder Progression Mapping Competition",
      tc: "職業指南針和技能階梯發展圖比賽",
    },
    awards: [
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        awardName: {
          en: "Winner",
          tc: "冠軍",
        },
        awardIcon: "mdi-podium-gold",
        iconColor: "amber accent-3",
        titleColor: "amber--text",
        applicants: [
          {
            en: "Ho Nga Chi Megan",
            tc: "何雅慈",
          },
          {
            en: "Chan Tsz Hei",
            tc: "陳紫熙"
          },
        ],
      },
      {
        schoolName: {
          en: "St. Paul's Secondary School",
          tc: "聖保祿中學",
        },
        awardName: {
          en: "1st Runner Up",
          tc: "亞軍",
        },
        awardIcon: "mdi-podium-silver",
        iconColor: "grey",
        titleColor: "grey--text",
        applicants: [
          {
            en: "Choi Lok Yin",
            tc: "蔡樂言",
          },
          {
            en: "Leung Kwun Yung",
            tc: "梁冠榕",
          },
          {
            en: "Ng Yeuk Yu",
            tc: "吳渃渝",
          },
          {
            en: "Chim Tin Wing Cynthia",
            tc: "詹天穎",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
        awardName: {
          en: "2nd Runner Up",
          tc: "季軍",
        },
        awardIcon: "mdi-podium-bronze",
        iconColor: "brown darken-1",
        titleColor: "brown--text",
        applicants: [
          {
            en: "Li Tiantian",
            tc: "李甜甜",
          },
          {
            en: "Chow Wing Chun",
            tc: "巢穎晉",
          },
          {
            en: "So Long Hin",
            tc: "蘇朗軒",
          },
          {
            en: "Du Yan Shun",
            tc: "杜欣淳",
          },
        ],
      },
      {
        schoolName: {
          en: "Liu Po Shan Memorial College",
          tc: "廖寶珊紀念書院",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "WONG SIU PANG",
            tc: "黃小鵬",
          },
          {
            en: "LEUNG YIK CHUN",
            tc: "梁亦竣",
          },
        ],
      },
      {
        schoolName: {
          en: "Tsuen Wan Governmentsecondary School",
          tc: "荃灣官立中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Lau Yuet Ching",
            tc: "劉悅晴",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "CV Module Building Competition",
      tc: "履歷模塊構建比賽",
    },
    awards: [
      {
        schoolName: {
          en: "Pentecostal Lam Hon Kwong School",
          tc: "五旬節林漢光中學",
        },
        awardName: {
          en: "Winner",
          tc: "冠軍",
        },
        awardIcon: "mdi-podium-gold",
        iconColor: "amber accent-3",
        titleColor: "amber--text",
        applicants: [
          {
            en: "Chu Hui Chyun",
            tc: "朱栩鋑",
          },
        ],
      },
      {
        schoolName: {
          en: "Good Hope School",
          tc: "德望學校",
        },
        awardName: {
          en: "1st Runner Up",
          tc: "亞軍",
        },
        awardIcon: "mdi-podium-silver",
        iconColor: "grey",
        titleColor: "grey--text",
        applicants: [
          {
            en: "Lam Hau Laam",
            tc: "林巧嵐",
          },
        ],
      },
      {
        schoolName: {
          en: "Buddhist Sin Tak College",
          tc: "佛教善德英文中學",
        },
        awardName: {
          en: "2nd Runner Up",
          tc: "季軍",
        },
        awardIcon: "mdi-podium-bronze",
        iconColor: "brown darken-1",
        titleColor: "brown--text",
        applicants: [
          {
            en: "Chan Cham Hei",
            tc: "陳湛熙",
          },
        ],
      },
      {
        schoolName: {
          en: "C.C.C. Kei Chi Secondary School",
          tc: "中華基督教會基智中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Chan Chak Fung",
            tc: "陳澤鋒",
          },
        ],
      },
      {
        schoolName: {
          en: "Fukien Secondary School",
          tc: "福建中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Cheung Chun Ming",
            tc: "張晉鳴",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "Essential Skills & Personality Traits X-Ray Competition",
      tc: "基本技能和個性特質解構比賽",
    },
    awards: [
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        awardName: {
          en: "Winner",
          tc: "冠軍",
        },
        awardIcon: "mdi-podium-gold",
        iconColor: "amber accent-3",
        titleColor: "amber--text",
        applicants: [
          {
            en: "Ho Nga Chi Megan",
            tc: "何雅慈",
          },
          {
            en: "Chan Tsz Hei",
            tc: "陳紫熙",
          },
        ],
      },
      {
        schoolName: {
          en: "NT Heung Yee Kuk Yuen Long District Secondary School",
          tc: "新界鄉議局元朗區中學",
        },
        awardName: {
          en: "1st Runner Up",
          tc: "亞軍",
        },
        awardIcon: "mdi-podium-silver",
        iconColor: "grey",
        titleColor: "grey--text",
        applicants: [
          {
            en: "Cai Ching Hang",
            tc: "蔡政恒",
          },
          {
            en: "Kung Ka Ho",
            tc: "龔嘉灝",
          },
          {
            en: "Chan Yu Chung",
            tc: "陳譽中",
          },
        ],
      },
      {
        schoolName: {
          en: "Holy Trinity College",
          tc: "寶血會上智英文書院",
        },
        awardName: {
          en: "2nd Runner Up",
          tc: "季軍",
        },
        awardIcon: "mdi-podium-bronze",
        iconColor: "brown darken-1",
        titleColor: "brown--text",
        applicants: [
          {
            en: "Ng Vincy",
            tc: "吳穎姿",
          },
        ],
      },
      {
        schoolName: {
          en: "Liu Po Shan Memorial College",
          tc: "廖寶珊紀念書院",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "LIN Shuqing",
            tc: "林舒晴",
          },
          {
            en: "Cheng Yuet",
            tc: "鄭悅",
          },
        ],
      },
      {
        schoolName: {
          en: "Pope Paul Vi College",
          tc: "保祿六世書院",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Kwan Yu Ching",
            tc: "關羽晴",
          },
          {
            en: "Wong Man Yiu",
            tc: "黃敏瑤",
          },
          {
            en: "Wong Tsz Ka",
            tc: "黃子嘉",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "Graphical User Interface & Design Competition",
      tc: "圖形用戶界面及設計比賽",
    },
    awards: [
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        awardName: {
          en: "Winner",
          tc: "冠軍",
        },
        awardIcon: "mdi-podium-gold",
        iconColor: "amber accent-3",
        titleColor: "amber--text",
        applicants: [
          {
            en: "Lam Yan Tung Jovie",
            tc: "林欣彤",
          },
        ],
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        awardName: {
          en: "1st Runner Up",
          tc: "亞軍",
        },
        awardIcon: "mdi-podium-silver",
        iconColor: "grey",
        titleColor: "grey--text",
        applicants: [
          {
            en: "Cheung Alice Hiu Yu",
            tc: "張曉榆",
          },
          {
            en: "Chui Cheuk Ying",
            tc: "徐卓盈",
          },
        ],
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        awardName: {
          en: "2nd Runner Up",
          tc: "季軍",
        },
        awardIcon: "mdi-podium-bronze",
        iconColor: "brown darken-1",
        titleColor: "brown--text",
        applicants: [
          {
            en: "Ho Megan",
            tc: "何雅慈",
          },
          {
            en: "Chan Tsz Hei",
          },
        ],
      },
      {
        schoolName: {
          en: "NT Heung Yee Kuk Yuen Long District Secondary School",
          tc: "新界鄉議局元朗區中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Cai Ching Hang",
            tc: "蔡政恒",
          },
          {
            en: "Kung Ka Ho",
            tc: "龔嘉灝",
          },
          {
            en: "Chan Yu Chung",
            tc: "陳譽中",
          },
        ],
      },
      {
        schoolName: {
          en: "TWGHs Wong Fut Nam College",
          tc: "東華三院黃笏南中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Lau Hoi Lok Ian",
            tc: "劉凱樂",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "Social Media Marketing Competition",
      tc: "社交媒體營銷比賽",
    },
    awards: [
      {
        schoolName: {
          en: "TWGHs Wong Fut Nam College",
          tc: "東華三院黃笏南中學",
        },
        awardName: {
          en: "Winner",
          tc: "冠軍",
        },
        awardIcon: "mdi-podium-gold",
        iconColor: "amber accent-3",
        titleColor: "amber--text",
        applicants: [
          {
            en: "Lau Hoi Lok Ian",
            tc: "劉凱樂",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
        awardName: {
          en: "1st Runner Up",
          tc: "亞軍",
        },
        awardIcon: "mdi-podium-silver",
        iconColor: "grey",
        titleColor: "grey--text",
        applicants: [
          {
            en: "So Nga Suet",
            tc: "蘇雅雪",
          },
          {
            en: "Kuang Waiki",
            tc: "鄺瑋琪",
          },
          {
            en: "Cheung Sau Wan",
            tc: "張秀云",
          },
        ],
      },
      {
        schoolName: {
          en: "C.C.C. Mong Man Wai College",
          tc: "中華基督教會蒙民偉書院",
        },
        awardName: {
          en: "2nd Runner Up",
          tc: "季軍",
        },
        awardIcon: "mdi-podium-bronze",
        iconColor: "brown darken-1",
        titleColor: "brown--text",
        applicants: [
          {
            en: "Ho Sing Yau",
            tc: "何昇祐",
          },
          {
            en: "Yu Cheuk Chun",
            tc: "余綽臻",
          },
          {
            en: "Lo Chun Kwan",
            tc: "盧俊均",
          },
        ],
      },
      {
        schoolName: {
          en: "Fanling Kau Yan College",
          tc: "粉嶺救恩書院",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Chan Kwan YI",
            tc: "陳君怡",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
        awardName: {
          en: "Outstanding Student Award",
          tc: "優秀學生獎",
        },
        applicants: [
          {
            en: "Leung Yiu Tung",
            tc: "梁耀東",
          },
          {
            en: "WEN LOK YI",
            tc: "温樂誼",
          },
          {
            en: "CHAN HIU YU",
            tc: "陳曉宇",
          },
          {
            en: "CHEUNG WAI MAN",
            tc: "張慧敏",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "Stem Ambassador School Award (Competition)",
    },
    awards: [
      {
        schoolName: {
          en: "C.C.C. Kei Chi Secondary School",
          tc: "中華基督教會基智中學",
        },
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
      },
      {
        schoolName: {
          en: "HKCWC Fung Yiu King Memorial Secondary School",
          tc: "香港中國婦女會馮堯敬紀念中學",
        },
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
      },
      {
        schoolName: {
          en: "Liu Po Shan Memorial College",
          tc: "廖寶珊紀念書院",
        },
      },
      {
        schoolName: {
          en: "Lok Sin Tong Leung Kau Kui College",
          tc: "樂善堂梁銶琚書院",
        },
      },
      {
        schoolName: {
          en: "TWGHs S C Gaw Memorial College",
          tc: "東華三院吳祥川紀念中學",
        },
      },
    ],
  },
  //
  {
    competitionName: {
      en: "STEM Ambassador School Award (Master Class) ",
    },
    awards: [
      {
        schoolName: {
          en: "Christian Alliance Cheng Wing Gee College",
          tc: "香港九龍塘基督教中華宣道會鄭榮之中學",
        },
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
      },
      {
        schoolName: {
          en: "Fanling Government Secondary School",
          tc: "粉嶺官立中學",
        },
      },
      {
        schoolName: {
          en: "Good Hope School",
          tc: "德望學校",
        },
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
      },
      {
        schoolName: {
          en: "Liu Po Shan Memorial College",
          tc: "廖寶珊紀念書院",
        },
      },
      {
        schoolName: {
          en: "Sheung Shui Government Secondary School",
          tc: "上水官立中學",
        },
      },
      {
        schoolName: {
          en: "Tung Wah Group of Hospital Lo Kon Ting Memorial College",
          tc: "東華三院盧幹庭紀念中學",
        },
      },
      {
        schoolName: {
          en: "Yan Chai Hospital Tung Chi Ying Memorial Secondary School",
          tc: "仁濟醫院董之英紀念中學",
        },
      },
    ],
  },
  //
  {
    competitionName: {
      en: "STEM Ambassador Teacher Award",
    },
    awards: [
      {
        schoolName: {
          en: "Good Hope School",
          tc: "德望學校",
        },
        applicants: [
          {
            en: "Edwin Chu",
          },
        ],
      },
      {
        schoolName: {
          en: "Tung Wah Group of Hospital Lo Kon Ting Memorial College",
          tc: "東華三院盧幹庭紀念中學",
        },
        applicants: [
          {
            en: "CHIU SIU LEONG",
            tc: "趙小良",
          },
        ],
      },
      {
        schoolName: {
          en: "Yan Chai Hospital Tung Chi Ying Memorial Secondary School",
          tc: "仁濟醫院董之英紀念中學",
        },
        applicants: [
          {
            en: "CHUNG SIU MING",
            tc: "鍾紹明",
          },
        ],
      },
      {
        schoolName: {
          en: "Sheung Shui Government Secondary School",
          tc: "上水官立中學",
        },
        applicants: [
          {
            en: "LEE PUI SHEUNG",
            tc: "李佩嫦",
          },
        ],
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        applicants: [
          {
            en: "Ng Siu Wah",
            tc: "吳少華",
          }
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
        applicants: [
          {
            en: "Davy Ng Tsz Kit",
            tc: "吳子傑",
          },
        ],
      },
      {
        schoolName: {
          en: "Liu Po Shan Memorial College",
          tc: "廖寶珊紀念書院",
        },
        applicants: [
          {
            en: "TONG CHEUK CHUN",
            tc: "唐卓俊",
          },
        ],
      },
      {
        schoolName: {
          en: "Christian Alliance Cheng Wing Gee College",
          tc: "香港九龍塘基督教中華宣道會鄭榮之中學",
        },
        applicants: [
          {
            en: "WEN HUA YAN",
            tc: "溫華恩",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Teachers' Association Lee Heng Kwei Secondary School",
          tc: "香港教師會李興貴中學",
        },
        applicants: [
          {
            en: "WONG TING FUNG",
            tc: " 黄廷峰",
          },
        ],
      },
      {
        schoolName: {
          en: "Fanling Government Secondary School",
          tc: "粉嶺官立中學",
        },
        applicants: [
          {
            en: "WONG WAI CHING",
            tc: "黃慧清",
          },
        ],
      },
      {
        schoolName: {
          en: "Diocesan Girls' School",
          tc: "拔萃女書院",
        },
        applicants: [
          {
            en: "Wong Wai Ying",
            tc: "王慧瑩",
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "STEM Proactive and Inquirying Minds Award (Primary)",
    },
    awards: [
      {
        schoolName: {
          en: "Maryknoll Convent School (Primary Section)",
          tc: "瑪利諾修院學校(小學部)",
        },
        applicants: [
          {
            en: "Faith Lee",
          },
        ],
      },
      {
        schoolName: {
          en: "The Hong Kong Chinese Christian Churches Union Logos Academy",
          tc: "真道小學",
        },
        applicants: [
          {
            en: "Au Shun Long Colin",
            tc: "區信朗"
          },
        ],
      },
      {
        schoolName: {
          en: "FDBWA Chow Chin Yau School",
          tc: "五邑鄒振猷學校",
        },
        applicants: [
          {
            en: "Cheung Lok Hei",
            tc: "張樂希"
          },
        ],
      },
      {
        schoolName: {
          en: "S.K.H. Holy Spirit Primary School",
          tc: "聖公會主風小學",
        },
        applicants: [
          {
            en: "Chu Hui Tak",
            tc: "朱栩德",
          },
        ],
      },
      {
        schoolName: {
          en: "S.K.H. St. Clement's Primary School",
          tc: "聖公會聖紀文小學",
        },
        applicants: [
          {
            tc: "李文浩",
          },
        ],
      },
      {
        schoolName: {
          en: "San Wui Commercial Society School",
          tc: "新會商會學校",
        },
        applicants: [
          {
            en: "LO MAN HO",
            tc: "羅文豪"
          },
        ],
      },
      {
        schoolName: {
          en: "St. Hilary’s Primary School",
          tc: "德萃小學",
        },
        applicants: [
          {
            en: "Choi Ho Hang",
            tc: "蔡皓行"
          },
        ],
      },
      {
        schoolName: {
          en: "International Christian Quality Music Secondary And Primary School (Primary Section)",
          tc: "國際基督教優質音樂中學暨小學 (小學部)",
        },
        applicants: [
          {
            en: "Chan Hin Yuk",
            tc: "陳顯旭"
          },
        ],
      },
      {
        schoolName: {
          en: "Tak Sun School",
          tc: "德信學校",
        },
        applicants: [
          {
            en: "Scofield Wong ",
            tc: "黃傲廷"
          },
        ],
      },
    ],
  },
  //
  {
    competitionName: {
      en: "STEM Proactive and Inquirying Minds Award (Secondary)",
    },
    awards: [
      {
        schoolName: {
          en: "C.C.C. Kei Chi Secondary School",
          tc: "中華基督教會基智中學",
        },
        applicants: [
          {
            en: "Chan Chak Fung",
            tc: "陳澤鋒"
          },
        ],
      },
      {
        schoolName: {
          en: "Pentecostal Lam Hon Kwong School",
          tc: "五旬節林漢光中學",
        },
        applicants: [
          {
            en: "CHU HUI CHYUN",
            tc: "朱栩鋑"
          },
        ],
      },
      {
        schoolName: {
          en: "TWGHs Lo Kon Ting Memorial College",
          tc: "東華三院盧幹庭紀念中學",
        },
        applicants: [
          {
            en: "KwanChak Ki Martin",
            tc: "關澤祺"
          },
        ],
      },
      {
        schoolName: {
          en: "Po Leung Kuk Laws Foundation College",
          tc: "保良局羅氏基金中學",
        },
        applicants: [
          {
            en: "LAM YUI SAN",
            tc: "林睿申"
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
        applicants: [
          {
            en: "LEUNG CHEUK LAM",
            tc: "梁卓琳",
          },
        ],
      },
      {
        schoolName: {
          en: "Catholic Ming Yuen Secondary School",
          tc: "天主教鳴遠中學",
        },
        applicants: [
          {
            en: "Andy Li",
            tc: "李建鋒"
          },
        ],
      },
      {
        schoolName: {
          en: "St. Teresa Secondary School",
          tc: "德蘭中學",
        },
        applicants: [
          {
            en: "Liu Ka Kwan",
            tc: "劉家君"
          },
        ],
      },
      {
        schoolName: {
          en: "Good Hope School",
          tc: "德望學校",
        },
        applicants: [
          {
            en: "Lo Yik Sze",
            tc: "勞奕斯",
          },
        ],
      },
      {
        schoolName: {
          en: "Raimondi College",
          tc: "高主教書院",
        },
        applicants: [
          {
            en: "OSCAR MAN ",
            tc: "文樂軒"
          },
        ],
      },
      {
        schoolName: {
          en: "TWGHs Lo Kon Ting Memorial College",
          tc: "東華三院盧幹庭紀念中學",
        },
        applicants: [
          {
            en: "Rai Amisha",
            tc: "韋美霞"
          },
        ],
      },
      {
        schoolName: {
          en: "St. Teresa Secondary School",
          tc: "德蘭中學",
        },
        applicants: [
          {
            en: "Tsoi Lok Yi",
            tc: "蔡樂怡"
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
        applicants: [
          {
            en: "WONG YI LAAM JADE",
            tc: "黃怡嵐",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
        applicants: [
          {
            en: "WONG YAN HEI ANSON",
            tc: "王昕熹",
          },
        ],
      },
      {
        schoolName: {
          en: "Hong Kong Chinese Women's Club College",
          tc: "香港中國婦女會中學",
        },
        applicants: [
          {
            en: "WONG YING LAM",
            tc: "黃櫻琳",
          },
        ],
      },
      {
        schoolName: {
          en: "Cheung Chuk Shan College",
          tc: "張祝珊英文中學",
        },
        applicants: [
          {
            en: "Yu Tsz Hin",
            tc: "余梓軒"
          },
        ],
      },
    ],
  },


  //
  {
    competitionName: {
      en: "STEM Talent Award",
    },
    awards: [
      {
        schoolName: {
          en: "International Christian Quality Music Secondary And Primary School (Primary Section)",
          tc: "國際基督教優質音樂中學暨小學 (小學部)",
        },
        applicants: [
          {
            en: "Chan Hin Yuk",
            tc: "陳顯旭"
          },
        ],
      },
      {
        schoolName: {
          en: "S.K.H. Holy Spirit Primary School",
          tc: "聖公會主風小學",
        },
        applicants: [
          {
            en: "Chu Hui Tak",
            tc: "朱栩德",
          },
        ],
      },
      {
        schoolName: {
          en: "Fukien Secondary School",
          tc: "福建中學",
        },
        applicants: [
          {
            en: "Cheung Chun Ming",
            tc: "張晉鳴",
          },
        ],
      },
      {
        schoolName: {
          en: "Islamic Kasim Tuet Memorial College",
          tc: "伊斯蘭脫維善紀念中學",
        },
        applicants: [
          {
            en: "Khan Ummar Haryat",
          },
        ],
      },
      {
        schoolName: {
          en: "FDBWA Szeto Ho Secondary School",
          tc: "五邑司徒浩中學",
        },
        applicants: [
          {
            en: "Ng Yan Ting",
            tc: "吳欣婷",
          },
        ],
      },
      {
        schoolName: {
          en: "Islamic Kasim Tuet Memorial College",
          tc: "伊斯蘭脫維善紀念中學",
        },
        applicants: [
          {
            en: "Rahman Md Aniq",
          },
        ],
      },
      {
        schoolName: {
          en: "TWGHs Lo Kon Ting Memorial College",
          tc: "東華三院盧幹庭紀念中學",
        },
        applicants: [
          {
            en: "Rai Amisha",
            tc: "韋美霞",
          },
        ],
      },
      {
        schoolName: {
          en: "Shatin Pui Ying College",
          tc: "沙田培英中學",
        },
        applicants: [
          {
            en: "She Chun Hin",
            tc: "施駿軒"
          },
        ],
      },
    ],
  },
];
