<template>
  <v-card>
    <v-card-title>
      {{ $t("participantManagement") }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>

      <v-btn
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="genAwardReport"
        :loading="isGenAwardReportLoading"
      >
        {{ $t("awardReport") }}
      </v-btn>

      <v-btn
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="exportData"
        :loading="isExportLoading"
      >
        {{ $t("course.exportData") }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
    >
      <template v-slot:[`item.yearGroup`]="{ item }">
        <span>{{ isEmptyString(item.yearGroup) ? "-" : item.yearGroup }}</span>
      </template>
      <template v-slot:[`item.classLevel`]="{ item }">
        <span>{{
          isEmptyString(item.classLevel) ? "-" : item.classLevel
        }}</span>
      </template>
      <template v-slot:[`item.isTeacher`]="{ item }">
        <v-icon
          color="green lighten-1"
          v-if="item.isTeacher == true || item.isTeacher == 'Y'"
          >mdi-check</v-icon
        >
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'green accent-4' : '#FE253D'" dark>
          {{ $i18n.locale == "en" ? item.userStatus.EN : item.userStatus.TC }}
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2c76b8" dark icon v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("updateInfo") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="80%"
      scrollable
      persistent
    >
      <EditInfo
        @close="onCloseDialog"
        v-if="selected"
        :participant="selected"
      />
    </v-dialog>

    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import EditInfo from "./EditInfo.vue";
import CSVFile from "@/components/common/CSVFile";

export default {
  components: {
    EditInfo,
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: "",
      dataList: [],
      sortBy: "emailAddress",
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,
      isGenAwardReportLoading: false,
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    headers: function() {
      return [
        {
          text: this.$t("email"),
          align: "start",
          value: "emailAddress",
        },
        {
          text: this.$t("school"),
          value: "schoolName",
        },
        {
          text: this.$t("teacher"),
          value: "isTeacher",
        },
        {
          text: this.$t("yearGroup"),
          value: "yearGroup",
        },
        {
          text: this.$t("class"),
          value: "classLevel",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getAll() {
      this.isLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GET_ALL_PARTICIPANT_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.participantList;

          this.isLoading = false;
        },
        (e) => {
          console.log("e", e);
          this.isLoading = false;
        }
      );
    },
    edit(item) {
      this.selected = item;
      this.showEditDialog = true;
    },
    onCloseDialog() {
      this.showEditDialog = false;
      this.getAll();
    },
    exportData() {
      this.isExportLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.EXPORT_PARTCIPANT_DATA_URL,
        requestData,
        (resultData) => {
          var tableHead = [
            this.$t("email"),
            this.$t("surname"),
            this.$t("givenName"),
            this.$t("chiName"),
            this.$t("schoolName") + "(EN)",
            this.$t("schoolName") + "(TC)",
            this.$t("schoolType2"),
            this.$t("teacher"),
            this.$t("yearGroup"),
            this.$t("class"),
            this.$t("course.name") + "(EN)",
            this.$t("course.name") + "(TC)",
            this.$t("course.enrollDate"),
            this.$t("refNo"),
            this.$t("completed"),
            this.$t("bonusScore"),
            this.$t("isEITP"),
            this.$t("isITLab"),
            "Survey answer #1",
            "Survey answer #2",
            "Survey answer #3",
            "Survey answer #4",
            "Survey answer #5",
            "Survey answer #6",
            "Survey answer #7",
            "Survey answer #8",
          ]
          var tableData = [
            {
              email: this.$t("email"),
              participantSurname: this.$t("surname"),
              participantGivenName: this.$t("givenName"),
              participantChiName: this.$t("chiName"),
              schoolNameEn: this.$t("schoolName") + "(EN)",
              schoolNameTc: this.$t("schoolName") + "(TC)",
              schoolType: this.$t("schoolType2"),
              isTeacher: this.$t("teacher"),
              yearGroup: this.$t("yearGroup"),
              classLevel: this.$t("class"),
              courseNameEn: this.$t("course.name") + "(EN)",
              courseNameTc: this.$t("course.name") + "(TC)",
              enrollDate: this.$t("course.enrollDate"),
              refNo: this.$t("refNo"),
              isAttended: this.$t("course.completed"),
              bonusScore: this.$t("bonusScore"),
              isEITP: this.$t("isEITP"),
              isITLab: this.$t("isITLab"),
              a1: "Survey answer #1",
              a2: "Survey answer #2",
              a3: "Survey answer #3",
              a4: "Survey answer #4",
              a5: "Survey answer #5",
              a6: "Survey answer #6",
              a7: "Survey answer #7",
              a8: "Survey answer #8",
            },
          ];
          var tableData = tableData.concat(resultData.dataList);

          var fileTitle = `Export_Data_${this.formatDate(
            new Date(),
            "YYYYMMDD_HHmmss"
          )}`;

          CSVFile(tableHead, null, "Data", tableData, fileTitle, false, () => {
            this.isExportLoading = false;
          });
        },
        () => {}
      );
    },
    genAwardReport() {
      this.isGenAwardReportLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GEN_AWARD_REPORT_URL,
        requestData,
        (resultData) => {
          var tableHead = [
            this.$t("email"),
            this.$t("course.award"),
            this.$t("surname"),
            this.$t("givenName"),
            this.$t("chiName"),
            this.$t("schoolName") + "(EN)",
            this.$t("schoolName") + "(TC)",
            this.$t("schoolType2"),
            this.$t("yearGroup"),
            this.$t("class"),
          ];
          var tableData = [
            {
              bronze: this.$t("bronze") + " (" + this.$t("primarySchool") + ")",
              silver: this.$t("silver") + " (" + this.$t("primarySchool") + ")",
              gold: this.$t("gold") + " (" + this.$t("primarySchool") + ")",
              diamond:
                this.$t("diamond") + " (" + this.$t("primarySchool") + ")",
            },
          ];

          tableData.push({
            bronze:
              resultData.bronzePrimaryStudents + " " + this.$t("students"),
            silver:
              resultData.silverPrimaryStudents + " " + this.$t("students"),
            gold: resultData.goldPrimaryStudents + " " + this.$t("students"),
            diamond:
              resultData.diamondPrimaryStudents + " " + this.$t("students"),
          });

          tableData.push({
            bronze: resultData.bronzePrimarySchools + " " + this.$t("schools"),
            silver: resultData.silverPrimarySchools + " " + this.$t("schools"),
            gold: resultData.goldPrimarySchools + " " + this.$t("schools"),
            diamond:
              resultData.diamondPrimarySchools + " " + this.$t("schools"),
          });

          tableData.push({
            bronze: "",
            silver: "",
            gold: "",
            diamond: "",
          });

          tableData.push({
            bronze: this.$t("bronze") + " (" + this.$t("secondarySchool") + ")",
            silver: this.$t("silver") + " (" + this.$t("secondarySchool") + ")",
            gold: this.$t("gold") + " (" + this.$t("secondarySchool") + ")",
            diamond:
              this.$t("diamond") + " (" + this.$t("secondarySchool") + ")",
          });

          tableData.push({
            bronze:
              resultData.bronzeSecondaryStudents + " " + this.$t("students"),
            silver:
              resultData.silverSecondaryStudents + " " + this.$t("students"),
            gold: resultData.goldSecondaryStudents + " " + this.$t("students"),
            diamond:
              resultData.diamondSecondaryStudents + " " + this.$t("students"),
          });

          tableData.push({
            bronze:
              resultData.bronzeSecondarySchools + " " + this.$t("schools"),
            silver:
              resultData.silverSecondarySchools + " " + this.$t("schools"),
            gold: resultData.goldSecondarySchools + " " + this.$t("schools"),
            diamond:
              resultData.diamondSecondarySchools + " " + this.$t("schools"),
          });

          var fileTitle =
            `Award_Stat_Report` +
            `_${this.formatDate(new Date(), "YYYYMMDD_HHmmss")}`;

          CSVFile(
            tableHead,
            null,
            "Data",
            tableData,
            fileTitle,
            false,
            () => {}
          );

          tableData = [];

          tableData.push({
            email: this.$t("email"),
            award: this.$t("course.award"),
            participantSurname: this.$t("surname"),
            participantGivenName: this.$t("givenName"),
            participantChiName: this.$t("chiName"),
            schoolNameEn: this.$t("schoolName") + "(EN)",
            schoolNameTc: this.$t("schoolName") + "(TC)",
            schoolType: this.$t("schoolType2"),
            yearGroup: this.$t("yearGroup"),
            classLevel: this.$t("class"),
            refNo: this.$t("refNo"),
          });

          var dataList = resultData.awardObjList.map((e) => {
            return {
              email: e.email,
              award: e.award ? this.$t(e.award) : "-",
              participantSurname: e.participantSurname,
              participantGivenName: e.participantGivenName,
              participantChiName: e.participantChiName
                ? e.participantChiName
                : "-",
              schoolNameEn: e.schoolNameEn,
              schoolNameTc: e.schoolNameTc ? e.schoolNameTc : "-",
              schoolType: e.schoolType,
              yearGroup: e.yearGroup ? e.yearGroup : "-",
              classLevel: e.classLevel ? e.classLevel : "-",
              refNo: e.refNo ? e.refNo : "-",
            };
          });

          tableData = tableData.concat(dataList);

          var fileTitle =
            `Award_Report` +
            `_${this.formatDate(new Date(), "YYYYMMDD_HHmmss")}`;

          CSVFile(tableHead, null, "Data", tableData, fileTitle, false, () => {
            this.isGenAwardReportLoading = false;
          });
        },
        () => {}
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
</style>
