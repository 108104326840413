<template>
  <v-footer color="#2c76b8" dark>
    <v-container style="max-width: 1400px" v-if="!isMobile">
      <!-- <v-row class="my-2"> -->
      <!-- <v-btn text rounded @click="showAboutUs">
          <v-icon left>
            mdi-information
          </v-icon>
          {{ $t('aboutUs') }}
        </v-btn> -->

      <!-- <v-btn text rounded @click="showFAQ">
          <v-icon left>
            mdi-forum
          </v-icon>
          {{ $t('faq') }}
        </v-btn> -->
      <!-- </v-row> -->

      <v-row>
        <v-col class="d-flex align-center">
          <strong class="subheading">
            {{ $t("copyright", { year: formatDate(new Date(), "yyyy") }) }}
          </strong>
        </v-col>

        <v-spacer />

        <v-col class="d-flex align-center justify-end">
          <v-btn
            color="white"
            depressed
            icon
            href="https://www.facebook.com/Ampower-Talent-Institute-105146461733710/"
            target="_blank"
            class="mr-5"
          >
            <v-icon size="40">mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            color="white"
            depressed
            icon
            href="https://www.instagram.com/ampowertalentinstitute/"
            target="_blank"
          >
            <v-icon size="40">mdi-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <!-- <v-row justify="center" class="mb-2"> -->
      <!-- <v-btn text rounded @click="showAboutUs">
          <v-icon left>
            mdi-information
          </v-icon>
          {{ $t('aboutUs') }}
        </v-btn> -->

      <!-- <v-btn text rounded @click="showFAQ">
          <v-icon left>
            mdi-forum
          </v-icon>
          {{ $t('faq') }}
        </v-btn> -->
      <!-- </v-row> -->

      <v-row justify="center" class="mb-2">
        <strong class="subheading">
          {{ $t("copyright", { year: formatDate(new Date(), "yyyy") }) }}
        </strong>
      </v-row>

      <v-row>
        <v-col class="d-flex align-center justify-center">
          <v-btn
            color="white"
            depressed
            icon
            href="https://www.facebook.com/Ampower-Talent-Institute-105146461733710/"
            target="_blank"
            class="mr-5"
          >
            <v-icon size="40">mdi-facebook</v-icon>
          </v-btn>

          <v-btn
            color="white"
            depressed
            icon
            href="https://www.instagram.com/ampowertalentinstitute/"
            target="_blank"
          >
            <v-icon size="40">mdi-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  methods: {
    showAboutUs() {
      this.$router.push({ path: "/AboutUs" });
    },
    showFAQ() {
      this.$router.push({ path: "./FAQ" });
    },
  },
};
</script>
