<template>
	<v-app id="app">
    <AppBar ref="appBar" :isHome="true"/>

    <v-main>
      <competition-content ref="competitionContent" />
    </v-main>

    <NewsFeed />

  </v-app>
</template>

<script>
import AppBar from './AppBar'
import AppFooter from '@/views/AppFooter'
import Home from './Home'
import NewsFeed from './NewsFeed'
import CompetitionContent from './CompetitionContent.vue'

export default {
	name: 'Competition',
	components: {
    AppBar,
    AppFooter,
    Home,
    NewsFeed,
    CompetitionContent,
	},
	data() {
    return {
      checkedData: 0,
      classData: [],
      index: 0,
    }
  },
  mounted() {
  },
}
</script>
