<template>
  <v-app>
    <AppBar />

    <v-main>
      <v-container style="max-width: 1400px">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="
                $constant.isUAT
                  ? $i18n.locale == 'en'
                    ? require('@/assets/images/competition_background_en_uat.png')
                    : require('@/assets/images/competition_background_tc_uat.png')
                  : $i18n.locale == 'en'
                  ? require('@/assets/images/competition_background_en.png')
                  : require('@/assets/images/competition_background_tc.png')
              "
              width="100%"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col style="background-color: #F4DCCF; margin: 0px 6px;">
            <v-card-title class="py-1">
              {{ $t('registration') }}
            </v-card-title>
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="isMobile ? 12 : 6">
            <v-card class="mx-auto" v-if="competition">
              <v-toolbar color="#2c76b8" dark>
                <v-card-title class="text-uppercase">
                  {{ $t("selectedCompetition") }}
                </v-card-title>
              </v-toolbar>

              <v-card-text class="text--primary">
                <v-container>
                  <v-row class="d-flex">
                    <v-col align-self="center">
                      <img 
                        :src="competition.imageBase64" 
                        style="max-height: 200px; object-fit: contain; max-width: 100%;"/>

                      <DisplayText
                        :content="
                          $i18n.locale == 'en'
                            ? competition.nameEn
                            : competition.nameTc
                        "
                        :title="$t('category') + ' ' + competition.categoryNo"
                        :inverted="false"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <DisplayText :title="$t('mission') + ':'"
                        :content="$i18n.locale == 'en' ? competition.missionEn : competition.missionTc"
                        :snip="isMobile ? 3 : 100"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col :cols="isMobile ? 12 : 6">
            <v-card
              class="mx-auto"
            >
              <v-card-title class="justify-center">
                <v-icon size="100px" color="green accent-4">mdi-check-circle</v-icon>
              </v-card-title>

              <v-card-text class="text-center text-uppercase pb-0 mb-0">
                <h2 style="color: #00C853">{{ $t('enrollSuccess') }}</h2>
              </v-card-text>

              <v-card-text class="text-center text-uppercase" style="color: #888">
                <h3>{{ $t('refNo') }}: {{ refNo }}</h3>
              </v-card-text>

              <v-card-text class="text--primary">
                <v-row>
                  <v-col v-for="(ca, n) in caList" :key="ca.email" cols="12" class="d-flex flex-column">
                    <v-card class="flex-grow-1" outlined>
                      <v-container>
                        <span class="d-flex">
                          <v-icon class="mr-2" color="grey darken-1">
                            mdi-account
                          </v-icon>
                          <div class="text-h6 font-weight-bold">
                            {{ $t('applicant') }} {{ n + 1 }}
                          </div>
                        </span>

                        <div class="text-body-1">
                          {{ ca.surname }} {{ ca.givenName }}
                        </div>

                        <div class="text-body-1" v-if="ca.chiName != undefined">
                          {{ ca.chiName }}
                        </div>

                        <div class="text-body-1">
                          {{ ca.schoolName }}
                        </div>

                        <div class="text-body-1">
                          {{ $t('class') }} {{ ca.yearGroup }} {{ ca.schoolClass }}
                        </div>

                        <div class="text-body-1">
                          {{ ca.email }}
                        </div>

                        <div class="text-body-1">
                          {{ ca.mobileNo }}
                        </div>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="pt-3">
                  <v-col class="text-center mb-0 pb-0">
                    <h4 class="perInfo">{{ $t('compNotice') }}</h4>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col class="text-center mb-0 pb-0">
                    <h4 class="perInfo">{{ $t('compNotice2') }}</h4>
                  </v-col>
                </v-row>
                
                <v-row class="pt-3">
                  <v-col class="text-center">
                    <v-btn
                      color="orange lighten-1"
                      dark
                      @click="confirm"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import AppBar from '@/views/AppBar'
import AppFooter from '@/views/AppFooter'
import DisplayText from '@/components/DisplayText'

export default {
	name: 'ApplyCompetitionSuccess',
  components: {
    AppBar,
    AppFooter,
    DisplayText,
  },
  mounted() {
    this.competition = this.$route.params.competition
    this.caList = this.$route.params.caList
    this.refNo = this.$route.params.refNo

    // Block user back to apply page
    window.onpopstate = event => {
      this.$router.push("/Competition")
    }
  },
	data() {
    return {
      competition: null,
      caList: [],
      refNo: null,
    }
  },
  methods: {
    confirm() {
      this.$router.push({name: 'Competition'})
    }
  },
}
</script>

<style lang="scss" scoped>
.perInfo {
  color: #888;
}
</style>