<template>
  <v-app>
    <div id="app" class="mb-1">
      <div id="container" style="max-width: unset;">
        <router-view style="margin: auto" />
      </div>
    </div>
    <AppFooter />
    <!-- snackbar -->
    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      :color="snackbarColor + ' lighten-1'"
    >
      <span class="white--text">
        {{ $t(snackbarText) }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppFooter from "@/views/AppFooter";

export default {
  name: "App",
  components: {
    AppFooter,
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    this.$root.$on("popSnackBar", (tx, color = "green") => {
      // your code goes here
      this.popSnackBar(tx, color);
    });
  },
  data() {
    return {
      showSnackbar: false,
      snackbarText: "",
      snackbarColor: "green",
    };
  },
  methods: {
    popSnackBar(tx, color = "green") {
      this.showSnackbar = true;
      this.snackbarText = tx;
      this.snackbarColor = color;
    },
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
}
#container {
  max-width: 1400px;
  margin: auto;
}
@font-face {
  font-family: "DIN";
  src: url("~@/assets/fonts/D-DIN.otf");
  src: url("~@/assets/fonts/D-DIN.eot");
  src: url("~@/assets/fonts/D-DIN.eot?#iefix") format("embedded-opentype"),
    url("~@/assets/fonts/D-DIN.woff2") format("woff2"),
    url("~@/assets/fonts/D-DIN.woff") format("woff"),
    url("~@/assets/fonts/D-DIN.ttf") format("truetype");
}
@font-face {
  font-family: "Oooh Baby";
  src: url("~@/assets/fonts/OoohBaby-Regular.ttf");
}
</style>
