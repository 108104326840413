<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('replay') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog(false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <p class="text-h6 font-weight-bold text-wrap pa-4">
        {{ $t('replayInstruction') + ':' }}
      </p>

      <v-checkbox
        v-model="isRefNo"
        color="orange lighten-1"
        class="px-3"
        hide-details
        @change="toggleIsRefNo"
      >
        <template v-slot:label>
          <div class="text-uppercase text-h6" style="color: #1A237E">
            {{ $t('referenceNo') }}
          </div>
        </template>
      </v-checkbox>

      <v-card-subtitle style="color: #888" class="py-0">
        {{ $t('fillInRefNo') + ':' }}
      </v-card-subtitle>

      <v-card-text class="text--primary px-md-0">
        <v-container class="px-md-0" ref="refNoContainer">
          <v-form ref="refNoForm" id="refNoForm">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="enquiryRefNo"
                  :label="$t('referenceNo') + ':'"
                  color="#2c76b8"
                  clearable
                  :disabled="!isRefNo"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="enquiryEmail"
                  :label="$t('email') + ':'"
                  color="#2c76b8"
                  clearable
                  :disabled="!isRefNo"
                />
              </v-col>
            </v-row>

            <v-row v-if="isRefNo">
              <v-col>
                <vue-recaptcha 
                  ref="recaptcha1"
                  :sitekey="$constant.RECAPTCHA_SITE_KEY" 
                  :loadRecaptchaScript="true" 
                  @verify="verify" 
                  :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                  @expired="isRobot = true"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-checkbox
        v-model="isPerInfo"
        color="orange lighten-1"
        class="px-3"
        hide-details
        @change="toggleIsPerInfo"
      >
        <template v-slot:label>
          <div class="text-uppercase text-h6" style="color: #1A237E">
            {{ $t('perInfo') }}
          </div>
        </template>
      </v-checkbox>

      <v-card-subtitle style="color: #888" class="py-0">
        {{ $t('fillInfo') + ':' }}
      </v-card-subtitle>

      <v-card-subtitle style="color: #888" class="py-0" >
        {{ '*' + $t('applyRemarks') }}
      </v-card-subtitle>

      <v-card-text class="text--primary px-md-0">
        <v-container class="px-md-0" ref="perInfoContainer">
          <v-form v-model="isFormValid" ref="perInfoForm" id="perInfoForm">
            <v-row>
              <v-col :cols="isMobile ? 12 : 6">
                <v-text-field
                  v-model="surname"
                  :label="$t('surname') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="100"
                  maxlength="100"
                  :rules="nameRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>

              <v-col :cols="isMobile ? 12 : 6">
                <v-text-field
                  v-model="givenName"
                  :label="$t('givenName') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="100"
                  maxlength="100"
                  :rules="nameRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="chiName"
                  :label="$t('chiName') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="10"
                  maxlength="10"
                  :rules="nameRules"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="school"
                  :items="schoolList"
                  :search-input.sync="search"
                  color="#2c76b8"
                  hide-no-data
                  hide-selected
                  :label="$t('school')"
                  class="required"
                  item-text="displayName"
                  return-object
                  clearable
                  @change="otherSchoolName = null"
                  :hint="$t('schoolHint')"
                  persistent-hint
                  :disabled="!isPerInfo"
                >
                  <template v-slot:item="data">
                    <h5>
                      {{ data.item.displayName }}
                    </h5>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="school != null && school.schoolId == 0">
              <v-col>
                <v-text-field
                  v-model="otherSchoolName"
                  :label="$t('schoolName') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="100"
                  maxlength="100"
                  :rules="schoolRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row v-if="!isMobile">
              <v-col cols="2">
                <v-checkbox
                  v-model="isTeacher"
                  :label="$t('teacher')"
                  :disabled="!isPerInfo"
                ></v-checkbox>
              </v-col>

              <v-col v-if="!isMobile && !isTeacher">
                <v-text-field
                  v-model="yearGroup"
                  :label="$t('yearGroup') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="30"
                  maxlength="30"
                  :rules="classRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>

              <v-col v-if="!isMobile && !isTeacher">
                <v-text-field
                  v-model="schoolClass"
                  :label="$t('class') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="30"
                  maxlength="30"
                  :rules="classRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row v-if="isMobile">
              <v-col>
                <v-checkbox
                  v-model="isTeacher"
                  :label="$t('teacher')"
                  :disabled="!isPerInfo"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row v-if="isMobile && !isTeacher">
              <v-col>
                <v-text-field
                  v-model="yearGroup"
                  :label="$t('yearGroup') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="30"
                  maxlength="30"
                  :rules="classRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>

              <v-col>
                <v-text-field
                  v-model="schoolClass"
                  :label="$t('class') + ':'"
                  color="#2c76b8"
                  clearable
                  counter="30"
                  maxlength="30"
                  :rules="classRules"
                  class="required"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="email"
                  :label="$t('email') + ':'"
                  color="#2c76b8"
                  :rules="emailRules"
                  clearable
                  counter="100"
                  maxlength="100"
                  class="required email"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="confirmEmail"
                  :label="$t('confirmEmail') + ':'"
                  color="#2c76b8"
                  :rules="confirmEmailRules"
                  clearable
                  counter="100"
                  maxlength="100"
                  class="required email"
                  :disabled="!isPerInfo"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-row v-if="isPerInfo">
            <v-col>
              <vue-recaptcha 
                ref="recaptcha2"
                :sitekey="$constant.RECAPTCHA_SITE_KEY" 
                :loadRecaptchaScript="true" 
                @verify="verify" 
                :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                @expired="isRobot = true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :loading="isLoading"
        :dark="!isDisabled"
        :disabled="isDisabled"
        @click="confirm"
      >
        {{ $t('confirm') }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
      v-model="showSnackbar"
      timeout="-1"
      color="#FE253D"
      top
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  props: ['courseId'],
  data() {
    return {
      isLoading: false,
      isRobot: true,
      snackbarText: null,
      showSnackbar: false,
      enrollmentId: null,
      surveyReq: true,
      refNo: null,

      isRefNo: true,
      isPerInfo: false,

      enquiryRefNo: null,
      enquiryEmail: null,
      surname: null,
      givenName: null,
      chiName: null,
      school: null,
      schoolClass: null,
      email: null,
      confirmEmail: null,
      isFormValid: false,
      schoolList: [],
      search: null,
      otherSchoolName: null,
      isTeacher: false,
      yearGroup: null,

      nameRules: [
        v => !v || /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noNumAndSpecialCharacters')
      ],
      schoolRules: [
        v => !v || /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
      classRules: [
        v => !v || /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
      emailRules: [
        v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('invalidEmail'),
      ],
      confirmEmailRules: [
        v => !v || v.toLowerCase() == this.email.toLowerCase() || this.$t('emailNotMatch')
      ],
    }
  },
  methods: {
    showMessage(message) {
      this.showSnackbar = true
      this.snackbarText = message
    },
    closeDialog(showVideo) {
			// evt.preventDefault()

      var localIsRefNo = this.isRefNo

      this.isRefNo = true
      this.isPerInfo = false
      if (this.$refs.perInfoForm != undefined) {
        this.$refs.perInfoForm.reset()
      }
      if (this.$refs.refNoForm != undefined) {
        this.$refs.refNoForm.reset()
      }
      this.isRobot = true
      if (this.$refs.recaptcha1 != undefined) {
        this.$refs.recaptcha1.reset()
      }
      if (this.$refs.recaptcha2 != undefined) {
        this.$refs.recaptcha2.reset()
      }
      this.isLoading = false
      this.showSnackbar = false

      this.$emit(showVideo ? 'showVideo' : 'close')
      if (showVideo) {
        this.$emit('showVideo', this.enrollmentId, this.surveyReq, this.refNo, localIsRefNo)
      } else {
        this.$emit('close')
      }
		},
    toggleIsRefNo() {
      this.isPerInfo = !this.isRefNo
      this.$refs.perInfoForm.reset()
      this.$refs.recaptcha2.reset()
      // this.$vuetify.goTo(this.$refs.perInfoForm, { container: this.$refs.perInfoContainer })
    },
    toggleIsPerInfo() {
      this.isRefNo = !this.isPerInfo
      this.$refs.refNoForm.reset()
      this.$refs.recaptcha1.reset()
      // this.$vuetify.goTo(this.$refs.refNoForm, { container: this.$refs.refNoContainer })
    },
    confirm() {
      if (this.isRefNo) {
        this.enquiry()
      } else {
        this.registerCourse()
      }
    },
    verify(response) {
      var requestData = {
        userResponse: response
      }
      this.callApi(this.$constant.VERIFY_RECAPTCHA_URL,
        requestData,
        (resultData) => {
          this.isRobot = !resultData.result
        },
        () => {
          this.isLoading = false
          this.isRobot = true
        }
      )
    },
    getAllSchool() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_ALL_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.schoolList = resultData.schoolList
          if (this.schoolList != null) {
            this.schoolList.map(s => {
              s.displayName = s.schoolNameEn + ' - ' + s.schoolNameTc
            })

            this.schoolList.push({
              schoolId: 0,
              displayName: 'Others - 其他',
            })
          }

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    enquiry() {
      this.isLoading = true

      var requestData = {
        refNo: this.enquiryRefNo.toLowerCase(),
        email: this.enquiryEmail.toLowerCase(),
      }

      this.callApi(this.$constant.ENQUIRY_URL,
        requestData,
        (resultData) => {
          var registered = false

          if (resultData.courseList != null) {
            for (var i in resultData.courseList) {
              if (resultData.courseList[i].courseId == this.courseId) {
                registered = true
                this.enrollmentId = resultData.courseList[i].enrollmentId
                this.surveyReq = (resultData.courseList[i].surveyId == null)
                this.refNo = resultData.courseList[i].refNo
                break
              }
            }
          }

          if (registered) {
            this.closeDialog(true)
          } else {
            this.showMessage(this.$t('course.didNotRegister'))
          }
          
          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    registerCourse() {
      this.isLoading = true

      var courseIdList = []
      courseIdList.push(this.courseId)

      var schoolName = this.school.schoolId == 0 ? this.otherSchoolName.trim() : this.school.displayName.trim()

      var requestData = {
        courseIdList: courseIdList,
        surname: this.surname.trim(),
        givenName: this.givenName.trim(),
        chiName: this.chiName == null ? null : this.chiName.trim(),
        school: schoolName,
        schoolClass: this.isTeacher ? null : this.schoolClass.trim(),
        yearGroup: this.isTeacher ? null : this.yearGroup.trim(),
        email: this.email.toLowerCase(),
        mobileNo: this.mobileNo,
        isTeacher: this.isTeacher,
        isExpired: true,
      }

      this.callApi(this.$constant.REGISTER_COURSE_URL,
        requestData,
        (resultData) => {
          if (!resultData.result) {
            this.showMessage(this.$t('registered'))
            this.isLoading = false
            return
          }
  
          this.enrollmentId = resultData.enrollmentId
          this.refNo = resultData.refNo

          this.showMessage(this.$t('course.confirmEmailSent'))
          this.closeDialog(true)
        },
        () => {
          this.isLoading = false
        }
      )
    }
  },
  mounted() {
    this.getAllSchool()
  },
  computed: {
    isDisabled: function() {
      if (this.isRobot) {
        return true
      }

      if (this.isRefNo) {
        if (this.isEmptyString(this.enquiryRefNo) || this.isEmptyString(this.enquiryEmail)) {
          return true
        }
      } else if (this.isPerInfo) {
        if (this.isEmptyString(this.surname) || this.isEmptyString(this.givenName) || (this.isEmptyString(this.school) || (this.school.schoolId == 0 && this.isEmptyString(this.otherSchoolName))) || (!this.isTeacher && this.isEmptyString(this.yearGroup) && this.isEmptyString(this.schoolClass)) || this.isEmptyString(this.confirmEmail) || this.isEmptyString(this.email) || !this.isFormValid) {
          return true
        }
      }

      return false
    },
  }
}
</script>