<template>
  <v-app>
    <AppBar ref="appBar" isAboutUs="true"/>

    <v-main>
      <v-container style="max-width: 1400px;" v-if="!isMobile">
        <v-row class="mt-5" no-gutters>
          <v-col>
            <h2 class="text-uppercase" v-if="!isMobile">
              {{ $t('itCourses') }}
            </h2>
            <h2 class="text-uppercase text-center" v-else>
              {{ $t('itCourses') }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-carousel 
              v-model="selectedSchool" 
              hide-delimiters 
              height="auto" 
              @change="filterSchool">
              <v-carousel-item
                v-for="school in schoolList"
                :key="school.id"
                eager
              >
                <v-col class="d-flex justify-center">
                  <v-img :src="school.image" contain max-width="500" eager/>
                </v-col>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
       
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-for="news in filterList" v-bind:key="news.newsId" >
          <v-col>
            <v-btn text @click="showVideo(news)" v-if="news.isYouTubeLink">
              <v-icon color="red" class="mr-2">
                mdi-youtube
              </v-icon>
              <span class="text-decoration-underline">
                {{ news.title }}
              </span>
            </v-btn>

            <v-btn text :href="news.link" target="_blank" v-else>
              <v-icon color="green" class="mr-2">
                mdi-link-variant
              </v-icon>
              <span class="text-decoration-underline">
                {{ news.title }}
              </span>
            </v-btn>

            <v-divider class="my-3" />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-pagination
              v-model="page"
              :length="Math.ceil(filterList.length / 5)"
              color="#76491b"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container class="mainContainer mt-5 mb-5" v-else>
        <v-container>
          <v-sheet style="background-color: transparent;">
            <v-row class="mb-3">
              <v-col class="text-center">
                <h1 class="text-uppercase">
                  {{ $t('itCourses') }}
                </h1>
              </v-col>
            </v-row>

            <v-carousel 
              v-model="selectedSchool" 
              hide-delimiters 
              height="auto" 
              class="my-3"
              @change="filterSchool" >
              <v-carousel-item
                v-for="school in schoolList"
                :key="school.id"
              >
                <v-img :src="school.image" contain />
              </v-carousel-item>
            </v-carousel>
      
            <v-row v-for="news in filterList" v-bind:key="news.newsId" >
              <v-col>
                <v-btn small text @click="showVideo(news)" v-if="news.isYouTubeLink">
                  <v-icon color="red" class="mr-2">
                    mdi-youtube
                  </v-icon>
                  <span class="text-decoration-underline">
                    {{ news.title }}
                  </span>
                </v-btn>

                <v-btn small text :href="news.link" target="_blank" v-else>
                  <v-icon color="green" class="mr-2">
                    mdi-link-variant
                  </v-icon>
                  <span class="text-decoration-underline">
                    {{ news.title }}
                  </span>
                </v-btn>

                <v-divider class="my-3" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(filterList.length / 5)"
                  color="#76491b"
                />
              </v-col>
            </v-row>
          </v-sheet>

          <v-dialog
            v-if="selected && showVideoDialog"
            v-model="showVideoDialog"
            width="unset"
            persistent
          >
            <YoutubePlayer 
              @close='showVideoDialog = false' 
              :videoList="[{
                id: getYoutubeId(selected.link),
                title: selected.title
              }]"
              :title="selected.title"
            />
          </v-dialog>
        </v-container>
      </v-container>
    </v-main>
    

    <v-dialog
      v-if="selected && showVideoDialog"
      v-model="showVideoDialog"
      width="50vw"
      persistent
    >
      <YoutubePlayer 
        @close='showVideoDialog = false' 
        :videoList="[{
          id: getYoutubeId(selected.link),
          title: selected.title
        }]"
        :title="selected.title"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import AppBar from './AppBar'
import AppFooter from '@/views/AppFooter'
import YoutubePlayer from '@/components/YoutubePlayer'

export default {
  components: {
    AppBar, 
    AppFooter,
    YoutubePlayer,
  },
  data() {
    return {
      page: 1,
      dataList: [],
      filterList: [],
      schoolList: [],
      showVideoDialog: false,
      selected: null,
      selectedSchool: null,
    }
  },
  mounted() {
    this.dataList = [
      {
        newsId: 1,
        date: new Date(),
        school: 'PolyU',
        title: "Department of Computing, PolyU",
        link: "https://www.youtube.com/watch?v=j67DnxYqzGA",
        isYouTubeLink: true,
      },
      // {
      //   newsId: 2,
      //   date: new Date(),
      //   school: 'PolyU',
      //   title: "KT @ PolyU - Smart City",
      //   link: "https://www.youtube.com/watch?v=j2mdUPTwJfE"
      // },
      // {
      //   newsId: 3,
      //   date: new Date(),
      //   school: 'PolyU',
      //   title: "Knowledge Transfer (KT) @ PolyU 2021",
      //   link: "https://www.youtube.com/watch?v=I0e3QeRddEs"
      // },
      // {
      //   newsId: 4,
      //   date: new Date(),
      //   school: 'PolyU',
      //   title: "PolyU video on ranking",
      //   link: "https://www.youtube.com/watch?v=ByEUq0o2Xok"
      // },
      {
        newsId: 5,
        date: new Date(),
        school: 'PolyU',
        title: "Big Data in a Tiny Package",
        link: "https://www.youtube.com/watch?v=zTFYKzBnqyI",
        isYouTubeLink: true,
      },
      {
        newsId: 6,
        date: new Date(),
        school: 'HKUST',
        title: "Computer Science & Engineering",
        link: "https://www.youtube.com/watch?v=oTsMdwqNJ2s",
        isYouTubeLink: true,
      },
      {
        newsId: 7,
        date: new Date(),
        school: 'HKUST',
        title: "Computer Engineering",
        link: "https://www.youtube.com/watch?v=jt0m5Py6mX8",
        isYouTubeLink: true,
      },
      // {
      //   newsId: 8,
      //   date: new Date(),
      //   school: 'HKUST',
      //   title: "HKUST Admissions Talk - Computer Science and Engineering",
      //   link: "https://www.youtube.com/watch?v=oTsMdwqNJ2s",
      //   isYouTubeLink: true,
      // },
      {
        newsId: 9,
        date: new Date(),
        school: 'HKUST',
        title: "Other innovation and technology degrees",
        link: "https://seng.ust.hk/videos/374",
        isYouTubeLink: false,
      },
    ]

    this.filterList = this.dataList

    var nameList = []
    var index = 0

    for (var i in this.dataList) {
      var school = this.dataList[i].school

      if (!nameList.includes(school)) {
        nameList.push(school)
        this.schoolList.push({id: index, name: school, image: require('@/assets/images/' + school.toLowerCase() + '.jpg')})
        index++
      }
    }
  },
  computed: {
    newsList() {
      return this.filterList.slice((this.page - 1) * 5, this.page * 5 > this.filterList.length ? (this.page * 5 - this.filterList.length) + (this.page - 1) * 5 : 5 + (this.page - 1) * 5)
    },
  },
  methods: {
    showVideo(news) {
      this.showVideoDialog = true
      this.selected = news
    },
    getYoutubeId(link) {
      var videoId = link.split('v=')[1]
      var ampersandPosition = videoId.indexOf('&')
      if(ampersandPosition != -1) {
        videoId = video_id.substring(0, ampersandPosition)
      }

      return videoId
    },
    filterSchool() {
      var school = this.schoolList[this.selectedSchool]

      if (school.name == undefined) {
        this.filterList = this.dataList
      } else {
        this.filterList = this.dataList.filter(function(d) {
          return d.school == school.name
        })
      }
    },
  }
}
</script>