<template>
  <v-app :class="isMobile ? 'mobile' : ''">
    <AppBar />

    <v-main>
      <v-container style="max-width: 1400px">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="
                $constant.isUAT
                  ? $i18n.locale == 'en'
                    ? require('@/assets/images/competition_background_en_uat.png')
                    : require('@/assets/images/competition_background_tc_uat.png')
                  : $i18n.locale == 'en'
                  ? require('@/assets/images/competition_background_en.png')
                  : require('@/assets/images/competition_background_tc.png')
              "
              width="100%"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col style="background-color: #f4dccf; margin: 0px 6px">
            <v-card-title class="py-1">
              {{ $t("competitionApplication") }}
            </v-card-title>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto" v-if="competition">
              <v-toolbar color="#2c76b8" dark>
                <v-card-title class="text-uppercase">
                  {{ $t("selectedCompetition") }}
                </v-card-title>
              </v-toolbar>

              <v-card-text class="text--primary">
                <v-container>
                  <v-row class="d-flex">
                    <v-col align-self="center">
                      <img
                        :src="competition.imageBase64"
                        style="
                          max-height: 200px;
                          object-fit: contain;
                          max-width: 100%;
                        "
                      />

                      <DisplayText
                        :content="
                          $i18n.locale == 'en'
                            ? competition.nameEn
                            : competition.nameTc
                        "
                        :title="$t('category') + ' ' + competition.categoryNo"
                        :inverted="false"
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <DisplayText
                        :title="$t('mission') + ':'"
                        :content="
                          $i18n.locale == 'en'
                            ? competition.missionEn
                            : competition.missionTc
                        "
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto">
              <!-- Tab Header -->
              <v-toolbar color="#2c76b8" dark>
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  centered
                  dark
                  icons-and-text
                  fixed-tabs
                  class="tabs"
                >
                  <v-tabs-slider color="#ffa726"></v-tabs-slider>

                  <v-tab href="#tab-1">
                    <v-card-title class="text-uppercase">
                      {{ $t("newApplication") }}
                    </v-card-title>
                  </v-tab>

                  <v-tab href="#tab-2">
                    <v-card-title class="text-uppercase">
                      {{ $t("editApplication") }}
                    </v-card-title>
                  </v-tab>
                </v-tabs>
              </v-toolbar>

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                  <v-card>
                    <v-card-text class="text--primary">
                      <v-container class="pt-5">
                        <v-form>
                          <v-row>
                            <v-col>
                              <v-row class="pa-0">
                                <div
                                  class="
                                    text-uppercase
                                    pa-0
                                    text-h5
                                    font-weight-bold
                                  "
                                  style="color: #1a237e"
                                >
                                  {{
                                    $t("part.A") + " " + $t("entryFormation")
                                  }}
                                </div>
                              </v-row>

                              <v-row>
                                <v-radio-group
                                  v-model="entryFormation"
                                  row
                                  @change="clearData"
                                >
                                  <v-radio
                                    :label="$t('individual')"
                                    value="i"
                                    color="orange lighten-1"
                                  ></v-radio>

                                  <v-radio
                                    :label="$t('team')"
                                    value="t"
                                    color="orange lighten-1"
                                  ></v-radio>
                                </v-radio-group>
                              </v-row>

                              <v-row>
                                <v-col>
                                  <v-autocomplete
                                    v-model="school"
                                    :items="schoolList"
                                    :search-input.sync="search"
                                    color="#2c76b8"
                                    hide-no-data
                                    hide-selected
                                    :label="$t('school')"
                                    class="required"
                                    item-text="displayName"
                                    return-object
                                    clearable
                                    @change="otherSchoolName = null"
                                    :hint="$t('schoolHint')"
                                    persistent-hint
                                  >
                                    <template v-slot:item="data">
                                      <h5>
                                        {{ data.item.displayName }}
                                      </h5>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>

                              <v-row
                                v-if="school != null && school.schoolId == 0"
                              >
                                <v-col>
                                  <v-text-field
                                    v-model="otherSchoolName"
                                    :label="$t('schoolName') + ':'"
                                    color="#2c76b8"
                                    clearable
                                    counter="100"
                                    maxlength="100"
                                    :rules="schoolRules"
                                    class="required"
                                  />
                                </v-col>
                              </v-row>

                              <v-row class="pa-0 mt-6">
                                <div
                                  class="
                                    text-uppercase
                                    pa-0
                                    text-h5
                                    font-weight-bold
                                  "
                                  style="color: #1a237e"
                                >
                                  {{ $t("part.B") + " " + $t("applicantInfo") }}
                                </div>
                              </v-row>

                              <v-row
                                class="mt-6"
                                v-if="entryFormation == 't'"
                                align="center"
                              >
                                <v-item-group
                                  v-model="applicant"
                                  class="mr-2"
                                  mandatory
                                >
                                  <v-item
                                    v-for="info in groupInfo"
                                    :key="info.id"
                                    v-slot="{ active, toggle }"
                                  >
                                    <div>
                                      <v-btn
                                        :color="
                                          active ? 'orange lighten-1' : ''
                                        "
                                        :input-value="active"
                                        icon
                                        @click="toggle"
                                      >
                                        <v-icon>mdi-record</v-icon>
                                      </v-btn>
                                    </div>
                                  </v-item>
                                </v-item-group>

                                <v-col>
                                  <v-window
                                    v-model="applicant"
                                    class="elevation-1"
                                    vertical
                                  >
                                    <v-window-item
                                      v-for="(info, n) in groupInfo"
                                      :key="info.id"
                                    >
                                      <v-form v-model="info.isFormValid">
                                        <v-card flat>
                                          <v-card-text>
                                            <v-row class="mb-2" align="center">
                                              <v-col cols="6">
                                                <span class="d-flex">
                                                  <v-icon
                                                    class="mr-2"
                                                    color="grey darken-1"
                                                  >
                                                    mdi-account
                                                  </v-icon>
                                                  <div
                                                    class="
                                                      text-h6
                                                      font-weight-bold
                                                    "
                                                  >
                                                    {{ $t("applicant") }}
                                                    {{ n + 1 }}
                                                  </div>
                                                </span>
                                              </v-col>

                                              <!-- <v-spacer></v-spacer>

                                              <v-col class="text-right">
                                                <v-btn text color="grey darken-1" @click="clearForm(info)">
                                                  <v-icon
                                                    left
                                                    dark
                                                  >
                                                    mdi-close
                                                  </v-icon>
                                                  {{ $t("clear") }}
                                                </v-btn>
                                              </v-col> -->
                                            </v-row>

                                            <v-container>
                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="info.surname"
                                                    :label="$t('surname') + ':'"
                                                    color="#2c76b8"
                                                    clearable
                                                    counter="100"
                                                    maxlength="100"
                                                    :rules="nameRules"
                                                    class="required"
                                                  />
                                                </v-col>
                                              </v-row>

                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="info.givenName"
                                                    :label="
                                                      $t('givenName') + ':'
                                                    "
                                                    color="#2c76b8"
                                                    clearable
                                                    counter="100"
                                                    maxlength="100"
                                                    :rules="nameRules"
                                                    class="required"
                                                  />
                                                </v-col>
                                              </v-row>

                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="info.chiName"
                                                    :label="$t('chiName') + ':'"
                                                    color="#2c76b8"
                                                    clearable
                                                    counter="10"
                                                    maxlength="10"
                                                    :rules="nameRules"
                                                  />
                                                </v-col>
                                              </v-row>

                                              <v-row>
                                                <v-col>
                                                  <v-text-field
                                                    v-model="info.yearGroup"
                                                    :label="
                                                      $t('yearGroup') + ':'
                                                    "
                                                    color="#2c76b8"
                                                    clearable
                                                    counter="30"
                                                    maxlength="30"
                                                    :rules="classRules"
                                                    class="required"
                                                  />
                                                </v-col>

                                                <v-col>
                                                  <v-text-field
                                                    v-model="info.schoolClass"
                                                    :label="$t('class') + ':'"
                                                    color="#2c76b8"
                                                    clearable
                                                    counter="30"
                                                    maxlength="30"
                                                    :rules="classRules"
                                                    class="required"
                                                  />
                                                </v-col>
                                              </v-row>

                                              <v-row>
                                                <v-text-field
                                                  v-model="info.email"
                                                  :label="$t('email') + ':'"
                                                  color="#2c76b8"
                                                  :rules="emailRules"
                                                  clearable
                                                  counter="100"
                                                  maxlength="100"
                                                  class="required email"
                                                />
                                              </v-row>

                                              <v-row>
                                                <v-text-field
                                                  v-model="info.mobileNo"
                                                  :label="$t('mobileNo') + ':'"
                                                  color="#2c76b8"
                                                  :rules="mobileNoRules"
                                                  clearable
                                                  counter="8"
                                                  maxlength="8"
                                                  :hint="$t('mobileNumHints')"
                                                  persistent-hint
                                                />
                                              </v-row>
                                            </v-container>
                                          </v-card-text>
                                        </v-card>
                                      </v-form>
                                    </v-window-item>
                                  </v-window>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>

                          <v-form
                            v-if="entryFormation == 'i'"
                            class="pt-6"
                            v-model="isFormValid"
                          >
                            <v-row>
                              <v-col :cols="isMobile ? 12 : 6">
                                <v-text-field
                                  v-model="surname"
                                  :label="$t('surname') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="100"
                                  maxlength="100"
                                  :rules="nameRules"
                                  class="required"
                                />
                              </v-col>

                              <v-col :cols="isMobile ? 12 : 6">
                                <v-text-field
                                  v-model="givenName"
                                  :label="$t('givenName') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="100"
                                  maxlength="100"
                                  :rules="nameRules"
                                  class="required"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="chiName"
                                  :label="$t('chiName') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="10"
                                  maxlength="10"
                                  :rules="nameRules"
                                />
                              </v-col>
                            </v-row>

                            <v-row v-if="!isMobile">
                              <!-- <v-col cols="2">
                                <v-checkbox
                                  v-model="isTeacher"
                                  :label="$t('teacher')"
                                ></v-checkbox>
                              </v-col> -->

                              <v-col v-if="!isMobile && !isTeacher">
                                <v-text-field
                                  v-model="yearGroup"
                                  :label="$t('yearGroup') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="30"
                                  maxlength="30"
                                  :rules="classRules"
                                  class="required"
                                />
                              </v-col>

                              <v-col v-if="!isMobile && !isTeacher">
                                <v-text-field
                                  v-model="schoolClass"
                                  :label="$t('class') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="30"
                                  maxlength="30"
                                  :rules="classRules"
                                  class="required"
                                />
                              </v-col>
                            </v-row>

                            <!-- <v-row v-if="isMobile">
                              <v-col>
                                <v-checkbox
                                  v-model="isTeacher"
                                  :label="$t('teacher')"
                                ></v-checkbox>
                              </v-col>
                            </v-row> -->

                            <v-row v-if="isMobile && !isTeacher">
                              <v-col>
                                <v-text-field
                                  v-model="yearGroup"
                                  :label="$t('yearGroup') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="30"
                                  maxlength="30"
                                  :rules="classRules"
                                  class="required"
                                />
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="schoolClass"
                                  :label="$t('class') + ':'"
                                  color="#2c76b8"
                                  clearable
                                  counter="30"
                                  maxlength="30"
                                  :rules="classRules"
                                  class="required"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="email"
                                  :label="$t('email') + ':'"
                                  color="#2c76b8"
                                  :rules="emailRules"
                                  clearable
                                  counter="100"
                                  maxlength="100"
                                  class="required email"
                                />
                              </v-col>
                            </v-row>

                            <!-- <v-row class="mb-4">
                              <v-col>
                                <v-text-field
                                  v-model="confirmEmail"
                                  :label="$t('confirmEmail') + ':'"
                                  color="#2c76b8"
                                  :rules="confirmEmailRules"
                                  clearable
                                  counter="100"
                                  maxlength="100"
                                  class="required email"
                                />
                              </v-col>
                            </v-row> -->

                            <v-row>
                              <v-col>
                                <v-text-field
                                  v-model="mobileNo"
                                  :label="$t('mobileNo') + ':'"
                                  color="#2c76b8"
                                  :rules="mobileNoRules"
                                  clearable
                                  counter="8"
                                  maxlength="8"
                                  :hint="$t('mobileNumHints')"
                                  persistent-hint
                                />
                              </v-col>
                            </v-row>
                          </v-form>

                          <v-row>
                            <v-col>
                              <v-row class="pa-0 mt-6">
                                <h2
                                  class="text-uppercase pa-0"
                                  style="color: #1a237e"
                                >
                                  {{
                                    $t("part.C") +
                                    " " +
                                    $t("competitionMaterial")
                                  }}
                                </h2>
                              </v-row>
                              <v-row class="pl-2 pt-2">
                                <h4 style="color: #888; white-space: pre-line;" class="pa-0">
                                  {{ $t("competitionFileFormat") }}
                                </h4>
                              </v-row>
                              <v-row class="pl-2">
                                <h4 style="color: #888" class="pa-0">
                                  {{ $t("fizeSizeLimit") + " 100 MB " + $t('videoUploadHints') }}
                                </h4>
                              </v-row>

                              <v-row>
                                <v-file-input
                                  v-model="uploadedFile"
                                  show-size
                                  chips
                                  truncate-length="30"
                                  :label="$t('uploadFile')"
                                  color="#2c76b8"
                                  @change="fileChange"
                                  accept=".pdf,.mp4,.m4v,.avi,.mov,.flv,.wmv"
                                  :rules="materialRules"
                                />
                              </v-row>

                              <v-row class="pl-2">
                                <h4 style="color: #888" class="pa-0">
                                  {{ $t("materialHints") }}
                                </h4>
                              </v-row>

                              <v-row>
                                <v-file-input
                                  v-model="writtenDescription"
                                  show-size
                                  chips
                                  truncate-length="30"
                                  :label="$t('writtenDescription')"
                                  color="#2c76b8"
                                  @change="fileChange"
                                  accept=".pdf"
                                  :class="isWrittenDescnReq ? 'required' : ''"
                                />
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-form>

                        <v-row>
                          <v-col>
                            <vue-recaptcha
                              :sitekey="$constant.RECAPTCHA_SITE_KEY"
                              :loadRecaptchaScript="true"
                              :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
                              @verify="verify"
                              @expired="isRobot = true"
                              class="mt-10"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col class="text-right">
                            <v-btn
                              text
                              color="orange lighten-1"
                              @click="cancel"
                            >
                              {{ $t("cancel") }}
                            </v-btn>

                            <v-btn
                              color="orange lighten-1"
                              :dark="!isDisabled"
                              :disabled="isDisabled"
                              @click="showConfirmDialog"
                              :loading="isLoading"
                            >
                              {{ $t("confirm") }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item value="tab-2">
                  <v-card flat>
                    <v-card-text>
                      <EditCompetitionForm
                        v-if="competition"
                        :competition="competition"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>

              <!--  -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>


    <v-snackbar v-model="showSnackbar" timeout="5000" color="#FE253D" top>
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="showSnackbar = false" color="white" text>
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="isShowConfirmDialog"
      scrollable
      persistent
      :width="isMobile ? 'unset' : '1000'"
      v-if="isShowConfirmDialog"
    >
      <ConfirmDialog
        :caList="caList"
        :competition="competition"
        @confirm="closeConfirmDialog(true)"
        @cancel="closeConfirmDialog(false)"
        @message="showMessage"
      />
    </v-dialog>
  </v-app>
</template>

<script>
import AppBar from "@/views/AppBar";
import AppFooter from "@/views/AppFooter";
import VueRecaptcha from "vue-recaptcha";
import DisplayText from "@/components/DisplayText";
import ConfirmDialog from "./ConfirmDialog.vue";
import EditCompetitionForm from "./EditCompetitionForm.vue";

export default {
  name: "ApplyCompetition",
  components: {
    "vue-recaptcha": VueRecaptcha,
    AppBar,
    AppFooter,
    DisplayText,
    ConfirmDialog,
    EditCompetitionForm,
  },
  beforeMount() {
    // Prevent direct access using this url
    if (!this.$store.state.competition) {
      alert("No competition is selected");
      this.$router.push({ path: "/Competition" });
    }
  },
  mounted() {
    this.competition = this.$store.state.competition;

    for (var i = 0; i < 4; i++) {
      var groupApplicant = {
        id: i,
        surname: null,
        givenName: null,
        chiName: null,
        yearGroup: null,
        schoolClass: null,
        email: null,
        mobileNo: null,
        isFormValid: true,
      };

      this.groupInfo.push(groupApplicant);
    }

    this.getAllSchool();
  },
  computed: {
    isDisabled: function () {
      if (this.isRobot && this.isLocalEnv == false) {
        return true;
      }

      if (this.school == undefined) {
        return true;
      }

      if (this.entryFormation == "i") {
        if (
          this.isEmptyString(this.surname) ||
          this.isEmptyString(this.givenName) ||
          this.isEmptyString(this.yearGroup) ||
          this.isEmptyString(this.schoolClass) ||
          this.isEmptyString(this.email)
        ) {
          return true;
        }

        if (!this.isFormValid) {
          return true;
        }
      } else if (this.entryFormation == "t") {
        var validMemberCount = 0;
        var memberList = [];

        for (var i in this.groupInfo) {
          var info = this.groupInfo[i];

          if (info.email == undefined) {
            continue;
          }

          if (
            this.isEmptyString(info.surname) ||
            this.isEmptyString(info.givenName) ||
            this.isEmptyString(info.yearGroup) ||
            this.isEmptyString(info.schoolClass)
          ) {
            return true;
          }

          if (!info.isFormValid) {
            return true;
          }

          if (!memberList.includes(info.email)) {
            memberList.push(info.email);
            validMemberCount++;
          }

          console.log(memberList);
        }

        if (validMemberCount <= 1) {
          return true;
        }
      }

      if (this.submitMaterial == undefined) {
        return true;
      }

      if (this.isWrittenDescnReq && this.writtenDescription == undefined) {
        return true;
      }

      return false;
    },
  },
  data() {
    return {
      competition: null,
      // name: null,
      surname: null,
      givenName: null,
      chiName: null,
      school: null,
      schoolClass: null,
      email: null,
      confirmEmail: null,
      mobileNo: null,
      isRobot: false,
      isLoading: false,
      showSnackbar: false,
      snackbarText: null,
      isFormValid: false,
      schoolList: [],
      search: null,
      otherSchoolName: null,
      isTeacher: false,
      yearGroup: null,
      uploadedFile: null,
      tab: null,
      entryFormation: "i",
      length: 4,
      groupInfo: [],
      applicant: 0,
      writtenDescription: null,
      isWrittenDescnReq: false,
      submitMaterial: null,
      submitMaterialFileName: null,
      submitWrittenDescn: null,
      submitWrittenDescnFileName: null,
      isShowConfirmDialog: false,
      caList: [],

      nameRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noNumAndSpecialCharacters"),
      ],
      schoolRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noSpecialCharacters"),
      ],
      classRules: [
        // v => (v || '').length <= 30 || this.$t('lengthTooLong', [30]),
        (v) =>
          !v ||
          /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) ||
          this.$t("noSpecialCharacters"),
      ],
      emailRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        (v) =>
          !v ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          this.$t("invalidEmail"),
      ],
      confirmEmailRules: [
        (v) =>
          !v ||
          this.email == undefined ||
          v.toLowerCase() == this.email.toLowerCase() ||
          this.$t("emailNotMatch"),
      ],
      mobileNoRules: [
        (v) => !v || /^[0-9]+$/.test(v) || this.$t("invalidMobileNo"),
      ],
      materialRules: [
        (value) =>
          !value ||
          value.size < 100000000 ||
          "File size should be less than 100 MB!",
        (value) =>
          !value ||
          // pdf,.mp4,.avi,.mov,.fly,.wmv
          [
            "application/pdf",
            "video/mp4",
            "video/m4v",
            "video/avi",
            "video/mov",
            "video/flv",
            "video/wmv",
          ].includes(value.type) ||
          "File type should be .pdf or valid video format",
      ],
      // materialRules: [
      //   (value) =>
      //     !value ||
      //     value.size < 10000000 ||
      //     "File size should be less than 1 MB!",
      //   (value) =>
      //     !value ||
      //     value.type == "application/pdf" ||
      //     "File type should be .pdf",
      // ],
    };
  },
  methods: {
    verify(response) {
      var requestData = {
        userResponse: response,
      };
      this.callApi(
        this.$constant.VERIFY_RECAPTCHA_URL,
        requestData,
        (resultData) => {
          this.isRobot = !resultData.result;
        },
        () => {
          this.isLoading = false;
          this.isRobot = true;
        }
      );
    },
    cancel() {
      this.$router.push({ path: "/Competition" });
    },
    confirm() {
      this.isLoading = true;

      var competitionId = this.competition.competitionId;

      var requestData = {
        competitionId: competitionId,
        b64SubmissionFile: this.submitMaterial,
        b64SubmissionFileName: this.submitMaterialFileName,
        b64WrittenDescn: this.submitWrittenDescn,
        b64WrittenDescnFileName: this.submitWrittenDescnFileName,
        caList: this.caList,
      };

      this.callApi(
        this.$constant.APPLY_COMPETITION_URL,
        requestData,
        (resultData) => {
          this.isLoading = false;

          if (!resultData.result) {
            this.showSnackbar = true;
            this.snackbarText = resultData.remarks;

            return;
          }

          this.$router.push({
            name: "ApplyCompetitionSuccess",
            params: {
              refNo: resultData.refNo,
              competition: this.competition,
              caList: this.caList,
            },
          });
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    getAllSchool() {
      this.isLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GET_ALL_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.schoolList = resultData.schoolList;
          if (this.schoolList != null) {
            this.schoolList.map((s) => {
              s.displayName = s.schoolNameEn + " - " + s.schoolNameTc;
            });

            this.schoolList.push({
              schoolId: 0,
              displayName: "Others - 其他",
            });
          }

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    fileChange() {
      if (this.uploadedFile != null) {
        this.isWrittenDescnReq = false;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.submitMaterial = e.target.result;
          this.submitMaterialFileName = this.uploadedFile.name;

          if (
            this.uploadedFile.type == "video/mp4" ||
            this.uploadedFile.type == "video/m4v" ||
            this.uploadedFile.type == "video/avi" ||
            this.uploadedFile.type == "video/mov" ||
            this.uploadedFile.type == "video/flv" ||
            this.uploadedFile.type == "video/wmv"
          ) {
            this.isWrittenDescnReq = true;
          }
        };
        reader.readAsDataURL(this.uploadedFile);
      } else if (this.writtenDescription != null) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.submitWrittenDescn = e.target.result;
          this.submitWrittenDescnFileName = this.writtenDescription.name;
        };
        reader.readAsDataURL(this.writtenDescription);
      }
    },
    clearForm(i) {
      i.surname = null;
      i.givenName = null;
      i.chiName = null;
      i.yearGroup = null;
      i.schoolClass = null;
      i.email = null;
      i.mobileNo = null;
    },
    showConfirmDialog() {
      this.caList = [];

      var schoolName =
        this.school.schoolId == 0
          ? this.otherSchoolName.trim()
          : this.school.displayName.trim();

      if (this.entryFormation == "i") {
        var applicant = {
          surname: this.surname != undefined ? this.surname.trim() : null,
          givenName: this.givenName != undefined ? this.givenName.trim() : null,
          chiName: this.chiName != undefined ? this.chiName.trim() : null,
          schoolName: schoolName != undefined ? schoolName.trim() : null,
          schoolClass:
            this.schoolClass != undefined ? this.schoolClass.trim() : null,
          yearGroup: this.yearGroup != undefined ? this.yearGroup.trim() : null,
          email:
            this.email != undefined ? this.email.trim().toLowerCase() : null,
          mobileNo: this.mobileNo != undefined ? this.mobileNo.trim() : null,
        };

        this.caList.push(applicant);
      } else {
        for (var i in this.groupInfo) {
          var info = this.groupInfo[i];

          if (info.email == undefined) {
            continue;
          }

          if (info.surname != undefined) {
            info.surname = info.surname.trim();
          }

          if (info.givenName != undefined) {
            info.givenName = info.givenName.trim();
          }

          if (info.chiName != undefined) {
            info.chiName = info.chiName.trim();
          }

          if (info.yearGroup != undefined) {
            info.yearGroup = info.yearGroup.trim();
          }

          if (info.schoolClass != undefined) {
            info.schoolClass = info.schoolClass.trim();
          }

          // if (i.email != undefined) {
          info.email = info.email.trim().toLowerCase();
          // }

          info.schoolName = schoolName;

          this.caList.push(info);
        }
      }

      this.isShowConfirmDialog = true;
    },
    closeConfirmDialog(confirm) {
      this.isShowConfirmDialog = false;

      if (confirm) {
        this.confirm();
      }
    },
    clearData() {
      this.surname = null;
      this.givenName = null;
      this.chiName = null;
      this.yearGroup = null;
      this.schoolClass = null;
      this.email = null;
      this.mobileNo = null;

      this.groupInfo = [];

      for (var i = 0; i < 4; i++) {
        var groupApplicant = {
          id: i,
          surname: null,
          givenName: null,
          chiName: null,
          yearGroup: null,
          schoolClass: null,
          email: null,
          mobileNo: null,
          isFormValid: true,
        };

        this.groupInfo.push(groupApplicant);
      }
    },
  },
};
</script>

<style lang="scss">
.required label::before {
  content: "* ";
  color: #fe253d;
}
.email input {
  text-transform: lowercase;
}
h5 {
  font-weight: normal;
}
.tabs {
  .v-card__title {
    margin-bottom: 0 !important;
  }
}
.mobile {
  .tabs {
    .v-card__title {
      font-size: 1rem;
    }
  }
}
</style>