<template>
  <v-card>
    <v-card-title>
      {{ $t("careerManagement") }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>
      <v-btn
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="onAddCareerBtnClick()"
      >
        {{ $t("addCareer") }}
      </v-btn>

      <v-btn 
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="exportData"
        :loading="isExportLoading"
      >
        {{ $t('course.exportData') }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="getDataList()"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
      style="white-space: pre"
    >
      <template v-slot:[`item.imageB64`]="{ item }">
        <div
          class="d-flex align-center justify-center"
          v-if="item.imageB64 != null"
        >
          <img
            class="py-1"
            width="50"
            :src="'data:image/png;base64,' + item.imageB64"
          />
        </div>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <v-icon :color="item.isActive == 'Y' ? 'success' : 'error'">
          mdi-checkbox-blank-circle
        </v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2c76b8" dark icon v-bind="attrs" v-on="on">
              <v-icon dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- edit -->
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("updateInfo") }}</v-list-item-title>
            </v-list-item>
            <!--  -->
            <v-list-item
              @click="setIsActive(item, false)"
              v-if="item.isActive == 'Y'"
            >
              <v-list-item-icon>
                <v-icon>mdi-diameter-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("deactivate") }}</v-list-item-title>
            </v-list-item>
            <!--  -->
            <v-list-item @click="setIsActive(item, true)" v-else>
              <v-list-item-icon>
                <v-icon>mdi-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("activate") }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="removeCareer(item)">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="80%"
      scrollable
      persistent
    >
      <CareerForm @close="onCloseDialog" :data="selected" />
    </v-dialog>

    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          :loading="isLoading"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import CareerForm from "./CareerForm.vue";
import _ from "lodash";
import CSVFile from "@/components/common/CSVFile";

export default {
  components: {
    CareerForm,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: "",
      dataList: [],
      sortBy: "schoolNameEn",
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,

      curFilters: [],
    };
  },
  created() {
    this.curFilters = this.filters;
  },
  mounted() {
    this.getAll();
  },
  computed: {
    headers: function() {
      const isEn = this.$i18n.locale == "en";
      return [
        {
          text: this.$t("jobTitle"),
          value: isEn ? "careerNameEn" : "careerNameTc",
        },
        {
          text: this.$t("partnership"),
          value: isEn
            ? "partnership.partnershipNameEn"
            : "partnership.partnershipNameTc",
        },
        {
          text: this.$t("isActive"),
          value: "isActive",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getDataList() {
      const filters = this.curFilters;

      let dataList = _.cloneDeep(this.dataList);

      if (filters.length > 0) {
        dataList = dataList.filter((item) => {
          let match = true;
          filters.map((filter) => {
            if (_.get(item, filter.path) != filter.value) {
              match = false;
            }
          });
          return match;
        });
      }

      return dataList;
    },
    removeCareer(item) {
      const isEn = this.$i18n.locale == "en";

      this.callApi(
        this.$constant.REMOVE_CAREER_URL,
        { id: item.careerId },
        (resultData) => {
          console.log(resultData);

          if (resultData.code == 0) {
            let msg = this.$t("generalRemoveSuccess", [
              isEn ? item.careerNameEn : item.careerNameTc,
            ]);

            this.$root.$emit("popSnackBar", msg, "green");
          } else {
            this.$root.$emit("popSnackBar", "generalError", "error");
          }

          this.isLoading = false;
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
    setIsActive(item, isActive) {
      //
      console.log(this.$root);
      this.callApi(
        this.$constant.SAVE_CAREER_URL,
        {
          career: {
            ...this.objectEncode(item),
            isActive: isActive ? "Y" : "N",
            partnership: null,
          },
        },
        (resultData) => {
          let msg = this.$t("generalSaveSuccess", [
            this.$t("partnershipCareer"),
          ]);

          this.$root.$emit("popSnackBar", msg, "green");
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
    getAll() {
      this.isLoading = true;

      var requestData = {};

      this.callApi(
        this.$constant.GET_ALL_CAREER,
        requestData,
        (resultData) => {
          console.log("resultData", resultData);
          // this.dataList = resultData.partnershipList.map((item) => {
          //   return this.objectDecode(item, ["b64FileName", "b64FileName"]);
          // });
          let dataList = resultData.careerObj.map((item) => {
            const career = item;
            const partnership = item.partnershipObj;

            let data = {
              ...career,
              partnership: this.objectDecode(partnership),
            };

            return this.objectDecode(data, ["b64FileName", "b64FileName"]);
          });

          this.dataList = dataList;

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    edit(item) {
      this.selected = item;
      this.showEditDialog = true;
    },
    onCloseDialog() {
      this.showEditDialog = false;
      this.selected = {};
      this.getAll();
    },

    onAddCareerBtnClick() {
      let _filter = this.filters.filter(
        (filter) => filter.path == "partnershipId"
      );
      if (_filter && _filter.length > 0) {
        this.selected = {
          partnershipId: _filter[0].value,
        };
      }
      console.log("onAddCareerBtnClick", this.selected);
      this.showEditDialog = true;
    },
    exportData() {
      this.callApi(
        this.$constant.EXPORT_CAREERS_URL,
        {},
        (resultData) => {
          //
          const tableData = [
            {
              "careerNameEn": "Job Title(EN)",
              "careerNameTc": "Job Title(TC)",
              "companyNameEn": "Company Name(EN)",
              "companyNameTc": "Company Name(TC)",
              "appliedCount": "Number Of Forms Applied As",
              "resultCount": "Number Of Forms Resulted As",
            },
            ...resultData.data
          ]
           const fileTitle = `Stat_`  + "CAREERS" + `_${this.formatDate(
              new Date(),
              "YYYYMMDD_HHmmss"
            )}`;
          console.log('export careers', resultData);
          CSVFile(null, null, 'Data', tableData, fileTitle, false)
        },
        () => {
          //
          console.log('fail')
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
.text-start,
td {
  white-space: pre;
}
</style>
