/* eslint-disable */
import serverpem from 'raw-loader!../../assets/keys/server.pem';
/* eslint-enable */

const forge = require('node-forge');
const fs = require('fs');

export default {
    encrypt(data) {
        const { pki } = forge;

        const cert = pki.certificateFromPem(serverpem);
        const key = forge.random.getBytesSync(32);
        const iv = forge.random.getBytesSync(16);

        // console.log(`iv: ${forge.util.encode64(iv)}`);
        // wrap key
        const wrappedKey = cert.publicKey.encrypt(key);
        // console.log(`wrappedKey: ${forge.util.encode64(wrappedKey)}`);

        data = JSON.stringify(data)
        data = forge.util.encodeUtf8(data)
        console.log(data)

        const cipher = forge.aes.createEncryptionCipher(key, 'CBC');
        cipher.start(iv);
        cipher.update(forge.util.createBuffer(data));
        cipher.finish();
        const encrypted = cipher.output;
        // console.log(`data: ${forge.util.encode64(encrypted.data)}`);

        return { key: forge.util.encode64(wrappedKey), iv: forge.util.encode64(iv), data: forge.util.encode64(encrypted.data) };
    },

    decrypt(data, priKey) {
        priKey = forge.pki.privateKeyFromPem(priKey)

        const respEncData = forge.util.decode64(data.data);
        const respKey = forge.util.decode64(data.key);
        const respIv = forge.util.decode64(data.iv);

        // unwrap key
        const aesKey = priKey.decrypt(respKey);

        const decipher = forge.aes.createDecryptionCipher(aesKey, 'CBC');
        decipher.start(respIv);
        decipher.update(forge.util.createBuffer(respEncData));
        const result = decipher.finish(); // check 'result' for true/false
        if (result) {
            return JSON.parse(forge.util.decodeUtf8(decipher.output.data));
        }
        return null;
    },

    gArrayBufferToString(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    },

    generateKeyPair(callback) {
        var rsa = forge.pki.rsa;
        var keypair = rsa.generateKeyPair({bits: 2048, e: 0x10001});
        rsa.generateKeyPair({bits: 2048, workers: 4}, function(err, keypair) {
            var pubKey = forge.pki.publicKeyToPem(keypair.publicKey);
            pubKey = pubKey.replace('-----BEGIN PUBLIC KEY-----', '').replace('-----END PUBLIC KEY-----', '').replace(/\r?\n|\r/g, '');
            
            var priKey = forge.pki.privateKeyToPem(keypair.privateKey)

            callback({ pubKey: pubKey, priKey: priKey}) ;
        });
    },

    gHashPassword(rawPwd, salt) {
        const md = forge.md.sha256.create();
        md.start();
        md.update(rawPwd + salt, 'utf8');
        return this.gHexToBase64(this.gStringToHex(salt) + md.digest().toHex());
    },

    gHexToBase64(str) {
        return btoa(String.fromCharCode.apply(null,
            str.replace(/\r|\n/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' ')));
    },

    gStringToHex(str) {
        const arr1 = [];
        for (let n = 0, l = str.length; n < l; n++) {
            const hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return arr1.join('');
    },
};
