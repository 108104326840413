<template>
  <v-card>
		<v-toolbar color="#2c76b8" dark>
			<v-toolbar-title>
				Survey 問卷
			</v-toolbar-title>
		</v-toolbar>

    <v-card-text>
      <v-card-title class="justify-center font-weight-bold text-h5 pb-0">
        感謝參加網路研討會
      </v-card-title>

      <v-card-title class="justify-center font-weight-bold text-h5 pt-0 pb-3">
        Thank you for attending the webinar
      </v-card-title>

      <v-card-subtitle class="text-center pt-3 pb-0">
        請用少許時間完成此問卷。
      </v-card-subtitle>

      <v-card-subtitle class="text-center pt-0">
        Please take a moment to fill out the survey below.

      </v-card-subtitle>

      <v-card-text v-for="(question, index) in questions" :key="index">
        <p class="text-h6 required">
          {{ question.num }}. {{question.text}}
        </p>

        <v-radio-group v-model="answer[index]">
          <v-radio
            label="Yes 是"
            color="#2c76b8"
            value="Yes 是"
          />
          <v-radio
            label="No 否"
            color="#2c76b8"
            value="No 否"
          />
        </v-radio-group>
      </v-card-text>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :loading="isLoading"
        :dark="!isDisabled"
        :disabled="isDisabled"
        @click="confirm"
      >
        Submit 提交
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['enrollmentId', 'courseId'],
  data() {
    return {
      isLoading: false,

      answer: [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],

      questions: [
        {
          num: 1,
          text: 'Have you learn something new? 你有沒有學到新東西?',
        },
        {
          num: 2,
          text: 'Did you learn more about an IT or Tech area of expertise and career option? 您是否對IT或科技方面的專業知識和職業選擇有更多了解?',
        },
        {
          num: 3,
          text: 'Prior to attending this Master Class program, have you considered: 在參加此大師班課程之前，你是否想過：that IT or Tech knowledge can add value to your dream job/career? IT或科技知識可以為您夢想的工作/職業增值?',
        },
        {
          num: 4,
          text: 'Prior to attending this Master Class program, have you considered: 在參加此大師班課程之前，你是否想過：studying IT or Tech related courses in your post-secondary studies? 在大專課程中學習IT或科技相關課程?',
        },
        {
          num: 5,
          text: 'Prior to attending this Master Class program, have you considered: 在參加此大師班課程之前，你是否想過：the possibility of pursuing a career in IT or Tech related industry? 從事IT或科技相關行業的職業?',
        },
        {
          num: 6,
          text: 'After attending this course, would you be interested to explore and learn more about this expertise or career option? 參加本課程後，你是否有興趣發掘和了解更多相關專業知識或職業選擇?',
        },
        {
          num: 7,
          text: 'Do you think It or Tech knowledge and expertise is necessary for futer job security or enhancing your career path? 您認為IT或科技知識和專業對於未來的工作保障或提升您的職業生涯規劃是否必要?',
        },
        {
          num: 8,
          text: 'If you don not plan to an IT or Tech major in your post-secondary studies, would you consider taking any IT or Tech related courses to strengthen your future career path in other industries? 如果您在大專課程中不打算主修IT或科技，您現時會否考慮報讀任何IT或科技相關課程，以提升未來在其他行業的專業能力?',
        },
      ]
    }
  },
  methods: {
    confirm() {
      this.isLoading = true

      var requestData = {
        courseId: this.courseId,
        enrollmentId: this.enrollmentId,
        q1: this.questions[0].text,
        q2: this.questions[1].text,
        q3: this.questions[2].text,
        q4: this.questions[3].text,
        q5: this.questions[4].text,
        q6: this.questions[5].text,
        q7: this.questions[6].text,
        q8: this.questions[7].text,
        a1: this.answer[0],
        a2: this.answer[1],
        a3: this.answer[2],
        a4: this.answer[3],
        a5: this.answer[4],
        a6: this.answer[5],
        a7: this.answer[6],
        a8: this.answer[7],
      }

      this.callApi(this.$constant.UPLOAD_SURVEY_FOR_EXPIRED_COURSE_REPORT_URL,
        requestData,
        (resultData) => {
          this.closeDialog(true)
        },
        () => {
          this.isLoading = false
        }
      )
    },
    closeDialog() {
      this.answer = [
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ]

      this.$emit('close')
		},
  },
  computed: {
    isDisabled: function() {
      for (var i in this.answer) {
        if (this.answer[i] == '') {
          return true
        }
      }

      return false
    },
  },
  mounted() {
    console.log(this.courseId)
  }
}
</script>

<style lang="scss" scoped>
p {
  color: #000;
}
.required::after {
  content: " *";
  color: #FE253D;
}
</style>