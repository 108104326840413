<template>
  <v-dialog
    v-model="isVisible"
    scrollable
    :width="isMobile ? '100vw' : '80vw'"
    :height="isMobile ? '100%' : 'auto'"
    max-width="1200"
    persistent
    :fullscreen="isMobile"
    id="test"
  >
    <v-card :class="isMobile ? 'mobile' : ''">
      <!-- dialog header -->
      <v-toolbar color="success" dark style="max-height: 56px">
        <v-toolbar-title>
          {{ $t("applyForCareerExp") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon
          @click="
            () => {
              setIsVisible(false);
              resetAll();
            }
          "
          >mdi-close</v-icon
        >
      </v-toolbar>

      <!-- <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ currentTitle }}</span>
        <v-avatar
          color="primary lighten-2"
          class="subheading white--text"
          size="24"
          v-text="step"
        ></v-avatar>
      </v-card-title> -->

      <!-- Apply Result -->
      <v-container v-if="isApplied && isPending" class="pa-6">
        <v-row>
          <v-col>
            <h4>
              {{ $t('applyCareerPending') }}
            </h4>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="isApplied && !isPending && !isRejected" class="pa-6">
        <v-row>
          <v-col>
            <h2 class="text-uppercase">
              {{ $t('congratulations') }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h4>
              {{ $t('applyCareerSuccess') }}
            </h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h4>
              {{
                $i18n.locale == "en"
                  ? applyResult.companyNameEn
                  : applyResult.companyNameTc
              }}
            </h4>

            <h4>
              {{
                $i18n.locale == "en"
                  ? applyResult.careerNameEn
                  : applyResult.careerNameTc
              }}
            </h4>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="isApplied && !isPending && isRejected" class="pa-6">
        <v-row>
          <v-col>
            <h2 class="text-uppercase">
              {{ $t('sorry') }}
            </h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h4>
              {{ $t('applyCareerRejected') }}
            </h4>
          </v-col>
        </v-row>
      </v-container>

      <!-- dialog content -->
      <v-window
        v-model="step"
        class="pa-6 overflow-y-auto"
        max-height="1000"
        style="height: auto"
        v-if="!isApplied"
      >
        <!-- tab 1 -->
        <v-window-item :value="1">
          <v-row>
            <v-col>
              <p v-for="(item, i) in ($i18n.locale == 'en' ? msg.en : msg.tc)" :key="i">
                {{ item }}
              </p>
            </v-col>
          </v-row>

          <v-row >
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col>
              <h4 style="color: #888" class="pa-0 mb-4">
                {{ $t("chooseUpToNCompany",[maxinumSelect]) }}
              </h4>
            </v-col>
          </v-row> -->

          <v-row align="stretch">
            <v-col
              class="d-flex flex-column"
              :cols="isMobile ? 12 : 6"
              v-for="(item, i) in companyList"
              :key="i"
            >
              <v-card
                v-if="item.partnership"
                elevation="2"
                class="d-flex flex-column pa-0"
                style="flex: auto"
                :ref="'v-card-' + i"
                :id="'v-card-' + i"
              >
                <v-responsive>
                  <v-img
                    class="white--text align-end"
                    :src="item.partnershipObj.imageB64"
                    :lazy-src="item.partnershipObj.imageB64"
                    contain
                  >
                  </v-img>
                  <!-- <div class="d-flex justify-center align-center">
                    <img
                      :src="item.partnershipObj.imageB64"
                      style="
                        object-fit: contain;
                        max-width: 100%;
                        margin: auto;
                      "
                    />
                  </div> -->
                </v-responsive>

                <!-- <v-card-title>
                  {{
                    $i18n.locale == "en"
                      ? item.partnership.partnershipNameEn
                      : item.partnership.partnershipNameTc
                  }}
                </v-card-title>

                <div v-for="(desc, j) in item.desc" :key="j">
                  <v-card-subtitle class="pb-0">
                    {{ $t(desc.title) }}
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div style="white-space: pre-line">
                      {{ desc.content[$i18n.locale] }}
                    </div>
                  </v-card-text>
                </div> -->

                <!-- <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-simple-checkbox
                    :value="
                      selectedCompany.filter((i) => Object.is(i, item)).length >
                      0
                    "
                    color="green"
                    :disabled="isCheckboxDisabled(item)"
                    @click="onCheckBoxClick(item)"
                  ></v-simple-checkbox>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-window-item>

        <!-- tab 2 -->
        <v-window-item :value="2">
          <h4 style="color: #888" class="pa-0">
            {{ $t("careerMobileNo") }}
          </h4>

          <v-text-field
            ref="mobileNo"
            v-model="mobileNo"
            :label="$t('mobileNo')"
            color="#2c76b8"
            :rules="mobileNoRules"
            clearable
            counter="8"
            maxlength="8"
          />

          <h4 style="color: #888" class="pa-0">
            {{ $t("provideReasonsInPDF") }}
          </h4>
          <div class="">
            <v-file-input
              v-model="uploadedFile"
              show-size
              chips
              truncate-length="30"
              :rules="rules"
              :label="$t('uploadPDF')"
              color="#2c76b8"
              @change="fileChange"
              accept="application/pdf"
            />
            <h5 style="color: #888" class="pa-0">
              {{ $t("materialHints") }}
            </h5>
          </div>
        </v-window-item>

        <!-- tab 3 -->
        <v-window-item :value="3">
          <v-card-title class="justify-center">
            <v-icon size="100px" color="green accent-4"
              >mdi-check-circle</v-icon
            >
          </v-card-title>
          <v-card-text class="text-center text-uppercase pb-0 mb-0">
            <h2 style="color: #00c853">{{ $t("succeed") }}</h2>
          </v-card-text>
          <!-- <v-simple-table class="my-3" style="margin: auto">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Company Name</th>
                  <th class="text-left">Job Title</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in selectedCompany" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td>
                    {{
                      $i18n.locale == "en"
                        ? item.partnership.partnershipNameEn
                        : item.partnership.partnershipNameTc
                    }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "en"
                        ? item.careerNameEn
                        : item.careerNameTc
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->

          <v-card-subtitle class="text-center">
            {{ $t("careerApplyResult") }}
          </v-card-subtitle>

        </v-window-item>
      </v-window>

      <v-spacer></v-spacer>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :disabled="step === 1 || step === 3" text @click="step--">
          {{ $t("prev") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="getRightBtns().disabled()"
          color="success"
          depressed
          :loading="isNextBtnLoading"
          @click="getRightBtns().onClick()"
        >
          {{ $t(getRightBtns().name) }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- snackbar -->
    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      :color="'red lighten-1'"
    >
      <span class="white--text">
        {{ $t(snackbarText) }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<style lang="scss" scoped>
.item,
.draggable-item {
  // background-color: aquamarine;
  width: 80%;
}

.mobile {
  h2 {
    font-size: 1rem !important;
  }
  .priority-text {
    font-size: 0.875rem;
  }
}
</style>


<script>
// @ is an alias to /src

export default {
  name: "CareerExpDialog",
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    setIsVisible: {
      type: Function,
      default: () => {},
    },
    email: {
      type: String,
      default: "",
    },
  },
  created() {
    //
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Select Company";
        case 2:
          return "Set Company Priority";
        default:
          return "Account created";
      }
    },
  },
  methods: {
    //
    checkCareerExpResult() {
      this.isLoading = true;
      var requestData = {
        email: this.email
      }

      this.callApi(
        this.$constant.CHECK_CAREER_EXP_RESULT_URL,
        requestData,
        (resultData) => {
          console.log('-------------')
          console.log(resultData)

          this.isApplied = resultData.isApplied
          this.isPending = resultData.isPending
          this.isRejected = resultData.isRejected
          this.applyResult = resultData.applyResult
        },
        (e) => {
          console.log(e);
        }
      );

    },
    submitCareerExpForm(onSucces, onFailure) {
      this.isLoading = true;
      const reader = new FileReader();

      reader.onload = (e) => {
        let imageB64 = e.target.result;

        const requestData = {
          email: this.email,
          careerExperienceFormObj: {
            pdfB64: imageB64,
            careerChoiceObjs: this.selectedCompany.map((item) => {
              return {
                careerId: item.careerId,
              };
            }),
          },
          mobileNo: this.mobileNo,
        };

        console.log("requestData", requestData);

        this.callApi(
          this.$constant.SUBMIT_CAREER_EXP_FORM,
          requestData,
          (resultData) => {
            console.log(resultData);
            if (resultData.code == -4) {
              onFailure && onFailure("careerExpFormSubmittedB4");
            } else {
              onSucces && onSucces();
            }
          },
          (e) => {
            console.log(e);
            onFailure && onFailure();
          }
        );
      };
      console.log("file", this.uploadedFile);
      reader.readAsDataURL(this.uploadedFile);
    },
    resetAll() {
      this.selectedCompany = [];
      this.uploadedFile = null;
      this.submitMaterial = null;
      this.submitMaterialFileName = null;
      this.step = 1;
    },
    getRightBtns() {
      switch (this.step) {
        case 1:
          return {
            name: "next",
            disabled: () => {
              if (this.isPending) {
                return true
              }
              if (this.selectedCompany) {
                return this.selectedCompany.length == 0;
              }
              return false;
            },
            onClick: () => {
              this.step = this.step + 1;
            },
          };
        case 2:
          return {
            name: "next",
            disabled: () => {
              if (this.mobileNo == undefined || this.mobileNo == "" || !this.$refs.mobileNo.validate()) {
                return true
              }
              if (this.selectedCompany) {
                return (
                  this.selectedCompany.length == 0 ||
                  this.submitMaterial == null
                );
              }
              return false;
            },
            onClick: () => {
              this.isNextBtnLoading = true;

              this.submitCareerExpForm(
                () => {
                  this.isNextBtnLoading = false;
                  this.step = this.step + 1;
                },
                (msg) => {
                  this.showSnackbar = true;
                  this.snackbarText = msg ?? "applyForECertFailure";
                  this.isNextBtnLoading = false;
                }
              );
            },
          };
        case 3:
          return {
            name: "confirm",
            disabled: () => {
              return false;
            },
            onClick: () => {
              this.setIsVisible(false);
              this.resetAll();
            },
          };
      }
    },
    onCompanyClick(item, i) {
      this.onCheckBoxClick(item);
      document.getElementById("v-card-" + i).blur();
      // const a = this.$refs["test"];
      // console.log(a)
    },
    isNextBtnDisabled() {
      switch (this.step) {
        case 1:
          if (this.isPending || this.selectedCompany.length == 0) {
            return true;
          }
          break;
        case 3:
          return true;
      }
      return false;
    },
    isCheckboxDisabled(item) {
      if (this.selectedCompany.length == this.maxinumSelect) {
        if (this.selectedCompany.filter((i) => Object.is(i, item)).length > 0) {
          // is selected
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    onCheckBoxClick(item) {
      if (!this.isCheckboxDisabled(item)) {
        if (this.selectedCompany.filter((i) => Object.is(i, item)).length > 0) {
          // is selected
          this.selectedCompany = this.selectedCompany.filter(
            (i) => !Object.is(i, item)
          );
        } else {
          this.selectedCompany.push(item);
        }
      }
    },
    moveArrayItemToNewIndex(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    fileChange() {
      if (this.uploadedFile != null) {
        this.isWrittenDescnReq = false;

        const reader = new FileReader();
        reader.onload = (e) => {
          this.submitMaterial = e.target.result;
          this.submitMaterialFileName = this.uploadedFile.name;

          if (
            this.uploadedFile.type == "video/mp4" ||
            this.uploadedFile.type == "video/m4v" ||
            this.uploadedFile.type == "video/avi" ||
            this.uploadedFile.type == "video/mov" ||
            this.uploadedFile.type == "video/flv" ||
            this.uploadedFile.type == "video/wmv"
          ) {
            this.isWrittenDescnReq = true;
          }
        };
        reader.readAsDataURL(this.uploadedFile);
        console.log(this.uploadedFile);
      }
    },
  },
  mounted() {
    this.checkCareerExpResult()

    this.callApi(
      this.$constant.GET_ALL_CAREER,
      {},
      (resultData) => {
        this.isLoading = false;
        console.log(resultData);
        const careerObj = resultData.careerObj;

        let list = [];

        careerObj
          .filter((item) => item.isActive == "Y")
          .map((_item) => {
            const career = this.objectDecode(_item);
            const partnership = this.objectDecode(_item.partnershipObj);
            // console.log("partnership", partnership);
            //
            // let item = this.objectDecode(_item, ["b64FileName", "b64FileName"]);
            let desc = [
              {
                title: "jobTitle",
                content: {
                  en: career.careerNameEn,
                  tc: career.careerNameTc,
                },
              },
            ];
            if (career.careerDescEn || career.careerDescTc) {
              desc.push({
                title: "jobDesc",
                content: {
                  en: career.careerDescEn
                    ? career.careerDescEn
                    : career.careerDescTc,
                  tc: career.careerDescTc
                    ? career.careerDescTc
                    : career.careerDescEn,
                },
              });
            }

            // if (career.careerRequirementsEn || career.careerRequirementsTc) {
            //   desc.push({
            //     title: "jobRequirements",
            //     content: {
            //       en: career.careerRequirementsEn
            //         ? career.careerRequirementsEn
            //         : career.careerRequirementsTc,
            //       tc: career.careerRequirementsTc
            //         ? career.careerRequirementsTc
            //         : career.careerRequirementsEn,
            //     },
            //   });
            // }
            // desc.push({
            //   title: "contact",
            //   content: {
            //     en: partnership.partnershipContactEn,
            //     tc: partnership.partnershipContactTc,
            //   },
            // });
            let listItem = {
              ...career,
              partnership: partnership,
              desc,
            };
            list.push(listItem);
          });

        this.companyList = list;

        this.selectedCompany = this.companyList

        this.showEditForm = true;
      },
      () => {
        this.isLoading = false;
        this.isRobot = true;
      }
    );
  },
  data() {
    return { 
      applyResult: null,
      isApplied: false,
      isPending: false,
      isRejected: false,
      maxinumSelect: 4,
      step: 1,
      items: ["Item 1", "Item 2", "Item 3"],
      file: null,
      uploadedFile: null,
      submitMaterial: null,
      submitMaterialFileName: null,
      selectedCompany: [],
      showSnackbar: false,
      snackbarText: "",
      rules: [
        (value) =>
          !value ||
          value.size < 100000000 ||
          "File size should be less than 100 MB!",
      ],
      isNextBtnLoading: false,
      companyList: [],
      msg: {
        en: [
          "Congratulations for achieving required levels of the Master Class! You are now inviting to submit a letter of 1000 words by telling us:",
          "",
          "1)	Which Master Classes most impressed you?",
          "2)	What careers you are most interested in exploring?",
          "3)	How master classes inspired you?",
          "4)	What you learned that you didn't know before?",
          "5)	Would you recommend Ampower's master classes to you friends?",
          "",
          "Students who submitted the most persuasive letters would be invited to join Ampower Career Experience Program this summer.",
          "",
          "Please submit your work as a PDF file.",
          "",
          "Hope to see you in person this summer! Ampower Your Future!",
          "",
          "Deadline: 29 July 2022",
          "",
          "If you have any questions, please whatsapp Miss Lee at 9250 7226! Thank you!",
        ],
        tc: [
          "恭喜你達到大師班所需級別！我們現在邀請你提交一封 1000 字的信函，告訴我們：",
          "",
          "1) 哪些大師班給您留下了最深刻的印象？",
          "2) 你最感興趣的職業是什麼？",
          "3) 大師班如何啟發了你？",
          "4) 你學到了哪些是以前不知道的新東西？",
          "5）你會向你的朋友推薦Ampower的大師班嗎？",
          "",
          "寫出最具說服力信件的學生將被邀請參加今年夏天舉行的 Ampower 職業體驗計劃。",
          "",
          "請以PDF格式提交。",
          "",
          "希望今年夏天我們能親身與你見面！前途在你手！Ampower Your Future!",
          "截止提交日期： 2022年 7月29日",
          "",
          "如有任何疑問，請 whatsapp 9250 7226 李小姐查詢！",
        ],
      },
      mobileNoRules: [
        (v) => !v || /^[0-9]{8}$/.test(v) || this.$t("invalidMobileNo"),
      ],
      mobileNo: null,
    }
  }
};
</script>
