import XLSX from 'xlsx';

export default function CSVFile(tableHead, sheetTitle, sheetName, data, fileTitle, isMulti, successCallBack) {
    var sheetData = []
    var name = []

    if(isMulti){
        for(var i in data){
            var multiSheet = []
            
            multiSheet.push([sheetTitle[0], data[i][2]])
            multiSheet.push([sheetTitle[1], data[i][1]])
            
            name.push(data[i][0])
           
            multiSheet.push("")
            multiSheet.push(tableHead)
            
            for(var j = 3; j < data[i].length ; j++){
                multiSheet.push(data[i][j])
            }
            // for(var j in data[i]){
            //     multiSheet.push(data[i][j])
            // }
            sheetData.push(XLSX.utils.json_to_sheet(multiSheet,{skipHeader: true}));
        }
    } else if (!isMulti){
        // data.unshift(tableHead)

        console.log(data)
        var sheet = XLSX.utils.json_to_sheet(data,{skipHeader:true});
    }
    
    openDownloadDialog(sheet2blob(), fileTitle);

    successCallBack()

    function sheet2blob() {
        var workbook = {
            SheetNames: [],
            Sheets: {}
        };

        if(isMulti){
            for(var i = 0; i < name.length; i++){
                workbook.SheetNames.push(name[i])
                workbook.Sheets[name[i]] = sheetData[i]
            }
        } else {
            workbook.SheetNames.push(sheetName)
            workbook.Sheets[sheetName] = sheet;
        }
        
        var wopts = {
            bookType: 'csv', 
            bookSST: false, 
            type: 'binary'
        };
        var wbout = XLSX.write(workbook, wopts);
        var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
      
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        return blob;
    }

    function openDownloadDialog(url, fileTitle)
    {
        if(typeof url == 'object' && url instanceof Blob)
        {
            url = URL.createObjectURL(url);
        }
        var aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = `${fileTitle}.csv` || 'export.csv';
        var event;
        if(window.MouseEvent) event = new MouseEvent('click');
        else
        {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
        }
}
