<template>
	<v-card>
		<v-toolbar color="#2c76b8" dark>
			<v-toolbar-title>
				{{ $t('userLogin') }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn
				icon
				dark
				@click="closeDialog"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-card-text>
			<v-container>
				<v-row>
					<v-col>
						<v-text-field
							v-model="username"
							:label="$t('username')"
							color="#2c76b8"
							clearable
							>
						</v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-text-field
							v-model="password"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							:type="showPassword ? 'text' : 'password'"
							:label="$t('password')"
							counter
							@click:append="showPassword = !showPassword"
							color="#2c76b8"
						>                      
						</v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<vue-recaptcha 
              :sitekey="$constant.RECAPTCHA_SITE_KEY" 
              :loadRecaptchaScript="true" 
              @verify="verify" 
              :language="$i18n.locale == 'en' ? 'en' : 'zh-TW'"
              @expired="isRobot = true"
            />
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-btn
							color="orange darken-1"
							:dark="!isDisabled"
							:disabled="isDisabled"
							@click="genKeyPair"
							block
              :loading="isLoading"
						>
							{{ $t('confirm') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
		<v-snackbar
			top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
	</v-card>
</template>

<script>

import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha'

export default {
	components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data() {
    return {
			showSnackbar: false,
			snackbarText: '',
      showPassword: false,
      username: null,
      password: null,
      isRobot: true,
    	key: {
        public: null,
        private: null,
      },
      isLoading: false,
    }
  },
  mounted() {
	},
  methods: {
		closeDialog(evt) {
      evt.preventDefault()
			// this.$refs.recaptcha.reset()
      this.username = ''
      this.password = ''

      this.$emit('close')
    },
    verify(response) {
      var requestData = {
        userResponse: response
      }
      this.callApi(this.$constant.VERIFY_RECAPTCHA_URL,
        requestData,
        (resultData) => {
          this.isRobot = !resultData.result
        },
        () => {
          this.isLoading = false
          this.isRobot = true
        }
      )
    },
    genKeyPair() {
      this.isLoading = true

      if (this.key.public != null) {
        this.login()
      } else {
        this.generateKeyPair(
          (result) => {
            this.key.public = result.pubKey
            this.key.private = result.priKey

            this.getSaltKey()
          }
        )
      }
    },
    getSaltKey() {
      if (this.$store.state.user.saltKey == null) {
        var requestData = {
        }
        this.callApi(this.$constant.GET_SALT_KEY_URL,
          requestData,
          (resultData) => {
            this.$store.commit('storeSaltKey', resultData.saltKey)

            this.login()
          },
          () => {
            this.isLoading = false
          }
        )
      } else {
        this.login()
      }
    },
    login() {
      this.$store.commit('storePriKey', this.key.private)

      var requestData = {
        username: this.username,
        password: this.password, 
        //this.hashPassword(this.password),
        //publicKey: this.key.public,
      }

      //this.$recaptcha("login").then(token => {
        var requestData = {
          username: this.username,
          password: this.hashPassword(this.password),
          publicKey: this.key.public,
          //token: token,
        }

        this.callApi(this.$constant.USER_LOGIN_URL,
          requestData,
          (resultData) => {
						console.log(resultData)
            if(resultData != null && resultData.loginResult) {
              this.key.public = null
              this.key.private = null

              this.$store.commit('setUser', resultData)

							this.showSnackbar = true
          		this.snackbarText = this.$t('login.successful')
              this.$router.push({path: '/Dashboard'})
            } else {
							this.showSnackbar = true
          		this.snackbarText = resultData.loginFailRemarks
            }

            this.isLoading = false
          },
          () => {
						console.log("fail")
            this.showSnackbar = true
            this.snackbarText = "Fail"

            this.isLoading = false
          }
        )
      //})
    }
	},
	computed: {
    isDisabled: function() {
      return this.isRobot || this.isEmptyString(this.username) || this.isEmptyString(this.password)
    },
  }
}
</script>