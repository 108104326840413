<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-card-title class="text-uppercase">
        {{ $t('updateInfo') }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-form v-model="isFormValid">
					<v-row>
            <v-col>
              <v-text-field
                v-model="participant.participantSurname"
                :label="$t('surname') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                :rules="nameRules"
                class="required"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-model="participant.participantGivenName"
                :label="$t('givenName') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                :rules="nameRules"
                class="required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="participant.participantChiName"
                :label="$t('chiName') + ':'"
                color="#2c76b8"
                clearable
                counter="10"
                maxlength="10"
                :rules="nameRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-autocomplete
                v-model="school"
                :items="schoolList"
                :search-input.sync="search"
                color="#2c76b8"
                hide-no-data
                hide-selected
                :label="$t('school')"
                class="required"
                item-text="displayName"
                return-object
                clearable
                @change="otherSchoolName = null"
                :hint="$t('schoolHint')"
                persistent-hint
              >
                <template v-slot:item="data">
                  <h5>
                    {{ data.item.displayName }}
                  </h5>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="school != null && school.schoolId == 0">
            <v-col>
              <v-text-field
                v-model="otherSchoolName"
                :label="$t('schoolName') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                :rules="schoolRules"
                class="required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="participant.isTeacher"
                :label="$t('teacher')"
                @change="toggleIsTeacher"
              ></v-checkbox>
            </v-col>

            <v-col v-if="!participant.isTeacher">
              <v-text-field
                v-model="participant.yearGroup"
                :label="$t('yearGroup') + ':'"
                color="#2c76b8"
                clearable
                counter="30"
                maxlength="30"
                :rules="classRules"
                class="required"
              />
            </v-col>

            <v-col v-if="!participant.isTeacher">
              <v-text-field
                v-model="participant.classLevel"
                :label="$t('class') + ':'"
                color="#2c76b8"
                clearable
                counter="30"
                maxlength="30"
                :rules="classRules"
                class="required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="participant.emailAddress"
                :label="$t('email') + ':'"
                color="#2c76b8"
                :rules="emailRules"
                clearable
                counter="100"
                maxlength="100"
                class="required email"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider />

		<v-card-actions class="justify-end">
      <v-spacer />
			
			<v-btn
				color="orange lighten-1"
				:dark="!isDisabled"
        :disabled="isDisabled"
				@click="submit"
				:loading="isLoading"
			>
				{{ $t('submit') }}
			</v-btn>
		</v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['participant'],
  data() {
    return {
      schoolList: [],
      school: null,
      otherSchoolName: null,
      search: null,
      isLoading: false,
      isFormValid: false,

      nameRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        v => !v || /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noNumAndSpecialCharacters')
      ],
      schoolRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        v => !v || /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
      classRules: [
        // v => (v || '').length <= 30 || this.$t('lengthTooLong', [30]),
        v => !v || /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
      emailRules: [
        // v => (v || '').length <= 100 || this.$t('lengthTooLong', [100]),
        v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('invalidEmail'),
      ],
      confirmEmailRules: [
        v => !v || v.toLowerCase() == this.email.toLowerCase() || this.$t('emailNotMatch')
      ],
    }
  },
  mounted() {
    this.getAllSchool()

    this.participant.isTeacher = ("Y" == this.participant.isTeacher)
  },
  methods: {
    getAllSchool() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_ALL_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.schoolList = resultData.schoolList
          if (this.schoolList != null) {
            this.schoolList.map(s => {
              s.displayName = s.schoolNameEn + ' - ' + s.schoolNameTc
            })

            var others = {
              schoolId: 0,
              displayName: 'Others - 其他',
            }

            this.schoolList.push(others)

            var filterList = this.schoolList.filter(s => {
              return s.displayName == this.participant.schoolName
            })

            if (filterList == undefined) {
              this.school = others
              this.otherSchoolName = this.participant.schoolName
            } else {
              this.school = filterList[0]
              this.otherSchoolName = null
            }
          }

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    toggleIsTeacher() {
      this.participant.yearGroup = null
      this.participant.classLevel = null
    },
    submit() {
      this.isLoading = true

      var schoolName = this.school.schoolId == 0 ? this.otherSchoolName.trim() : this.school.displayName.trim()

      var requestData = {
        enrollmentId: this.participant.enrollmentId,
        surname: this.participant.participantSurname.trim(),
        givenName: this.participant.participantGivenName.trim(),
        chiName: this.participant.participantChiName == null ? null : this.participant.participantChiName.trim(),
        schoolName: schoolName,
        yearGroup: this.participant.isTeacher ? null : this.participant.yearGroup.trim(),
        classLevel: this.participant.isTeacher ? null : this.participant.classLevel.trim(),
        isTeacher: this.participant.isTeacher,
        email: this.participant.emailAddress.toLowerCase().trim(),
      }

      this.callApi(this.$constant.UPDATE_PARTICIPANT_INFO_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
  },
  computed: {
    isDisabled: function() {
      return  this.isEmptyString(this.participant.participantSurname) || 
              this.isEmptyString(this.participant.participantGivenName) || 
              this.isEmptyString(this.school) || 
              (!this.participant.isTeacher && this.isEmptyString(this.participant.yearGroup) && this.isEmptyString(this.participant.schoolClass)) || 
              this.isEmptyString(this.participant.emailAddress) || 
              !this.isFormValid
    }
  },
}
</script>