<template>
  <v-app id="app">
    <AppBar ref="appBar" isAboutUs="true" />
    <v-main>
      <v-container style="max-width: 1400px" :class="isMobile ? 'mobile' : ''">
        <!-- Title -->
        <v-row style="background-color: #f4dccf; margin: 12px -6px">
          <v-col class="d-flex justify-center align-center tabs">
            <v-card-title
              class="py-1 text-center"
              style="word-break: break-word"
            >
              Ampower Talent Institute <br />
              {{ $t("closingTitle1") }}<br />
              {{ $t("closingTitle2") }}
            </v-card-title>
          </v-col>
        </v-row>
        <!-- poster -->
        <v-row>
          <v-col cols="12">
            <div>
              <v-img
                class="shrink mt-1"
                contain
                :src="
                  $i18n.locale == 'en'
                    ? require('@/assets/images/closing-banner-en.jpg')
                    : require('@/assets/images/closing-banner-tc.jpg')
                "
                transition="scale-transition"
                :width="isMobile ? '100%' : '100%'"
              />
            </div>
          </v-col>
          <!-- Intro -->
          <v-col cols="12" class="pa-4" style="background-color: white">
            <h2 class="red--text mb-4">
              {{ $t("closingIntroTitle") }}
            </h2>
            <p class="text-body-1">
              {{ $t("closingIntroBody1") }}
            </p>
            <p class="text-body-1">
              {{ $t("closingIntroBody2") }}
            </p>
          </v-col>
          <!-- rundown -->
          <v-col md="8" sm="12" class="" style="background-color: floralwhite">
            <h2 class="red--text my-4 text-center">
              {{ $t("programRundown") }}
            </h2>
            <div class="d-flex justify-center mb-4 pr-4">
              <v-timeline align-top dense :style="{flex: isMobile ? '1' : '0.8'}">
                <v-timeline-item
                  color="indigo darken-2 "
                  small
                  v-for="(item, i) in rundown"
                  :key="i"
                >
                  <v-row class="pt-1">
                    <v-col cols="3">
                      <strong style="font-family: auto">{{ item.time }}</strong>
                    </v-col>
                    <v-col>
                      <strong>{{ item.title[$i18n.locale] }}</strong>
                      <div
                        class="text-caption"
                        v-for="(caption, j) in item.captions"
                        :key="j"
                      >
                        {{ caption[$i18n.locale] }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>
          <!--  -->
          <v-col md="4" sm="12" class="pa-4" style="background-color: honeydew">
            <h2 class="red--text mb-4 text-center">
              {{ $t("details") }}
            </h2>
            <v-list two-line style="background-color: honeydew">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-clock </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="mb-2">{{
                    $t("time")
                  }}</v-list-item-title>
                  <v-list-item-subtitle>5:00pm – 6:30pm</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-calendar </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="mb-2">{{
                    $t("course.date")
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $i18n.locale == "en"
                      ? "06-04-2022 (Wednesday)"
                      : "2022年 4月6日（星期三）"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-email </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="mb-2">{{
                    $t("enquiries")
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    >secretariat@ampowertalent.org</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t("format") }}</v-list-item-title>
                  <v-list-item-subtitle>ZOOM </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider inset></v-divider>

              <v-list-item>
                <v-list-item-icon class="pt-5">
                  <v-icon color="indigo"> mdi-account-multiple-check </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t("apply") }}</v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <!-- <v-btn>
                    <v-icon>mdi-message-text</v-icon>
                  </v-btn> -->
                  <v-btn
                    color="green lighten-1"
                    class="mt-3"
                    :dark="isEmptyString(closingCeremonyClassData.youtubeLink)"
                    :disabled="!isEmptyString(closingCeremonyClassData.youtubeLink)"
                    @click="register()"
                    v-if="false"
                  >
                    {{ $t("apply") }}
                  </v-btn>
                  <v-btn
                    :dark="!isEmptyString(closingCeremonyClassData.youtubeLink)"
                    :disabled="isEmptyString(closingCeremonyClassData.youtubeLink)"
                    color="red"
                    class="mt-3 ml-2 text--white"
                    @click="showCheckApply"
                  >
                    {{ $t("replayCeremony") }}
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-col>
          <!--  -->
          <v-col
            cols="12"
            class="pa-4"
            style="background-color: aliceblue"
            v-if="true"
          >
            <h2 class="red--text mb-4" :class="isMobile ? 'text-center' : ''">
              {{ $t("winningList") }}
            </h2>
            <v-row class="pa-3">
              <v-col md="4" sm="12" v-for="(item, i) in awardList" :key="i">
                <v-card
                  class="mx-auto d-flex flex-column"
                  :loading="isLoading"
                  height="100%"
                >
                  <v-card-title
                    class="d-block pb-0"
                    style="word-break: break-word"
                  >
                    {{ t(item.competitionName) }}
                  </v-card-title>

                  <v-list two-line>
                    <!--  -->
                    <v-list-item v-for="(award, j) in item.awards" :key="j">
                      <v-list-item-icon>
                        <v-icon
                          :color="award.iconColor"
                          v-if="award.awardIcon != null"
                        >
                          {{ award.awardIcon }}
                        </v-icon>
                        <v-icon color="blue-grey" v-else class="pt-3">
                          mdi-trophy-award
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            'mb-2 ' +
                            (award.titleColor
                              ? award.titleColor
                              : 'blue-grey--text')
                          "
                          v-if="award.awardName != null"
                        >
                          {{ t(award.awardName) }}
                        </v-list-item-title>
                        <v-list-item-title v-if="award.schoolName != null">
                          {{ t(award.schoolName) }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-for="(applicant, k) in award.applicants"
                          :key="k"
                        >
                          {{ t(applicant) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <!-- end -->
                </v-card>
              </v-col>
            </v-row>
            <!--  -->
          </v-col>

          <!-- careerExpProgramAmbassadors -->
          <v-col
            :cols="isMobile ? 12 : 5"
            class="pa-4"
            style="background-color: white"
          >
            <h2 class="red--text mb-4">
              {{ $t("careerExpProgramAmbassadors") }}
            </h2>
            <v-row dense align="center">
              <v-col
                :cols="isMobile ? (isTablet? '2' : '4') : 'auto'"
                v-for="(x, i) in careerExpProgramAmbassadors"
                :key="i"
              >
                <v-responsive width="100%" max-height="64px" max-width="100px">
                  <v-img :src="x.img" />
                </v-responsive>
              </v-col>
            </v-row>
          </v-col>
          <!-- giftsSponsorship -->
          <v-col
            :cols="isMobile ? 12 : 7"
            class="pa-4"
            style="background-color: white"
          >
            <h2 class="red--text mb-4">
              {{ $t("giftsSponsorship") }}
            </h2>
            <v-row dense align="center">
              <v-col :cols="isMobile ? (isTablet? '2' : '4') : 'auto'" v-for="(x, i) in giftsSponsorship" :key="i">
                <v-responsive width="100%" max-height="64px" max-width="100px">
                  <v-img :src="x.img" />
                </v-responsive>
              </v-col>
            </v-row>
          </v-col>
          <!-- supportingOrganizations -->
          <v-col cols="12" class="pa-4 mb-4" style="background-color: white">
            <h2 class="red--text mb-4">
              {{ $t("supportingOrganizations") }}
            </h2>
            <v-row dense align="center">
              <v-col
                :cols="isMobile ? (isTablet? '2' : '4') : 'auto'"
                v-for="(x, i) in supportingOrganizations"
                :key="i"
              >
                <v-responsive width="100%" max-height="64px" max-width="100px">
                  <v-img :src="x.img" contain />
                </v-responsive>
              </v-col>
            </v-row>
          </v-col>
          <!-- apply replay dialog -->
          <v-dialog
            v-if="checkApplyDialog"
            v-model="checkApplyDialog"
            scrollable
            persistent
            width="unset"
          >
            <CheckApply
              :courseId="closingCeremonyClassData.courseId"
              @close="closeCheckApply"
              @showVideo="showVideo"
            />
          </v-dialog>
          <!-- dialogs -->
          <v-dialog
            v-if="showVideoDialog"
            v-model="showVideoDialog"
            :width="isMobile ? 'unset' : '50vw'"
            persistent
          >
            <YoutubePlayer
              @playing="playing"
              @ended="ended"
              @close="closeVideo"
              :videoList="videoList"
              :title="
                $i18n.locale == 'en'
                  ? closingCeremonyClassData.courseNameEn
                  : closingCeremonyClassData.courseNameTc
              "
              :showAmpowerLogo="false"
              :showClassReplay="true"
            />
          </v-dialog>

          <v-dialog
            v-if="showSurveyDialog"
            v-model="showSurveyDialog"
            :width="isMobile ? 'unset' : '50vw'"
            persistent
            scrollable
          >
            <Survey
              :courseId="closingCeremonyClassData.courseId"
              :enrollmentId="enrollmentId"
              @close="closeSurvey"
            />
          </v-dialog>

          <v-dialog
            v-if="showWatchNowDialog"
            v-model="showWatchNowDialog"
            :width="isMobile ? 'unset' : '50vw'"
            persistent
          >
            <WatchNow
              :refNo="refNo"
              @watchNow="watchNow"
              @close="closeWatchNow"
            />
          </v-dialog>

          <v-dialog
            v-model="showEndMsg"
            :width="isMobile ? 'unset' : '50vw'"
            persistent
          >
            <v-card>
              <v-toolbar color="#2c76b8" dark>
                <v-toolbar-title>
                  {{ $t("thx4Watching") + "!" }}
                </v-toolbar-title>
                <!-- <v-spacer />
          <v-btn
            icon
            dark
            @click="showEndMsg = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
              </v-toolbar>

              <v-card-title>
                {{ $t("thx4WatchingContent") + "!" }}
              </v-card-title>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="orange lighten-1"
                  dark
                  @click="showEndMsg = false"
                >
                  {{ $t("ok") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- end -->
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ClassCard from "@/views/class/ClassCard";
import DisplayText from "@/components/DisplayText";
import YoutubePlayer from "@/components/YoutubePlayer";
import StringSimilarity from "string-similarity";
import AppBar from "./AppBar";
import { awardList } from "@/constants/awardList";
import CheckApply from "./class/CheckApply";
import Survey from "./class/Survey";
import WatchNow from "./class/WatchNow";

export default {
  name: "ClosingCeremony",
  components: {
    AppBar,
    ClassCard,
    DisplayText,
    YoutubePlayer,
    Survey,
    WatchNow,
    CheckApply,
  },
  data() {
    return {
      isLoading: false,

      supportingOrganizations: [
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 204.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Mask Group 5.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Mask Group 2.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 202.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 193.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 195.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 189.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 203.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 205.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Mask Group 3.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 206.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 190.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 191.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 207.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 208.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 192.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 196.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 197.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 209.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 199.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Group 201.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/SupportingOrganizations/Mask Group 4.jpg"),
        },
      ],
      careerExpProgramAmbassadors: [
        {
          img: require("@/assets/images/OrganizationLogo/CareerExperienceProgramAmbassadors/Group 210.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/CareerExperienceProgramAmbassadors/Group 211.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/CareerExperienceProgramAmbassadors/Mask Group 6.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/CareerExperienceProgramAmbassadors/Group 213.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/CareerExperienceProgramAmbassadors/Group 214.jpg"),
        },
      ],
      giftsSponsorship: [
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Group 216.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/cyberport.png"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Group 215.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Mask Group 9.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Group 217.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Mask Group 11.jpg"),
        },

        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Group 218.jpg"),
        },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Mask Group 7.jpg"),
        },
        // {
        //   img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Mask Group 8.jpg"),
        // },
        {
          img: require("@/assets/images/OrganizationLogo/GiftsSponsorship/Mask Group 10.jpg"),
        },
      ],

      rundown: [
        {
          time: "17:00 – 17:05",
          title: {
            tc: "發佈「為同學打打氣」正能量影片",
            en: "Encouragement Trailer",
          },
        },
        {
          time: "17:05 – 17:07",
          title: {
            tc: "開幕 － 主持人介紹",
            en: "Ceremony starts by MC",
          },
        },
        {
          time: "17:07 – 17:10",
          title: {
            tc: "召集人致辭",
            en: "Speech by Convenor",
          },
          captions: [
            {
              tc: "陳曉峰律師MH, JP",
              en: "Nick Chan, MH, JP, Convenor of Ampower Talent Institute",
            },
          ],
        },
        {
          time: "17:10 – 17:13",
          title: {
            tc: "行政總裁致辭",
            en: "Speech by CEO",
          },
          captions: [
            {
              tc: "陳葉佩坤女士",
              en: "Sophia Chan-Yap, CEO of Ampower Talent Institute",
            },
          ],
        },
        {
          time: "17:13 – 17:27",
          title: {
            tc: "分享環節",
            en: "Chit Chat Session",
          },
          captions: [
            {
              tc: "立法會議員霍啟剛, JP",
              en: "Legislative Council Member The Hon Kenneth FOK Kai-kong, JP",
            },
            {
              tc: "陳曉峰律師MH, JP",
              en: "Nick Chan, MH, JP, Convenor of Ampower Talent Institute",
            },
          ],
        },
        {
          time: "17:27 – 17:30",
          title: {
            tc: "主禮嘉賓致辭",
            en: "Speech by Guest of Honour",
          },
          captions: [
            {
              tc: "香港特別行政區政府資訊科技總監林偉喬, JP",
              en: "Victor Lam, JP, Government Chief Information Officer of the HKSAR",
            },
          ],
        },
        {
          time: "17:30 – 18:15",
          title: {
            tc: "學生代表致謝詞、頒獎、優秀作品分享及網上合照",
            en: "Thank you speech by student representative, results announcement, winning teams’ presentations and online group photo moment",
          },
        },
        {
          time: "18:15 – 18:20",
          title: {
            tc: "閉幕 － 主持人總結",
            en: "Closing remarks by MC and End of Ceremony",
          },
        },
      ],

      awardList: awardList,

      closingCeremonyClassData: {},

      checkApplyDialog: false,
      showVideoDialog: false,
      showSurveyDialog: false,
      videoList: [],
      enrollmentId: null,
      surveyReq: true,
      showWatchNowDialog: false,
      refNo: null,
      isWatched: false,
      showEndMsg: false,

      isReqRegister: true,
    };
  },
  methods: {
    register() {
      this.$store.commit("clearCourses");

      this.$store.commit("addCourses", this.closingCeremonyClassData);

      this.$router.push({ path: "/Apply" });
    },
    showCheckApply() {
      if (this.isReqRegister) {
        this.checkApplyDialog = true;
      } else {
        this.surveyReq = false;

        this.checkApplyDialog = false;

        this.showWatchNowDialog = false;
        this.showVideoDialog = true;

        this.videoList = [];
        this.videoList.push({
          id: this.getYoutubeId(this.youtubeLink),
          title: this.title,
        });
      }
    },
    closeCheckApply() {
      this.checkApplyDialog = false;
    },
    showVideo(enrollmentId, surveyReq, refNo, isRefNo) {
      if (isRefNo == undefined && refNo == undefined) {
        return;
      }

      this.enrollmentId = enrollmentId;
      this.surveyReq = surveyReq;

      this.checkApplyDialog = false;
      if (!isRefNo) {
        this.refNo = refNo;
        this.showWatchNowDialog = true;
        this.showVideoDialog = false;
      } else {
        this.showWatchNowDialog = false;
        this.showVideoDialog = true;
      }
      this.videoList = [];
      this.videoList.push({
        id: this.getYoutubeId(this.youtubeLink),
        title: this.title,
      });
    },
    closeVideo() {
      this.showVideoDialog = false;
      // if (this.surveyReq) {
      //   this.showSurveyDialog = true
      // }
    },
    watchNow() {
      this.showVideoDialog = true;
      this.showWatchNowDialog = false;
    },
    closeWatchNow() {
      this.showVideoDialog = false;
      this.showWatchNowDialog = false;
    },
    playing() {
      if (!this.isWatched) {
        var requestData = {
          courseId: this.courseId,
          enrollmentId: this.enrollmentId,
        };

        this.callApi(
          this.$constant.MARK_AS_ATTENDED_URL,
          requestData,
          (resultData) => {
            this.isWatched = true;
          },
          () => {}
        );
      }
    },
    ended() {
      this.showVideoDialog = false;
      if (this.surveyReq) {
        this.showSurveyDialog = true;
      } else {
        this.showEndMsg = true;
      }
    },
    closeSurvey() {
      this.showSurveyDialog = false;
      this.showEndMsg = true;
    },
  },
  updated: function () {
    this.$nextTick(function () {
      // scroll to corresponding class element
      if (this.scrollToId > 0) {
        let element = document.getElementById(`class-${this.scrollToId}`);
        element.scrollIntoView(false);
        this.scrollToId = -1;
      }
    });
  },
  mounted() {
    console.log("awardList", awardList);
    this.$store.commit("logout");

    this.isLoading = true;

    var requestData = {
      showLecturer: true,
    };

    this.callApi(
      this.$constant.GET_ALL_COURSE_URL,
      requestData,
      (resultData) => {
        let classData = resultData.courseList;
        if (classData != null) {
          //
          for (let data of classData) {
            //   console.log("closingCeremonyID", this.closingCeremonyID, data.courseId == this.closingCeremonyID)
            if (data.courseId == this.closingCeremonyID) {
              console.log(data);
              this.closingCeremonyClassData = data;

              this.youtubeLink = data.youtubeLink;
              this.isReqRegister = data.isReqRegister;
              break;
            }
          }
        }

        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  },
  computed: {
    size() {
      const size = { xs: "x-small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
</style>
