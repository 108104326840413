<template>
  <v-card class="mx-auto d-flex flex-column" :loading="loading" height="100%">
    <img
      :src="image != null ? image : require('@/assets/images/ampower_logo.png')"
      style="object-fit: fill; max-width: 100%;"
      :style="{'height': isMobile ? 'auto' : '230px'}"
    />

    <!-- <v-card-subtitle>
      <v-icon>mdi-calendar</v-icon>
      {{ formatDate(date, $i18n.locale == 'en' ? 'DD MMM YYYY hh:mm a' : 'YYYY年MMMD日 ahh:mm') }}
    </v-card-subtitle> -->

    <v-card-subtitle class="pb-0">
      {{ $t("category") }} #{{ no }}
    </v-card-subtitle>

    <v-card-title class="d-block pt-0" style="word-break: break-word">
      {{ title }}
    </v-card-title>

    <v-card-text>
      <p v-snip="3">
        {{ scenario }}
      </p>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions style="padding-right: 16px; padding-left: 16px">
      <v-btn @click="showDetails" dark color="orange lighten-1">
        {{ $t("details") }}
      </v-btn>

      <v-btn
        v-if="
          !$constant.isStaffApp &&
            expiryDate >= this.formatDate(new Date(), 'YYYY-MM-DD')
        "
        color="green lighten-1"
        dark
        @click="applyCompetition"
      >
        {{ $t("apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CheckApply from "./CheckApply";

export default {
  name: "CompetitionCard",
  props: [
    "competitionId",
    "title",
    "image",
    "date",
    "loading",
    "scenario",
    "no",
  ],
  components: {},
  data() {
    return {
      isSelected: this.checked,
      checkApplyDialog: false,
      showVideoDialog: false,
      showSurveyDialog: false,
      videoList: [],
      enrollmentId: null,
      surveyReq: true,
      refNo: null,
      isWatched: false,
      showEndMsg: false,
      expiryDate: null,
    };
  },
  methods: {
    toggleSelect() {
      this.$parent.toggleSelect(this.competitionId, this.isSelected);
    },
    showDetails() {
      this.$parent.showDetails(this.competitionId);
    },
    applyCompetition() {
      this.$parent.applyCompetition(this.competitionId);
    },
    showCheckApply() {
      this.checkApplyDialog = true;
    },
    closeCheckApply() {
      this.checkApplyDialog = false;
    },
  },
  watch: {
    checked: function(newVal, oldVal) {
      this.isSelected = newVal;
    },
  },
  mounted() {
    // this.isExpired = true
    this.callApi(
      this.$constant.GET_CONFIG_URL,
      {
        category: "COMPETITION",
        type: "COMP_EXPIRY_DATE",
      },
      (resultData) => {
        this.expiryDate = this.formatDate(
          resultData.resultData.configValue,
          "YYYY-MM-DD"
        );
      },
      () => {
        this.isLoading = false;
        this.$root.$emit("popSnackBar", "generalError", "error");
      }
    );
  },
};
</script>

<style lang="scss" scoped>
// TODO: common scss
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.singleLineDetails {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stickToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.imageTextBottom {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}
</style>
