<template>
  <v-card>
    <v-card-title>
      {{ $t('courseManagement') }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>

      <v-btn 
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="showCourseDialog({})"
      >
        {{ $t('course.add') }}
      </v-btn>

      <v-btn 
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="exportExcel"
        :loading="isExportLoading"
      >
        {{ $t('course.exportData') }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="dataList"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',        
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
    >
      <template v-slot:[`item.courseCategory`]="{ item }">
        <span>{{ isEmptyString(item.courseCategory) ? '-' : item.courseCategory }}</span>
      </template>
      <template v-slot:[`item.courseSubcategory`]="{ item }">
        <span>{{ isEmptyString(item.courseSubcategory) ? '-' : item.courseSubcategory }}</span>
      </template>
      <template v-slot:[`item.courseDate`]="{ item }">
        <span>{{ isEmptyString(item.courseDate) ? '-' : formatDate(item.courseDate, $i18n.locale == 'en' ? 'DD MMM YYYY hh:mm a' : 'YYYY年MMMD日 ahh:mm') }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2c76b8"
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showCourseDialog(item)">
              <v-list-item-icon>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="showEnrollmentList(item)" >
              <v-list-item-icon>
                <v-icon>mdi-format-list-checkbox</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('listOfEnrollment') }}</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item @click="showUploadZoomDialog(item)">
              <v-list-item-icon>
                <v-icon>mdi-cloud-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('uploadZoom') }}</v-list-item-title>
            </v-list-item> -->

            <v-list-item @click="showUploadSurveyReportDialog(item)">
              <v-list-item-icon>
                <v-icon>mdi-text-box-check</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('course.uploadSurveyReport') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="showUploadQAReportDialog(item)">
              <v-list-item-icon>
                <v-icon>mdi-forum</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('course.uploadQAReport') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="genStudentNumReport(item)">
              <v-list-item-icon>
                <v-icon>mdi-chart-pie</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('course.statReport') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="genSurveyReport(item)">
              <v-list-item-icon>
                <v-icon>mdi-chart-pie</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('course.surveyReport') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="removeCourse(item.courseId)">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('remove') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog
      v-model="showZoomDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark>
          <v-toolbar-title class="text-uppercase">
            {{ $t('courseDetails') }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="showZoomDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-subheader>{{ $t('course.uploadDescn') + ':' }}</v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-file-input
                  v-model="zoomFile"
                  show-size
                  truncate-length="30"
                  :label="$t('course.zoomFile')"
                  color="#2c76b8"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange lighten-1"
            :dark="zoomFile != null"
            @click="uploadZoomFile"
            :loading="isDialogLoading"
            :disabled="zoomFile == null"
          >
            {{ $t('upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showListOfEnrollmentDialog"
      v-model="showListOfEnrollmentDialog"
      width="80%"
      scrollable
      persistent
    >      
      <ListOfEnrollment @close="showListOfEnrollmentDialog = false" v-if="selected" :course="selected" />
    </v-dialog>

    <v-dialog
      v-if="courseDialog"
      v-model="courseDialog"
      scrollable
      persistent
      width="unset"
    >
      <EditCourseForm
        :course="Object.assign({}, this.selected)"
        @close="closeCourseDialog"
        @message="showMessage"
        @success="getAllCourse"
      />
    </v-dialog>

    <v-dialog
      v-model="surveyReportDialog"
      scrollable
      persistent
      width="unset"
      v-if="selected"
    >
      <UploadSurveyReport
        :courseId="selected.courseId"
        @close="closeUploadSurveyReportDialog"
        @message="showMessage"
      />
    </v-dialog>

    <v-dialog
      v-model="QAReportDialog"
      scrollable
      persistent
      width="unset"
      v-if="selected"
    >
      <UploadQAReport
        :courseId="selected.courseId"
        @close="closeUploadQAReportDialog"
        @message="showMessage"
      />
    </v-dialog>

    <v-snackbar
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
      top
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ListOfEnrollment from './ListOfEnrollment'
import EditCourseForm from '@/components/EditCourseForm'
import CSVFile from "@/components/common/CSVFile";
import UploadSurveyReport from './UploadSurveyReport'
import UploadQAReport from './UploadQAReport'

export default {
  components: {
    ListOfEnrollment,
    EditCourseForm,
    UploadSurveyReport,
    UploadQAReport,
  },
  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      search: '',
      dataList: [],
      snackbarText: null,
      showSnackbar: false,
      sortBy: 'courseDate',
      showZoomDialog: false,
      zoomFile: null,
      selected: null,
      showListOfEnrollmentDialog: false,
      courseDialog: false,
      attendanceDialog: false,
      isExportLoading: false,
      surveyReportDialog: false,
      QAReportDialog: false,
    }
  },
  mounted() {
    this.getAllCourse()
  },
  computed: {
    headers: function() {
      return [
        {
          text: this.$t('course.name'),
          align: 'start',
          value: this.$i18n.locale == 'en' ? 'courseNameEn' : 'courseNameTc',
        },
        // { 
        //   text: this.$t('course.category'), 
        //   value: 'courseCategory' 
        // },
        // { 
        //   text: this.$t('course.subcategory'), 
        //   value: 'courseSubcategory' 
        // },
        { 
          text: this.$t('course.date'), 
          value: 'courseDate' 
        },
        { 
          text: this.$t('course.duration'), 
          value: 'courseDuration'
        },
        { 
          text: this.$t('course.instructor'), 
          value: this.$i18n.locale == 'en' ? 'instructorEn' : 'instructorTc',
        },
        { 
          text: this.$t('actions'), 
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },
  methods: {
    getAllCourse() {
      this.isLoading = true

      var requestData = {}

      this.callApi(this.$constant.GET_ALL_COURSE_URL,
        requestData,
        (resultData) => {
          this.dataList = resultData.courseList

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    },
    removeCourse(courseId) {
      this.isDialogLoading = true

      var requestData = {
        courseId: courseId
      }

      this.callApi(this.$constant.REMOVE_COURSE_URL,
        requestData,
        (resultData) => {
          this.showSnackbar = true
          this.snackbarText = this.$t('course.removeSuccess')

          this.isDialogLoading = false

          this.getAllCourse()
        },
        () => {
          
        }
      )
    },
    showUploadZoomDialog(selectedData) {
      this.selected = selectedData
      this.showZoomDialog = true
    },
    showEnrollmentList(selectedData) {
      this.selected = selectedData
      this.showListOfEnrollmentDialog = true
    },
    showCourseDialog(selectedData) {
      this.selected = selectedData
      this.courseDialog = true
    },
    showUploadSurveyReportDialog(selectedData) {
      this.selected = selectedData
      this.surveyReportDialog = true
    },
    showUploadQAReportDialog(selectedData) {
      this.selected = selectedData
      this.QAReportDialog = true
    },
    closeUploadSurveyReportDialog() {
      this.surveyReportDialog  = false
      this.selected = null
    },
    closeUploadQAReportDialog() {
      this.QAReportDialog  = false
      this.selected = null
    },
    uploadZoomFile() {
      this.isDialogLoading = true

      const reader = new FileReader()
      reader.onload = e =>{
        var requestData = {
          courseId: this.selected.courseId,
          b64ZoomFile: e.target.result,
          b64ZoomFileName: this.zoomFile.name,
        }

        this.callApi(this.$constant.UPLOAD_ZOOM_FILE_URL,
          requestData,
          (resultData) => {
            this.showSnackbar = true
            this.snackbarText = this.$t('course.uploadZoomFileSuccess')

            this.showZoomDialog = false
            this.isDialogLoading = false
            this.zoomFile = null

            this.getAllCourse()
          },
          () => {
            
          }
        )
      }
      reader.readAsDataURL(this.zoomFile)   
    },
    showMessage(message) {
      this.showSnackbar = true
      this.snackbarText = message
    },
    closeCourseDialog() {
      this.courseDialog  = false
      this.selected = null
    },
    exportExcel() {
      this.isExportLoading = true

      var requestData = {
      }
      
      this.callApi(this.$constant.GEN_COURSE_REG_REPORT_URL,
        requestData,
        (resultData) => {
          var secondarySchoolList = resultData.secondarySchoolList
          var nonSecondarySchoolList = resultData.nonSecondarySchoolList
          
          var tempList = [
            
          ]
          tempList.push({
            schoolNo: this.$t('schoolNo'),
            schoolNameEn: this.$t('schoolNameEn'), 
            schoolNameTc: this.$t('schoolNameTc'), 
            location: this.$t('location'), 
            district: this.$t('district'),
            isEITP: this.$t('isEITP'), 
            isITLab: this.$t('isITLab'), 
            isAmpower: 'Ampower',
          })

          for (var i in secondarySchoolList) {
            var s = secondarySchoolList[i]

            tempList.push({
              schoolNo: s.schoolNo,
              schoolNameEn: s.schoolNameEn,
              schoolNameTc: s.schoolNameTc,
              location: s.location,
              district: s.district,
              isEITP: s.isEITP,
              isITLab: s.isITLab,
              isAmpower: 'Y',
            })
          }
          var fileTitle = `2nd_Schools_List_${this.formatDate(
            new Date(),
            "YYYYMMDD_HHmmss"
          )}`;

          CSVFile(null, null, 'Publicly-funded Secondary', tempList, fileTitle, false, 
          () => {
            tempList = []
            tempList.push({
              schoolNameEn: this.$t('schoolNameEn'), 
              schoolNameTc: this.$t('schoolNameTc'), 
              location: this.$t('location'), 
              district: this.$t('district'),
              isEITP: this.$t('isEITP'), 
              isITLab: this.$t('isITLab'), 
              isAmpower: 'Ampower',
            })

            for (var i in nonSecondarySchoolList) {
              var s = nonSecondarySchoolList[i]

              tempList.push({
                schoolNameEn: s.schoolNameEn,
                schoolNameTc: s.schoolNameTc,
                location: s.location,
                district: s.district,
                isEITP: s.isEITP,
                isITLab: s.isITLab,
                isAmpower: 'Y',
              })
            }
            var fileTitle = `Other_Schools_List_${this.formatDate(
              new Date(),
              "YYYYMMDD_HHmmss"
            )}`;
            
            CSVFile(null, null, 'Other Schools', tempList, fileTitle, false, 
            () => {
              this.isExportLoading = false
            })
          })
        },
        () => {
          
        }
      )
    },
    genStudentNumReport(item) {
      var requestData = {
        courseId: item.courseId
      }

      this.callApi(this.$constant.GEN_REG_SCHOOL_LIST_URL,
        requestData,
        (resultData) => {
          var tableData = [
            {
              title: 'Registration - School - EITP',
              result: resultData.regSchoolEitb
            }, 
            {
              title: 'Registration - School - Non-EITP',
              result: resultData.regSchoolNonEitb
            }, 
            {
              title: 'Registration - Student - EITP',
              result: resultData.regStudentEitb
            }, 
            {
              title: 'Registration - Student - Non-EITP',
              result: resultData.regStudentNonEitb
            }, 
            {
              title: 'Attendance(Live) - School - EITP',
              result: resultData.attendLiveSchoolEitb
            }, 
            {
              title: 'Attendance(Live) - School - Non-EITP',
              result: resultData.attendLiveSchoolNonEitb
            }, 
            {
              title: 'Attendance(Live) - Student - EITP',
              result: resultData.attendLiveStudentEitb
            }, 
            {
              title: 'Attendance(Live) - Student - Non-EITP',
              result: resultData.attendLiveStudentNonEitb
            }, 
            {
              title: 'Attendance(Record) - School - EITP',
              result: resultData.attendReplaySchoolEitb
            }, 
            {
              title: 'Attendance(Record) - School - Non-EITP',
              result: resultData.attendReplaySchoolNonEitb
            }, 
            {
              title: 'Attendance(Record) - Student - EITP',
              result: resultData.attendReplayStudentEitb
            }, 
            {
              title: 'Attendance(Record) - Student - Non-EITP',
              result: resultData.attendReplayStudentNonEitb
            }, 
          ]

          var fileTitle = `Stat_` + item.courseNameEn + `_${this.formatDate(
              new Date(),
              "YYYYMMDD_HHmmss"
            )}`;

          CSVFile(null, null, 'Data', tableData, fileTitle, false)
            this.isExportLoading = false
        },
        () => {
          
        }
      )
    },
    genSurveyReport(item) {
      var requestData = {
        courseId: item.courseId
      }

      this.callApi(this.$constant.GEN_SURVEY_REPORT_URL,
        requestData,
        (resultData) => {
          var tableHead = [
            this.$t('question', ['1']),
            this.$t('answer', ['1']),
            this.$t('question', ['2']),
            this.$t('answer', ['2']),
            this.$t('question', ['3']),
            this.$t('answer', ['3']),
            this.$t('question', ['4']),
            this.$t('answer', ['4']),
            this.$t('question', ['5']),
            this.$t('answer', ['5']),
            this.$t('question', ['6']),
            this.$t('answer', ['6']),
            this.$t('question', ['7']),
            this.$t('answer', ['7']),
            this.$t('question', ['8']),
            this.$t('answer', ['8']),
            this.$t('email'),
            this.$t('surname'),
            this.$t('givenName'),
            this.$t('chiName'),
            this.$t('schoolName'),
            this.$t('yearGroup'),
            this.$t('class'),
            this.$t('isEITP'),
          ]
          var tableData = [{
            q1: this.$t('question', ['1']),
            a1: this.$t('answer', ['1']),
            q2: this.$t('question', ['2']),
            a2: this.$t('answer', ['2']),
            q3: this.$t('question', ['3']),
            a3: this.$t('answer', ['3']),
            q4: this.$t('question', ['4']),
            a4: this.$t('answer', ['4']),
            q5: this.$t('question', ['5']),
            a5: this.$t('answer', ['5']),
            q6: this.$t('question', ['6']),
            a6: this.$t('answer', ['6']),
            q7: this.$t('question', ['7']),
            a7: this.$t('answer', ['7']),
            q8: this.$t('question', ['8']),
            a8: this.$t('answer', ['8']),
            email: this.$t('email'),
            participantSurname: this.$t('surname'),
            participantGivenName: this.$t('givenName'),
            participantChiName: this.$t('chiName'),
            schoolName: this.$t('schoolName'),
            yearGroup: this.$t('yearGroup'),
            classLevel: this.$t('class'),
            isEITP: this.$t('isEITP'),
          }]
          var tableData = tableData.concat(resultData.surveyResultList)

          var fileTitle = `Survey_Data_` + item.courseNameEn + `_${this.formatDate(
              new Date(),
              "YYYYMMDD_HHmmss"
            )}`;

          CSVFile(tableHead, null, 'Data', tableData, fileTitle, false, 
          () => {
            this.isExportLoading = false
          })
        },
        () => {
          
        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
</style>