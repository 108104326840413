<template>
  <div v-if="statusValue" class="d-flex justify-start align-center">
    <v-icon :color="color">
      mdi-checkbox-blank-circle
    </v-icon>
    <span :style="{ color: color }">
      {{ $t(tx) }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["statusValue"],
  data: {
    color: "#4caf50",
    tx: "",
  },
  created() {
    this.changeStatusSetting();
  },
  watch: {
    statusValue: function() {
      console.log("???????");
      this.changeStatusSetting();
    },
  },
  methods: {
    changeStatusSetting() {
      switch (this.statusValue) {
        case "P":
          this.color = "#ff9800";
          this.tx = "pending";
          break;
        case "R":
          this.color = "#ff5252";
          this.tx = "rejected";
          break;
        case "A":
          this.color = "#4caf50";
          this.tx = "approved";
          break;
      }
    },
  },
};
</script>
