<template>
  <v-card width="1200">
		<v-toolbar color="#2c76b8" dark>
			<v-toolbar-title class="text-uppercase">
				{{ 
					this.course.courseId == null ?
					$t('course.add'):
					$t('course.edit') 
				}}
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				icon
				dark
				@click="closeDialog"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-card-text class="text--primary">
			<v-container>
				<v-form ref="form">
					<v-row class="mt-5">
						<v-col cols="8">
							<v-row>
								<v-col>
									<h2>
										{{$t('course.name') }}
									</h2>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<v-text-field
											v-model="course.courseNameEn"
											label= "EN"
											color="#2c76b8"
											clearable
											counter="200"
									/>
								</v-col>
								<v-col cols="6">
									<v-text-field
										v-model="course.courseNameTc"
										label= "繁"
										color="#2c76b8"
										clearable
										counter="200"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<h2>
										{{$t('course.date') }}
									</h2>
								</v-col>
								<v-col cols="6">
									<h2>
										{{$t('time') }}
									</h2>
								</v-col>
							</v-row>
							
							<v-row justify="space-around">
								<v-col cols="6">
									<v-menu
										v-model="menu"
										:close-on-content-click="false"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="date"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="date"
											color="green lighten-1"
											header-color="primary"
										/>
									</v-menu>
								</v-col>
								<v-col cols="6">
									<v-menu
										ref="menu"
										v-model="menu2"
										:close-on-content-click="false"
										:nudge-right="40"
										:return-value.sync="time"
										transition="scale-transition"
										offset-y
										max-width="290px"
										min-width="290px"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="time"
												prepend-icon="mdi-clock-time-four-outline"
												readonly
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-time-picker
											v-if="menu2"
											v-model="time"
											full-width
											@click:minute="$refs.menu.save(time)"
										></v-time-picker>
									</v-menu>
								</v-col>
								<v-col>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="6">
									<h2>
										{{$t('course.duration') }}
									</h2>
								</v-col>
								<v-col cols="6">
									<h2>
										{{$t('course.lang') }}
									</h2>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<v-text-field
										v-model="course.courseDuration"
										:suffix="$t('minutes')"/>
								</v-col>
								<v-col cols="6">
									<v-select
										v-model="course.languageEn"
										:items="langList"
										item-text="descn"
										item-value="value"
									/>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<h2>
										{{ $t('course.category')}}
									</h2>
								</v-col>

								<v-col cols="6">
									<h2>
										{{ $t('course.noOfParticipant')}}
									</h2>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="6">
									<v-select
										v-model="course.courseCategory"
										:items="courseTypeList" 
										:item-text="$i18n.locale == 'en' ? 'codeName.EN' : 'codeName.TC'"
										item-value="codeValue"
									/>
								</v-col>

								<v-col cols="6">
									<v-text-field
										v-model="course.noOfParticipants"
									/>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-row>
								<v-col>
									<h2>
										{{$t('course.image') }}
									</h2>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-file-input
										v-model="imageFile"
										show-size
										truncate-length="30"
										:label="$t('course.image')"
										color="#2c76b8"
										@change="imageChange"
										accept="image/jpeg, image/png"
									/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-img
										class="white--text align-end"
										max-width="500px"
										:src="currentImage"
									/>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<h2>
								{{$t('course.instructor') }}
							</h2>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-textarea
								v-model="course.instructorEn"
								label="EN"
								no-resize
								filled
								counter="500"
							/>
						</v-col>
						<v-col>
							<v-textarea
								v-model="course.instructorTc"
								label="繁"
								no-resize
								filled
								counter="500"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<h2>
								{{$t('course.descn') }}
							</h2>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-textarea
								v-model="course.courseDescEn"
								label="EN"
								no-resize
								filled
								counter="8000"
							/>
						</v-col>
						<v-col>
							<v-textarea
								v-model="course.courseDescTc"
								label="繁"
								no-resize
								filled
								counter="8000"
							/>
						</v-col>
					</v-row>

					<!-- <v-row>
						<v-col>
							<h2>
								{{$t('course.requirement') }}
							</h2>
						</v-col>
					</v-row> -->

					<!-- <v-row>
						<v-col>
							<v-textarea
								v-model="course.courseRequirementEn"
								label="EN"
								no-resize
								filled
								counter="3000"
							/>
						</v-col>
						<v-col>
							<v-textarea
								v-model="course.courseRequirementTc"
								label="繁"
								no-resize
								filled
								counter="3000"
							/>
						</v-col>
					</v-row> -->

					<v-row>
						<v-col>
							<h2>
								{{$t('course.contactName') }}
							</h2>
						</v-col>
						<v-col>
							<h2>
								{{$t('course.contactDetail') }}
							</h2>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-text-field
									v-model="course.contactName"
									color="#2c76b8"
									clearable
									counter="100"
							/>
						</v-col>
						<v-col>
							<v-text-field
									v-model="course.contactDetail"
									color="#2c76b8"
									clearable
									counter="100"
							/>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<h2>
								{{$t('course.youtubeLink') }}
							</h2>
						</v-col>
						<v-col>
							
						</v-col>
					</v-row>


					<v-row>
						<v-col>
							<v-text-field
								v-model="course.youtubeLink"
								color="#2c76b8"
								clearable
								counter="100"
							/>
						</v-col>
						<v-col>
							
						</v-col>
					</v-row>
				</v-form>
			</v-container>
		</v-card-text>

		<v-divider />

		<v-card-actions class="justify-end">
			<v-btn
				text
				color="orange lighten-1"
				@click="closeDialog"
			>
				{{ $t('close') }}
			</v-btn>
			
			<v-btn
				color="orange lighten-1"
				dark
				@click="submit"
				:loading="isLoading"
			>
				{{ $t('submit') }}
			</v-btn>
		</v-card-actions>
  </v-card>
</template>

<script>

import DisplayText from '@/components/DisplayText'

export default {
	components : {
		DisplayText,
	},
	props: ['course'],
	watch: { 
		course: function(newVal, oldVal) { // watch it
      if(newVal.courseDate != null){
				this.date = this.formatDate(this.course.courseDate,'YYYY-MM-DD')
				this.time = this.formatDate(this.course.courseDate,'hh:mm')
				this.currentImage = this.course.imageBase64
			}else {
				this.date = this.formatDate(new Date().getTime() ,'YYYY-MM-DD')
				this.time = this.formatDate(new Date().getTime(),'hh:mm')
				this.currentImage = null
			}
			this.imageFile = null
    }
  },
  data() {
    return {
			date : this.formatDate(this.course.courseDate,'YYYY-MM-DD'),
			time : this.formatDate(this.course.courseDate,'HH:mm'),
			menu : false,
			menu2 : false,
			langList: [
				{
					value: 'EN',
					descn: this.$t('english'),
				},
				{
					value: 'TC',
					descn: this.$t('chinese'),
				},
				{
					value: 'BOTH',
					descn: this.$t('bothLang'),
				},
			],
			isLoading: false,
			imageFile: null,
			currentImage: this.course.imageBase64,
			courseTypeList: [],
    }
  },
  mounted() {
		this.getCourseTypeList()

		if (this.course.courseId == null) {
			this.course.contactName = 'Ampower Talent Institute Limited.'
			this.course.contactDetail = 'secretariat@ampowertalent.org'
		}
  },
  methods: {
		closeDialog(evt) {
			evt.preventDefault()
			this.$refs.form.reset()
      this.$emit('close')
		},
		submit: function() {
			this.isLoading = true
			
			// Format required by Safari
			let timestamp = Date.parse(this.date + 'T' + this.time + ':00')
			this.course.courseDate = timestamp

			let url = this.course.courseId == null? this.$constant.ADD_COURSE_URL : this.$constant.EDIT_COURSE_URL
			var requestData = {
				course: this.course,
			}

			this.callApi(url,
				requestData,
				(resultData) => {
					console.log(resultData)
					if(this.imageFile != null){
						let id = this.course.courseId != null? this.course.courseId : resultData.courseId
						this.uploadLogoFile(id)
					}else{
						this.successAction()
					}
				},
				() => {
					this.isLoading = false
					this.$emit('message', this.$t('fail'))
				}
			)
		},
		uploadLogoFile(courseId) {
      const reader = new FileReader()

			this.compress(this.imageFile,
        (image) => {
          reader.onload = e =>{
						var requestData = {
							courseId: courseId,
							b64ZoomFile: e.target.result,
							b64ZoomFileName: this.imageFile.name,
						}

						this.callApi(this.$constant.UPLOAD_COURSE_LOGO_URL,
							requestData,
							(resultData) => {
								this.successAction()
							},
							() => {
								this.isLoading = false
								this.$emit('message', this.$t('fail'))
							}
						)
					}
					reader.readAsDataURL(image)      
        },
        () => {
          
        }
      )
		},
		successAction(){
			this.isLoading = false
			this.$refs.form.reset()
			this.$emit('close')
			this.$emit('message', this.course.courseId == null ? this.$t('addSuccess') : this.$t('editSuccess'))
			this.$emit('success')
		},
		imageChange(){
			if(this.imageFile != null){
				const reader = new FileReader()
				reader.onload = e =>{
					this.currentImage = e.target.result
				}
				reader.readAsDataURL(this.imageFile) 
			}
		},
		getCourseTypeList() {
			this.isLoading = true

			var requestData = {}

			this.callApi(this.$constant.GET_COURSE_TYPE_URL,
				requestData,
				(resultData) => {
					this.courseTypeList = resultData.codeList
					this.isLoading = false
				},
				() => {
					this.isLoading = false
					this.$emit('message', this.$t('fail'))
				}
			)
		}
	},
	computed: {

  }
}
</script>