<template>
  <div>
    <div
      style="
        width: 1080px;
        visibility: hidden;
        position: absolute;
        overflow: hidden;
      "
    >
      <v-responsive
        id="certContainer1"
        width="1080"
        style="visibility: hidden; position: absolute"
        :aspect-ratio="4501 / 6965"
      >
        <canvas id="cert2" width=" 100%" />
      </v-responsive>
    </div>

    <v-dialog
      v-model="isVisible"
      scrollable
      :width="isMobile ? '100vw' : '80vw'"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark>
          <v-toolbar-title>
            {{ $t("eCert") }}
          </v-toolbar-title>
        </v-toolbar>

        <!-- <v-divider></v-divider> -->

        <div style="font-family: Oooh Baby">.</div>
        <div
          style="
            overflow: auto;
            margin: 0 auto;
            height: 75vh;
            width: 90%;
            padding-top: 16px;
          "
        >
          <v-responsive
            id="certContainer2"
            max-height="80vh"
            style="margin: auto"
            :aspect-ratio="6965 / 4501"
            height="99%"
          >
            <div
              style="
                display: flex;
                flex: 1;
                justify-content: center;
                width: 99%;
                height: 99%;
              "
            >
              <canvas style="border: solid 1px #c6c6c6" id="cert1" />
            </div>
          </v-responsive>
        </div>
        <div style="font-family: Oooh Baby">.</div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="onTestBtnClick" color="primary" :loading="isLoading">
            {{ $t("applyForECert") }}
          </v-btn>
          <v-btn color="error lighten-1" dark @click="setIsVisible(false)">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      :color="snackbarColor + ' lighten-1'"
    >
      <span class="white--text">
        {{ $t(snackbarText) }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ECertDialog",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    setIsVisible: {
      type: Function,
      default: () => {},
    },
    refNo: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    enName: {
      type: String,
      default: "TEST",
    },
    competitionCertData: {
      type: Object | null,
      default: {},
    },
  },
  watch: {
    isVisible: function (val) {
      // console.log("changed");
      this.initCanvas();
      // console.log(this.$refs.cert.width)

      // console.log(val);
    },
  },
  created() {
    //
    this.$emit("message", this.$t("success"));
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    // console.log(this.competitionCertData.categoryNo);
    this.certData = this.competitionCertData;
    this.initCanvas();
  },
  data: () => ({
    //
    isLoading: false,
    certData: {},

    showSnackbar: false,
    snackbarText: "",
    snackbarColor: "green",
  }),
  methods: {
    //
    onTestBtnClick() {
      this.isLoading = true;

      let canvas = document.getElementById("cert2");
      const certContainer = document.getElementById("certContainer1");
      const h = certContainer.offsetHeight;
      const w = certContainer.offsetWidth;

      this.drawCanvas(canvas, w, h, () => {
        let base64 = canvas
          .toDataURL("image/jpeg")
          .toString()
          .replace("data:image/jpeg;base64,", "");

        const requestData = {
          imageBase64: base64,
          email: this.email.toLowerCase(),
          refNo: this.refNo.toLowerCase(),
          chiName: this.enName,
          enName: this.enName,
        };

        this.callApi(
          this.$constant.SEND_CERT_EMAIL,
          requestData,
          (resultData) => {
            this.showSnackbar = true;
            this.snackbarText = "applyForECertSuccess";
            this.snackbarColor = "green";

            this.isLoading = false;
          },
          () => {
            this.showSnackbar = true;
            this.snackbarText = "applyForECertFailure";
            this.snackbarColor = "red";

            this.isLoading = false;
          }
        );
      });
    },

    initCanvas() {
      var canvas = document.getElementById("cert1");
      const certContainer = document.getElementById("certContainer2");

      const certRatio = 4501 / 6965;

      let h = certContainer.offsetHeight;
      let w = certContainer.offsetWidth;

      if (w > h) {
        w = h * certRatio;
      } else {
        h = w / certRatio;
      }

      this.drawCanvas(canvas, w, h);
    },

    drawCanvas(canvas, w, h, callback) {
      let name = this.enName;
      if (canvas) {
        var img = document.createElement("img");

        img.src = require("../assets/images/cert/competition_cat_" +
          this.certData.categoryNo +
          ".png");

        // console.log(
        //   "../assets/images/cert/competition_cat_" +
        //     this.certData.categoryNo +
        //     ".png"
        // );

        // img.src = require("../assets/images/e-cert-template.png");

        img.onload = function (a) {
          let fontRatio = 1;
          if (name.length > 34) {
            fontRatio = 0.9;
          }
          if (name.length > 46) {
            fontRatio = 0.8;
          }
          if (name.length > 56) {
            fontRatio = 0.7;
          }

          var ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, w, h);
          canvas.width = w;
          canvas.height = h;
          ctx.drawImage(img, 0, 0, w, h);
          ctx.font = "bold " + w * 0.06 * fontRatio + "px Oooh Baby";
          ctx.fillStyle = "#000";
          ctx.textAlign = "center";

          ctx.fillText(name, w * 0.5, h * 0.525);

          callback && callback();
        };
      }
      return canvas;
    },
  },
};
</script>
