<template>
  <section v-if="!inverted">
    <h4 class="displayTitle">{{ title == undefined ? "" : title }}</h4>
    <!-- string case -->
    <h2
      v-snip="snip != null ? snip : 100"
      class="displayContent"
      v-if="typeof content == 'string'"
    >
      {{ content }}
    </h2>
    <!-- array case -->
    <div v-if="typeof content == 'object'">
      <div class="mb-4" v-for="(item, i) in content" :key="i">
        <div class="d-flex align-start mb-2">
          <v-icon class="pa-0 mr-2" color="yellow darken-2"> mdi-star </v-icon>
          <a @click="onContentBtnClick(item)">
            <h2 class="displayContent text-decoration-underline">
              {{ item }}
            </h2>
          </a>
          <!-- <h2 class="displayContent">
            {{ item }}
          </h2> -->
        </div>
        <!-- <div class="d-flex align-end mb-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!$constant.isStaffApp"
            color="orange lighten-1"
            dark
            @click="onContentBtnClick(item)"
          >
            {{ $t("details") }}
          </v-btn>
        </div> -->
      </div>
    </div>
  </section>
  <section v-else>
    <h2 v-snip="snip != null ? snip : 100" class="displayContent">
      {{ title == undefined ? "" : title }}
    </h2>
    <!-- string case -->
    <h4 class="displayTitle" v-if="typeof content == 'string'">
      {{ content }}
    </h4>
    <!-- array case -->
    <div v-if="typeof content == 'object'">
      <div
        class="d-flex align-start mb-2"
        v-for="(item, i) in content"
        :key="i"
      >
        <v-icon class="pa-0 mr-2" color="yellow darken-2"> mdi-star </v-icon>
        <h4 class="displayTitle">
          {{ item }}
        </h4>
        <div>???</div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["title", "content", "inverted", "snip", "onBtnClick"],
  mounted() {
    console.log(this.content);
    console.log(typeof this.content);
  },
  methods: {
    onContentBtnClick(item) {
      if (this.onBtnClick) {
      this.onBtnClick(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: 1.7rem;
  white-space: pre-line;
}

.displayTitle {
  color: rgba(0, 0, 0, 0.4);
}

.displayContent {
  color: rgba(0, 0, 0, 0.7);
}
</style>