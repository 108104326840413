import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Welcome from '../views/Welcome.vue'
import Apply from '../views/class/Apply.vue'
import ApplySuccess from '../views/class/ApplySuccess.vue'
import Competition from '../views/Competition.vue'
import ApplyCompetition from '../views/competition/ApplyCompetition.vue'
import ApplyCompetitionSuccess from '../views/competition/ApplyCompetitionSuccess.vue'
import Dashboard from '../views/cms/Dashboard.vue'
import AboutUs from '../views/AboutUs.vue'
import AwardScheme from '../views/AwardScheme.vue'
import Enquiry from '../views/Enquiry.vue'
import VideoList from '../views/VideoList.vue'
import FAQ from '../views/FAQ.vue'
import Partnership from '../views/Partnership.vue'
import PressRelease from '../views/PressRelease.vue'
import ClosingCeremony from '../views/ClosingCeremony.vue'

import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

  const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      title: "Master Class Course Catalogue",
      description: "Knowing student’s yearning to develop IT skills, not-for-profit NGO Ampower Talent Institute invites students in all secondary schools and their affiliated primary schools in Hong Kong to participate in an upcoming mentorship programme and a series of master classes, which are mainly in the form of recorded/live online classes or webinars, to equip themselves with the essential IT knowledge and skills. Participating students of the tuition-free programme will be eligible for an IT Talent Competition, with the chance of winning great prizes and certificates, while outstanding students will be offered internship opportunities in large organizations to develop their potential and broaden their horizons.",
      image: "https://www.ampowertalent.org/course_banner.jpg",
    }
  },
  {
    path: '/Apply',
    name: 'Apply',
    component: Apply,
    beforeEnter (to, from, next) {
      if(from.name != 'Welcome' && from.name != 'AboutUs' && from.name != 'ClosingCeremony') {
        next({name: 'Welcome'})
      }

      if (store.state.courses.length == 0) {
        next({name: 'Welcome'})
      }
      
      next()
    },
    meta: {
      title: "Master Class Course Catalogue - Apply",
    }
  },
  {
    path: '/ApplySuccess',
    name: 'ApplySuccess',
    component: ApplySuccess,
    beforeEnter (to, from, next) {
      if(from.name != 'Apply') {
        next({name: 'Welcome'})
      }

      if (store.state.courses.length == 0) {
        next({name: 'Welcome'})
      }
      
      next()
    },
    meta: {
      title: "Master Class Course Catalogue - Apply Success",
    }
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter (to, from, next) {
      if(store.state.user.priKey == null) {
        next({name: 'Welcome'})
      }
      
      next()
    },
    meta: {
      title: "Dashboard",
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: "About Us",
    }
  },
  {
    path: '/AwardScheme',
    name: 'AwardScheme',
    component: AwardScheme,
    meta: {
      title: "Master Classes Award Scheme",
    }
  },
  {
    path: '/Enquiry',
    name: 'Enquiry',
    component: Enquiry,
    meta: {
      title: "Enrollment History",
    }
  },
  {
    path: '/VideoList',
    name: 'VideoList',
    component: VideoList,
    meta: {
      title: "POST SECONDARY STUDIES IN IT, TECH & INNOVATION",
    }
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ,
    meta: {
      title: "FAQ",
    }
  },
  {
    path: '/SubmitSolution',
    name: 'SubmitSolution',
    component: VideoList,
    meta: {
      title: "Submit Solution",
    }
  },
  {
    path: '/Competition',
    name: 'Competition',
    component: Competition,
    meta: {
      title: "Career Planning App (POC) Design Competition",
      description: "The “Career Planning App (Proof of Concept) Design Competition” guides students to think big and contribute their ideas and creativity as a member of a virtual mobile app development team. Students can apply the skills they acquired in the master classes to the app design, and at the same time develop right values and plan for their future to get a head startfor own career development. Prizes and certificates awaits! Winners of the competition will be offered an opportunity to participate in the Career Experience Program in the form of company visits, job shadow or internship opportunities (subject to age and COVID-19 restrictions) in large organizations and potentially interact with companies or youngsters in the Greater Bay Area to broaden their horizons.",
      image: "https://www.ampowertalent.org/competition_banner.jpg",
    }
  },
  {
    path: '/Partnership',
    name: 'Partnership',
    component: Partnership,
    meta: {
      title: "Partnetship",
    }
  },
  {
    path: '/ApplyCompetition',
    name: 'ApplyCompetition',
    component: ApplyCompetition,
    beforeEnter (to, from, next) {
      if(from.name != 'Competition') {
        next({name: 'Competition'})
      }
      next()
    },
    meta: {
      title: "Career Planning App (POC) Design Competition - Apply",
    }
  },
  {
    path: '/ClosingCeremony',
    name: 'ClosingCeremony',
    component: ClosingCeremony,
    meta: {
      title: "Career Planning App (Proof of Concept) Design Competition - 2022 Online Award Ceremony",
      description: "Thank you for your participation and support to Ampower Talent Institute Career Planning App (Proof of Concept) Design Competition. After careful deliberation by the judges, winners from the 5 categories are selected. All winners will be notified individually, and the winners list and details will be uploaded to this website soon. We would like to invite you and your family to join our online ceremony. \nThe programme received overwhelming responses from over 10, 000 guests, school principals, teachers, students and industry representatives. Victor Lam, JP, Government Chief Information Officer of the HKSAR, Nick Chan, MH, JP, Convenor of Ampower Talent Institute, Sophia Chan-Yap, CEO of Ampower Talent Institute and Legislative Council Member The Hon Kenneth FOK Kai-kong, JP will be officiated at the Ceremony.  The Hon Kenneth FOK Kai-kong, JP will also have a chit-chat with us. We will present awards to winning teams and will also invite some of them to present their works. The Awards Ceremony will take place virtually via Zoom and we would like to invite you to share our happiness together.",
      image: "https://www.ampowertalent.org/closing-banner-en.jpg",
    }
  },
  {
    path: '/PressRelease',
    name: 'PressRelease',
    component: PressRelease,
    meta: {
      title: "News",
    }
  },
  {
    path: '/ApplyCompetitionSuccess',
    name: 'ApplyCompetitionSuccess',
    component: ApplyCompetitionSuccess,
    beforeEnter (to, from, next) {
      if(from.name != 'ApplyCompetition') {
        next({name: 'Competition'})
      }

      // if (store.state.courses.length == 0) {
      //   next({name: 'Competition'})
      // }
      
      next()
    },
    meta: {
      title: "Career Planning App (POC) Design Competition - Apply Success",
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

export default router
