// var API_URL = "http://127.0.0.1:18080/ClassEnrollmentWebService/";
var API_URL = "https://www.ampowertalent.org/ClassEnrollmentWebService/";
// var API_URL = "http://humane.nec.com.hk:8107/ClassEnrollmentWebService/";
// var API_URL = "https://humane.nec.com.hk:8108/ClassEnrollmentWebService/";

export const isLocalEnv = false;
// export var isUAT = process.env.VUE_APP_IS_UAT === "true";
// export var isStaffApp = process.env.VUE_APP_IS_STAFF_APP === "true";
export var isUAT = false;
export var isStaffApp = false;

export var isCareerExperienceEnabled = true;

export var IMEI = "b46d796482e74301869762f30566b53c";
export var RECAPTCHA_SITE_KEY = "6Ld8IqwaAAAAAOystQVjUcMg-zCgDome17Zj4aOB";

export var CLOSING_CEREMONY_ID_UAT = 52;
export var CLOSING_CEREMONY_ID = 52;

//Course
export var GET_ALL_COURSE_URL = API_URL + "Course/GetAll";
export var ADD_COURSE_URL = API_URL + "Course/Add";
export var EDIT_COURSE_URL = API_URL + "Course/Edit";
export var REMOVE_COURSE_URL = API_URL + "Course/Remove";
export var UPLOAD_ZOOM_FILE_URL = API_URL + "Course/UploadZOOMFile";
export var REGISTER_COURSE_URL = API_URL + "Course/Register";
export var LIST_ENROLLMENT_URL = API_URL + "Course/ListEnrollment";
export var UPLOAD_COURSE_LOGO_URL = API_URL + "Course/UploadCourseLogo";
export var ENQUIRY_URL = API_URL + "Course/Enquiry";
export var EXPORT_PARTCIPANT_DATA_URL =
  API_URL + "Course/ExportParticipantData";
export var EXPORT_SCHOOL_DATA_URL = API_URL + "Course/ExportSchoolData";
export var UPDATE_ATTENDANCE_URL = API_URL + "Course/UpdateAttendance";
export var UPLOAD_SURVEY_REPORT_URL = API_URL + "Course/UploadSurveyReport";
export var UPLOAD_SURVEY_FOR_EXPIRED_COURSE_REPORT_URL =
  API_URL + "Course/UploadSurveyForExpiredCourse";
export var UPDATE_BONUS_SCORE_URL = API_URL + "Course/UpdateBonusScore";
export var UPLOAD_QA_REPORT_URL = API_URL + "Course/UploadQAReport";
export var IMPORT_ATTENDANCE_DATA_URL = API_URL + "Course/ImportData";
export var GET_AWARD_NO_URL = API_URL + "Course/GetAwardNo";
export var SEND_CERT_EMAIL = API_URL + "Course/SendCertEmail";

// Users
export var USER_LOGIN_URL = API_URL + "AppUser/UserLogin";
export var USER_LOGOUT_URL = API_URL + "AppUser/Logout";
export var GET_SALT_KEY_URL = API_URL + "AppUser/GetSaltKey";
export var GET_USER_LIST_URL = API_URL + "AppUser/GetUserList";
export var BLOCK_USER_URL = API_URL + "AppUser/BlockAcc";
export var UNBLOCK_USER_URL = API_URL + "AppUser/UnblockAcc";
export var GET_APP_USER_DETAILS_URL = API_URL + "AppUser/GetAppUserDetails";
export var GET_LOGIN_HISTORY_BY_ID_URL =
  API_URL + "AppUser/GetLoginHistoryById";
export var CHANGE_PASSWORD_URL = API_URL + "AppUser/ChangePassword";
export var CREATE_APP_USER_URL = API_URL + "AppUser/CreateAppUser";

// School
export var GET_ALL_SCHOOL_URL = API_URL + "School/GetAll";
export var GET_SCHOOL_LIST_URL = API_URL + "School/GetSchoolList";
export var EDIT_SCHOOL_URL = API_URL + "School/EditSchool";

// Participant
export var GET_ALL_PARTICIPANT_URL = API_URL + "Participant/GetAll";
export var UPDATE_PARTICIPANT_INFO_URL = API_URL + "Participant/UpdateInfo";
export var MARK_AS_ATTENDED_URL = API_URL + "Participant/MarkAsAttended";
export var FIND_ENROLLMENT_BY_ID_URL = API_URL + "Participant/FindById";

// Others
export var GET_COURSE_TYPE_URL = API_URL + "CodeTable/GetCourseType";
export var VERIFY_RECAPTCHA_URL = API_URL + "Common/VerifyRecaptcha";
export var GET_ALL_AREA_LIST_URL = API_URL + "CodeTable/GetAllArea";
export var GET_ALL_DISTRICT_LIST_URL = API_URL + "CodeTable/GetAllDistrict";
export var GET_SCHOOL_TYPE_URL = API_URL + "CodeTable/GetSchoolType";

// Report
export var GEN_COURSE_REG_REPORT_URL = API_URL + "Report/GenCourseRegReport";
export var GEN_REG_SCHOOL_LIST_URL = API_URL + "Report/GenRegSchoolList";
export var GEN_SURVEY_REPORT_URL = API_URL + "Report/GenSurveyReport";
export var GEN_AWARD_REPORT_URL = API_URL + "Report/GenAwardReport";
export var GEN_CAREER_EXP_REPORT_URL = API_URL + "Report/GenCareerExpReport";

// Career
export var GET_PARTNERSHIP_LIST_URL = API_URL + "Career/GetPartnerShipList";
export var SAVE_PARTNERSHIP_URL = API_URL + "Career/SavePartnership";
export var REMOVE_PARTNERSHIP_URL = API_URL + "Career/RemovePartnership";
export var GET_ALL_CAREER = API_URL + "Career/GetAllCareer";
export var SUBMIT_CAREER_EXP_FORM = API_URL + "Career/SubmitCareerExpForm";
export var SAVE_CAREER_URL = API_URL + "Career/SaveCareer";
export var REMOVE_CAREER_URL = API_URL + "Career/RemoveCareer";
export var GET_ALL_CAREER_EXP_FORM_URL = API_URL + "Career/GetAllCareerExpForm";
export var GET_CAREER_EXP_FORM_BY_ID_URL = API_URL + "Career/GetCareerExpFormById";
export var GET_CAREER_CHOICES_BY_ID_URL = API_URL + "Career/GetCareerChoicesById";
export var EXPORT_CAREERS_URL = API_URL + "Career/ExportCareers";
export var CHECK_CAREER_EXP_RESULT_URL = API_URL + "Career/CheckCareerExpResult";

// Competition
export var GET_ALL_COMPETITION_URL = API_URL + "Competition/GetAll";
export var SAVE_COMPETITION_URL = API_URL + "Competition/SaveCompetition";
export var REMOVE_COMPETITION_URL = API_URL + "Competition/RemoveCompetition";
export var GET_ALL_COMPETITION_ENROLLMENT_URL =
  API_URL + "Competition/GetAllCompetitionEnrollment";
export var UPDATE_COMPETITION_SCORE_URL =
  API_URL + "Competition/UpdateCompetitionScore";
export var APPLY_COMPETITION_URL = API_URL + "Competition/ApplyCompetition";
export var COMPETITION_ENQUIRY_URL = API_URL + "Competition/Enquiry";
export var ENQUIRY_TO_EDIT_URL = API_URL + "Competition/EnquiryToEdit";
export var GET_COMPETITION_ENROLLMENT_MATERIAL_URL =
  API_URL + "Competition/GetCompetitionEnrollmentMaterial";
export var UPDATE_COMPETITION_STATUS_URL =
  API_URL + "Competition/UpdateCompetitionEnrollmentStatus";

// Config
export var GET_CONFIG_URL = API_URL + "Common/GetConfig";
