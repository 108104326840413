<template>
  <v-app>
    <AppBar />

    <v-main>
      <v-container style="max-width: 1400px">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="
                $constant.isUAT
                  ? $i18n.locale == 'en'
                    ? require('@/assets/images/background_en_UAT.png')
                    : require('@/assets/images/background_tc_UAT.png')
                  : $i18n.locale == 'en'
                  ? require('@/assets/images/background_en.png')
                  : require('@/assets/images/background_tc.png')
              "
              width="100%"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col style="background-color: #f4dccf; margin: 0px 6px">
            <v-card-title class="py-1">
              {{ $t("registration") }}
            </v-card-title>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card class="mx-auto">
              <v-toolbar color="#2c76b8" dark>
                <v-badge
                  inline
                  :content="courses.length"
                  color="orange lighten-1"
                  :value="courses.length > 0"
                >
                  <v-card-title class="text-uppercase">
                    {{
                      courses != null &&
                      courses[0].courseId == closingCeremonyID
                        ? $t("ceremonyRegistration")
                        : $t("courseApplied")
                    }}
                  </v-card-title>
                </v-badge>
              </v-toolbar>

              <v-card-text class="text--primary">
                <v-container>
                  <v-row
                    v-for="(course, index) in courses"
                    v-bind:key="course.courseId"
                    class="d-flex"
                  >
                    <v-col cols="1" align-self="center">
                      <h2>
                        {{ index + 1 }}
                      </h2>
                    </v-col>

                    <v-col cols="11" align-self="center">
                      <DisplayText
                        :title="
                          $i18n.locale == 'en'
                            ? course.courseNameEn
                            : course.courseNameTc
                        "
                        :content="
                          formatDate(
                            course.courseDate,
                            $i18n.locale == 'en'
                              ? 'DD MMM YYYY hh:mm a'
                              : 'YYYY年MMMD日 ahh:mm'
                          )
                        "
                        :inverted="true"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto">
              <v-card-title class="justify-center">
                <v-icon size="100px" color="green accent-4"
                  >mdi-check-circle</v-icon
                >
              </v-card-title>

              <v-card-text class="text-center text-uppercase pb-0 mb-0">
                <h2 style="color: #00c853">{{ $t("enrollSuccess") }}</h2>
              </v-card-text>

              <v-card-text
                class="text-center text-uppercase"
                style="color: #888"
              >
                <h3>{{ $t("refNo") }}: {{ refNo }}</h3>
              </v-card-text>

              <v-card-text class="text--primary">
                <v-container>
                  <v-row>
                    <v-col :cols="isMobile ? 3 : 2">
                      <h3 class="perInfo">{{ $t("name") }}</h3>
                    </v-col>
                    <v-col cols="1">
                      <h3 class="perInfo">:</h3>
                    </v-col>
                    <v-col>
                      <h3 class="perInfo">{{ name }}</h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col :cols="isMobile ? 3 : 2">
                      <h3 class="perInfo">{{ $t("school") }}</h3>
                    </v-col>
                    <v-col cols="1">
                      <h3 class="perInfo">:</h3>
                    </v-col>
                    <v-col>
                      <h3 class="perInfo">{{ school }}</h3>
                    </v-col>
                  </v-row>

                  <v-row v-if="yearGroup != undefined">
                    <v-col :cols="isMobile ? 3 : 2">
                      <h3 class="perInfo">{{ $t("yearGroup") }}</h3>
                    </v-col>
                    <v-col cols="1">
                      <h3 class="perInfo">:</h3>
                    </v-col>
                    <v-col>
                      <h3 class="perInfo">{{ yearGroup }}</h3>
                    </v-col>
                  </v-row>

                  <v-row v-if="schoolClass != undefined">
                    <v-col :cols="isMobile ? 3 : 2">
                      <h3 class="perInfo">{{ $t("class") }}</h3>
                    </v-col>
                    <v-col cols="1">
                      <h3 class="perInfo">:</h3>
                    </v-col>
                    <v-col>
                      <h3 class="perInfo">{{ schoolClass }}</h3>
                    </v-col>
                  </v-row>

                  <v-row class="pt-3">
                    <v-col class="text-center mb-0 pb-0">
                      <h4 class="perInfo">{{ $t("notice") }}</h4>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text-center mb-0 pb-0">
                      <h4 class="perInfo">{{ $t("notice2") }}</h4>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="text-center">
                      <h2 style="color: #1a237e">{{ email }}</h2>
                    </v-col>
                  </v-row>

                  <v-row class="pt-3">
                    <v-col class="text-center">
                      <v-btn color="orange lighten-1" dark @click="confirm">
                        {{ $t("ok") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/views/AppBar";
import AppFooter from "@/views/AppFooter";
import DisplayText from "@/components/DisplayText";

export default {
  name: "ApplySuccess",
  components: {
    AppBar,
    AppFooter,
    DisplayText,
  },
  mounted() {
    this.courses = this.$store.state.courses;
    this.name = this.$store.state.studentInfo.name;
    this.school = this.$store.state.studentInfo.school;
    this.yearGroup = this.$store.state.studentInfo.yearGroup;
    this.schoolClass = this.$store.state.studentInfo.schoolClass;
    this.email = this.$store.state.studentInfo.email;

    this.refNo = this.$route.query.refNo;

    console.log("this.courses", this.courses);

    // Block user back to apply page
    window.onpopstate = (event) => {
      this.$router.push("/");
    };
  },
  data() {
    return {
      courses: [],
      name: null,
      school: null,
      yearGroup: null,
      schoolClass: null,
      email: null,
      refNo: null,
    };
  },
  methods: {
    confirm() {
      this.$store.commit("clearStudentInfo");
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.perInfo {
  color: #888;
}
</style>
