<template>
  <v-card>
    <v-card-title>
      {{ $t("competitionManagement") }}

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('search') + '...'"
        single-line
        hide-details
        color="orange lighten-1"
        clearable
      ></v-text-field>
      <!-- <v-btn
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="onAddCareerBtnClick()"
      >
        {{ $t("addCareer") }}
      </v-btn> -->

      <v-btn
        v-if="checkAccessRight('RPT_COMPETITION_FORM', 'read')"
        class="ml-2"
        color="orange lighten-1"
        dark
        @click="exportData"
        :loading="isExportLoading"
      >
        {{ $t("course.exportData") }}
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="getDataList()"
      :items-per-page="10"
      class="elevation-1"
      multi-sort
      :search="search"
      :loading="isLoading"
      :footer-props="{
        'items-per-page-text': this.$t('rowsPerPage') + ':',
      }"
      :sort-by.sync="sortBy"
      :loading-text="$t('loading') + '...'"
      :no-results-text="$t('noRecords')"
      :no-data-text="$t('noRecords')"
      style="white-space: pre"
    >
      <template v-slot:[`item.imageB64`]="{ item }">
        <div
          class="d-flex align-center justify-center"
          v-if="item.imageB64 != null"
        >
          <img
            class="py-1"
            width="50"
            :src="'data:image/png;base64,' + item.imageB64"
          />
        </div>
      </template>

      <template v-slot:[`item.deadlineDate`]="{ item }">
        <span>{{
          isEmptyString(item.deadlineDate)
            ? "-"
            : formatDate(
                item.deadlineDate,
                $i18n.locale == "en"
                  ? "DD MMM YYYY hh:mm a"
                  : "YYYY年MMMD日 ahh:mm"
              )
        }}</span>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        <v-icon :color="item.isActive == 'Y' ? 'success' : 'error'">
          mdi-checkbox-blank-circle
        </v-icon>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2c76b8" dark icon v-bind="attrs" v-on="on">
              <v-icon dark> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- edit -->
            <v-list-item @click="edit(item)">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $t("checkApplication")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              @click="update(item)"
              v-if="$store.state.user.userGroup == 'SYSADMIN'"
            >
              <v-list-item-icon v-if="item.status == 'A'">
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-icon v-if="item.status == 'H'">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-if="item.status == 'A'">
                {{ $t("markAsHide") }}</v-list-item-title
              >
              <v-list-item-title v-if="item.status == 'H'">{{
                $t("markAsVisible")
              }}</v-list-item-title>
            </v-list-item>
            <!--  -->
            <!-- <v-list-item @click="removeCareer(item)">
              <v-list-item-icon>
                <v-icon>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t("remove") }}</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon v-if="item.status == 'A'" color="green">
          mdi-check
        </v-icon>
      </template>

      <template v-slot:[`body.append`]>
        <tr>
          <td colspan="12" style="text-align: right;">
            {{ $t("totalApplicants") + ":" }} {{ totalApplicants }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog
      v-if="showEditDialog"
      v-model="showEditDialog"
      width="80%"
      scrollable
      persistent
    >
      <CompetitionEnrollmentForm @close="onCloseDialog" :data="selected" />
    </v-dialog>

    <v-snackbar
      top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          :loading="isLoading"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
// import CareerForm from "./CareerForm.vue";
import _ from "lodash";
import CSVFile from "@/components/common/CSVFile";
import CompetitionEnrollmentForm from "./CompetitionEnrollmentForm.vue";

export default {
  components: {
    // CareerForm,
    CompetitionEnrollmentForm,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      isExportLoading: false,
      search: "",
      dataList: [],
      sortBy: "",
      selected: null,
      showSnackbar: false,
      snackbarText: null,
      showEditDialog: false,
      curFilters: [],
    };
  },
  created() {
    this.curFilters = this.filters;
  },
  mounted() {
    this.getAll();
  },
  computed: {
    headers: function() {
      const isEn = this.$i18n.locale == "en"

      if (this.$store.state.user.userGroup == 'SYSADMIN') {
        return [
          {
            text: this.$t("name"),
            value: isEn ? "nameEn" : "nameTc",
          },
          {
            text: this.$t("refNo"),
            value: "refNo",
          },
          {
            text: this.$t("teamSize"),
            value: "teamSize",
          },
          {
            text: this.$t("averageScore"),
            value: "averageScore",
          },
          {
            text: this.$t("visible"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$t("actions"),
            value: "actions",
            sortable: false,
          },
        ]
      } else {
        return [
          {
            text: this.$t("name"),
            value: isEn ? "nameEn" : "nameTc",
          },
          {
            text: this.$t("refNo"),
            value: "refNo",
          },
          {
            text: this.$t("teamSize"),
            value: "teamSize",
          },
          {
            text: this.$t("totalScore"),
            value: "totalScore",
          },
          {
            text: this.$t("actions"),
            value: "actions",
            sortable: false,
          },
        ]
      }
    },
    totalApplicants() {
      var totalApplicants = 0;

      for (var i in this.dataList) {
        totalApplicants += this.dataList[i].teamSize;
      }

      return totalApplicants;
    },
  },
  methods: {
    getDataList() {
      const filters = this.curFilters;

      let dataList = _.cloneDeep(this.dataList);

      if (filters.length > 0) {
        dataList = dataList.filter((item) => {
          let match = true;
          filters.map((filter) => {
            console.log(typeof filter.value);
            if (
              typeof filter.value == "string" ||
              typeof filter.value == "number"
            ) {
              // string case
              if (_.get(item, filter.path) != filter.value) {
                match = false;
              }
            } else if (typeof filter.value == "object") {
              // array case
              if (!filter.value.includes(_.get(item, filter.path))) {
                match = false;
              }
            }
          });
          return match;
        });
      }
      console.log("dataList", dataList);
      return dataList;
    },
    removeCareer(item) {
      const isEn = this.$i18n.locale == "en";

      this.callApi(
        this.$constant.REMOVE_COMPETITION_URL,
        { id: item.competitionId },
        (resultData) => {
          console.log(resultData);

          if (resultData.code == 0) {
            let msg = this.$t("generalRemoveSuccess", [
              isEn ? item.nameEn : item.nameTc,
            ]);

            this.$root.$emit("popSnackBar", msg, "green");
          } else {
            this.$root.$emit("popSnackBar", "generalError", "error");
          }

          this.isLoading = false;
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
    setIsActive(item, isActive) {
      //
      console.log(this.$root);
      this.callApi(
        this.$constant.SAVE_CAREER_URL,
        {
          career: {
            ...this.objectEncode(item),
            isActive: isActive ? "Y" : "N",
            partnership: null,
          },
        },
        (resultData) => {
          let msg = this.$t("generalSaveSuccess", [
            this.$t("partnershipCareer"),
          ]);

          this.$root.$emit("popSnackBar", msg, "green");
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
    getAll() {
      this.isLoading = true;

      var requestData = {
        userGroup: this.$store.state.user.userGroup,
        appUserId: this.$store.state.user.userId,
      };

      console.log(this.$store.state.userId);

      this.callApi(
        this.$constant.GET_ALL_COMPETITION_ENROLLMENT_URL,
        requestData,
        (resultData) => {
          //   this.competitionData = resultData.competitionList;
          console.log(resultData);
          this.dataList = resultData.dataList.map((e) => {
            let totalScore = null;
            if (e.score1 != null) {
              if (e.score2 != null) {
                if (e.score3 != null) {
                  if (e.score4 != null) {
                    totalScore = e.score1 + e.score2 + e.score3 + e.score4;
                  }
                }
              }
            }

            let averageScore = null;
            if (e.averageScore1 != null) {
              if (e.averageScore2 != null) {
                if (e.averageScore3 != null) {
                  if (e.averageScore4 != null) {
                    averageScore = (e.averageScore1 + e.averageScore2 + e.averageScore3 + e.averageScore4);
                    averageScore = averageScore.toFixed(1)
                  }
                }
              }
            }

            return {
              ...e,
              teamSize: e.competitionApplicantObjList
                ? e.competitionApplicantObjList.length
                : "-",
              totalScore: totalScore == null ? "-" : totalScore,
              averageScore: averageScore == null ? "-" : averageScore,
            };
          });

          this.isLoading = false;
        },
        () => {
          console.log("fail");
          this.isLoading = false;
        }
      );
    },
    edit(item) {
      this.selected = item;
      this.showEditDialog = true;
    },
    onCloseDialog() {
      this.showEditDialog = false;
      this.selected = {};
      this.getAll();
    },

    onAddCareerBtnClick() {
      let _filter = this.filters.filter(
        (filter) => filter.path == "partnershipId"
      );
      if (_filter && _filter.length > 0) {
        this.selected = {
          partnershipId: _filter[0].value,
        };
      }
      console.log("onAddCareerBtnClick", this.selected);
      this.showEditDialog = true;
    },
    exportData() {
      console.log("??");
      let dataList = this.getDataList();
      const tableData = [
        {
          nameEn: "Competition Name(EN)",
          nameTc: "Competition Name(TC)",
          refNo: "Ref. No.",
          teamSize: "Team Size",
          averageScore: "Average Score",
          score1:
            "Design Thinking, Creativity, Innovation & Effectiveness of Solution",
          score2: "Clarity of Presentation & Completeness of Submission",
          score3: "Written Description of Solution Within Word/Character Limit",
          score4:
            "Incorporation of ABCD Elements, Privacy-by-design, Cybersecurity by Design and Open Data",
          schoolName: "School Name",
          isEITP: this.$t('isEITP'), 
          applicant1Email: "Applicant 1",
          applicant1Name: "Applicant 1 (Name)",
          applicant1Form: "Applicant 1 (Form)",
          applicant1Mobile: "Applicant 1 (Mobile)",
          applicant2Email: "Applicant 2",
          applicant2Name: "Applicant 2 (Name)",
          applicant2Form: "Applicant 2 (Form)",
          applicant2Mobile: "Applicant 2 (Mobile)",
          applicant3Email: "Applicant 3",
          applicant3Name: "Applicant 3 (Name)",
          applicant3Form: "Applicant 3 (Form)",
          applicant3Mobile: "Applicant 3 (Mobile)",
          applicant4Email: "Applicant 4",
          applicant4Name: "Applicant 4 (Name)",
          applicant4Form: "Applicant 4 (Form)",
          applicant4Mobile: "Applicant 4 (Mobile)",
        },
        ...dataList.map((data) => {
          return {
            nameEn: data.nameEn,
            nameTc: data.nameTc,
            refNo: data.refNo,
            teamSize: data.teamSize,
            averageScore: data.averageScore,
            score1: data.averageScore1 ? data.averageScore1 : "-",
            score2: data.averageScore2 ? data.averageScore2 : "-",
            score3: data.averageScore3 ? data.averageScore3 : "-",
            score4: data.averageScore4 ? data.averageScore4 : "-",
            schoolName: data.competitionApplicantObjList[0].schoolName,
            isEITP: data.competitionApplicantObjList[0].isEITP ? "Y" : "N",
            applicant1Email: data.competitionApplicantObjList[0]
              ? data.competitionApplicantObjList[0].email
              : "-",
            applicant1Name: data.competitionApplicantObjList[0]
              ? data.competitionApplicantObjList[0].surname +
                " " +
                data.competitionApplicantObjList[0].givenName
              : "-",
            applicant1Form: data.competitionApplicantObjList[0]
              ? data.competitionApplicantObjList[0].yearGroup +
                "_" +
                data.competitionApplicantObjList[0].schoolClass
              : "-",
            applicant1Mobile: data.competitionApplicantObjList[0]
              ? data.competitionApplicantObjList[0].mobileNo != undefined
                ? data.competitionApplicantObjList[0].mobileNo
                : "-"
              : "-",
            applicant2Email: data.competitionApplicantObjList[1]
              ? data.competitionApplicantObjList[1].email
              : "-",
            applicant2Name: data.competitionApplicantObjList[1]
              ? data.competitionApplicantObjList[1].surname +
                " " +
                data.competitionApplicantObjList[1].givenName
              : "-",
            applicant2Form: data.competitionApplicantObjList[1]
              ? data.competitionApplicantObjList[1].yearGroup +
                "_" +
                data.competitionApplicantObjList[1].schoolClass
              : "-",
            applicant2Mobile: data.competitionApplicantObjList[1]
              ? data.competitionApplicantObjList[1].mobileNo != undefined
                ? data.competitionApplicantObjList[1].mobileNo
                : "-"
              : "-",
            applicant3Email: data.competitionApplicantObjList[2]
              ? data.competitionApplicantObjList[2].email
              : "-",
            applicant3Name: data.competitionApplicantObjList[2]
              ? data.competitionApplicantObjList[2].surname +
                " " +
                data.competitionApplicantObjList[2].givenName
              : "-",
            applicant3Form: data.competitionApplicantObjList[2]
              ? data.competitionApplicantObjList[2].yearGroup +
                "_" +
                data.competitionApplicantObjList[2].schoolClass
              : "-",
            applicant3Mobile: data.competitionApplicantObjList[2]
              ? data.competitionApplicantObjList[2].mobileNo != undefined
                ? data.competitionApplicantObjList[2].mobileNo
                : "-"
              : "-",
            applicant4Email: data.competitionApplicantObjList[3]
              ? data.competitionApplicantObjList[3].email
              : "-",
            applicant4Name: data.competitionApplicantObjList[3]
              ? data.competitionApplicantObjList[3].surname +
                " " +
                data.competitionApplicantObjList[3].givenName
              : "-",
            applicant4Form: data.competitionApplicantObjList[3]
              ? data.competitionApplicantObjList[3].yearGroup +
                "_" +
                data.competitionApplicantObjList[3].schoolClass
              : "-",
            applicant4Mobile: data.competitionApplicantObjList[3]
              ? data.competitionApplicantObjList[3].mobileNo != undefined
                ? data.competitionApplicantObjList[3].mobileNo
                : "-"
              : "-",
          };
        }),
      ];
      const fileTitle =
        `Stat_` +
        "COMPETITIONS" +
        `_${this.formatDate(new Date(), "YYYYMMDD_HHmmss")}`;

      CSVFile(null, null, "Data", tableData, fileTitle, false);

      // this.callApi(
      //   this.$constant.EXPORT_CAREERS_URL,
      //   {},
      //   (resultData) => {
      //     //
      //     const tableData = [
      //       {
      //         careerNameEn: "Job Title(EN)",
      //         careerNameTc: "Job Title(TC)",
      //         companyNameEn: "Company Name(EN)",
      //         companyNameTc: "Company Name(TC)",
      //         appliedCount: "Number Of Forms Applied As",
      //         resultCount: "Number Of Forms Resulted As",
      //       },
      //       ...resultData.data,
      //     ];
      //     const fileTitle =
      //       `Stat_` +
      //       "CAREERS" +
      //       `_${this.formatDate(new Date(), "YYYYMMDD_HHmmss")}`;
      //     console.log("export careers", resultData);
      //     CSVFile(null, null, "Data", tableData, fileTitle, false);
      //   },
      //   () => {
      //     //
      //     console.log("fail");
      //   }
      // );
    },

    update(item) {
      this.selected = item;
      this.callApi(
        this.$constant.UPDATE_COMPETITION_STATUS_URL,
        { id: this.selected.competitionEnrollmentId },
        (resultData) => {
          console.log(resultData);
          this.isLoading = false;
          this.showHiddenDialog = false;
          this.getAll();
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-footer__select {
  display: none;
}
.text-start,
td {
  white-space: pre;
}
</style>
