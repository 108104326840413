<template>
  <v-card-text>
    <v-container v-if="isLoading">
      <v-progress-linear
        indeterminate
        color="orange lighten-1"
      />
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col>
          <DisplayText
            :title="$t('username') + ':'"
            :content="appUser == undefined ? '' : appUser.username"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <DisplayText
            :title="$t('lastName') + ':'"
            :content="appUser == undefined ? '' : appUser.lastName"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <DisplayText
            :title="$t('firstName') + ':'"
            :content="appUser == undefined ? '' : appUser.firstName"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <DisplayText
            :title="$t('email') + ':'"
            :content="appUser == undefined ? '' : appUser.email"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <section>
            <h6 style="color: rgba(0, 0, 0, 0.4)">{{ $t('status') + ':' }}</h6>
            <v-chip
              :color="appUser.status == 'A' ? 'green accent-4' : '#FE253D'"
              dark
              v-if="appUser != undefined"
            >
              {{ $i18n.locale == 'en' ? appUser.userStatus.EN : appUser.userStatus.TC }}
            </v-chip>
          </section>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import DisplayText from '@/components/DisplayText'

export default {
  props: ['appUserId'],
  components: {
    DisplayText,
  },
  data() {
    return {
      isLoading: false,
      appUser: null,
    }
  },
  methods: {
    getAppUserDetails() {
      this.isLoading = true

      var requestData = {
        appUserId: this.appUserId,
      }

      this.callApi(this.$constant.GET_APP_USER_DETAILS_URL,
        requestData,
        (resultData) => {
          this.appUser = resultData.appUser

          this.isLoading = false
        },
        () => {
          this.isLoading = false
        }
      )
    }
  },
  mounted() {
    this.getAppUserDetails()
  },
  watch: {
    appUserId: function(newVal) {
      this.appUserId = newVal
      this.getAppUserDetails()
    }
  }
}
</script>