<template>
  <v-app>
    <AppBar ref="appBar" isAboutUs="true"/>

    <v-main>
      <v-container style="max-width: 1400px">
        <v-row class="mt-5" no-gutters>
          <v-col>
            <h2 class="text-uppercase">
              {{ $t('masterClassesAwardScheme') }}
            </h2>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col v-bind:style="{width: isMobile ? '100%' : '50%'}">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="$i18n.locale == 'en' ? require('@/assets/images/award1_en.jpg') : require('@/assets/images/award1_tc.jpg')"
              width="100%"
            />
          </v-col>

          <v-col v-bind:style="{width: isMobile ? '100%' : '50%'}">
            <v-img
              class="shrink mt-1 d-flex align-center"
              contain
              :src="$i18n.locale == 'en' ? require('@/assets/images/award2_en.jpg') : require('@/assets/images/award2_tc.jpg')"
              width="100%"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import AppBar from './AppBar'
import AppFooter from '@/views/AppFooter'


export default {
  components: {
    AppBar,
    AppFooter,
  },
  data() {
    return {
    }
  },
  computed: {
    content: function() {
      return this.$i18n.locale == 'en' ? this.contentEn : this.contentTc
    }
  },
  mounted() {
  }
}
</script>