<template>
  <v-container class="mainContainer mt-5 mb-5" v-if="!isMobile">
    <v-container style="max-width: 60%;">
      <v-sheet style="background-color: transparent;">
        <v-row>
          <v-col class="text-center">
            <span class="d-flex justify-center">
              <v-icon color="red" class="mr-2" size="30">
                mdi-youtube
              </v-icon>
              <router-link to="/VideoList">
                <h3 class="text-uppercase text-decoration-underline">
                  {{ $t('itCourses') }}
                </h3>
              </router-link>
            </span>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-container>

  <v-container class="mainContainer mt-5 mb-5" v-else>
    <v-container>
      <v-sheet style="background-color: transparent;">
        <v-row>
          <v-col class="text-center">
            <span class="d-flex justify-center">
              <v-icon color="red" class="mr-2">
                mdi-youtube
              </v-icon>
              <router-link to="/VideoList">
                  <h3 class="text-uppercase text-decoration-underline">
                    {{ $t('itCourses') }}
                  </h3>
              </router-link>
            </span>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      dataList: [],
      filterList: [],
      schoolList: [],
      showVideoDialog: false,
      selected: null,
      selectedSchool: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.mainContainer {
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #d7e8f0;
}
h4 {
  font-weight: normal;
}
.v-application a {
  color: #000;
}
.v-application a:hover {
  text-decoration: none;
}
</style>