var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("competitionManagement"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search') + '...',"single-line":"","hide-details":"","color":"orange lighten-1","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"orange lighten-1","dark":""},on:{"click":function($event){return _vm.onAddCareerBtnClick()}}},[_vm._v(" "+_vm._s(_vm.$t("addCareer"))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"white-space":"pre"},attrs:{"headers":_vm.headers,"items":_vm.getDataList(),"items-per-page":10,"multi-sort":"","search":_vm.search,"loading":_vm.isLoading,"footer-props":{
      'items-per-page-text': this.$t('rowsPerPage') + ':',
    },"sort-by":_vm.sortBy,"loading-text":_vm.$t('loading') + '...',"no-results-text":_vm.$t('noRecords'),"no-data-text":_vm.$t('noRecords')},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.imageB64",fn:function(ref){
    var item = ref.item;
return [(item.imageB64 != null)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('img',{staticClass:"py-1",attrs:{"width":"50","src":'data:image/png;base64,' + item.imageB64}})]):_vm._e()]}},{key:"item.deadlineDate",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.isEmptyString(item.deadlineDate) ? "-" : _vm.formatDate( item.deadlineDate, _vm.$i18n.locale == "en" ? "DD MMM YYYY hh:mm a" : "YYYY年MMMD日 ahh:mm" )))])]}},{key:"item.isActive",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isActive == 'Y' ? 'success' : 'error'}},[_vm._v(" mdi-checkbox-blank-circle ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#2c76b8","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("updateInfo")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onShowCareerListDialogBtnClick(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-format-list-checkbox")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("listOfCompetitionForm")))])],1)],1)],1)]}}],null,true)}),(_vm.showEditDialog)?_c('v-dialog',{attrs:{"width":"80%","scrollable":"","persistent":""},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('CompetitionForm',{attrs:{"data":_vm.selected},on:{"close":_vm.onCloseDialog}})],1):_vm._e(),(_vm.showCareerListDialog)?_c('v-dialog',{attrs:{"width":"80%","scrollable":"","persistent":""},model:{value:(_vm.showCareerListDialog),callback:function ($$v) {_vm.showCareerListDialog=$$v},expression:"showCareerListDialog"}},[_c('v-card',{attrs:{"width":"1000"}},[_c('v-toolbar',{attrs:{"color":"#2c76b8","dark":""}},[_c('v-toolbar-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("competitionManagement"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.onCareerListDialogClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('CompetitionEnrollmentManagement',{attrs:{"filters":_vm.careerFilter}})],1)],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"","timeout":"5000","color":"orange lighten-1"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","loading":_vm.isLoading,"text":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }