<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t("partnershipManagement") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text class="px-0"></v-card-text>

        <!-- partnershipName -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("companyName") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.partnershipNameEn"
              label="EN"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.partnershipNameTc"
              label="繁"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <!-- image -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("course.image") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              show-size
              truncate-length="30"
              color="#2c76b8"
              accept=".png, .jpg"
              class="pt-0"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <!-- link -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("companyWebsite") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.link"
              color="#2c76b8"
              clearable
              counter="100"
              class="pt-0"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <!-- partnershipDesc -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("aboutCompany") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.partnershipDescEn"
              label="EN"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.partnershipDescTc"
              label="繁"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
        <!-- partnershipContact -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("contact") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.partnershipContactEn"
              label="EN"
              no-resize
              filled
              counter="500"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.partnershipContactTc"
              label="繁"
              no-resize
              filled
              counter="500"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="true"
        @click="uploadFile"
        :loading="isLoading"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      schoolList: [],
      school: null,
      otherSchoolName: null,
      search: null,

      valid: false,
      requiredRules: [(v) => !!v || this.$t("requiredField")],

      file: null,
      formData: {
        partnershipContactEn: null,
        partnershipContactTc: "",
        partnershipDescEn: "",
        partnershipDescTc: "",
        partnershipImgFileName: null,
        partnershipNameEn: "",
        partnershipNameTc: "",
        link: "",
      },
    };
  },
  created() {
    console.log("created formData", this.formData);

    this.formData = Object.assign(this.formData, this.data);
    if (this.data.imageB64) {
      this.b64ToFile(this.data.imageB64, (file) => {
        this.file = file;
      });
    }
  },
  mounted() {
    //
    console.log("mounted formData", this.formData);
    console.log("mounted formData", this.data);
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    closeDialog(evt) {
      evt.preventDefault();
      this.$emit("close");
    },
    fileChange() {
      if (this.file != null) {
        this.isWrittenDescnReq = false;

        const reader = new FileReader();
        reader.onload = (e) => {
          // this.submitMaterial = e.target.result;
          // this.submitMaterialFileName = this.file.name;
          // this.isWrittenDescnReq = true;
        };
        reader.readAsDataURL(this.uploadedFile);
      }
    },
    uploadFile() {
      this.validate();
      if(this.valid == false){
        return
      }

      this.isLoading = true;
      const reader = new FileReader();

      if (this.file) {
        this.compress(this.file, (image) => {
          console.log("1");
          reader.onload = (e) => {
            console.log("2");
            let formData = this.formData;

            // formData = this.objectEncode(formData);

            formData.imageB64 = e.target.result;

            var requestData = {
              partnershipObj: formData,
            };

            this.callApi(
              this.$constant.SAVE_PARTNERSHIP_URL,
              requestData,
              (resultData) => {
                console.log(resultData);
                this.isLoading = false;
                this.$emit("close");
              },
              () => {
                this.isLoading = false;
                this.$emit("message", this.$t("fail"));
              }
            );
          };
          reader.readAsDataURL(image);
        });
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>
