<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-card-title class="text-uppercase">
        {{ $t('updateInfo') }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-form v-model="isFormValid">
					<v-row>
            <v-col>
              <v-text-field
                v-model="school.schoolNameEn"
                :label="$t('schoolNameEn') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                class="required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="school.schoolNameTc"
                :label="$t('schoolNameTc') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                class="required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-model="schoolType"
                :label="$t('schoolType') + ':'"
                :items="schoolTypeList"
                :item-text="$i18n.locale == 'en' ? 'codeName.EN' : 'codeName.TC'"
                item-value="codeValue"
                class="required"
              />
            </v-col>

            <v-col>
              <v-text-field
                v-model="school.schoolNo"
                type="number"
                :label="$t('schoolNo') + ':'"
                color="#2c76b8"
                clearable
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-model="area"
                :label="$t('location') + ':'"
                :items="areaList"
                :item-text="$i18n.locale == 'en' ? 'codeName.EN' : 'codeName.TC'"
                item-value="codeValue"
                @change="onChangeArea"
                clearable
                v-bind:class="{required: district != undefined}"
              />
            </v-col>

            <v-col>
              <v-select
                v-model="district"
                :label="$t('district') + ':'"
                :items="filteredDistrictList"
                :item-text="$i18n.locale == 'en' ? 'codeName.EN' : 'codeName.TC'"
                item-value="codeValue"
                @change="onChangeDistrict"
                clearable
                return-object
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="school.isITLab"
                :label="$t('isITLab')"
              ></v-checkbox>
            </v-col>

            <v-col cols="2">
              <v-checkbox
                v-model="school.isEITP"
                :label="$t('isEITP')"
              ></v-checkbox>
            </v-col>
          </v-row>

        </v-form>
      </v-container>
    </v-card-text>

    <v-divider />

		<v-card-actions class="justify-end">
      <v-spacer />
			
			<v-btn
				color="orange lighten-1"
				:dark="!isDisabled"
        :disabled="isDisabled"
				@click="submit"
				:loading="isLoading"
			>
				{{ $t('submit') }}
			</v-btn>
		</v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['school'],
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      areaList: [],
      districtList: [],
      schoolTypeList: [],
      area: null,
      district: null,
      schoolType: null,
    }
  },
  mounted() {
    this.getAllArea()
    this.getAllDistrict()
    this.getAllSchoolType()

    if (this.school.location != undefined) {
      this.area = this.school.location.value
    }
    if (this.school.district != undefined) {
      this.district = this.school.district.value
    }
    if (this.school.schoolType != undefined) {
      this.schoolType = this.school.schoolType.value
    }
  },
  methods: {
    getAllArea() {
      this.isLoading = true

      var requestData = {
      }

      this.callApi(this.$constant.GET_ALL_AREA_LIST_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          this.areaList = resultData.codeList
        },
        () => {
          this.isLoading = false
        }
      )
    },
    getAllDistrict() {
      this.isLoading = true

      var requestData = {
      }

      this.callApi(this.$constant.GET_ALL_DISTRICT_LIST_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          this.districtList = resultData.codeList
        },
        () => {
          this.isLoading = false
        }
      )
    },
    getAllSchoolType() {
      this.isLoading = true

      var requestData = {
      }

      this.callApi(this.$constant.GET_SCHOOL_TYPE_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          this.schoolTypeList = resultData.codeList
        },
        () => {
          this.isLoading = false
        }
      )
    },
    submit() {
      this.isLoading = true

      var requestData = {
        schoolId: this.school.schoolId,
        schoolNameEn: this.school.schoolNameEn,
        schoolNameTc: this.school.schoolNameTc,
        schoolType: this.schoolType,
        schoolNo: this.school.schoolNo,
        location: this.area,
        district: this.district != null ? this.district.codeValue : null,
        isITLab: this.school.isITLab,
        isEITP: this.school.isEITP,
      }

      this.callApi(this.$constant.EDIT_SCHOOL_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    onChangeArea() {
      if (this.area == undefined) {
        this.district = null
      }
    },
    onChangeDistrict() {
      if (this.area == undefined) {
        this.area = this.district.codeType
      }
    },
  },
  computed: {
    filteredDistrictList: function() {
      if (this.area != undefined) {
        return this.districtList.filter(d => {
          return d.codeType == this.area
        })
      }

      return this.districtList
    },
    isDisabled: function() {
      return  this.isEmptyString(this.school.schoolNameEn) || 
              this.isEmptyString(this.school.schoolNameTc) || 
              this.isEmptyString(this.schoolType) || 
              (this.isEmptyString(this.area) && !this.isEmptyString(this.district))
              !this.isFormValid
    }
  },
}
</script>