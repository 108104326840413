<template>
  <v-app>
    <AppBar
      ref="appBar"
      :isHome="false"
    />
    <v-main>
      <v-container class="mb-8" style="max-width: 1400px">
        <!--  -->
        <div>
          <v-row class="mt-2" no-gutters>
            <v-col>
              <h2 class="text-uppercase">
                {{ $t("partnership") }}
              </h2>
            </v-col>
          </v-row>
          <v-row align="stretch">
            <v-col
              class="d-flex flex-column justify-center mb-6"
              :cols="isMobile ? 12 : 6"
              v-for="(item, i) in list"
              :key="i"
            >
              <v-card
                elevation="2"
                class="d-flex flex-column"
                style="flex: auto;"
              >
                <v-responsive :height="isMobile ? 'auto' : 400" style="max-height: 400px">
                  <!-- <v-img
                    class="white--text align-end"
                    height="300"
                    :src="
                      item.imageB64 == 'data:image/png;base64,'
                        ? require('@/assets/images/ampower_logo.png')
                        : item.imageB64
                    "
                    contain
                  >
                  </v-img> -->
                  <div class="d-flex justify-center align-center">
                    <img
                      :src="
                        item.imageB64 == null
                          ? require('@/assets/images/ampower_logo.png')
                          : item.imageB64
                      "
                      style="
                        max-height: 400px;
                        object-fit: contain;
                        max-width: 100%;
                        margin: auto;
                      "
                    />
                  </div>
                </v-responsive>

                <v-card-title>
                  {{
                    $i18n.locale == "en"
                      ? item.partnershipNameEn
                      : item.partnershipNameTc
                  }}
                </v-card-title>

                <div v-for="(desc, j) in item.desc" :key="j">
                  <v-card-subtitle class="pb-0">
                    {{ $t(desc.title) }}
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div style="white-space: pre-line">
                      {{ desc.content[$i18n.locale] }}
                    </div>
                  </v-card-text>
                </div>

                <v-spacer></v-spacer>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn @click="onDetailsClick(item.link)" color="orange" text>
                    {{ $t("details") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./AppBar";
import AppFooter from "@/views/AppFooter";
import Home from "./Home";
import NewsFeed from "./NewsFeed";

export default {
  name: "Welcome",
  components: {
    AppBar,
    AppFooter,
    Home,
    NewsFeed,
  },
  data() {
    return {
      classData: [],
      index: 0,
      list: [],
    };
  },
  created() {
    //
  },
  methods: {
    onDetailsClick(link) {
      // window.location.replace(link)
      window.open(link, "_blank");
    },
  },
  mounted() {
    this.callApi(
      this.$constant.GET_PARTNERSHIP_LIST_URL,
      {},
      (resultData) => {
        this.isLoading = false;
        console.log(resultData);
        const partnershipList = resultData.partnershipList;

        let list = [];

        partnershipList.map((_item) => {
          //
          let item = this.objectDecode(_item, ["b64FileName", "b64FileName"]);
          let listItem = {
            ...item,
            imageB64:
              item.imageB64 != null
                ? "data:image/png;base64, " + item.imageB64
                : null,
            desc: [
              {
                title: "aboutCompany",
                content: {
                  en: item.partnershipDescEn,
                  tc: item.partnershipDescTc,
                },
              },
              // {
              //   title: "companyAddress",
              //   content: {
              //     en: item.partnershipContactEn,
              //     tc: item.partnershipContactTc,
              //   }
              // },
              // {
              //   title: "contact",
              //   content: {
              //     en: item.partnershipContactEn,
              //     tc: item.partnershipContactTc,
              //   },
              // },
            ],
          };
          list.push(listItem);
        });

        this.list = list;

        this.showEditForm = true;
      },
      () => {
        this.isLoading = false;
        this.isRobot = true;
      }
    );

    this.$watch(
      () => {
        return this.$refs.appBar.index;
      },
      (val) => {
        this.index = val;
      }
    );
  },
};
</script>
