<template>
  <v-container style="max-width: 1400px" :class="isMobile ? 'mobile' : ''">
    <v-row>
      <v-col class="d-flex align-center">
        <v-img
          class="shrink mt-1 d-flex align-end text-right"
          contain
          :src="
            $constant.isUAT
              ? $i18n.locale == 'en'
                ? require('@/assets/images/background_en_UAT.png')
                : require('@/assets/images/background_tc_UAT.png')
              : $i18n.locale == 'en'
              ? require('@/assets/images/background_en.png')
              : require('@/assets/images/background_tc.png')
          "
          width="100%"
        >
          <v-row style="width: 100%" justify="end" v-if="!isMobile">
            <v-col cols="4" class="mr-3">
              <v-btn
                tile
                @click="showAboutOurProgramDialog = true"
                light
                color="white"
                block
                v-bind="size"
              >
                <v-icon left color="red" v-bind="size" class="mr-5">mdi-youtube</v-icon>
                {{ $t("aboutOurProgram") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row style="width: 100%;" justify="end" v-if="!isMobile">
            <v-col cols="4" class="mr-3">
              <v-btn
                tile
                @click="showAboutOurCompetitionDialog = true"
                light
                color="white"
                block
                v-bind="size"
              >
                <v-icon left color="red" v-bind="size" class="mr-5">mdi-youtube</v-icon>
                {{ $t("aboutOurCompetition") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row style="width: 100%; margin-bottom: 4%;" justify="end" v-if="!isMobile">
            <v-col cols="4" class="mr-3">
              <v-btn
                tile
                @click="showCareerExpDialog = true"
                light
                color="white"
                block
                v-bind="size"
              >
                <v-icon left color="red" v-bind="size" class="mr-5">mdi-youtube</v-icon>
                {{ $t("careerExp") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>

    <v-row v-if="isMobile" class="mt-3">
      <v-col>
        <v-btn tile @click="showAboutOurProgramDialog = true" light :size="size" color="white" block>
          <v-icon left color="red" :size="size" class="mr-5">mdi-youtube</v-icon>
          {{ $t("aboutOurProgram") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="isMobile">
      <v-col>
        <v-btn tile @click="showAboutOurCompetitionDialog = true" light :size="size" color="white" block>
          <v-icon left color="red" :size="size" class="mr-5">mdi-youtube</v-icon>
          {{ $t("aboutOurCompetition") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="isMobile" class="mb-3">
      <v-col>
        <v-btn tile @click="showCareerExpDialog = true" light :size="size" color="white" block>
          <v-icon left color="red" :size="size" class="mr-5">mdi-youtube</v-icon>
          {{ $t("careerExp") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="background-color: #f44336; margin: 12px -6px">
      <v-col class="text-center tabs">
        <span class="d-flex justify-center" style="">
          <router-link class="text-decoration-none" to="/Competition">
            <v-card-title
              class="py-1 font-weight-bold text-center"
              style="line-height: 1.75rem; color: white"
            >
              {{ $t("competitionName") }}<br />
              {{ `(${$t("submissionDeadline")} 11 Feb 2022)` }}
            </v-card-title>
          </router-link>
        </span>
      </v-col>
    </v-row>

    <v-row style="background-color: #f44336; margin: 12px -6px">
      <v-col class="text-center tabs">
        <span class="d-flex justify-center" style="">
          <router-link class="text-decoration-none" to="/ClosingCeremony">
            <v-card-title
              class="py-1 font-weight-bold text-center"
              style="line-height: 1.75rem; color: white"
            >
              {{ $t("closingTitle1") }}<br />
              {{ $t("closingTitle2") }}
            </v-card-title>
          </router-link>
        </span>
      </v-col>
    </v-row>

    <v-row style="background-color: #f4dccf; margin: 12px -6px">
      <v-col class="d-flex justify-center align-center text-center tabs">
        <v-card-title class="py-1 text-center" style="line-height: 1.75rem">
          {{ $t("latestClass") }}
        </v-card-title>
      </v-col>
    </v-row>

    <!-- <v-row style="margin: 12px -6px">
      <v-col
        style="background-color: #f44336"
        class="d-flex justify-center align-center tabs"
      >
        <v-card-title
          class="py-1 font-weight-bold text-center"
          style="line-height: 1.75rem; color: white"
        >
          {{ $t("latestClass") }}<br />
          {{ `(${$t("submissionDeadline")} Jan 2022)` }}
        </v-card-title>
      </v-col>
      <v-col
        style="background-color: #f4dccf"
        class="d-flex justify-center align-center tabs"
      >
        <span class="d-flex justify-center">
          <router-link class="text-decoration-none" to="/Competition">
            <v-card-title
              class="py-1 text-center"
              style="line-height: 1.75rem; color: black"
            >
              {{ $t("competitionName") }}
            </v-card-title>
          </router-link>
        </span>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <v-icon class="pa-0" color="yellow darken-2"> mdi-star </v-icon>
          <p class="mb-0 ml-2">
            {{ $t("recommended") }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="isLoading" class="text-center mt-5 mb-5">
        <h2>
          {{ $t("loading") + "..." }}
        </h2>
        <v-progress-linear indeterminate color="orange lighten-1" />
      </v-col>

      <v-col
        :cols="isMobile ? '12' : 4"
        v-for="value in classData"
        v-bind:key="value.courseId"
        v-else-if="classData != null"
        ref="test"
      >
        <ClassCard
          :id="'class-' + value.courseId"
          :ref="'class-' + value.courseId"
          :courseId="value.courseId"
          :title="
            $i18n.locale == 'en' ? value.courseNameEn : value.courseNameTc
          "
          :details="
            $i18n.locale == 'en' ? value.courseDescEn : value.courseDescTc
          "
          :image="value.imageBase64"
          :date="value.courseDate"
          :checked="value.checked"
          :loading="isLoading"
          :lecturerList="value.lecturerList"
          :isRecommended="value.isRecommended"
          :isExpired="value.isExpired"
          :youtubeLink="value.youtubeLink"
          :isReqRegister="value.isReqRegister"
        />
      </v-col>

      <v-col class="mt-3" v-else>
        <v-sheet
          color="grey lighten-4"
          width="100%"
          class="text-center pt-5 pb-5"
        >
          <v-icon size="100" color="grey lighten-1"> mdi-cancel </v-icon>
          <h2 style="color: #bdbdbd">
            {{ $t("emptyClass") }}
          </h2>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog
      width="1000"
      scrollable
      persistent
      v-model="detailsDialog"
      v-if="detailsDialog"
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark style="height: auto">
          <v-toolbar-title class="text-uppercase">
            {{ $t("courseDetails") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text--primary">
          <v-container>
            <v-row>
              <v-col class="py-3">
                <!-- <v-img
                  class="white--text align-end"
                  width="1000"
                  contain
                  :src="showClass.imageBase64"
                /> -->

                <img
                  :src="showClass.imageBase64"
                  style="width: 1000px; object-fit: contain; max-width: 100%"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card-title class="d-block pa-0 text-break">
                  <!-- <v-tooltip bottom v-if="showClass.isRecommended">
                    <template v-slot:activator="{ on, attrs }"> -->
                  <v-icon color="yellow darken-2 mb-2"> mdi-star </v-icon>
                  <!-- </template>
                    <span>{{ $t('recommended') }}</span>
                  </v-tooltip> -->
                  {{
                    $i18n.locale == "en"
                      ? showClass.courseNameEn
                      : showClass.courseNameTc
                  }}
                </v-card-title>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.category') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showClass.courseCategoryEn
                      : showClass.courseCategoryTc
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.date') + ':'"
                  :content="
                    formatDate(
                      showClass.courseDate,
                      this.$i18n.locale == 'en'
                        ? 'DD MMM YYYY hh:mm a'
                        : 'YYYY年MMMD日 ahh:mm'
                    )
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.duration') + ':'"
                  :content="showClass.courseDuration + ''"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.lang') + ':'"
                  :content="
                    showClass.languageEn == 'EN'
                      ? this.$t('english')
                      : showClass.languageEn == 'TC'
                      ? this.$t('chinese')
                      : this.$t('bothLang')
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.instructor') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showClass.instructorEn
                      : showClass.instructorTc
                  "
                />
              </v-col>
            </v-row>

            <v-row
              v-if="
                ($i18n.locale == 'en' &&
                  !isEmptyString(showClass.courseDescEn)) ||
                ($i18n.locale == 'tc' && !isEmptyString(showClass.courseDescTc))
              "
            >
              <v-col>
                <DisplayText
                  :title="$t('course.descn') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showClass.courseDescEn
                      : showClass.courseDescTc
                  "
                />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.noOfParticipant') + ':'"
                  :content="showClass.noOfParticipants"
                />
              </v-col>
            </v-row> -->

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('course.contact') + ':'"
                  :content="
                    showClass.contactName + ' - ' + showClass.contactDetail
                  "
                />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <v-text-field
                  :value="formatDate(showClass.courseDate, this.$i18n.locale == 'en' ? 'DD MMM YYYY hh:mm a' : 'YYYY年MMMD日 ahh:mm')"
                  :label="$t('course.targetAudience')"
                  readonly
                />
              </v-col>
            </v-row> -->
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end">
          <v-btn text color="orange lighten-1" @click="detailsDialog = false">
            {{ $t("close") }}
          </v-btn>

          <v-btn
            v-if="!$constant.isStaffApp && showClass.courseNameEn != 'Career Planning App (Proof of Concept) Design Competition Briefing'"
            color="orange lighten-1"
            dark
            @click="register(showClass)"
          >
            {{ $t("register") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAboutOurProgramDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @close="showAboutOurProgramDialog = false"
        :videoList="programVideoList"
        :title="$t('aboutOurProgram')"
        :showAmpowerLogo="true"
      />
    </v-dialog>

    <v-dialog
      v-model="showAboutOurCompetitionDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @close="showAboutOurCompetitionDialog = false"
        :videoList="competitionVideoList"
        :title="$t('aboutOurCompetition')"
        :showAmpowerLogo="true"
      />
    </v-dialog>

    <v-dialog
      v-model="showCareerExpDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @close="showCareerExpDialog = false"
        :videoList="careerExpVideoList"
        :title="$t('careerExp')"
        :showAmpowerLogo="true"
      />
    </v-dialog>

    <!-- <v-btn
      color="red"
      dark
      bottom
      right
      fab
      fixed
      x-large
      class="transition-swing"
    >
      <v-icon color="white">mdi-youtube</v-icon>
      {{ $t('aboutOurProgram') }}
    </v-btn> -->
  </v-container>
</template>

<script>
import ClassCard from "@/views/class/ClassCard";
import DisplayText from "@/components/DisplayText";
import YoutubePlayer from "@/components/YoutubePlayer";
import StringSimilarity from "string-similarity";

export default {
  name: "Home",
  components: {
    ClassCard,
    DisplayText,
    YoutubePlayer,
  },
  data() {
    return {
      isLoading: false,
      showClass: null,
      detailsDialog: false,
      classData: [],
      checkedData: 0,
      showAboutOurProgramDialog: false,
      showAboutOurCompetitionDialog: false,
      showCareerExpDialog: false,
      scrollToId: -1,
      programVideoList: [
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=rYLufuUEF7o"),
          title:
            "Ampower Talent Institute資訊科技大師班課程之《解構未來》Master Classes to Broaden IT & Tech Career Options Trailer",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=ZTy-P4jBMfU"),
          title:
            "Ampower Talent Institute資訊科技大師班之《傳授功力》Work Experience to Reach IT & Tech Career Goals Trailer",
        },
      ],
      competitionVideoList: [
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=RtYKlbU34WA"),
          title:
            "Ampower Talent Institute Career Planning App (POC) Design Competition「職業規劃應用程式(概念化)設計比賽」",
        },
      ],
      careerExpVideoList: [
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=yXKdeoIrHXE"),
          title:
            "政府資訊科技總監辦公室助理政府資訊科技總監(產業發展) 黃敬文先生講話",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=wfCn8pW8olQ"),
          title:
            "Ampower Talent Institute 聯合創辦人及召集人陳曉峰律師 MH, JP 分享",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=38CwLZxcJM0"),
          title:
            "Ampower Talent Institute 聯合創辦人陳葉佩坤女士分享",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=sQeSCkzUdoI"),
          title:
            "Ampower Career Experience - Microsoft",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=7x2OsOuGIho"),
          title:
            "Ampower Career Experience - Hutchitson & 3",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=Rs8zidfjSA4"),
          title:
            "Ampower Career Experience - Deloitte",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=2BrOlK-BGaU"),
          title:
            "Ampower Career Experience - NEC",
        },
      ],
    };
  },
  methods: {
    toggleSelect(courseId, checked) {
      var c = this.classData.find((c) => c.courseId == courseId);
      c.checked = checked;

      var tempList = this.classData.filter((c) => c.checked);
      this.checkedData = tempList.length;

      this.detailsDialog = false;
    },
    showDetails: function (id) {
      this.showClass = this.classData.find((c) => c.courseId == id);
      this.detailsDialog = true;
    },
    register(data) {
      this.$store.commit("clearCourses");

      data.checked = true;

      for (var i in this.classData) {
        if (this.classData[i].checked) {
          this.$store.commit("addCourses", this.classData[i]);
        }
      }

      this.$router.push({ path: "/Apply" });
    },
  },
  updated: function () {
    this.$nextTick(function () {
      // scroll to corresponding class element
      if (this.scrollToId > 0) {
        let element = document.getElementById(`class-${this.scrollToId}`);
        element.scrollIntoView(false);
        this.scrollToId = -1;
      }
    });
  },
  mounted() {
    this.$store.commit("logout");

    this.isLoading = true;

    var requestData = {
      showLecturer: true,
    };

    this.callApi(
      this.$constant.GET_ALL_COURSE_URL,
      requestData,
      (resultData) => {
        this.classData = resultData.courseList;

        if (this.classData != null) {
          this.classData.map((d) => Object.assign(d, { checked: false }));
          this.classData = this.classData.filter(d => d.courseId != this.closingCeremonyID);
          // open related couse details popup
          if (this.$route.params.courseName) {
            let similarity = 0;
            let result = -1;

            this.classData.forEach((data) => {
              let _similarity = 0;
              if (this.$i18n.locale == "en") {
                _similarity = StringSimilarity.compareTwoStrings(
                  this.$route.params.courseName,
                  data.courseNameEn
                );
              } else {
                _similarity = StringSimilarity.compareTwoStrings(
                  this.$route.params.courseName,
                  data.courseNameTc
                );
              }
              if (_similarity > similarity) {
                similarity = _similarity;
                result = data.courseId;
              }
            });

            if (result > 0) {
              this.showDetails(result);
              // scroll to corresponding class element
              // this.scrollToId = result;
            }
          }
        }

        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  },
  computed: {
    size () {
      const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 85%;
}

.v-input--checkbox .v-input__control .v-messages {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.theme--dark.v-badge .v-badge__badge::after {
  border-color: white !important;
}
.course-descn {
  line-height: 1.7rem;
}
.tabs {
  text-align: center;
}

.mobile {
  .tabs {
    .v-card__title {
      font-size: 0.95rem;
      line-height: 1.25rem !important;
      padding: 8px;
      word-break: break-word;
    }
  }
}
</style>
