import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    courses: [],
    competition: null,
    studentInfo: {
      name: null,
      school: null,
      yearGroup: null,
      schoolClass: null,
      email: null,
    },
    user: {
      userId: "",
      firstName: "",
      lastName: "",
      session: "",
      priKey: null,
      saltKey: null,
      accessRightList: [],
      userGroup: "",
    },
    siteKey: null,
    lang: "en",
    metaTag: {
      title: null,
      description: null,
      url: null,
      image: null,
    },
  },
  mutations: {
    addCourses(state, course) {
      state.courses.push(course);
    },
    removeCourses(state, course) {
      for (var i = 0; i < state.courses.length; i++) {
        if (state.courses[i].courseId == course.courseId) {
          state.courses.splice(i, 1);
          break;
        }
      }
    },
    removeCourse(state, course) {
      var index = state.courses
        .map((c) => {
          return c.courseId;
        })
        .indexOf(course.courseId);

      state.courses.splice(index, 1);
    },
    clearCourses(state) {
      state.courses = [];
    },
    saveStudentInfo(state, info) {
      state.studentInfo.name = info.name;
      state.studentInfo.school = info.school;
      state.studentInfo.yearGroup = info.yearGroup;
      state.studentInfo.schoolClass = info.schoolClass;
      state.studentInfo.email = info.email;
    },
    clearStudentInfo(state) {
      state.studentInfo.name = null;
      state.studentInfo.school = null;
      state.studentInfo.yearGroup = null;
      state.studentInfo.schoolClass = null;
      state.studentInfo.email = null;
    },
    setUser(state, user) {
      state.user.userId = user.userId;
      state.user.firstName = user.firstName;
      state.user.lastName = user.lastName;
      state.user.session = user.session;
      state.user.accessRightList = user.accessRightList;
      state.user.userGroup = user.userGroup;
    },
    logout(state) {
      state.user.userId = "";
      state.user.firstName = "";
      state.user.lastName = "";
      state.user.session = "";
      state.user.priKey = null;
      state.user.accessRightList = [];
    },
    storePriKey(state, priKey) {
      state.user.priKey = priKey;
    },
    storeSaltKey(state, saltKey) {
      state.user.saltKey = saltKey;
    },
    changeLang(state, lang) {
      state.lang = lang;
    },
    setCompetition(state, competition) {
      state.competition = competition;
    },
    clearCompetition(state, competition) {
      state.competition = null;
    },
    setMetaTag(state, metaTag) {
      state.metaTag.title = metaTag.title;
      state.metaTag.description =
        metaTag.description == undefined ? metaTag.title : metaTag.description;
      state.metaTag.url = window.location.href;

      if (metaTag.image == undefined) {
        state.metaTag.image = "https://www.ampowertalent.org/logo.png";
      } else if (window.location.href.endsWith("Competition")) {
        state.metaTag.image =
          "https://www.ampowertalent.org/competition_banner.jpg";
      } else {
        state.metaTag.image = "https://www.ampowertalent.org/course_banner.jpg";
      }
    },
  },
  actions: {},
  modules: {},
});
