<template>
  <v-card width="100%">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t("careerExp") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-text class="px-0"></v-card-text>

      <v-row>
        <v-col cols="6" class="pa-4" align="stretch">
          <!-- PDF -->
          <v-row>
            <v-col>
              <h2>
                {{ $t("careerExpMaterial") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <iframe
                style="height: 50vh; width: 100%"
                :src="'data:application/pdf;base64,' + formData.pdfB64"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-4" align="stretch">
          <!-- participantGivenName  -->
          <v-row>
            <v-col>
              <h2>
                {{ $t("name") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :value="getEnrollmentName(enrollment)"
                color="#2c76b8"
                clearable
                class="pt-0"
                disabled
              />
            </v-col>
          </v-row>
          <!--  emailAddress -->
          <v-row>
            <v-col>
              <h2>
                {{ $t("email") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :value="enrollment.emailAddress"
                color="#2c76b8"
                clearable
                class="pt-0"
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>
                {{ $t("mobileNo") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :value="enrollment.mobileNo"
                color="#2c76b8"
                clearable
                class="pt-0"
                disabled
              />
            </v-col>
          </v-row>
          <!--  schoolName -->
          <v-row>
            <v-col>
              <h2>
                {{ $t("schoolName") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                :value="enrollment.schoolName"
                color="#2c76b8"
                clearable
                class="pt-0"
                disabled
              />
            </v-col>
          </v-row>
          <!--  status -->
          <v-row>
            <v-col>
              <h2>
                {{ $t("status") }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="formData.status"
                :disabled="formData.isMarked == 'Y'"
                :items="[
                  { tx: $t('pending'), value: 'P' },
                  { tx: $t('approved'), value: 'A' },
                  { tx: $t('rejected'), value: 'R' },
                ]"
                item-text="tx"
                item-value="value"
              />
            </v-col>
          </v-row>
          <!--  Result Career -->
          <v-row v-if="formData.status == 'A'">
            <v-col>
              <h2>
                {{ $t("finalCareerResult") }}
              </h2>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-if="formData.status == 'A'">
            <v-col>
              <v-autocomplete
                v-model="formData.resultCareerId"
                :disabled="formData.isMarked == 'Y'"
                :items="[
                  { header: $t('applicantPreference') },
                  ...careerChoices,
                ]"
                item-text="partnership"
                item-value="career.careerId"
              >
                <!-- v-slot:selection -->
                <template v-slot:selection="data">
                  <div style="width:100%">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          displayTextByLocale(
                            data.item.partnership.partnershipNameEn,
                            data.item.partnership.partnershipNameTc
                          )
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          displayTextByLocale(
                            data.item.career.careerNameEn,
                            data.item.career.careerNameTc
                          )
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </div>
                </template>
                <!-- v-slot:item -->
                <template v-slot:item="data">
                  <template v-if="isObject(data)">
                    <v-list-item-content>
                      <h2 style="color: #666" class="pa-2">
                        {{
                          displayTextByLocale(
                            data.partnership.partnershipNameEn,
                            data.partnership.partnershipNameTc
                          )
                        }}
                      </h2>
                    </v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          displayTextByLocale(
                            data.item.partnership.partnershipNameEn,
                            data.item.partnership.partnershipNameTc
                          )
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          displayTextByLocale(
                            data.item.career.careerNameEn,
                            data.item.career.careerNameTc
                          )
                        }}
                        <!-- <span v-if="isFirstChoice(data.item)" class="pa-2">
                          <v-icon small color="error" class="mr-1"
                            >mdi-circle</v-icon
                          >
                          <span class="priority-text" style="color: #ff5252">
                            {{ $t("highestPriority") }}
                          </span>
                        </span> -->
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <!--  -->
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="file != null"
        @click="uploadFile"
        :loading="isLoading"
        :disabled="formData.isMarked == 'Y' || formData.status == 'P'"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style lang="scss" scoped>
.v-item--active {
  background-color: #1976d233 !important;
  border: none;
}
.career-radio-btn {
  border: solid 1px #666666;
  border-radius: 8px;
  flex: 1;
}
</style>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      schoolList: [],
      school: null,
      otherSchoolName: null,
      search: null,

      enrollment: null,
      careerChoices: null,
      careerList: null,

      file: null,
      formData: {
        partnershipContactEn: null,
        partnershipContactTc: "",
        partnershipDescEn: "",
        partnershipDescTc: "",
        partnershipImgFileName: null,
        partnershipNameEn: "",
        partnershipNameTc: "",
        link: "",
      },
    };
  },
  created() {
    console.log("created formData", this.formData);

    this.formData = Object.assign(this.formData, this.data);
    if (this.data.imageB64) {
      this.b64ToFile(this.data.imageB64, (file) => {
        this.file = file;
      });
    }

    this.fetchCareerExpFormById(this.data.careerExperienceFormId);
    this.fetchEnrollmentById(this.data.enrollmentId);
    this.fetchCareerChoiceById(this.data.careerExperienceFormId);
    this.fetchCareerList();
  },
  mounted() {
    //
    // console.log("mounted formData", this.formData);
    // console.log("mounted formData", this.data);

    this.fetchCareerExpFormById(this.data.careerExperienceFormId);
    this.fetchEnrollmentById(this.data.enrollmentId);
    this.fetchCareerChoiceById(this.data.careerExperienceFormId);
    this.fetchCareerList();
  },
  methods: {
    isFirstChoice(item) {
      if (
        this.careerChoices &&
        this.careerChoices[0] &&
        this.careerChoices[0].career
      ) {
        if (this.careerChoices[0].career.careerId == item.career.careerId) {
          return true;
        }
      }
      return false;
    },
    fetchCareerExpFormById(id) {
      this.callApi(
        this.$constant.GET_CAREER_EXP_FORM_BY_ID_URL,
        {
          careerExperienceFormId: id,
        },
        (resultData) => {
          console.log("careerExperienceForm", resultData.careerExperienceForm);

          this.formData = resultData.careerExperienceForm;
          this.isLoading = false;
          // this.$emit("close");
        },
        () => {
          this.isLoading = false;
          // this.$emit("message", this.$t("fail"));
        }
      );
    },
    fetchEnrollmentById(id) {
      this.callApi(
        this.$constant.FIND_ENROLLMENT_BY_ID_URL,
        {
          enrollmentId: id,
        },
        (resultData) => {
          this.enrollment = resultData.enrollment;

          this.isLoading = false;
          // this.$emit("close");
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    fetchCareerList() {
      this.callApi(
        this.$constant.GET_ALL_CAREER,
        {},
        (resultData) => {
          console.log("fetchCareerList", resultData);
          if (resultData.careerObj) {
            this.careerList = resultData.careerObj.map((item) => {
              return {
                career: item[0],
                partnership: item[1],
              };
            });
          }
          console.log(this.careerList);
          // this.careerList = resultData.careerObj;
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    fetchCareerChoiceById(id) {
      this.callApi(
        this.$constant.GET_CAREER_CHOICES_BY_ID_URL,
        {
          id: id,
        },
        (resultData) => {
          console.log('-----------------')
          console.log(resultData)

          this.careerChoices = resultData.careerChoiceObjList;
          console.log("careerChoices", this.careerChoices);

          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    isObject(data) {
      if (data) {
        return typeof data.item !== "object";
      }
      return false;
    },
    closeDialog(evt) {
      evt.preventDefault();
      this.$emit("close");
    },
    fileChange() {
      if (this.file != null) {
        this.isWrittenDescnReq = false;

        const reader = new FileReader();
        reader.onload = (e) => {
          // this.submitMaterial = e.target.result;
          // this.submitMaterialFileName = this.file.name;
          // this.isWrittenDescnReq = true;
        };
        reader.readAsDataURL(this.uploadedFile);
      }
    },
    getEnrollmentName(enrollment) {
      const isEn = this.$i18n.locale == "en";

      const chiName = enrollment.participantChiName;
      const enName =
        enrollment.participantSurname + ", " + enrollment.participantGivenName;

      if (!isEn && chiName != null) {
        return chiName;
      } else {
        return enName;
      }
    },
    displayTextByLocale(en, tc) {
      const isEn = this.$i18n.locale == "en";
      if (!isEn && tc != null) {
        return tc;
      }
      return en;
    },
    uploadFile() {
      this.isLoading = true;
      var requestData = {
        careerExperienceFormObj: this.formData,
        isBackend: true,
        email: this.enrollment.emailAddress,
      };

      this.callApi(
        this.$constant.SUBMIT_CAREER_EXP_FORM,
        requestData,
        (resultData) => {
          console.log("success");
          console.log(resultData);
          this.isLoading = false;
          this.$emit("close");
        },
        () => {
          console.log("err");
          this.isLoading = false;
          this.$emit("message", this.$t("fail"));
        }
      );
    },
  },
};
</script>
