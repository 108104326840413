<template>
  <v-app>
    <AppBar ref="appBar" isAboutUs="true"/>

    <v-main>
      <v-container style="max-width: 1400px;">
        <v-row class="mt-5" no-gutters>
          <v-col>
            <h2 class="text-uppercase">
              {{ $t('news') }}
            </h2>
          </v-col>
        </v-row>

        <v-row class="mt-5" no-gutters>
          <v-col>
            <vue-pdf-app 
              style="height: 75vh"
              theme="light"
              :pdf="pdf" 
              :config="config"
            ></vue-pdf-app>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import AppBar from './AppBar'
import AppFooter from '@/views/AppFooter'
import VuePdfApp from "vue-pdf-app"

const pdf_en = require("@/assets/pdf/press_release_en.pdf");
const pdf_tc = require("@/assets/pdf/press_release_tc.pdf");

export default {
  components: {
    AppBar,
    AppFooter,
    VuePdfApp,
  },
  data() {
    return {
      config: {
        toolbar: false,
      },
    }
  },
  computed: {
    pdf() {
      return this.$i18n.locale == 'en' ? pdf_en : pdf_tc
    }
  }
}
</script>