<template>
  <v-card>
     <v-toolbar
      color="#2c76b8"
      dark
      flat
    >
      <v-toolbar-title>
        {{ $t('changePw') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-subheader>{{ $t('fillInfo') + ':' }}</v-subheader>
          </v-col>
        </v-row>

        <v-form v-model="isFormValid" ref="form">
          <v-text-field
            v-model="oldPassword"
            :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showOldPassword ? 'text' : 'password'"
            :label="$t('oldPw')"
            counter
            @click:append="showOldPassword = !showOldPassword"
            color="#2c76b8"
          />

          <v-text-field
            v-model="newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showNewPassword ? 'text' : 'password'"
            :label="$t('newPw')"
            counter
            @click:append="showNewPassword = !showNewPassword"
            color="#2c76b8"
            :rules="[rules.newPassword1, rules.newPassword2, rules.confirmNewPassword]"
          />

          <v-text-field
            v-model="confirmNewPassword"
            :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfirmNewPassword ? 'text' : 'password'"
            :label="$t('confirmNewPw')"
            counter
            @click:append="showConfirmNewPassword = !showConfirmNewPassword"
            color="#2c76b8"
            :rules="[rules.confirmNewPassword]"
          />
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn
        color="orange lighten-1"
        :dark="!isDisabled"
        :disabled="isDisabled"
        @click="confirm"
        :loading="isLoading"
      >
        {{ $t('confirm') }}
      </v-btn>
    </v-card-actions> 

    <v-snackbar
			top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>   
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      oldPassword: null,
      showOldPassword: false,
      newPassword: null,
      showNewPassword: false,
      confirmNewPassword: null,
      showConfirmNewPassword: false,
      showSnackbar: false,
      snackbarText: null,
      isFormValid: false,
      rules: {
        newPassword1: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          return (
            pattern.test(value) || this.$t('pwPolicy')
          );
        },
        newPassword2: value => {
          return (
            value != this.oldPassword || this.$t('sameWithOldPw')
          );
        },
        confirmNewPassword: value => {
          return (
            (value == null || value == this.newPassword) || this.$t('pwNotMatch')
          );
        }
      }
    }
  },
  methods: {
    confirm() {
      this.isLoading = true

      var requestData = {
        oldPassword: this.hashPassword(this.oldPassword),
        newPassword: this.hashPassword(this.newPassword),
      }

      this.callApi(this.$constant.CHANGE_PASSWORD_URL,
        requestData,
        (resultData) => {
          this.$store.commit('logout')

          this.showSnackbar = true
          this.snackbarText = this.$t('pwChanged')

          this.$router.push({path: '/'})
        },
        () => {
          
        }
      )
    }
  },
  computed: {
    isDisabled: function() {
      return this.isEmptyString(this.oldPassword) || this.isEmptyString(this.newPassword) || this.isEmptyString(this.confirmNewPassword) || !this.isFormValid
    },
  },
  watch: {
    newPassword: function(newVal) {
      this.$refs.form.validate()
    },
    confirmNewPassword: function(newVal) {
      this.$refs.form.validate()
    }
  }
}
</script>