<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-card-title class="text-uppercase">
        {{ $t('addUser') }}
      </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-form ref="form" v-model="isFormValid">
          <v-row>
            <v-col>
              <v-subheader>{{ $t('fillInfo') + ':' }}</v-subheader>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="username"
                :label="$t('username') + ':'"
                color="#2c76b8"
                clearable
                counter="50"
                maxlength="50"
                :rules="nameRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="lastName"
                :label="$t('lastName') + ':'"
                color="#2c76b8"
                clearable
                counter="50"
                maxlength="50"
                :rules="nameRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="firstName"
                :label="$t('firstName') + ':'"
                color="#2c76b8"
                clearable
                counter="100"
                maxlength="100"
                :rules="nameRules"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="email"
                :label="$t('email') + ':'"
                color="#2c76b8"
                :rules="emailRules"
                clearable
                counter="100"
                maxlength="100"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      
      <v-btn
        color="orange lighten-1"
        :dark="!isDisabled"
        :disabled="isDisabled"
        @click="confirm"
        :loading="isLoading"
      >
        {{ $t('confirm') }}
      </v-btn>
    </v-card-actions>

    <v-snackbar
			top
      v-model="showSnackbar"
      timeout="5000"
      color="orange lighten-1"
    >
      <span class="white--text">
        {{ snackbarText }}
      </span>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      isFormValid: false,
      username: null,
      lastName: null,
      firstName: null,
      email: null,
      showSnackbar: false,
      snackbarText: null,

      nameRules: [
        v => !v || /^[A-Za-z0-9 ]+$/.test(v) || this.$t('noSpecialCharacters')
      ],
      emailRules: [
        v => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('invalidEmail'),
      ],
    }
  },
  methods: {
    confirm() {
      this.isLoading = true

      var requestData = {
        username: this.username,
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email,
      }

      this.callApi(this.$constant.CREATE_APP_USER_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (!resultData.result) {
            this.showSnackbar = true
            this.snackbarText = resultData.remarks

            return
          }

          this.$refs.form.reset()
          this.$emit('close', true)
        },
        () => {
          this.isLoading = false
        }
      )
    },
    closeDialog() {
      this.$refs.form.reset()
      this.$emit('close')
    }
  },
  computed: {
    isDisabled: function() {
      return this.isEmptyString(this.username) || this.isEmptyString(this.lastName) || this.isEmptyString(this.firstName) || this.isEmptyString(this.email) || !this.isFormValid
    },
  }
}
</script>