// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// Axios
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);

// i18n
import i18n from "./assets/i18n/i18n";

// Global Mixins
import { utilityMixins } from "@/components/common/Utility.js";
Vue.mixin(utilityMixins);

// Constants
import * as constants from "./constants/constants.js";
Vue.prototype.$constant = constants;

import { apiServiceMixins } from "@/components/common/APIService.js";
Vue.mixin(apiServiceMixins);

// Youtube
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

// Vue-snip
import VueSnip from "vue-snip";
Vue.use(VueSnip);

// Drag and Drop list
import VueDND from "awe-dnd";
Vue.use(VueDND);

import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";

Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

router.afterEach((to, from) => {
  var metaTag = {};
  metaTag.title = to.meta.title;
  metaTag.description = to.meta.description;
  metaTag.image = to.meta.image;

  store.commit("setMetaTag", metaTag);
});

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event")),
  metaInfo() {
    return {
      title: this.$store.state.metaTag.title,
      meta: [
        { name: "description", content: this.$store.state.metaTag.description },
        { property: "og:title", content: this.$store.state.metaTag.title },
        { property: "og:url", content: this.$store.state.metaTag.url },
        {
          property: "og:description",
          content: this.$store.state.metaTag.description,
        },
        { property: "og:image", content: this.$store.state.metaTag.image },
        { property: "og:type", content: "website" },
        { property: "og:locale", content: "en_US" },
        { property: "og:site_name", content: "Ampower Talent" },
      ],
    };
  },
}).$mount("#app");
