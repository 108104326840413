<template>
  <v-card>
    <v-toolbar color="#2c76b8" dark>
      <v-badge
        inline
        :content="dataList != null ? dataList.length : 0"
        color="orange lighten-1"
        :value="dataList != null && dataList.length > 0"
      >
        <v-card-title class="text-uppercase">
          {{ status == 'A' ? $t('block') : $t('unblock') }}
        </v-card-title>
      </v-badge>

      <v-spacer></v-spacer>

      <v-btn
        icon
        dark
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            {{ status == 'A' ? $t('confirmBlock') : $t('confirmUnblock') }}
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      
      <v-btn
        color="orange lighten-1"
        dark
        @click="confirm"
        :loading="isLoading"
      >
        {{ $t('confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['appUserId', 'status'],
  data() {
    return {
      dataList: [],
      isLoading: false,
    }
  },
  methods: {
    confirm() {
      this.isLoading = true

      var requestData = {
        appUserId: this.appUserId,
      }

      this.callApi(this.status == 'A' ? this.$constant.BLOCK_USER_URL : this.$constant.UNBLOCK_USER_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          this.$emit('close', true)
        },
        () => {
          this.isLoading = false
        }
      )
    }
  },
  watch: {
    appUserId(newVal) {
      this.appUserId = newVal
    },
    status(newVal) {
      this.status = newVal
    }
  }
}
</script>