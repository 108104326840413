<template>
  <v-card>
		<v-toolbar color="#2c76b8" dark>
			<v-toolbar-title>
				{{ title }}
			</v-toolbar-title>
			<v-spacer />
			<v-btn
        v-if="showCloseBtn"
				icon
				dark
				@click="closeDialog"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

    <v-card-text>
			<v-container v-if="!isMobile">
        <v-row class="my-2" v-if="showAmpowerLogo">
          <v-img
            class="shrink mt-1"
            contain
            :src="require('@/assets/images/ampower_logo_wide.png')"
            transition="scale-transition"
            width="400"
          />
        </v-row>

        <v-row class="mt-2" >
          <v-col v-for="video in videoList" :key="video.id" :cols="12 / videoList.length">
            <v-card>
              <v-card-text class="pa-0">
                <youtube 
                  :ref="video.id"
                  :video-id="video.id" 
                  :player-vars="playerVars" 
                  @playing="playing"
                  @paused="paused"
                  @ended="ended"
                  @error="error"
                  class="mb-3"
                  fitParent  />
              </v-card-text>
              <v-card-subtitle>
                {{ video.title }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-else>
        <v-row class="my-2" v-if="showAmpowerLogo">
          <v-img
            class="shrink mt-1"
            contain
            :src="require('@/assets/images/ampower_logo_wide.png')"
            transition="scale-transition"
            width="400"
          />
        </v-row>

        <v-row class="mt-2" v-for="video in videoList" :key="video.id">
					<v-card class="mb-3">
            <v-card-text class="pa-0">
              <youtube 
                :ref="video.id"
                :video-id="video.id" 
                :player-vars="playerVars" 
                @playing="playing"
                @paused="paused"
                @ended="ended"
                @error="error"
                fitParent  />
            </v-card-text>
            <v-card-subtitle>
              {{ video.title }}
            </v-card-subtitle>
          </v-card>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['title', 'videoList', 'showAmpowerLogo', 'videoTitle', 'showClassReplay'],
  data() {
    return {
      playerVars: {
        autoplay: 0,
      },
      showCloseBtn: true,
    }
  },
  mounted() {
    if (this.showClassReplay) {
      this.showCloseBtn = false
    }
  },
  methods: {
    closeDialog(evt) {
      Object.keys(this.$refs).forEach(el => {
        var player = this.$refs[el][0].player
        if (player != undefined) {
          player.stopVideo()
        }
      })

      evt.preventDefault()
      this.$emit('close')
    },
    playing() {
      this.$emit('playing')
    },
    paused() {
      console.log('paused')
    },
    ended() {
      this.$emit('ended')
    },
    error() {
      console.log('error')
    }
  },
}
</script>