<template>
  <v-card width="1000">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t("careerManagement") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-text class="px-0"></v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <!-- careerName -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("jobTitle") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formData.careerNameEn"
              label="EN"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="formData.careerNameTc"
              label="繁"
              color="#2c76b8"
              clearable
              counter="200"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- Company -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("companyName") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="partnershipList"
              v-model="formData.partnershipId"
              :item-text="
                $i18n.locale == 'en' ? 'partnershipNameEn' : 'partnershipNameTc'
              "
              item-value="partnershipId"
              :rules="requiredRules"
            >
            </v-select>
          </v-col>
        </v-row>

        <!-- is Active -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("isActive") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              v-model="formData.isActive"
              row
              :rules="requiredRules"
            >
              <v-radio label="Y" value="Y"></v-radio>
              <v-radio label="N" value="N"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- jobDesc -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("jobDesc") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.careerDescEn"
              label="EN"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.careerDescTc"
              label="繁"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <!-- jobRequirements -->
        <v-row>
          <v-col>
            <h2>
              {{ $t("jobRequirements") }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="formData.careerRequirementsEn"
              label="EN"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
          <v-col>
            <v-textarea
              v-model="formData.careerRequirementsTc"
              label="繁"
              no-resize
              filled
              counter="2000"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="true"
        @click="uploadFile"
        :loading="isLoading"
      >
        {{ $t("submit") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      search: null,

      valid: false,
      requiredRules: [(v) => !!v || this.$t("requiredField")],

      file: null,
      formData: {
        careerNameEn: "",
        careerNameTc: "",

        partnership: {},
      },
      selected: null,
      partnershipList: [],
    };
  },
  created() {
    this.fetchPartnership();
    console.log("created formData", this.formData);

    this.formData = Object.assign(this.formData, this.data);
    if (this.data.imageB64) {
      this.b64ToFile(this.data.imageB64, (file) => {
        this.file = file;
      });
    }
  },
  methods: {
    fetchPartnership() {
      console.log("fetchPartnership");
      this.callApi(
        this.$constant.GET_PARTNERSHIP_LIST_URL,
        {},
        (resultData) => {
          console.log("22");
          this.partnershipList = resultData.partnershipList.map((item) => {
            return this.objectDecode(item);
          });

          console.log(this.partnershipList);

          this.isLoading = false;
        },
        (e) => {
          console.log(e);
          this.isLoading = false;
        }
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    closeDialog(evt) {
      evt.preventDefault();
      this.$emit("close");
    },
    fileChange() {
      if (this.file != null) {
        this.isWrittenDescnReq = false;

        const reader = new FileReader();
        reader.onload = (e) => {
          // this.submitMaterial = e.target.result;
          // this.submitMaterialFileName = this.file.name;
          // this.isWrittenDescnReq = true;
        };
        reader.readAsDataURL(this.uploadedFile);
      }
    },
    uploadFile() {
      this.validate();
      if (this.valid == false) {
        return;
      }
      this.isLoading = true;

      let enCodedFormData = this.formData; // this.objectEncode(this.formData);
      delete enCodedFormData.partnership;

      this.callApi(
        this.$constant.SAVE_CAREER_URL,
        {
          career: {
            ...enCodedFormData,
          },
        },
        (resultData) => {
          this.isLoading = false;

          let msg = this.$t("generalSaveSuccess", [
            this.$t("partnershipCareer"),
          ]);

          this.$root.$emit("popSnackBar", msg, "green");
          this.$emit("close");
        },
        () => {
          this.isLoading = false;
          this.$root.$emit("popSnackBar", "generalError", "error");
          this.formData = this.objectDecode(this.formData);
        }
      );
    },
  },
};
</script>
