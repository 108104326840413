<template>
  <v-card width="500">
    <v-toolbar color="#2c76b8" dark>
      <v-toolbar-title class="text-uppercase">
        {{ $t('course.uploadSurveyReport') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        dark
        @click="closeDialog"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card-subtitle>{{ $t('course.uploadCSVDescn') + ':' }}</v-card-subtitle>
      <v-card-text>{{ '*' + $t('engCsvOnly') }}</v-card-text>

      <v-file-input
        v-model="file"
        show-size
        truncate-length="30"
        :label="$t('course.csvFile')"
        color="#2c76b8"
        accept=".csv"
      />
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="orange lighten-1"
        :dark="file != null"
        @click="uploadFile"
        :loading="isLoading"
        :disabled="file == null"
      >
        {{ $t('upload') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ['courseId'],
  data() {
    return {
      file: null,
      isLoading: false,
    }
  },
  methods: {
    closeDialog(evt) {
			evt.preventDefault()
      this.$emit('close')
		},
    uploadFile() {
      const reader = new FileReader()
      reader.onload = e =>{
        var content = e.target.result
        var lines = content.split('\n')

        var submittedTimeLineNo = 0
        for (var line in lines) {
          if (lines[line].startsWith('Survey Details')) {
            submittedTimeLineNo = parseInt(line)
            break;
          }
        }

        var q1, q2, q3, q4, q5, q6, q7, q8

        var columnNo = 0, emailColumnNo = 0
        var columnNames = lines[submittedTimeLineNo + 1].split(',')
        for (var i = 0; i < columnNames.length; i++) {
          if (columnNames[i] == 'User Email') {
            emailColumnNo = i
          }

          if (columnNames[i] == 'Submitted Time') {
            columnNo = i

            q1 = columnNames[i + 1]
            q2 = columnNames[i + 2]
            q3 = columnNames[i + 3]
            q4 = columnNames[i + 4]
            q5 = columnNames[i + 5]
            q6 = columnNames[i + 6]
            q7 = columnNames[i + 7]
            q8 = columnNames[i + 8]
          }
        }

        var map = []
        for (var i = submittedTimeLineNo + 2; i < lines.length ; i++) {
          if (lines[i] == '') {
            continue
          }

          var temp = lines[i].match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g)

          map.push({
            email: temp[emailColumnNo],
            q1: q1,
            q2: q2,
            q3: q3,
            q4: q4,
            q5: q5,
            q6: q6,
            q7: q7,
            q8: q8,
            a1: temp[columnNo + 1],
            a2: temp[columnNo + 2],
            a3: temp[columnNo + 3],
            a4: temp[columnNo + 4],
            a5: temp[columnNo + 5],
            a6: temp[columnNo + 6],
            a7: temp[columnNo + 7],
            a8: temp[columnNo + 8],
          })
        }

        this.uploadSurveyReport(map)
      }
      reader.readAsText(this.file)    
    },
    uploadSurveyReport(map) {
      this.isLoading = true

      var requestData = {
        surveyList: map,
        courseId: this.courseId,
      }

      this.callApi(this.$constant.UPLOAD_SURVEY_REPORT_URL,
        requestData,
        (resultData) => {
          this.isLoading = false

          if (resultData.result) {
            this.$emit('close')
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
  }
}
</script>