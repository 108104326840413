<template>
  <v-app>
    <AppBar isBackend="true" @toggle="showDrawer = !showDrawer"/>

    <v-main>
      <v-navigation-drawer enable-resize-watcher app dark color="#2c76b8" v-model="showDrawer">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              TMS <span style="color: #FFD600">{{ $constant.isUAT ? '(UAT)' : '' }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('systemTitle') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
          flat          
        >
          <v-list-item>
            <v-list-item-title>
              {{ $t('functions') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item-group
            v-model="selectedItem"
            color="orange lighten-1"
            @change="listChange"
          >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <section style="position: absolute; bottom: 12px;">
          <v-divider />

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t('welcomeBack') + ',' }}
              </v-list-item-subtitle>

              <v-list-item-title>
                <div class="text-h5">{{userName}}</div>
              </v-list-item-title>

              <v-btn color="yellow accent-4" light class="mt-2" @click="showChangePwDialog = true">
                {{ $t('changePw') }}
              </v-btn>

              <v-dialog
                v-model="confirmLogoutDialog"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#FE253D"
                    class="mt-2" 
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('logout') }}
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar color="#2c76b8" dark>
                    <v-toolbar-title class="text-uppercase">
                      {{ $t('logout') }}
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                      icon
                      dark
                      @click="confirmLogoutDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          {{ $t('confirmLogout') }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="orange lighten-1"
                      text
                      @click="confirmLogoutDialog = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>

                    <v-btn
                      color="orange lighten-1"
                      dark
                      @click="logout"
                      :loading="isLoading"
                    >
                      {{ $t('confirm') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

        </section>
      </v-navigation-drawer>

      <v-container>
        <CourseManagement v-if="items[selectedItem].key === 'COURSE'" />
        <ParticipantManagement v-if="items[selectedItem].key === 'PARTICIPANT'" />
        <SchoolManagement v-if="items[selectedItem].key === 'SCHOOL'" />
        <CompetitionManagement v-if="items[selectedItem].key === 'COMPETITION'" />
        <CompetitionEnrollmentManagement v-if="items[selectedItem].key === 'COMPETITION_FORM'" />
        <PartnershipManagement v-if="items[selectedItem].key === 'PARTNERSHIP'" />
        <CareerManagement v-if="items[selectedItem].key === 'CAREER_PARTNERSHIP'" />
        <CareerExpManagement v-if="items[selectedItem].key === 'CAREER_EXP'" />
        <UserManagement v-if="items[selectedItem].key === 'USERS'" />
      </v-container>
    </v-main>

    <v-dialog
      v-model="showChangePwDialog"
      width="450"
      persistent
    >      
      <ChangePassword @close="showChangePwDialog = false" />
    </v-dialog>
  </v-app>
</template>

<script>
import AppBar from '@/views/AppBar'
import AppFooter from '@/views/AppFooter'
import CourseManagement from './course/CourseManagement'
import UserManagement from './user/UserManagement'
import ChangePassword from './user/ChangePassword'
import ParticipantManagement from './participants/ParticipantManagement.vue'
import SchoolManagement from './schools/SchoolManagement.vue'
// import PartnershipManagement from './partnership/PartnershipManagement.vue'
import PartnershipManagement from './partnership/PartnershipManagement.vue'
import CareerManagement from './career/CareerManagement.vue'
import CareerExpManagement from './careerExp/CareerExpManagement.vue'
import CompetitionManagement from './competition/CompetitionManagement.vue'
import CompetitionEnrollmentManagement from './competition/enrollment/CompetitionEnrollmentManagement.vue'

export default {
  components: {
    AppBar,
    AppFooter,
    CourseManagement,
    UserManagement,
    ChangePassword,
    ParticipantManagement,
    SchoolManagement,
    PartnershipManagement,
    CareerManagement,
    CareerExpManagement,
    CompetitionManagement,
    CompetitionEnrollmentManagement,
  },
  data () {
    return {
      selectedItem: 0,
      confirmLogoutDialog: false,
      userName: null,
      showDrawer: true,
      showChangePwDialog: false,
      isLoading: false,
    }
  },
  computed: {
    items: function() {
      var menuList = []

      if (this.checkAccessRight('COURSE', 'read')) {
        menuList.push({ key: 'COURSE', title: this.$t('courses'), icon: 'mdi-view-dashboard' })
      }

      if (this.checkAccessRight('PARTICIPANT', 'read')) {
        menuList.push({ key: 'PARTICIPANT', title: this.$t('participants'), icon: 'mdi-card-account-details' })
      }

      if (this.checkAccessRight('SCHOOL', 'read')) {
        menuList.push({ key: 'SCHOOL', title: this.$t('schools'), icon: 'mdi-school' })
      }

      if (this.checkAccessRight('COMPETITION', 'read')) {
        menuList.push({ key: 'COMPETITION', title: this.$t('competition'), icon: 'mdi-podium-gold'})
      }

      if (this.checkAccessRight('COMPETITION_FORM', 'read')) {
        menuList.push({ key: 'COMPETITION_FORM', title: this.$t('competitionForm'), icon: 'mdi-application-edit-outline'})
      }

      if (this.checkAccessRight('PARTNERSHIP', 'read')) {
        menuList.push({ key: 'PARTNERSHIP', title: this.$t('partnership'), icon: 'mdi-account-multiple'})
      }

      if (this.checkAccessRight('CAREER_PARTNERSHIP', 'read')) {
        menuList.push({ key: 'CAREER_PARTNERSHIP', title: this.$t('partnershipCareer'), icon: 'mdi-account-tie'})
      }

      if (this.checkAccessRight('CAREER_EXP', 'read')) {
        menuList.push({ key: 'CAREER_EXP', title: this.$t('careerExp'), icon: 'mdi-form-select'})
      }

      if (this.checkAccessRight('USERS', 'read')) {
        menuList.push({ key: 'USERS', title: this.$t('users'), icon: 'mdi-account-cog' })
      }

      return menuList
    }
  },
  methods: {
    logout: function() {
      this.isLoading = true

      var requestData = {
      }
      this.callApi(this.$constant.USER_LOGOUT_URL,
        requestData,
        (resultData) => {
          this.$store.commit('logout')
          //this.showNotification(this.$t('login.logoutSuccessful'), 'is-success')
          this.isLoading = false
          this.$router.push({path: '/'})
        },
        () => {
          this.isLoading = false
        }
      )
    },
    listChange() {
      if (this.selectedItem == undefined) {
        this.selectedItem = 0
      }
    }
  },
  mounted() {
    this.userName = this.$store.state.user.lastName +  " " + this.$store.state.user.firstName

    this.selectedItem = 0
  },
}
</script>