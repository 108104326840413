<template>
  <v-container style="max-width: 1400px" :class="isMobile ? 'mobile' : ''">
    <v-row>
      <v-col class="d-flex align-center">
        <v-img
          class="shrink mt-1 d-flex align-end text-right"
          contain
          :src="
            $constant.isUAT
              ? $i18n.locale == 'en'
                ? require('@/assets/images/competition_background_en_uat.png')
                : require('@/assets/images/competition_background_tc_uat.png')
              : $i18n.locale == 'en'
              ? require('@/assets/images/competition_background_en.png')
              : require('@/assets/images/competition_background_tc.png')
          "
          width="100%"
        >
          <v-row style="width: 100%" justify="end" v-if="!isMobile">
            <v-col cols="4" class="mr-3">
              <v-btn
                tile
                @click="showAboutOurProgramDialog = true"
                light
                color="white"
                block
                v-bind="size"
              >
                <v-icon left color="red" v-bind="size" class="mr-5">mdi-youtube</v-icon>
                {{ $t("aboutOurProgram") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row style="width: 100%; margin-bottom: 4%;" justify="end" v-if="!isMobile">
            <v-col cols="4" class="mr-3">
              <v-btn
                tile
                @click="showAboutOurCompetitionDialog = true"
                light
                color="white"
                block
                v-bind="size"
              >
                <v-icon left color="red" v-bind="size" class="mr-5">mdi-youtube</v-icon>
                {{ $t("aboutOurCompetition") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>

    <v-row v-if="isMobile" class="mt-3">
      <v-col>
        <v-btn tile @click="showAboutOurProgramDialog = true" light :size="size" color="white" block>
          <v-icon left color="red" :size="size" class="mr-5">mdi-youtube</v-icon>
          {{ $t("aboutOurProgram") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="isMobile" class="mb-3">
      <v-col>
        <v-btn tile @click="showAboutOurCompetitionDialog = true" light :size="size" color="white" block>
          <v-icon left color="red" :size="size" class="mr-5">mdi-youtube</v-icon>
          {{ $t("aboutOurCompetition") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row style="background-color: #f44336; margin: 12px -6px">
      <v-col class="text-center tabs">
        <span class="d-flex justify-center">
          <router-link class="text-decoration-none" to="/">
            <v-card-title
              class="py-1 font-weight-bold"
              style="line-height: 1.75rem; color: white"
            >
              {{ $t("latestClass") }}
            </v-card-title>
          </router-link>
        </span>
      </v-col>
    </v-row>

    <v-row style="background-color: #f44336; margin: 12px -6px">
      <v-col class="text-center tabs">
        <span class="d-flex justify-center" style="">
          <router-link class="text-decoration-none" to="/ClosingCeremony">
            <v-card-title
              class="py-1 font-weight-bold text-center"
              style="line-height: 1.75rem; color: white"
            >
              {{ $t("closingTitle1") }}<br />
              {{ $t("closingTitle2") }}
            </v-card-title>
          </router-link>
        </span>
      </v-col>
    </v-row>
    
    <v-row style="background-color: #f4dccf; margin: 12px -6px">
      <v-col class="d-flex justify-center align-center tabs">
        <v-card-title class="py-1 text-center" style="word-break: break-word">
          {{ $t("competitionName") }}<br />
          {{ `(${$t("submissionDeadline")} 11 Feb 2022)` }}
        </v-card-title>
      </v-col>
    </v-row>

    <!-- <v-row style="margin: 12px -6px">
      <v-col
        style="background-color: #f4dccf"
        class="d-flex justify-center align-center tabs"
      >
        <router-link class="text-decoration-none" to="/">
          <v-card-title class="py-1" style="line-height: 1.75rem; color: black">
            {{ $t("latestClass") }}<br />
            {{ `(${$t("submissionDeadline")} 11 Feb 2022)` }}
          </v-card-title>
        </router-link>
      </v-col>
      <v-col
        style="background-color: #f44336"
        class="d-flex justify-center align-center tabs"
      >
        <span class="d-flex justify-center">
          <v-card-title
            class="py-1 font-weight-bold"
            style="line-height: 1.75rem; color: white"
          >
            {{ $t("competitionName") }}
          </v-card-title>
        </span>
      </v-col>
    </v-row> -->

    <!-- <v-row>
      <v-col>
        <div class="d-flex align-start">
          <div>
            <v-icon class="pa-0" color="yellow darken-2"> mdi-star </v-icon>
            <p class="mb-0 ml-2">選擇⼀個或多個比賽類別:</p>
          </div>
          <div>
            <p class="mb-0 ml-2">
              合共有 5 個類別可供選擇，分別與不 同的技能組合相關。學⽣可以選擇參
              加⼀個或多個類別。每個類別都有各 ⾃的要求、先決條件和評分指南。下
              ⾯將列出每個類別的詳細資訊。每個 類別都迎合不同的興趣和技能組合，
              總體⽬標是幫助參加者更了解⾃⼰的 職業規劃。
            </p>
          </div>
        </div>
      </v-col>
    </v-row> -->

    <v-row style="margin: 12px -6px">
      <img :src="image != null ? image : $i18n.locale == 'en' ? require('@/assets/images/competition_poster_en.png') : require('@/assets/images/competition_poster_tc.png')" style="object-fit: contain; max-width: 100%;"/>
    </v-row>

    <v-row style="margin: 12px -6px">
      <h2 class="">
        {{ $t("compititionGuide") }}
      </h2>
    </v-row>
    
    <v-row justify="center" style="margin: 12px -6px">
      <v-expansion-panels v-model="panel" accordion>
        <v-expansion-panel v-for="(item, i) in competitionGudies" :key="i">
          <v-expansion-panel-header>{{
            item[$i18n.locale].title
          }}</v-expansion-panel-header>
          <v-expansion-panel-content style="white-space: pre-line">
            {{ item[$i18n.locale].content }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <v-row class="mb-4" justify="end" style="margin: 12px -6px">
      <v-btn
        :href="getPdfLink()"
        color="primary"
        download="COMPETITION_GUIDELINES.pdf"
      >
        <v-icon left> mdi-file </v-icon>
        {{ $t("downloadPdfBtn") }}
      </v-btn>
    </v-row>

    <v-row>
      <v-col v-if="isLoading" class="text-center mt-5 mb-5">
        <h2>
          {{ $t("loading") + "..." }}
        </h2>
        <v-progress-linear indeterminate color="orange lighten-1" />
      </v-col>

      <v-col
        v-else-if="competitionData != null"
        :cols="isMobile ? 'auto' : 4"
        v-for="value in competitionData"
        v-bind:key="value.competitionId"
      >
        <CompetitionCard
          :competitionId="value.competitionId"
          :title="$i18n.locale == 'en' ? value.nameEn : value.nameTc"
          :scenario="$i18n.locale == 'en' ? value.scenarioEn : value.scenarioTc"
          :image="value.imageBase64"
          :date="value.deadlineDate"
          :no="value.categoryNo"
          :loading="isLoading"
        />
      </v-col>
    </v-row>

    <!-- DetailsDialog -->
    <v-dialog
      v-if="detailsDialog"
      v-model="detailsDialog"
      width="700"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar color="#2c76b8" dark style="height: auto">
          <v-toolbar-title class="text-uppercase">
            {{ $t("competitionDetails") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="text--primary">
          <v-container>
            <v-row>
              <v-col class="py-3">
                <img
                  :src="showCompetition.imageBase64"
                  style="width: 100%; object-fit: contain; max-width: 100%"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card-title class="d-block pa-0 text-break">
                  {{
                    $i18n.locale == "en"
                      ? showCompetition.nameEn
                      : showCompetition.nameTc
                  }}
                </v-card-title>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('deadlineDate') + ':'"
                  :content="
                    formatDate(
                      showCompetition.deadlineDate,
                      this.$i18n.locale == 'en' ? 'DD MMM YYYY' : 'YYYY年MMMD日'
                    )
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('scenario') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showCompetition.scenarioEn
                      : showCompetition.scenarioTc
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('mission') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showCompetition.missionEn
                      : showCompetition.missionTc
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('toBeInc') + ':'"
                  :content="
                    $i18n.locale == 'en'
                      ? showCompetition.toBeIncludedEn
                      : showCompetition.toBeIncludedTc
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <DisplayText
                  :title="$t('recommendedMasterClasses') + ':'"
                  :content="getRecommendMasterClassesDesc()"
                  :onBtnClick="(item) => {
                    $router.push({
                      name: 'Welcome', 
                      params: {
                        courseName: item.split('(')[0]
                      } 
                    })}"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end">
          <v-btn text color="orange lighten-1" @click="detailsDialog = false">
            {{ $t("close") }}
          </v-btn>

          <v-btn
            v-if="!$constant.isStaffApp"
            color="green lighten-1"
            dark
            @click="applyCompetition(showCompetition.competitionId)"
          >
            {{ $t("apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAboutOurProgramDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @close="showAboutOurProgramDialog = false"
        :videoList="programVideoList"
        :title="$t('aboutOurProgram')"
        :showAmpowerLogo="true"
      />
    </v-dialog>

    <v-dialog
      v-model="showAboutOurCompetitionDialog"
      :width="isMobile ? 'unset' : '50vw'"
      persistent
    >
      <YoutubePlayer
        @close="showAboutOurCompetitionDialog = false"
        :videoList="competitionVideoList"
        :title="$t('aboutOurCompetition')"
        :showAmpowerLogo="true"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import CompetitionCard from "@/views/competition/CompetitionCard";
import DisplayText from "@/components/DisplayText";
import YoutubePlayer from "@/components/YoutubePlayer";

const pdf_en = require("@/assets/pdf/COMPETITION_en.pdf");
const pdf_tc = require("@/assets/pdf/COMPETITION_tc.pdf");

export default {
  name: "CompetitionContent",
  components: {
    CompetitionCard,
    DisplayText,
    YoutubePlayer,
  },
  data() {
    return {
      panel: [0],
      isLoading: false,
      showCompetition: null,
      detailsDialog: false,
      competitionData: [],
      checkedData: 0,
      showAboutOurProgramDialog: false,
      showAboutOurCompetitionDialog: false,
      programVideoList: [
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=rYLufuUEF7o"),
          title:
            "Ampower Talent Institute資訊科技大師班課程之《解構未來》Master Classes to Broaden IT & Tech Career Options Trailer",
        },
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=ZTy-P4jBMfU"),
          title:
            "Ampower Talent Institute資訊科技大師班之《傳授功力》Work Experience to Reach IT & Tech Career Goals Trailer",
        },
      ],
      competitionVideoList: [
        {
          id: this.getYoutubeId("https://www.youtube.com/watch?v=RtYKlbU34WA"),
          title:
            "Ampower Talent Institute Career Planning App (POC) Design Competition「職業規劃應用程式(概念化)設計比賽」",
        },
      ],
      competitionGudies: [
        {
          en: {
            title: "1：Choose One or More Competition Categories",
            content:
              "There are 5 categories to choose from which relates to different skillsets. Students may choose to enter one or more categories. Each category has its own requirements, pre-requisites and grading guidelines. Details of each category are listed below. Each of the categories caters to different interests and skill sets with an overall aim of helping participants understand more about their own career planning.",
          },
          tc: {
            title: "1：選擇⼀個或多個比賽類別",
            content:
              "合共有 5 個類別可供選擇，分別與不同的技能組合相關。學⽣可以選擇參加⼀個或多個類別。每個類別都有各⾃的要求、先決條件和評分指南。下⾯將列出每個類別的詳細資訊。每個類別都迎合不同的興趣和技能組合，總體⽬標是幫助參加者更了解⾃⼰的職業規劃。",
          },
        },
        {
          en: {
            title: "2：Decide Entry Formation",
            content:
              "Entry formation for each category may be submitted as an Individual; or Team (comprising of not more than 4 persons within a team). Team composition need not be students from the same year grade but must be students from the same school.",
          },
          tc: {
            title: "2：參賽組合",
            content:
              "每個類別的參賽作品可以 個⼈名義；或 以團隊⽅式（團隊不可超過 4 ⼈）提交。團隊組成不必是同⼀年級的學⽣，但必須是同⼀間學校的學⽣。",
          },
        },
        {
          en: {
            title:
              "3a：Submission Format For Primary Students (Primary 5 - Primary 6 Students)",
            content:
              "Entries may be submitted in any of the following formats as: (a) PowerPoint format (saved as PDF version) not more than 10 pages; (b) hand drawings (PDF version); OR (c) a video clip not longer than 5 mins TOGETHER WITH a written description of not more than 250 words/characters describing the design thinking, uniqueness and effectiveness of your proposed solution. Late entries will not be entertained. Submissions may be in English or Chinese. All competition submission information should be uploaded as one complete set with a fully completed application form to the link provided in NOTE 4. Additional information submitted separately will not be considered.",
          },
          tc: {
            title: "3A：⼩學學⽣的提交格式（⼩五 - ⼩六年級學⽣）",
            content:
              "參賽作品可透過以下任何⼀種格式提交：(a) PowerPoint 格式（另存為PDF 版本）不超過 10 ⾴； (b) ⼿繪圖（PDF 版本）；或 (c) ⼀段不超過5分鐘的短片以及不超過 250 字的書⾯描述，描述您提出的建議⽅案的設計思維、獨特性和有效性。逾期報名將不獲受理。參賽作品以英文或中文提交均可。所有提交的資料應與完整填寫的比賽申請表⼀同上傳到 （注釋4） 中提供的連結。個別分開提交的資料將不被考慮。",
          },
        },
        {
          en: {
            title:
              "3b：Submission Format For Secondary Students (Form 1 – Form 6 Students)",
            content:
              "Entries may be submitted in any of the following formats as: (a) PowerPoint format (saved as PDF version) not more than 20 pages; (b) hand drawings (PDF version); OR (c) a video clip not longer than 8 mins TOGETHER WITH a written description of not more than 500 words/characters describing the design thinking, uniqueness and effectiveness of your proposed solution. Submissions may be in English or Chinese. All competition submission information should be uploaded as one complete set with a fully completed application form to the link provided in NOTE 4. Additional information submitted separately will not be considered.",
          },
          tc: {
            title: "3B：中學學⽣的提交格式（中⼀ - 中六年級學⽣）",
            content:
              "參賽作品可透過以下任何⼀種格式提交： (a) PowerPoint 格式（另存為PDF 版本）不超過 20 ⾴； (b) ⼿繪圖（PDF 版本）；或 (c) ⼀段不超過8 分鐘的短片以及不超過 500 字的書⾯描述，描述您提出的建議⽅案的設計思維、獨特性和有效性。逾期報名將不獲受理。可以英文或中文⽅式提交。所有提交的資料應與完整填寫的比賽申請表⼀同上傳到 （注釋4） 中提供的連結。個別分開提交的資料將不被考慮。",
          },
        },
        {
          en: {
            title: "4：Submission Procedure & Results",
            content:
              "- Deadline for submissions is 11 Feb 2022 \n- Fill in online Contest Application Form by scanning the Competition Application QR code \n- Upload your submission on the link provided in the QR code \n- Winners will be published on www.AmpowerTalent.org in Feb 2022 \n- You may also check your status with the confirmation code sent to your specified email \n- Winners will be offered company visits, internships or job shadow opportunities at highly sought after companies",
          },
          tc: {
            title: "4：提交程序和結果",
            content:
              "- 截⽌⽇期為2022年2⽉11⽇\n- 掃描比賽⼆維碼並填寫網上比賽申請表\n- 於⼆維碼中提供的連結上傳您的申請\n- 得獎名單將於 2022 年 2 ⽉於www.AmpowerTalent.org 公佈\n- 您也可以使⽤發送到您指定電⼦郵件的確認碼來查閱您的狀態\n- 得獎者將獲得參觀公司、實習或影⼦⼯作機會",
          },
        },
        {
          en: {
            title: "5：Competition Briefing – What You Need To Know",
            content:
              "Congratulations! You are now part of a virtual IT team with a mission to build a Career Planning App that will help all youths around the world to identify and understand their personality traits and skills that will help them navigate their path to career planning in realizing their dream job.\n\nEach of you may select one or more of the categories set out below and design your Proof of Concept(s) which you believe will make this the best Career Planning app to help the next generation.\n\nEach of the categories will have a mission to accomplish and marks will be awarded in accordance with the Marking Scheme set out in NOTE 6 below.",
          },
          tc: {
            title: "5：比賽簡介——參賽須知",
            content:
              "恭喜您！您現在是虛擬 IT 團隊的⼀分⼦，您的使命是建構職業規劃應⽤程式，以協助世界各地所有年輕⼈識別和了解他們的個性特徵和技能，從⽽幫助他們在職業規劃路上找到⾃⼰的夢想⼯作。\n\n您們每個⼈都可以選擇下列的⼀個或多個比賽類別，並設計您⼼⽬中的應⽤程式，\n\n想想如何將您的想法轉化成為幫助下⼀代的最佳職業規劃應⽤程式。每個類別都有⼀個必須完成的任務，並且將根據下⾯註釋 6 中規定的評分⽅案評分。",
          },
        },
        {
          en: {
            title: "6：Understand The Marking Scheme (Total 100%)",
            content:
              "25% - Design Thinking, Creativity, Innovation & Effectiveness of Solution\n25% - Clarity of Presentation & Completeness of Submission\n25% - Written Description of Solution Within Word/Character Limit\n25% - Incorporation of ABCD Elements, Privacy-by-design, Cybersecurity by Design and Open Data",
          },
          tc: {
            title: "6：了解評分準則（總分 100%）",
            content:
              "25% - 設計思維、創意、創新和建議⽅案的有效性\n25% - 陳述的清晰性及遞交申請的完整性\n25% - 建議⽅案的書⾯說明不應超出字數限制\n25% - 引入 ABCD 元素、隱私設計、網絡安全設計和開放數據",
          },
        },
      ],
      pdfLink: require("@/assets/pdf/COMPETITION_tc.pdf"),
      image: null,
    };
  },
  methods: {
    toggleSelect(courseId, checked) {
      var c = this.competitionData.find(
        (c) => c.competitionId == competitionId
      );
      c.checked = checked;

      var tempList = this.competitionData.filter((c) => c.checked);
      this.checkedData = tempList.length;

      this.detailsDialog = false;
    },
    showDetails: function (id) {
      this.showCompetition = this.competitionData.find(
        (c) => c.competitionId == id
      );
      this.detailsDialog = true;
    },
    applyCompetition(competitionId) {
      this.$store.commit("clearCompetition");

      var cmpt = this.competitionData.find(
        (c) => c.competitionId == competitionId
      );
      cmpt.checked = true;

      this.$store.commit("setCompetition", cmpt);

      this.$router.push({ path: "/ApplyCompetition" });
    },
    getPdfLink() {
      if (this.$i18n.locale == "en") {
        return pdf_en;
      } else {
        return pdf_tc;
      }
    },
    getRecommendMasterClassesDesc() {
      const locale = this.$i18n.locale;
      return locale == "en"
        ? [
            "The Art of Design Thinking – Understanding Users’ Needs & Designing the Right Graphical User Interfaces to Improve User Experience & Stickiness (Prof Rong Zhang, HKUST)",
            "Design Thinking Master Class (David Chung, InnoEdge Consulting)",
            "Creative, Character Design and Animation Design (Gordon Chiu, Asia Animation)",
            "The Art of Character Animation (Sunny Tang, Act Plus Education Foundation)",
            "Privacy by Design Workshop, Your Rights & Responsibilities in the Digital World from a Privacy Perspective (Ada Chung, Privacy Commissioner for Personal Data; Tony Wong JP, OGCIO)",
            "Cyber Security By Design Workshop (Dr.FrankLaw, Hong Kong Police Force; Frank Chow, Ping An One Connect Bank)",
            "The Innovation & Technology Development and Opportunities in HK (Alfred Sit JP, Innovation & Technology Bureau)",
            "Power & Ethics of Artificial Intelligence (Prof Pascale Fung, HKUST)",
            "Power & Responsibility of Using Artificial Intelligence (Darren Chiu, Google Hong Kong)",
            "Blockchain, Distributed Ledger and Data Integrity (John Fuego, NECHongKong)",
            "Harnessing and Adopting Cloud Computing (FredSheu, Microsoft Hong Kong)",
            "BigData, Smart Data & Open Data in a Smart City (Gary Yeung MH, The Smart City Consortium; Victor Lam JP, OGCIO)",
            "How does Intellectual Property Light Up Your Creativity? (David Wong JP, Intellectual Property Department; Michael Li & Simon Wong, Titanology)",
          ]
        : [
            "設計思維的藝術–了解用戶需求並設計正確的圖形用戶界面以改善用戶體驗和黏著度(張榮教授, 香港科技大學)",
            "設計思維大師班(鍾大為,創峰顧問)",
            "創作、角色設計和動畫設計(錢國棟,亞洲動畫多媒體)",
            "角色動畫的藝術與發展(鄧日昇,樂言社教育基金)",
            "貫徹私隱設計研討會，從私隱的角度，探討數碼世界中的權利與責任 (鍾麗玲, 個人資料私隱專員;黃志光 JP, 政府資訊科技辦公室)",
            "網絡安全設計工作坊(羅越榮博士,香港警務處;周啟發,平安壹賬通銀行)",
            "香港的創科發展與機遇(薛永恒JP,創新及科技局)",
            "人工智能的力量與倫理準則(馮雁教授,香港科技大學)",
            "使用人工智能的權力和責任(趙善弘,Google香港)",
            "區塊鏈、分佈式分類賬與數據完整性(傅偉高,日本電氣香港)",
            "雲端計算的有效運用(許遵發,Microsoft香港)",
            "智慧城市中的大數據、智能數據與開放數據(楊文銳MH,智慧城市聯盟;林偉喬JP,政府資訊科技辦公室)",
            "知識產權如何燃亮創意?(黃福來JP,知識產權署;李鍵邦及黃深銘,鈦硏科技)",
          ];
    },
  },
  mounted() {
    this.isLoading = true;

    var requestData = {};

    this.callApi(
      this.$constant.GET_ALL_COMPETITION_URL,
      requestData,
      (resultData) => {
        this.competitionData = resultData.competitionList;

        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  },
  computed: {
    size () {
      const size = {xs:'x-small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 85%;
}

.v-input--checkbox .v-input__control .v-messages {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.theme--dark.v-badge .v-badge__badge::after {
  border-color: white !important;
}
.course-descn {
  line-height: 1.7rem;
}
.mobile {
  .tabs {
    .v-card__title {
      font-size: 0.95rem;
      line-height: 1.25rem !important;
      padding: 8px;
      word-break: break-word;
    }
  }
}
</style>
